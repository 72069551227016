import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import { format, subDays} from 'date-fns';
import { resize, hideSidebar } from '../../settings/resize';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Input, ConfigProvider, Select, Space, Button, Modal } from "antd";

const today = format(new Date(), 'dd-MMM-yyyy');
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy');

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    action: string,
}

const ConfigurationLogReport: FC<Props> = ({ globalData, action}) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    const [selector, setSelector] = useState(false)
    const [logReqBody, setlogReqBody] = useState<any>({
        orgId: 0, batteryId: "", vehicleId: "",
        locationId: -1, buildingId: -1, divisionId: -1, pageOffset: 1, pageLimit: 10, sortColumns: "",
        sortDirection: "", timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, serialNumber: "",
        batteryRxSim: ""
    })
    const selectorRef = useRef<HTMLDivElement>(null)
    const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);

    const [pageSize, setPageSize] = useState<number>(10)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [current, setCurrent] = useState<number>(1)
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [configlogs, setConfigLogs] = useState<string[]>([]);
    const [clearAll, setClearAll] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [alert, setAlert] = useState<boolean>(false);
    const [sort, setSort] = useState<any>({
        batteryId: false, vehicleId: false, serialNumer: false, batteryRxSim: false, logNumber: false, datetime: false, batteryType: false, totalCells: false, capacity: false, startcurrlmt: false,
        socLimit: false, maxahrsBtwEQ: false, maxdaysBteEq: false, eqStartDay: false, eqStartTime: false, eqStartTimeDesc: false, eqEndtDay: false, eqEndTime: false, eqEndTimeDesc: false, internalResistance: false, tarqVoltLimit: false, tempFbk: false,
        runTimeCurrThresh: false, keyonDect: false, keyonLogic: false, keyonCurr: false, parasiticCurr: false, Gmtoffset: false, dailyCallTime: false, lowSocLmt: false, highVoltLmt: false, lowVoltLmt: false,
        highC: false, lowC: false, maxChargeCurrLmt: false, maxDaysSinceCompEq: false, packVs: false, EqCurrRange: false, MaxDayBtwChg: false, org: false, div: false, loc: false, site: false, updatedDate: false
    })
    const [initial, setInitial] = useState<boolean>(true);
    const [emptyError, setemptyError] = useState<string>("");
    const [column, setColumn] = useState<any[]>([])
    const [selectorLabel, setSelectorLabel] = useState<any[]>([])

    const [tableHeight, setTableHeight] = useState<any>(445)
    const filtersRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
                callDebounce()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useLayoutEffect(() => {
        resize();
        hideSidebar();
        callDebounce()
    }, []);

    const callDebounce = () => {
        //if(window.innerHeight<=568) return
        let marginAndPaddings: any = 80
        let navHeight = localStorage.getItem('navHeight')
        let filtersHeight
        let paginationHeight
        if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
        if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
        let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
        let tableHeight = window.innerHeight - subHeight
        tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
    };

    const [searchObject, setSearchObject] = useState<any>({
        batteryId: '',
        vehicleId: '',
        batteryRxSim: '',
        serialNumber: ''
    });

    const DropList = [
        { label: "Battery Id" },
        { label: "Vehicle ID" },
        { label: "Battery Rx SIM" },
        { label: "Battery Rx Serial Number" }

    ];

    const [dropdownvalue, setDropDownValue] = useState<string>("Battery Id");
    const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
    const [inputvalue, setInput] = useState<any>("")

    const onChangedropdown = (value: string) => {
        setemptyError("")
        setDropDownValue(value);
        setPlaceholder(`Search ${value}`)
        setInput("");
        setSearchObject({
            batteryId: '',
            vehicleId: '',
            batteryRxSim: '',
            serialNumber: ''
        })

    }

    const onChangePayload = (value: any) => {
        let obj = { ...searchObject };
        if (dropdownvalue === "Battery Id") {
            obj.batteryId = value;
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Battery Rx Serial Number") {
            obj.batteryRxSim = value;
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Vehicle ID") {
            obj.vehicleId = value;
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Battery Rx SIM") {
            obj.serialNumber = value;
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        }
        setemptyError("")
    }

    const callCountAndReport = (obj: any) => {
        setlogReqBody({ ...logReqBody, ...obj })
    };

    // Search Enter Button
    const onPressEnter = (event: any) => {
        let value = event.target.value.trim()
        let obj = { ...searchObject };
        switch (dropdownvalue) {
            case 'Battery Id':
                obj.batteryId = value;
                break;
            case 'Battery Rx Serial Number':
                obj.batteryRxSim = value;
                break;
            case 'Vehicle ID':
                obj.vehicleId = value;
                break;
            case 'Battery Rx SIM':
                obj.serialNumber = value;
                break;
        }
        setSearchObject({ ...obj })
        setlogReqBody({ ...logReqBody, ...obj, pageOffset: 1 })

    };

    const handleSearch = () => {
        const { batteryId, vehicleId, batteryRxSim, serialNumber } = searchObject
        let obj = { ...searchObject }
        switch (dropdownvalue) {
            case 'Battery Id':
                if (!batteryId) {
                    setemptyError("Please Enter Battery Id")
                    return
                } else {
                    obj.batteryId = inputvalue.trim();
                }
                break;
            case 'Battery Rx Serial Number':
                if (!batteryRxSim) {
                    setemptyError("Please Enter Battery Rx Serial Number")
                    return
                } else {
                    obj.batteryRxSim = inputvalue.trim();
                }
                break;
            case 'Battery Rx SIM':
                if (!serialNumber) {
                    setemptyError("Please Enter Battery Rx SIM")
                    return
                } else {
                    obj.serialNumber = inputvalue.trim();
                }
                break;
            case 'Vehicle ID':
                if (!vehicleId) {
                    setemptyError("Please Enter Vehicle ID")
                    return
                } else {
                    obj.vehicleId = inputvalue.trim();
                }
                break;
            default:
                setemptyError("")
        }
        setSearchObject({ ...obj })
        setlogReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
    }

    useEffect(() => {
        let date1: any = value && format(value[0], 'dd-MMM-yyyy');
        let date2: any = value && format(value[1], 'dd-MMM-yyyy');
        if (date1 && date2) {
            daysFilter(date1 ?? sevenDaysAgo, date2 ?? today);
        }
    }, [value])

    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setPageSize(numPageSize)
        setCurrent(1);
        setlogReqBody({ ...logReqBody, pageLimit: numPageSize, pageOffset: 1 })
    }

    const handlePagination = (pageNumber: number) => {
        setlogReqBody({ ...logReqBody, pageOffset: pageNumber });
        setCurrent(pageNumber);
    }

    const daysFilter = (startDate: string, endDate: string) => setlogReqBody({ ...logReqBody, startDate, endDate });

    const selectColumn = () => {
        if (selectorRef.current && selector) {
            selectorRef.current.style.right = "-370px";
        } else {
            if (selectorRef.current) { selectorRef.current.style.right = "0px" }
        }
        setSelector(!selector);
    };

    const applyChanges = async () => {
        selectColumn()
        var columnPayload = {
            reportId: 'CONFIGURATION_LOG_REPORT',
            reportName: 'CONFIGURATION_LOG_REPORT',
            columnSettings: selectorLabel,
            sortColumn: logReqBody?.sortColumns,
            sortDirection: logReqBody?.sortDirection
        };
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;
        setIsShowSpinner(true);
        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}`,
                method: "PUT",
                headers: { Authorization: "Bearer " + authToken },
                data: columnPayload
            })
            setIsShowSpinner(false)
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn([...columnData])
            setSelectorLabel([...selectorData])
        }
        catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    };

    const onchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let columndata: any = selectorLabel
        let index: number = parseInt(event.target.name);
        let checked: boolean = event.target.checked;
        columndata[index].enabled = checked
        setSelectorLabel([...columndata])
        setClearAll(false)
        setSelectAll(false)
    };

    const clearall = () => {
        let columnLable = selectorLabel
        let lengths = columnLable.length
        for (let i = 0; i < lengths; i++) {
            columnLable[i]['enabled'] = false;
        }
        setSelectorLabel([...columnLable])
        setClearAll(true)
        setSelectAll(false)
    }

    const selectall = () => {
        let columnLable = selectorLabel
        let lengths = columnLable.length
        for (let i = 0; i < lengths; i++) {
            columnLable[i]['enabled'] = true;
        }
        setSelectorLabel(columnLable)
        setClearAll(false)
        setSelectAll(true)
    }

    const cancelSelection = () => {
        let columnData = selectorLabel;
        let lengths = columnData.length
        for (let i = 0; i < lengths; i++) {
            columnData[i]['enabled'] = columnData[i].enabled;
        }
        setSelectorLabel(columnData)
        selectColumn()
    };


    const getReportSettings = async () => {
        const reportId = 'CONFIGURATION_LOG_REPORT'
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let org = data?.orgId > -1 ? data?.orgId : userId;

        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
                method: "GET",
                headers: { Authorization: "Bearer " + authToken },
            })
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn(columnData)
            setSelectorLabel(selectorData)

            setlogReqBody({ ...logReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            setInitial(false)
        }
        catch (error) {
            console.log(error)
        }
    };

    const getConfigLogs = async () => {
        let payload = logReqBody;
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);


        if (payload.batteryId === "") payload.batteryId = data?.batteryId.trim();
        if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();

        if (payload.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.configurationLogReport.getLogs,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token }
            });

            setConfigLogs(response)
            setIsShowSpinner(false)
        } catch (error) {
            console.error("systemSummaryErr:", error);
            setIsShowSpinner(false)
        }
    }

    const getConfigLogsCount = async () => {
        let payload = logReqBody;
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);


        if (payload.batteryId === "") payload.batteryId = data?.batteryId.trim();
        if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();

        if (payload.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        try {
            const response = await service({
                url: constantUrl.api.configurationLogReport.getLogsCount,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token }
            });
            setTotalCount(response)
        } catch (error) {
            console.error("systemSummaryErr:", error);
        }
    }

    useEffect(() => {
        if (action !== "") {
            getDowFormat(action)
        }
    }, [action])

    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]
        switch (id) {
            case "batteryId":
                if (sort.batteryId) {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "vehicleId":
                if (sort.vehicleId) {
                    setlogReqBody({ ...logReqBody, sortColumns: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "serialNumer":
                if (sort.serialNumer) {
                    setlogReqBody({ ...logReqBody, sortColumns: "deviceSerialNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "deviceSerialNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "batteryRxSim":
                if (sort.batteryRxSim) {
                    setlogReqBody({ ...logReqBody, sortColumns: "serialNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "serialNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "logNumber":
                if (sort.logNumber) {
                    setlogReqBody({ ...logReqBody, sortColumns: "logNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "logNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "datetime":
                if (sort.datetime) {
                    setlogReqBody({ ...logReqBody, sortColumns: "timesStamp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "timesStamp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "batteryType":
                if (sort.batteryType) {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryTypeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryTypeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "totalCells":
                if (sort.totalCells) {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalCells", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "totalCells", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "capacity":
                if (sort.capacity) {
                    setlogReqBody({ ...logReqBody, sortColumns: "capacity", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "capacity", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "startcurrlmt":
                if (sort.startcurrlmt) {
                    setlogReqBody({ ...logReqBody, sortColumns: "startCurrentLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "startCurrentLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "socLimit":
                if (sort.socLimit) {
                    setlogReqBody({ ...logReqBody, sortColumns: "socLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "socLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "maxahrsBtwEQ":
                if (sort.maxahrsBtwEQ) {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxAhBetweenEQ", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxAhBetweenEQ", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "maxdaysBteEq":
                if (sort.maxdaysBteEq) {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxDaysBetweenEQS", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxDaysBetweenEQS", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "eqStartDay":
                if (sort.eqStartDay) {
                    setlogReqBody({ ...logReqBody, sortColumns: "startDayDescription", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "startDayDescription", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "eqStartTime":
                if (sort.eqStartTime) {
                    setlogReqBody({ ...logReqBody, sortColumns: "eqStartTimeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "eqStartTimeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "eqEndtDay":
                if (sort.eqEndtDay) {
                    setlogReqBody({ ...logReqBody, sortColumns: "endDayDescription", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "endDayDescription", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "eqEndTime":
                if (sort.eqEndTime) {
                    setlogReqBody({ ...logReqBody, sortColumns: "eqEndTimeDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "eqEndTimeDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "internalResistance":
                if (sort.internalResistance) {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryInternalOhms", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "batteryInternalOhms", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "tarqVoltLimit":
                if (sort.tarqVoltLimit) {
                    setlogReqBody({ ...logReqBody, sortColumns: "targetVoltageLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "targetVoltageLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "tempFbk":
                if (sort.tempFbk) {
                    setlogReqBody({ ...logReqBody, sortColumns: "temperatureFoldBackDesc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "temperatureFoldBackDesc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "runTimeCurrThresh":
                if (sort.runTimeCurrThresh) {
                    setlogReqBody({ ...logReqBody, sortColumns: "runCurrentThreshold", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "runCurrentThreshold", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "keyonDect":
                if (sort.keyonDect) {
                    setlogReqBody({ ...logReqBody, sortColumns: "detectionTypeDescription", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "detectionTypeDescription", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "keyonLogic":
                if (sort.keyonLogic) {
                    setlogReqBody({ ...logReqBody, sortColumns: "logicTypeDescription", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "logicTypeDescription", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "keyonCurr":
                if (sort.keyonCurr) {
                    setlogReqBody({ ...logReqBody, sortColumns: "keyOnThreshold", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "keyOnThreshold", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "parasiticCurr":
                if (sort.parasiticCurr) {
                    setlogReqBody({ ...logReqBody, sortColumns: "parasiticCurrent", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "parasiticCurrent", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "Gmtoffset":
                if (sort.Gmtoffset) {
                    setlogReqBody({ ...logReqBody, sortColumns: "msTimezoneId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "msTimezoneId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "dailyCallTime":
                if (sort.dailyCallTime) {
                    setlogReqBody({ ...logReqBody, sortColumns: "dailyCallTime", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "dailyCallTime", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "lowSocLmt":
                if (sort.lowSocLmt) {
                    setlogReqBody({ ...logReqBody, sortColumns: "lowSocLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "lowSocLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "highVoltLmt":
                if (sort.highVoltLmt) {
                    setlogReqBody({ ...logReqBody, sortColumns: "highVoltageLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "highVoltageLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "lowVoltLmt":
                if (sort.lowVoltLmt) {
                    setlogReqBody({ ...logReqBody, sortColumns: "lowVoltageLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "lowVoltageLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "highC":
                if (sort.highC) {
                    setlogReqBody({ ...logReqBody, sortColumns: "highTempLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "highTempLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "lowC":
                if (sort.lowC) {
                    setlogReqBody({ ...logReqBody, sortColumns: "lowTempLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "lowTempLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "maxChargeCurrLmt":
                if (sort.maxChargeCurrLmt) {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxChargeCurrLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxChargeCurrLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "maxDaysSinceCompEq":
                if (sort.maxDaysSinceCompEq) {
                    setlogReqBody({ ...logReqBody, sortColumns: "daySinceCompEqLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "daySinceCompEqLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "packVs":
                if (sort.packVs) {
                    setlogReqBody({ ...logReqBody, sortColumns: "packVoltageImbalLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "packVoltageImbalLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "EqCurrRange":
                if (sort.EqCurrRange) {
                    setlogReqBody({ ...logReqBody, sortColumns: "eqCurrRangeLimit", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "eqCurrRangeLimit", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "MaxDayBtwChg":
                if (sort.MaxDayBtwChg) {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxDaysNoCharge", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "maxDaysNoCharge", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "org":
                if (sort.org) {
                    setlogReqBody({ ...logReqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "div":
                if (sort.div) {
                    setlogReqBody({ ...logReqBody, sortColumns: "divisionName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "divisionName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "loc":
                if (sort.loc) {
                    setlogReqBody({ ...logReqBody, sortColumns: "locationName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "locationName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "site":
                if (sort.site) {
                    setlogReqBody({ ...logReqBody, sortColumns: "buildingName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "buildingName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "updatedDate":
                if (sort.updatedDate) {
                    setlogReqBody({ ...logReqBody, sortColumns: "updatedDate", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "updatedDate", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
        }
    };


    const getDowFormat = async (format: string) => {
        if (selectorLabel.some((item: any) => item.enabled == true)) {
            let userInfo: any = Utility.getUserInfo();
            let token = userInfo.authToken
            setIsShowSpinner(true)
            let payload = {
                orgId: logReqBody?.orgId,
                batteryId: logReqBody?.batteryId,
                vehicleId: logReqBody?.vehicleId,
                locationId: logReqBody?.locationId,
                buildingId: logReqBody?.buildingId,
                divisionId: logReqBody?.divisionId,
                batteryRxSim: logReqBody?.batteryRxSim.trim(),
                serialNumber: logReqBody?.serialNumber.trim(),
                pageOffset: 1,
                pageLimit: totalCount + 1,
                sortColumns: logReqBody?.sortColumns,
                sortDirection: logReqBody?.sortDirection,
                timeZone: logReqBody?.timeZone
            }
            try {
                const response = await service({
                    url: constantUrl.api.configurationLogReport.downloadFile + format,
                    method: 'POST',
                    responseType: 'blob',
                    headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                    data: payload
                })
                if (response) {
                    let url = window.URL.createObjectURL(response);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'config_log_report.' + format;
                    a.click();
                    setIsShowSpinner(false)
                } else {
                    setIsShowSpinner(false)
                }
            } catch (error) {
                console.log(error)
                setIsShowSpinner(false)
            }
        }
        else {
            setAlert(true)
        }
    }

    useEffect(() => {
        if (initial === false) {
            getConfigLogs();
            getConfigLogsCount();
        }
    }, [logReqBody]);

    useEffect(() => {
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('configuration_Logs_Report_W')) window.history.back()
        }

        getReportSettings();
        setInitial(true)
    }, [])

    useEffect(() => {
        if (initial === false) {
            let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
            setlogReqBody({ ...logReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])


    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div ref={filtersRef} className="posi_bmid_search_container">
                <div className="col-md-6 px-0 drop_search-main">
                    <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                        <div className='ant-design-select'>
                            <Select
                                value={dropdownvalue}
                                size={'middle'}
                                onChange={onChangedropdown}
                                className='drop-filter'
                                dropdownStyle={{ zIndex: 900 }}
                            >
                                {DropList && DropList.map((item: any, index: any) => {
                                    return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                                })}
                            </Select>
                        </div>
                        <div>
                            <Space direction="vertical" size="middle" className='search-input'>
                                <Space.Compact className='posi_bmid_search_width'>
                                    <Input allowClear placeholder={placeholder} value={inputvalue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                                        onPressEnter={onPressEnter} />
                                    <Button type="primary" onClick={handleSearch}>Find</Button>
                                </Space.Compact>
                            </Space>
                            <div className="errorColor">{emptyError}</div>
                        </div>
                    </ConfigProvider>
                </div>
                <div className="column-selector-btn">
                    <button
                        onClick={selectColumn}
                        type="button"
                        className="btn btn-light selector-btn"
                    >
                        Column Selector
                    </button>
                </div>
            </div>
            <div className="main-panel pt-3 position-relative">
                <div className="ChartPanel panelShadow bg-white">
                    <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                        <div className='table_title'>Configuration Log Report</div>
                        <div className='show-entries'>
                            <ShowEntries getPageSize={onSelect} />
                        </div>
                    </div>
                    <div id="table-container">
                        <div style={{ height: tableHeight + 'px' }} className="configuration_log overflow-auto scrollbar">
                            <table className="billable width100">
                                <thead className='tableHeader'>
                                    <tr className="billable-head">
                                        {column?.[0]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[0]?.['columnName']}</span>
                                                {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th>
                                            : ""}
                                        {column?.[1]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[1]?.['columnName']}</span>
                                                {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[2]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('serialNumer')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[2]?.['columnName']}</span>
                                                {sort.serialNumer ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[3]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('batteryRxSim')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[3]?.['columnName']}</span>
                                                {sort.batteryRxSim ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[4]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('logNumber')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[4]?.['columnName']}</span>
                                                {sort.logNumber ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[5]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('datetime')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[5]?.['columnName']}</span>
                                                {sort.datetime ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[6]?.['enabled'] ? <th><span>{column?.[6]?.['columnName']}</span></th> : ""}
                                        {column?.[7]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('totalCells')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[7]?.['columnName']}</span>
                                                {sort.totalCells ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[8]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('capacity')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[8]?.['columnName']}</span>
                                                {sort.capacity ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[9]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('startcurrlmt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[9]?.['columnName']}</span>
                                                {sort.startcurrlmt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[10]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('socLimit')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[10]?.['columnName']}</span>
                                                {sort.socLimit ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[11]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('maxahrsBtwEQ')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[11]?.['columnName']}</span>
                                                {sort.maxahrsBtwEQ ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[12]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('maxdaysBteEq')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[12]?.['columnName']}</span>
                                                {sort.maxdaysBteEq ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[13]?.['enabled'] ? <th><span>{column?.[13]?.['columnName']}</span></th> : ""}
                                        {column?.[14]?.['enabled'] ? <th><span>{column?.[14]?.['columnName']}</span></th> : ""}
                                        {column?.[15]?.['enabled'] ? <th><span>{column?.[15]?.['columnName']}</span></th> : ""}
                                        {column?.[16]?.['enabled'] ? <th><span>{column?.[16]?.['columnName']}</span></th> : ""}
                                        {column?.[17]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('internalResistance')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[17]?.['columnName']}</span>
                                                {sort.internalResistance ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[18]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('tarqVoltLimit')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[18]?.['columnName']}</span>
                                                {sort.tarqVoltLimit ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[19]?.['enabled'] ? <th><span>{column?.[19]?.['columnName']}</span></th> : ""}
                                        {column?.[20]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('runTimeCurrThresh')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[20]?.['columnName']}</span>
                                                {sort.runTimeCurrThresh ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[21]?.['enabled'] ? <th><span>{column?.[21]?.['columnName']}</span></th> : ""}
                                        {column?.[22]?.['enabled'] ? <th><span>{column?.[22]?.['columnName']}</span></th> : ""}
                                        {column?.[23]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('keyonCurr')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[23]?.['columnName']}</span>
                                                {sort.keyonCurr ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[24]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('parasiticCurr')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[24]?.['columnName']}</span>
                                                {sort.parasiticCurr ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[25]?.['enabled'] ? <th><span>{column?.[25]?.['columnName']}</span></th> : ""}
                                        {column?.[26]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('dailyCallTime')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[26]?.['columnName']}</span>
                                                {sort.dailyCallTime ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[27]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('lowSocLmt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[27]?.['columnName']}</span>
                                                {sort.lowSocLmt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[28]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('highVoltLmt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[28]?.['columnName']}</span>
                                                {sort.highVoltLmt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[29]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('lowVoltLmt')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[29]?.['columnName']}</span>
                                                {sort.lowVoltLmt ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[30]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('highC')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[30]?.['columnName']}</span>
                                                {sort.highC ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[31]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('lowC')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[31]?.['columnName']}</span>
                                                {sort.lowC ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[32]?.['enabled'] ? <th>
                                            <span >{column?.[32]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[33]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('maxDaysSinceCompEq')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[33]?.['columnName']}</span>
                                                {sort.maxDaysSinceCompEq ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[34]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('packVs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[34]?.['columnName']}</span>
                                                {sort.packVs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[35]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('EqCurrRange')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[35]?.['columnName']}</span>
                                                {sort.EqCurrRange ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[36]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('MaxDayBtwChg')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[36]?.['columnName']}</span>
                                                {sort.MaxDayBtwChg ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[37]?.['enabled'] ? <th><span>{column?.[37]?.['columnName']}</span></th> : ""}
                                        {column?.[38]?.['enabled'] ? <th><span>{column?.[38]?.['columnName']}</span></th> : ""}
                                        {column?.[39]?.['enabled'] ? <th><span>{column?.[39]?.['columnName']}</span></th> : ""}
                                        {column?.[40]?.['enabled'] ? <th><span> {column?.[40]?.['columnName']}</span></th> : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        configlogs?.map((item: any, index: any) => {
                                            return <tr key={'keys' + index}>
                                                {column?.[0]?.['enabled'] ? <td>{item?.batteryId}</td> : ""}
                                                {column?.[1]?.['enabled'] ? <td>{item?.vehicleId}</td> : ""}
                                                {column?.[2]?.['enabled'] ? <td>{item?.serialNumber}</td> : ""}
                                                {column?.[3]?.['enabled'] ? <td>{item?.deviceSerialNumber}</td> : ""}
                                                {column?.[4]?.['enabled'] ? <td>{item?.logNumber}</td> : ""}
                                                {column?.[5]?.['enabled'] ? <td>{item?.formatTimesStamp}</td> : ""}
                                                {column?.[6]?.['enabled'] ? <td>{item?.batteryTypeDesc}</td> : ""}
                                                {column?.[7]?.['enabled'] ? <td>{item?.totalCells}</td> : ""}
                                                {column?.[8]?.['enabled'] ? <td>{(item?.capacity == undefined || item?.capacity == null || item?.capacity == "") ? "" : item?.capacity + " AHrs"}</td> : ""}
                                                {column?.[9]?.['enabled'] ? <td>{(item?.startCurrentLimit == undefined || item?.startCurrentLimit == null || item?.startCurrentLimit == "") ? "" : item?.startCurrentLimit + "A/100AHrs"}</td> : ""}
                                                {column?.[10]?.['enabled'] ? <td>{(item?.socLimit == undefined || item?.socLimit == null || item?.socLimit == "") ? "" : item?.socLimit + "%"}</td> : ""}
                                                {column?.[11]?.['enabled'] ? <td>{(item?.maxAhBetweenEQ == undefined || item?.maxAhBetweenEQ == null || item?.maxAhBetweenEQ == "") ? "" : item?.maxAhBetweenEQ + "AHrs"}</td> : ""}
                                                {column?.[12]?.['enabled'] ? <td>{item?.maxDaysBetweenEQS}</td> : ""}
                                                {column?.[13]?.['enabled'] ? <td>{item?.startDayDescription}</td> : ""}
                                                {column?.[14]?.['enabled'] ? <td>{item?.eqStartTimeDesc}</td> : ""}
                                                {column?.[15]?.['enabled'] ? <td>{item?.endDayDescription}</td> : ""}
                                                {column?.[16]?.['enabled'] ? <td>{item?.eqEndTimeDesc}</td> : ""}
                                                {column?.[17]?.['enabled'] ? <td>{(item?.batteryInternalOhms == undefined || item?.batteryInternalOhms == null) ? "" : item?.batteryInternalOhms + "mOhm/CI/Ahrs"}</td> : ""}
                                                {column?.[18]?.['enabled'] ? <td>{(item?.targetVoltageLimit == undefined || item?.targetVoltageLimit == null || item?.targetVoltageLimit == "") ? "" : item?.targetVoltageLimit + "mV/Cell"}</td> : ""}
                                                {column?.[19]?.['enabled'] ? <td>{item?.temperatureFoldBackDesc}</td> : ""}
                                                {column?.[20]?.['enabled'] ? <td>{(item?.runCurrentThreshold == undefined || item?.runCurrentThreshold == null || item?.runCurrentThreshold == "") ? "" : item?.runCurrentThreshold + "mA"}</td> : ""}
                                                {column?.[21]?.['enabled'] ? <td>{item?.detectionTypeDescription}</td> : ""}
                                                {column?.[22]?.['enabled'] ? <td>{item?.logicTypeDescription}</td> : ""}
                                                {column?.[23]?.['enabled'] ? <td>{(item?.keyOnThreshold == undefined || item?.keyOnThreshold == null || item?.keyOnThreshold == "") ? "" : item?.keyOnThreshold + "mA"}</td> : ""}
                                                {column?.[24]?.['enabled'] ? <td>{(item?.parasiticCurrent == undefined || item?.parasiticCurrent == null) ? "" : item?.parasiticCurrent + "mA"}</td> : ""}
                                                {column?.[25]?.['enabled'] ? <td>{item?.msTimezoneId}</td> : ""}
                                                {column?.[26]?.['enabled'] ? <td>{item?.dailyCallTime}</td> : ""}
                                                {column?.[27]?.['enabled'] ? <td>{(item?.lowSocLimit == undefined || item?.lowSocLimit == null || item?.lowSocLimit == "") ? "" : item?.lowSocLimit + "%"}</td> : ""}
                                                {column?.[28]?.['enabled'] ? <td>{(item?.highVoltageLimit == undefined || item?.highVoltageLimit == null || item?.highVoltageLimit == "") ? "" : item?.highVoltageLimit + "mV/Cell"}</td> : ""}
                                                {column?.[29]?.['enabled'] ? <td>{(item?.lowVoltageLimit == undefined || item?.lowVoltageLimit == null || item?.lowVoltageLimit == "") ? "" : item?.lowVoltageLimit + "mV/Cell"}</td> : ""}
                                                {column?.[30]?.['enabled'] ? <td>{(item?.highTempLimit == undefined || item?.highTempLimit == null || item?.highTempLimit == "") ? "" : item?.highTempLimit + "°C"}</td> : ""}
                                                {column?.[31]?.['enabled'] ? <td>{(item?.lowTempLimit == undefined || item?.lowTempLimit == null || item?.lowTempLimit == "") ? "" : item?.lowTempLimit + "°C"}</td> : ""}
                                                {column?.[32]?.['enabled'] ? <td>{item?.maxChargeCurrLimit}</td> : ""}
                                                {column?.[33]?.['enabled'] ? <td>{item?.daySinceCompEqLimit}</td> : ""}
                                                {column?.[34]?.['enabled'] ? <td>{(item?.packVoltageImbalLimit == undefined || item?.packVoltageImbalLimit == null || item?.packVoltageImbalLimit == "") ? "" : item?.packVoltageImbalLimit + "mV/Cell"}</td> : ""}
                                                {column?.[35]?.['enabled'] ? <td>{(item?.eqCurrRangeLimit == undefined || item?.eqCurrRangeLimit == null || item?.eqCurrRangeLimit == "") ? "" : "+/-" + item?.eqCurrRangeLimit + "%"}</td> : ""}
                                                {column?.[36]?.['enabled'] ? <td>{(item?.maxDaysNoCharge == undefined || item?.maxDaysNoCharge == null || item?.maxDaysNoCharge == "") ? "" : item?.maxDaysNoCharge + "d"}</td> : ""}
                                                {column?.[37]?.['enabled'] ? <td>{item?.organization}</td> : ""}
                                                {column?.[38]?.['enabled'] ? <td>{item?.divisionName}</td> : ""}
                                                {column?.[39]?.['enabled'] ? <td>{item?.locationName}</td> : ""}
                                                {column?.[40]?.['enabled'] ? <td>{item?.buildingName}</td> : ""}
                                            </tr>
                                        })
                                    }
                                    {configlogs?.length === 0 && <tr><td colSpan={column.filter((item: any) => item?.['enabled'] === true)?.length} className="pt-3" style={{ textAlign: "center" }}>No Data Available</td></tr>}
                                </tbody>
                            </table>
                        </div>
                        <div ref={paginationRef}>
                            <PaginationComponent
                                handlePagination={handlePagination}
                                totalLength={totalCount}
                                pageSize={pageSize}
                                current={current}
                            />
                        </div>
                    </div>

                </div>
                <div ref={selectorRef} className="column-selector selector ">
                    <div className="column-title">Column Selector</div>
                    <div className="slector-body">
                        <label>
                            <input
                                checked={clearAll}
                                value="clearAll"
                                type="checkbox"
                                aria-label="checkbox"
                                onChange={clearall}
                            />
                            <span></span>
                        </label>
                        <p>Clear All</p>

                        <label>
                            <input
                                checked={selectAll}
                                value="selectAll"
                                type="checkbox"
                                aria-label="checkbox"
                                onChange={selectall}
                            />
                            <span></span>
                        </label>
                        <p>Select All</p>
                    </div>
                    <div style={{ height: tableHeight - 126 + 'px' }} className="selector-body-height scrollbar">
                        {selectorLabel?.map((item: any, index: any) => {
                            return (
                                <div key={index + 2} className="slector-body">
                                    <label>
                                        <input
                                            key={index}
                                            name={index}
                                            onChange={onchange}
                                            checked={item['enabled']}
                                            value={item.value}
                                            type="checkbox"
                                            aria-label="checkbox"
                                        />
                                        <span></span>
                                    </label>
                                    <p>{item['columnName']}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className="m-flex">
                        <button onClick={cancelSelection} className="cancel">
                            Cancel
                        </button>
                        <button onClick={applyChanges} className="apply-changes mr-3">
                            Apply Changes
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                open={alert}
                closable={false}
                centered title={"Atleast Select 1 Column"}
                footer={[
                    <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
                ]}
            ></Modal>
            <SessionWarning />
        </>
    )
}
export default ConfigurationLogReport