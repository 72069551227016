import React, { FC, useState, useEffect,useLayoutEffect } from 'react';
import Donut from '../fusionChart/donutChart';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import SessionWarning from '../reusableComponent/sessionWarning';
import { format, startOfWeek, subDays } from 'date-fns'
import LoadingSpinner from '../spinner/loading-spinner'
import PaginationComponent from '../reusableComponent/pagination';
import * as roles from '../../settings/roles';
import { resize, hideSidebar } from '../../settings/resize';


interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    }
}

const today = format(new Date(), 'dd-MMM-yyyy');
const sevenDaysAgo = format(subDays(new Date(), 7), 'dd-MMM-yyyy');

const req = {
    orgId: 0,
    divisionId: -1,
    locationId: -1,
    buildingId: -1,
    startDate: sevenDaysAgo,
    endDate: today,
    pageOffset: 1,
    pageLimit: 8
}

const SiteDashboard: FC<Props> = ({ globalData }) => {
    const [soc, setSoc] = useState<{ highSOCUnits: number, lowSOCUnits: number, totalChargeCount: number, totalNoChargeUnits: number, totalDisconnectedCount: number, totalInstalledUnits: number }>({ highSOCUnits: 0, lowSOCUnits: 0, totalChargeCount: 0, totalNoChargeUnits: 0, totalDisconnectedCount: 0, totalInstalledUnits: 0 })
    const [taskSummaryCount, setTaskSummaryCount] = useState<{ severeFaults: number, overdueTasks: number, upcomingTasks: number }>({ severeFaults: 0, overdueTasks: 0, upcomingTasks: 0 })
    const [locSummary, setLocSummary] = useState<{ locationAddress: string, locationCountry: string, battersCount: number, chargersCount: number }>({ locationAddress: "", locationCountry: "", battersCount: 0, chargersCount: 0 })
    const [operationStatus, setIsOperationStatus] = useState<{ highTempBatteries: number, totalAhsCharged: number, totalCompletedUnits: number, totalUpcomingUnits: number, totalOverdueUnits: number, totalWateringEvents: number }>({ highTempBatteries: 0, totalAhsCharged: 0, totalCompletedUnits: 0, totalUpcomingUnits: 0, totalOverdueUnits: 0, totalWateringEvents: 0 })
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [reqBody, setReqBody] = useState<any>(req)
    const [devicereport, setDevicereport] = useState<any[]>([])
    const [count, setCount] = useState<number>(1)
    const [current, setCurrent] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(8)
    const [date, setDate] = useState<{ startDate: string, endDate: string }>({ startDate: '', endDate: '' });
    const [onload, setOnLoad] = useState<boolean>(true);


    useLayoutEffect(()=>{
        let timer:any
        const debouncedHandleResize = ()=>{
         clearTimeout(timer)
         timer=setTimeout(()=>{
          resize()
         },500)
        }
        window.addEventListener("resize",debouncedHandleResize)
        return()=>{
         window.removeEventListener("resize",debouncedHandleResize)       }
     })

    useEffect(() => {
        resize();
        hideSidebar();
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('siteDashBoard_W')) window.history.back()
        }

        setOnLoad(true)
        let userInfo: any = Utility.getUserInfo();
        let userId = userInfo.userId;

        const today = new Date()
        today.setDate(today.getDate());
        const lastDayOfWeek = format(today, 'dd-MMM-yyyy')
        const firstDayOfWeek = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy');
        // setDate({ ...date, startDate: firstDayOfWeek, endDate: lastDayOfWeek });

        setTimeout(() => {
            let data: any = sessionStorage.getItem('globalData');
            data = data && JSON.parse(data);

            let orgid = data?.orgId > -1 ? data?.orgId : userId;
            let locid = data?.locationId;

            if (localStorage?.UserType === roles.SITE_ADMIN || localStorage?.UserType === roles.SITE_MANAGER) {
                locid = localStorage?.locationId ? Number(localStorage?.locationId) : -1;
            }

            let week_of_day = localStorage.getItem("weeklyStartDay");
            let date_selected = new Date();
            date_selected = new Date(date_selected.getFullYear(), date_selected.getMonth(), date_selected.getDate());

            switch (week_of_day) {
                case "SUN":
                    while (date_selected.getDay() !== 0) {
                        date_selected.setDate(date_selected.getDate() - 1);
                    }
                    break;
                case "MON":
                    while (date_selected.getDay() !== 1) {
                        date_selected.setDate(date_selected.getDate() - 1);
                    }
                    break;
                case "TUE":
                    while (date_selected.getDay() !== 2) {
                        date_selected.setDate(date_selected.getDate() - 1);
                    }
                    break;
                case "WED":
                    while (date_selected.getDay() !== 3) {
                        date_selected.setDate(date_selected.getDate() - 1);
                    }
                    break;
                case "THU":
                    while (date_selected.getDay() !== 4) {
                        date_selected.setDate(date_selected.getDate() - 1);
                    }
                    break;
                case "FRI":
                    while (date_selected.getDay() !== 5) {
                        date_selected.setDate(date_selected.getDate() - 1);
                    }
                    break;
                case "SAT":
                    while (date_selected.getDay() !== 6) {
                        date_selected.setDate(date_selected.getDate() - 1);
                    }
                    break;
                default:
                    return;
            }

            let date_end = new Date(date_selected.getTime() + (7 * 24 * 60 * 60 * 1000));

            if (localStorage?.UserType === roles.SITE_ADMIN || localStorage?.UserType === roles.SITE_MANAGER) {
                if (localStorage?.buildingId > -1) {
                    setReqBody({ ...reqBody, orgId: orgid, divisionId: data?.divId, locationId: locid, buildingId: localStorage?.buildingId > -1 ? Number(localStorage?.buildingId) : -1 })
                    getTaskSummary(orgid, data?.divId, locid, localStorage?.buildingId > -1 ? Number(localStorage?.buildingId) : -1);
                    locationSummary(orgid, data?.divId, locid, localStorage?.buildingId > -1 ? Number(localStorage?.buildingId) : -1);
                    getOperationStatus(format(date_selected, 'dd-MMM-yyyy'), format(date_end, 'dd-MMM-yyyy'), orgid, data?.divId, locid, localStorage?.buildingId > -1 ? Number(localStorage?.buildingId) : -1);
                    stateofcharge(firstDayOfWeek, lastDayOfWeek, orgid, data?.divId, locid, localStorage?.buildingId > -1 ? Number(localStorage?.buildingId) : -1);
                    getDeviceReport({ ...reqBody, orgId: orgid, divisionId: data?.divId, locationId: locid, buildingId: localStorage?.buildingId > -1 ? Number(localStorage?.buildingId) : -1 })
                    getCount({ ...reqBody, orgId: orgid, divisionId: data?.divId, locationId: locid, buildingId: localStorage?.buildingId > -1 ? Number(localStorage?.buildingId) : -1 })
                }
            } else {
                getTaskSummary(orgid, data?.divId, locid, data?.buildingId > -1 ? Number(data?.buildingId) : -1);
                locationSummary(orgid, data?.divId, locid, data?.buildingId > -1 ? Number(data?.buildingId) : -1);
                setReqBody({ ...reqBody, orgId: orgid, divisionId: data?.divId, locationId: locid, buildingId: data?.buildingId > -1 ? Number(data?.buildingId) : -1 })
                getDeviceReport({ ...reqBody, orgId: orgid, divisionId: data?.divId, locationId: locid, buildingId: data?.buildingId > -1 ? Number(data?.buildingId) : -1 })
                getCount({ ...reqBody, orgId: orgid, divisionId: data?.divId, locationId: locid, buildingId: data?.buildingId > -1 ? Number(data?.buildingId) : -1 })
                getOperationStatus(format(date_selected, 'dd-MMM-yyyy'), format(date_end, 'dd-MMM-yyyy'), orgid, data?.divId, locid, data?.buildingId > -1 ? Number(data?.buildingId) : -1);
                stateofcharge(firstDayOfWeek, lastDayOfWeek, orgid, data?.divId, locid, data?.buildingId > -1 ? Number(data?.buildingId) : -1);
            }
            setOnLoad(false)
        }, 1000);
    }, [])

    useEffect(() => {
        let userInfo: any = Utility.getUserInfo();
        let userId = userInfo.userId;

        let orgid = globalData?.orgId > -1 ? globalData?.orgId : userId;
        let locid = globalData?.locationId;

        if (localStorage?.UserType === roles.SITE_ADMIN || localStorage?.UserType === roles.SITE_MANAGER) {
            locid = localStorage?.locationId ? Number(localStorage?.locationId) : -1;
        }

        const today = new Date()
        today.setDate(today.getDate());
        const lastDayOfWeek = format(today, 'dd-MMM-yyyy')
        const firstDayOfWeek = format(startOfWeek(new Date(), { weekStartsOn: 0 }), 'dd-MMM-yyyy');

        let week_of_day = localStorage.getItem("weeklyStartDay");
        let date_selected = new Date();
        date_selected = new Date(date_selected.getFullYear(), date_selected.getMonth(), date_selected.getDate());

        switch (week_of_day) {
            case "SUN":
                while (date_selected.getDay() !== 0) {
                    date_selected.setDate(date_selected.getDate() - 1);
                }
                break;
            case "MON":
                while (date_selected.getDay() !== 1) {
                    date_selected.setDate(date_selected.getDate() - 1);
                }
                break;
            case "TUE":
                while (date_selected.getDay() !== 2) {
                    date_selected.setDate(date_selected.getDate() - 1);
                }
                break;
            case "WED":
                while (date_selected.getDay() !== 3) {
                    date_selected.setDate(date_selected.getDate() - 1);
                }
                break;
            case "THU":
                while (date_selected.getDay() !== 4) {
                    date_selected.setDate(date_selected.getDate() - 1);
                }
                break;
            case "FRI":
                while (date_selected.getDay() !== 5) {
                    date_selected.setDate(date_selected.getDate() - 1);
                }
                break;
            case "SAT":
                while (date_selected.getDay() !== 6) {
                    date_selected.setDate(date_selected.getDate() - 1);
                }
                break;
            default:
                return;
        }

        let date_end = new Date(date_selected.getTime() + (7 * 24 * 60 * 60 * 1000));

        if (onload == false) {
            if (localStorage?.UserType === roles.SITE_ADMIN || localStorage?.UserType === roles.SITE_MANAGER) {
                if (Number(localStorage?.buildingId) !== globalData?.buildingId) {
                    getTaskSummary(orgid, globalData?.divId, locid, globalData?.buildingId);
                    locationSummary(orgid, globalData?.divId, locid, globalData?.buildingId);
                    setReqBody({ ...reqBody, orgId: orgid, divisionId: globalData?.divId, locationId: locid, buildingId: globalData?.buildingId })
                    getDeviceReport({ ...reqBody, orgId: orgid, divisionId: globalData?.divId, locationId: locid, buildingId: globalData?.buildingId })
                    getCount({ ...reqBody, orgId: orgid, divisionId: globalData?.divId, locationId: locid, buildingId: globalData?.buildingId })
                    getOperationStatus(format(date_selected, 'dd-MMM-yyyy'), format(date_end, 'dd-MMM-yyyy'), orgid, globalData?.divId, locid, globalData?.buildingId);
                    stateofcharge(firstDayOfWeek, lastDayOfWeek, orgid, globalData?.divId, locid, globalData?.buildingId);
                }
            } else {
                getTaskSummary(orgid, globalData?.divId, locid, globalData?.buildingId);
                locationSummary(orgid, globalData?.divId, locid, globalData?.buildingId);
                setReqBody({ ...reqBody, orgId: orgid, divisionId: globalData?.divId, locationId: locid, buildingId: globalData?.buildingId })
                getDeviceReport({ ...reqBody, orgId: orgid, divisionId: globalData?.divId, locationId: locid, buildingId: globalData?.buildingId })
                getCount({ ...reqBody, orgId: orgid, divisionId: globalData?.divId, locationId: locid, buildingId: globalData?.buildingId })
                getOperationStatus(format(date_selected, 'dd-MMM-yyyy'), format(date_end, 'dd-MMM-yyyy'), orgid, globalData?.divId, locid, globalData?.buildingId);
                stateofcharge(firstDayOfWeek, lastDayOfWeek, orgid, globalData?.divId, locid, globalData?.buildingId);
            }
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId])

    const getTaskSummary = async (orgId: any, divId: any, locId: any, buildingId: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        let url = constantUrl.api.dashboard.getTaskSummary;

        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locId,
            buildingId: buildingId
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });
            const { severeFaults, overdueTasks, upcomingTasks } = response
            setTaskSummaryCount({ ...taskSummaryCount, severeFaults, overdueTasks, upcomingTasks })
        } catch (error) {
            console.log(error)
        }
    }

    const locationSummary = async (orgId: any, divId: any, locId: any, buildingId: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        let url = constantUrl.api.dashboard.getLocationSummary;

        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locId,
            buildingId: buildingId
        }

        try {
            const response = await service({
                url: url,
                method: 'POST',
                headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            })
            const { locationAddress, locationCountry, battersCount, chargersCount } = response
            setLocSummary({ ...locSummary, locationAddress, locationCountry, battersCount, chargersCount })
        } catch (error) {
            console.error('locationsummary:', error)
        }
    }

    const getDeviceReport = async (payload: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        setIsShowSpinner(true);

        try {
            const response = await service({
                url: constantUrl.api.siteDashboard.postDeviceReport,
                method: "POST",
                headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
                data: payload
            })
            setDevicereport(response)
            setIsShowSpinner(false)
        } catch (error) {
            console.error("deviceError:", error)
            setIsShowSpinner(false)
        }
    }

    const getCount = async (payload: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        try {
            const response = await service({
                url: constantUrl.api.siteDashboard.count,
                method: "POST",
                headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
                data: payload
            })
            setCount(response.recordCount)
        } catch (error) {
            console.error("deviceError:", error)
        }
    }

    const stateofcharge = async (startDate: string, endDate: string, orgId: number, divId: any, locId: number, siteId: number) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        let url = constantUrl.api.dashboard.getOperationSoc;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locId,
            buildingId: siteId,
            startDate: startDate,
            endDate: endDate
        }

        try {
            const response = await service({
                url: url,
                method: 'POST',
                headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            })
            const { highSOCUnits, lowSOCUnits, totalChargeCount, totalNoChargeUnits, totalDisconnectedCount, totalInstalledUnits } = response
            setSoc({ ...highSOCUnits, lowSOCUnits, totalChargeCount, totalNoChargeUnits, totalDisconnectedCount, totalInstalledUnits })
        } catch (error) {
            console.error('soc:', error)
        }
    }

    const getOperationStatus = async (startDate: any, endDate: any, orgId: number, divId: any, locId: number, siteId: number) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        var url = constantUrl.api.dashboard.getOperationStatus;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locId,
            buildingId: siteId,
            startDate: startDate,
            endDate: endDate,
            weekStartDay: localStorage.getItem("weeklyStartDay")
        }

        try {
            const response = await service({
                url: url,
                method: 'POST',
                headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            })
            const { highTempBatteries, totalAhsCharged, totalCompletedUnits, totalUpcomingUnits, totalOverdueUnits, totalWateringEvents } = response
            setIsOperationStatus({ ...operationStatus,highTempBatteries, totalAhsCharged, totalCompletedUnits, totalUpcomingUnits, totalOverdueUnits, totalWateringEvents })
        } catch (error) {
            console.error('operation:', error)
            setIsShowSpinner(false)
        }
    }

    const handlePagination = (pageNumber: number) => {
        getDeviceReport({ ...reqBody, pageOffset: pageNumber })
        setCurrent(pageNumber)
    }

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div className="ChartPanel">
                <div className="container-fluid">
                    <div className="row site-top-block">
                        <div className="col-md-6 top-left-col">
                            <div className="row card-div location-left-col posi_mobile_tasksummary-site">
                                <div className="col-md-12 col-lg-12 col-xl-4 server-faults-container remove-padd">
                                    <div className="server-faults-location posi_mobile_tasksummary_card">
                                        <img src={require('../../images/Alerts-Icon.png')} className="location-top-img" alt='img' />
                                        <div style={{ display: '100%' }}>
                                            <div id="sever-faults-count" className="location-count position-top">{taskSummaryCount.severeFaults}</div>
                                            <div className="text-right position-bottom posi_mobile_tasksummary_card_desc">SEVERE FAULTS</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-4 overdue-task-container">
                                    <div className="overdue-task posi_mobile_tasksummary_card">
                                        <img src={require('../../images/OverdueTasks.png')} className="location-top-img" alt='img' />
                                        <div>
                                            <div id="overdue-count" className="location-count position-top">{taskSummaryCount.overdueTasks}</div>
                                            <div className="text-right position-bottom posi_mobile_tasksummary_card_desc">OVERDUE TASKS</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-4   upcoming-tasks-container">
                                    <div className="upcoming-tasks posi_mobile_tasksummary_card">
                                        <img src={require('../../images/Upcoming-Tasks.png')} className="location-top-img" alt='img' />
                                        <div>
                                            <div id="upcoming-count" className="location-count position-top">{taskSummaryCount.upcomingTasks}</div>
                                            <div className="text-right position-bottom posi_mobile_tasksummary_card_desc">UPCOMING TASKS</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Location summary  */}
                        <div className="col-md-6 ">
                            <div className="row location">
                                <div className="col-12 px-2">
                                    <div className="col-12 mb-2  chartTitle">LOCATION SUMMARY</div>
                                </div>
                                <div className="col-12">
                                    <div className="row loc-summary-row">
                                        <div className="col-4  col-md-12 col-lg-12 col-xl-4 px-2">
                                            <div className="location-summary-card" style={{ height: "100%" }}>
                                                <div>
                                                    <img src={require('../../images/location.png')} className="img_sizeL float-left" alt='img' />
                                                </div>
                                                <div className="system-nameL">{locSummary.locationAddress ? locSummary.locationAddress : "STREET ADDRESS"}</div>
                                                <div className="system-nameL">{locSummary.locationCountry ? locSummary.locationCountry : "CITY,STATE"}</div>
                                            </div>
                                        </div>
                                        <div className="col-4  col-md-12 col-lg-12 col-xl-4 px-2">
                                            <div className="location-summary-card" style={{ height: "100%" }}>
                                                <div>
                                                    <img src={require('../../images/ProCoreEdge.png')} className="img_sizeL float-left" alt='img' />
                                                </div>
                                                <div className="system-countL">{locSummary.chargersCount}</div>
                                                <div className="system-nameL">CHARGERS</div>
                                            </div>
                                        </div>
                                        <div className="col-4 col-md-12 col-lg-12 col-xl-4 px-2">
                                            <div className="location-summary-card" style={{ height: "100%" }}>
                                                <div>
                                                    <img src={require('../../images/Battery Dashboard.png')} className="img_sizeL float-left" alt='img' />
                                                </div>
                                                <div id="locationBatteryCount" className="system-countL">{locSummary.battersCount}</div>
                                                <div className="system-nameL">BATTERIES</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* DeVice Report  */}
                    <div className="row site-bottom-row">
                        <div className="col-md-8 col-lg-9 col-xl-9 col-xxl-9">
                            <div className="row device-report-row">
                                <div className="col-12 pl-0 pr-0 mt-1">
                                    <div className="col-12  chartTitle">DEVICE REPORT</div>
                                </div>
                                {/* for Batteries  */}
                                <div id="forBatteries" className="col-12 p-0 mt-3">
                                    <div className="add-device-main row ml-0">
                                        {devicereport?.map((item: any, index: number) => {
                                            return (
                                                <div className="bg-white all-device-card">
                                                    <div className="device-card-body">
                                                        <img className="float-left device-card-battery-img" src={require('../../images/Device Type-1.png')} alt="img" />
                                                        <div>
                                                            <div className='posi_device_report_content'>
                                                                <span className="ids">Device ID</span>
                                                                <span style={{ fontSize: '10px', paddingLeft: '10px' }}>{item.deviceId}</span>
                                                            </div>
                                                            <div className='posi_device_report_content mt-3'>
                                                                <span className="ids">Vechile ID</span>
                                                                <span style={{ fontSize: '10px', paddingLeft: '8px' }}>{item.vehicleId}</span>
                                                            </div>
                                                            <div className='device-card-left d-flex justify-content-between mt-5'>
                                                                <span className="capacity-count">Battery Capacity</span>
                                                                <span style={{ fontSize: 14 }}>{item.batteryCapacity ? item.batteryCapacity : 0}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between ids mt-4" style={{ fontSize: 12 }}>
                                                        <div>SOC Status</div>
                                                        <div>{item.socStatus}</div>
                                                    </div>
                                                    <div className="progress-main-red mt-2">
                                                        <div className="progress-container-red">
                                                            <div id="progress1" className="progress1"></div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between ids mt-3" style={{ fontSize: 12 }}>
                                                        <div>EQ Status</div>
                                                        <div>{item.eqStatus}</div>
                                                    </div>
                                                    <div className="progress-main-green mt-2">
                                                        <div className="progress-container-green">
                                                            <div id="progress2" className="progress2"></div>
                                                        </div>
                                                    </div>
                                                    <div className="location-footer-img mt-3">
                                                        <div className='position-relative'>
                                                            <img src={require('../../images/Plugins Icon.png')} alt="img" />
                                                            <span className="red-circle2 circle-p2"></span>
                                                            <span className='count-p2' style={{ left: item?.totalPlugins == 0 ? "70%" : "66%" }}>{item?.totalPlugins}</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <img src={require('../../images/Path 12222.png')} alt="img" />
                                                            <span className="red-circle2 circle-p2"></span>
                                                            <span className='count-p2' style={{ left: item?.daysLowWater == 0 ? "70%" : "66%" }}>{item?.daysLowWater}</span>
                                                        </div>
                                                        <div className='position-relative'>
                                                            <img src={require('../../images/Temp Icon.png')} alt="img" />
                                                            <span className="red-circle2 circle-p2"></span>
                                                            <span className='count-p2' style={{ left: item?.daysHighTemperature == 0 ? "70%" : "66%" }}>{item?.daysHighTemperature}</span>
                                                        </div>
                                                    </div>
                                                    <div className="location-img-labels mt-2">
                                                        <div>Total Plugins</div>
                                                        <div>Days Low Water</div>
                                                        <div>Days High Temp</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <PaginationComponent
                                        handlePagination={handlePagination}
                                        totalLength={count}
                                        pageSize={pageSize}
                                        current={current}
                                    />
                                </div>
                                {/* <!-- for batteries end --> */}
                            </div>
                        </div>
                        {/* <!-- Right side --> */}
                        <div className="col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                            <div className="row location-right-col">
                                {/* <!-- STATE OF CHARGE --> */}
                                {/* <div className="col-12 px-2 mb-2 state-chargeL">
                                    <div className=" mt-2 chartTitle">STATE OF CHARGE</div> */}
                                    {/* <!-- doughnut chart --> */}
                                    {/* <div className="py-2 donut-site">
                                        <Donut pieRaidus={50} doughnutRadius={40} soc={soc} />
                                    </div>
                                </div> */}
                                {/* <!-- OPERATIONS STATUS --> */}
                                <div className="col-md-12 operation-statusL">
                                    <div className="row-12  chartTitle">OPERATIONS STATUS</div>
                                    <div className="row ">
                                        <div className="col-12 mt-2 operation-status-card">
                                            <div className="operation-cardL ">
                                                <img src={require('../../images/High-Temp-Icon.png')} className="img-operation-site" alt='img' />
                                                <div style={{ width: '100%' }}>
                                                    <div id="ahsCharged" className="system-countL">{operationStatus.highTempBatteries ? operationStatus.highTempBatteries : 0}</div>
                                                    <div className="system-nameL">HIGH TEMP BATTERIES</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 col-lg-12 col-xl-6  mt-2 pl-2 operation-status-card">
                                            <div className="operation-cardL">
                                                <img src={require('../../images/Ahs-Icon.png')} className="img-operation-site" alt='img' />
                                                <div style={{ width: '100%' }}>
                                                    <div id="ahsCharged" className="system-countL">{operationStatus.totalAhsCharged}</div>
                                                    <div className="system-nameL">Ahs CHARGED</div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row ">
                                        <div className=" col-lg-12 col-md-12 col-xl-12  mt-2 operation-status-card">
                                            <div className="operation-cardL ">
                                                <img src={require('../../images/Group-601.png')} className="img-operation-site" alt='img' />
                                                <div style={{ width: '100%' }}>
                                                    <div id="ahsCharged" className="system-countL">{operationStatus.totalWateringEvents ? operationStatus.totalWateringEvents : 0}</div>
                                                    <div className="system-nameL">NUMBER OF DAYS WITH LOW WATER</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-12 operation-card-col py-1 mt-2 ">
                                        <div className="operation-card-units">
                                            <div className="operation-eq-image-main">
                                                <img className="operation-eq-image" src={require('../../images/Green@2x.png')} alt="img" />
                                                <div className="unit-div">
                                                    <div id="eqCompleted" className="unit-count">{operationStatus.totalCompletedUnits}</div>
                                                    <div className="unit-lable">Units</div>
                                                </div>
                                                <div className="progress-bar"></div>
                                            </div>
                                            <div className="operation-eq-image-main">
                                                <img className="operation-eq-image" src={require('../../images/Yellow@2x.png')} alt="img" />
                                                <div className="unit-div">
                                                    <div id="edUpcoming" className="unit-count">{operationStatus.totalUpcomingUnits}</div>
                                                    <div className="unit-lable">Units</div>
                                                </div>
                                                <div className="progress-bar"></div>
                                            </div>
                                            <div className="operation-eq-image-main">
                                                <img className="operation-eq-image" src={require('../../images/Red@2x.png')} alt="img" />
                                                <div className="unit-div">
                                                    <div id="eqOverdue" className="unit-count">{operationStatus.totalOverdueUnits}</div>
                                                    <div className="unit-lable">Units</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-12 operation-card-col py-1 mt-2 ">
                                        <div className="operation-card">
                                            <div>
                                                <img src={require('../../images/Group-601.png')} className="img-operation-site" alt='img' />
                                            </div>
                                            <div>
                                                <div id="watering" className="system-countL">{operationStatus.totalWateringEvents}</div>
                                                <div className="system-nameL">NUMBER OF DAYS WITH LOW WATER</div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <!--end of right side  --> */}
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    )
}

export default SiteDashboard