import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import LoadingSpinner from '../spinner/loading-spinner'
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import SessionWarning from '../reusableComponent/sessionWarning';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import {resize, hideSidebar} from '../../settings/resize';


ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface Props {
  globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
  action: string
  // refresh:number
}

const WarrantyByTimeChart: FC<Props> = ({globalData, action }) => {
  // const WarrantyByTimeChart: FC<Props> = ({globalData, action,refresh }) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [chartData, setChartData] = useState<any>({})
  const [reqBody, setReqBody] = useState({ orgId: 0, batteryId: "", vehicleId: "", divisionId: -1, locationId: -1, buildingId: -1 })
  const [batteryIdDropdown, setBatteryIdDropdown] = useState<any>([])
  const [serilaNumDropdown, setSNDropdown] = useState<any>([])
  const [batteryIdChart, setbatteryIdChart] = useState<any>([])
  const [estimateTimeRemaining, setEstimateTimeRemaining] = useState<any>([])
  const [elapsedMonths, setElapsedMonths] = useState<any>([])
  const [overWarrantyPeriod, setOverWarrantyPeriod] = useState<any>([])
  const [selectedBatteryId, setSelectedBatteryId] = useState<any>("")
  const [selectedSerialNumber, setSelectedSerialNumber] = useState<any>("")
  const [chartHeight, setChartHeight] = useState(window.innerHeight * 0.72);
  const [callChartApi, setCallChartApi] = useState<boolean>(false)
  const [sort, setSort] = useState<boolean>(false)
  const [initial, setInitial] = useState<boolean>(true);

  const ref = useRef<HTMLDivElement>(null)

  // useEffect(()=>{
  //   if (refresh !== 0)setReqBody({ ...reqBody})
  // },[refresh])

  useEffect(() => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    var gettoken = userInfo.authToken;
    
    if (reqBody.orgId === 0) return

      setIsShowSpinner(true);
      (async () => {
        const response = await service({
          url: constantUrl.api.warrantyTime.warrantyByTimeChart,
          method: "POST",
          data: reqBody,
          headers: { accept: "*/*", Authorization: "Bearer " + gettoken }
        })
        
        if (response) {
          setChartData(response)
          getChartData(response)
          battery(response)
          serial(response)
          setIsShowSpinner(false)
        } else {
          setIsShowSpinner(false)
        }
      })();
  }, [selectedBatteryId, reqBody])


  const battery = (response: any) => {
    let btIds: any = []
    response.forEach((item: any, index: any) => {
      btIds.push({ label: item.batteryId })
    })
    setBatteryIdDropdown(btIds)
  };

  const serial = (response: any) => {
    let serialNums: any = []
    response.forEach((item: any, index: any) => {
      serialNums.push({ label: item.serialNumber })
    })
    setSNDropdown(serialNums)
  };

  const getChartData = (response: any) => {
    let chartBtIds = response.flatMap((item: any) => { return [{ label: item.batteryId }] })
    let timeRemaining = response.flatMap((item: any) => { return [{ value: item.estimateTimeRemaining, displayValue: item.estimateTimeRemaining === 0 || "" ? " " : item.estimateTimeRemaining}] })
    let elapsedMonths = response.flatMap((item: any) => { return [{ value: item.elapsedMonths, displayValue: item.elapsedMonths === 0 || "" ? " " : item.elapsedMonths }] })
    let overWarrantyPeriod = response.flatMap((item: any) => { return [{ value: item.overWarrantyPeriod, displayValue: item.overWarrantyPeriod === 0 || "" ? " " : item.overWarrantyPeriod }] })
    setbatteryIdChart(chartBtIds)
    setEstimateTimeRemaining(timeRemaining)
    setElapsedMonths(elapsedMonths)
    setOverWarrantyPeriod(overWarrantyPeriod)
  }

  useEffect(() => {
    const handleResize = () => {
      setChartHeight(window.innerHeight * 0.72);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chartHeight]);

  const dataSource: any = {
    chart: {
      numvisibleplot: "6",
      formatnumberscale: "0",
      legendposition: "Top",
      xAxisNameFontColor: "#4f82b6",
      xAxisNameFontBold: "1",
      yAxisNameFontColor: "#4f82b6",
      yAxisNameFontBold: "1",
      //numberscalevalue: "60",
      "yaxisminvalue": "0",
      "yaxismaxvalue": "60",
      numberscaleunit: " Min",
      showvalues: "1",
      numberSuffix: "m",
      showsum: "1",
      rotateValues: "0",
      thickness: "10",
      animation: "1",
      // legendPadding: "400",
      //useRoundEdges: "1",
      //useRoundEdges: "8",
      useplotgradientcolor: "1",
      plotSpacePercent: "60",
      plotgradientcolor: "#FCF3F1",
      plotfillangle: "100",
      plotfillalpha: "90",
      plotfillratio: "0,90",
      xAxisName: "Batteries (By Battery ID)",
      yaxisname: "Warranty Months",
      // exportEnabled:"1",
      // exportfilename:"Warrent_Report",
      plottooltext:
        "<b>$dataValue</b> visits from $seriesName in $label {br}<b>$percentValue</b> of total visits in the month",
      theme: "fusion"
    },
    categories: [
      {
        category: batteryIdChart
        //  [{label: "000211DK"}] 
      }
    ],
    dataset: [
      {
        seriesname: "Estimated Time Remaining < 12 month",
        color: "#DBEC2E",
        data: estimateTimeRemaining
        // [{value: "7",displayValue : "7" }]
      },
      {
        seriesname: "Elapsed Months",
        color: "#496EED",
        data: elapsedMonths
        // [{value: "1", displayValue:"1"}]
      },
      {
        seriesname: "Over Warranty Period",
        color: "#EC502E",
        data: overWarrantyPeriod
        //  [{value: "28",displayValue : "28"}]
      }]
  };



  const chartConfigs: any = {
    type: "scrollstackedbar2d",
    width: "100%",
    valuefontcolor: "#FFFFFF",
    height: chartHeight.toString(),
    dataFormat: "json",
    dataSource: dataSource
  };

  const filterByBatteryId = (bId: string) => {
    setSelectedBatteryId(bId)
    const newData = chartData.filter((item: any) => {
      return item.batteryId === bId
    })
    getChartData(newData)
  };

  const filterBySerialNumber = (sN: string) => {
    setSelectedSerialNumber(sN)
    const newData = chartData.filter((item: any) => {
      return item.serialNumber === sN
    })
    getChartData(newData)
  };

  const sortByTime = () => {
    if (sort) {
      let data = chartData.sort((a: any, b: any) => (a.elapsedMonths < b.elapsedMonths) ? -1 : 1)
      setSort(false)
      setChartData(data)
      getChartData(data)
      battery(data)
      serial(data)
    } else {
      let data = chartData.sort((a: any, b: any) => (a.elapsedMonths > b.elapsedMonths) ? -1 : 1)
      setSort(true)
      setChartData(data)
      getChartData(data)
      battery(data)
      serial(data)
    }
  }

  const sortByAZ = () => {
    if (sort) {
      let data = chartData.sort((a: any, b: any) => (a.batteryId < b.batteryId) ? -1 : 1)
      setSort(false)
      setChartData(data)
      getChartData(data)
      battery(data)
    } else {
      let data = chartData.sort((a: any, b: any) => (a.batteryId > b.batteryId) ? -1 : 1)
      setSort(true)
      setChartData(data)
      getChartData(data)
      battery(data)
    }
  }

  const getIds = (orgId: number, locationId: number, batteryId: string, vehicleId: string) => {
    setCallChartApi(true)
    setReqBody({ ...reqBody, orgId, locationId, batteryId, vehicleId })
  }

  useEffect(()=>{
    // check the page permission
    let permission:any =  localStorage.getItem('permissions')
    if(!permission.includes('warrenty_time_W'))window.history.back()  

    setInitial(true);
    resize();
    hideSidebar();
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    setCallChartApi(true)
    let org = data?.orgId > -1 ? data?.orgId : userId;
    setReqBody({ ...reqBody, orgId: org,divisionId: data?.divId,locationId: data?.locationId,buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    setInitial(false);
  }, [])

  useEffect(() => {
    if(initial === false) {
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
        setReqBody({ ...reqBody, orgId: org,divisionId: globalData?.divId,locationId: globalData?.locationId,buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
    }
}, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

useEffect(() => {
  if(action !== "") {
    onButtonClick(action)
  }
}, [action])

  const onButtonClick = useCallback((value:string) => {
    if (ref.current === null) {
      return
      
    }
    setIsShowSpinner(true);
    if(value==='png'){
      toPng(ref.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'Warrent_Report.png'
        link.href = dataUrl
        link.click()
        setIsShowSpinner(false);
      })
      .catch((err) => {
        console.log(err)
        setIsShowSpinner(false);
      })
    }else{
      html2canvas(ref.current)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a')
        link.download = 'my-image-name.png';
        const pdf = new jsPDF();
        //pdf.addImage(imgData, 'PNG',2,20,0,91.3);
        pdf.addImage(imgData, 'PNG',5,20,200,100);
        pdf.save("Warrent_Report.pdf");
        setIsShowSpinner(false);
      })
      .catch((err) => {
        console.log(err)
        setIsShowSpinner(false);
      })
    }
   
  }, [ref])


  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      {/* <PosinetHeader 
        billing={<HeaderFilter
        getId={getIds}
        vId={true}
        noinitialfilter={true}
        onButtonClick={onButtonClick}
        />}
        /> */}
      <div className="warranty-report-main">
        <div className="ChartPanel pr-3">
          <div>
            <div className="row mb-2">
              {/* <SelectFilter
        placeholder={"Battery by Battery ID"}
        dropDownList={batteryIdDropdown}
        filterById={filterByBatteryId} 
       /> */}
              {/* <SelectFilter
                placeholder={"Serial Number"}
                dropDownList={serilaNumDropdown}
                filterById={filterBySerialNumber}
              /> */}
              <button onClick={sortByTime} type="button" className="btn btn-light sort-btn mx-3">Sort by:Time</button>
              <button onClick={sortByAZ} type="button" className="btn btn-light sort-btn">Sort by:A-Z</button>
            </div>
          </div>
        </div>
        {/* <div className="warranty-report-body"> */}<div className='position-relative' ref={ref}>
          <div className="chartTitle p-2 text-center">Warranty Report</div>
          <span className="export">
            {/* <SelectDays daysFilter={daysFilter} /> */}
            {/* <button className='mx-2 exportstyle' type='button' onClick={onButtonClick}>Export as PNG</button> */}
            {/* <img onClick={onButtonClick} className='dow-icon ml-2 mb-2' src={DownloadIcon} alt="" /> */}
          </span>
          <div className='warranty-report-chart'>
            <ReactFC {...chartConfigs} />
          </div>
        </div>
        {/* </div> */}
      </div>
      <SessionWarning />
    </>
  )
}

export default WarrantyByTimeChart;
