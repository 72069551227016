import React, { FC, useState, useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { Modal, Form, Input, Button, Select, Tooltip, ConfigProvider } from 'antd';
import LockResetTwoToneIcon from '@mui/icons-material/LockResetTwoTone';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined, MinusCircleOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import SelectToSearch from '../maintenance/selectToSearch';
import LoadingSpinner from '../spinner/loading-spinner'
import SessionWarning from '../reusableComponent/sessionWarning';
import PaginationComponent from "../reusableComponent/pagination";
import ShowEntries from "../reusableComponent/showEntries";

import { Utility } from "../../helper/index";
import * as roles from '../../settings/roles';
import { resize, hideSidebar } from '../../settings/resize';
import constant1 from '../../settings/constants';
import service from '../../service/api/common';

import { Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';

const req = {
  pageOffset: 1,
  pageLimit: 10,
  orgId: 0,
  email: "",
  firstName: "",
  lastName: "",
  locationId: -1,
  divisionId: -1,
  buildingId: -1,
  sortColumns: "firstName",
  sortDirection: "ASC"
}

type reqBody = {
  pageOffset: number,
  pageLimit: number,
  orgId: number,
  email: string,
  firstName: string,
  lastName: string,
  locationId: number,
  divisionId: number,
  buildingId: number,
  sortColumns: string,
  sortDirection: string
}

interface props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  refresh: number

}

const UserMaintenance: FC<props> = ({ globalData, refresh }) => {
  const [tableReqBody, setTableReqBody] = useState<reqBody>(req);
  const [permissions, setPermissions] = useState<any[]>([])
  const [countReqBody, setCountReqBody] = useState<reqBody>(req);
  const [show, setShow] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [reset, setReset] = useState(false)
  const [tableData, setTableData] = useState<any[]>([])
  const [orgList, setOrgList] = useState<null[]>([])
  const [divList, setDivList] = useState<null[]>([])
  const [locList, setLocList] = useState<null[]>([])
  const [siteList, setSiteList] = useState<null[]>([])
  const [successAddUser, setSuccessAddUser] = useState<boolean>(false)
  const [isResetPwd, setIsResetPwd] = useState<boolean>(false)
  const [emailCountValidation, setEmailCountValidation] = useState<string>("")
  const [emailForDelete, setEmailForDelete] = useState<any>("")
  const [userId, setUserId] = useState<number | null>(null)
  const [isUserDeleted, setIsUserDeleted] = useState<boolean>(false)
  const [isUserUpdated, setIsUserUpdated] = useState<boolean>(false)
  const [deleteResponse, setDeleteResponse] = useState<string>("")
  const [rolesList, setRolesList] = useState<any[]>([])
  const [toUpdate, setToUpdate] = useState<any>({ tenant: "", role: "", firstName: "", lastName: "", email: "", mobileNumber: "", homePhone: "", photoURL: "", temperatureFormat: "", organisationId: 0, locationId: 0 })
  const [getLoginUserId, setLoginUserId] = useState<string>("")
  const [addMsg, setAddMsg] = useState("User Submitted Successfully")
  const [sort, setSort] = useState<any>({ orgName: false, role: false, locationName: false, firstName: false, lastName: false, email: false, buildingName: false, mobileNumber: false, homePhone: false })
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [current, setCurrent] = useState<number>(1)
  const [orgid, setOrgID] = useState<number>(0)
  const [locid, setLocId] = useState<number>(0)
  const [divid, setDivId] = useState<number>(0)
  const [emailEmpty, setEmailEmpty] = useState<boolean>(false)
  const [firstEmpty, setFirstEmpty] = useState<boolean>(false)
  const [lastEmpty, setLastEmpty] = useState<boolean>(false)
  const [addpayload, setAddPayload] = useState<any>({
    selectedRole: "",
    selectedOrgId: "",
    email: "",
    firstname: "",
    lastname: "",
    mobile: "",
    home: "",
    photo: "",
    location: "",
    site: "",
    division: "",
    temp: "F"
  });

  const [callBySearch, setCallBySearch] = useState<boolean>(false)
  const [searchResult, setSearchResult] = useState<any[]>([])
  const [initial, setInitial] = useState<boolean>(true);
  const [value, setValue] = useState(1);

  const location = useLocation();

  const [form] = Form.useForm()
  const [updateForm] = Form.useForm()

  const platform_roles = [roles.PLATFORM_ADMIN, roles.PLATFORM_MANAGER];
  const dealer_roles = [roles.DEALER_ADMIN, roles.DEALER_MANAGER];
  const company_roles = [roles.COMPANY_ADMIN, roles.COMPANY_MANAGER];
  const location_roles = [roles.LOCATION_ADMIN, roles.LOCATION_MANAGER];
  const site_roles = [roles.SITE_ADMIN, roles.SITE_MANAGER];

  useLayoutEffect(() => {
    let timer: any
    const debouncedHandleResize = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        resize()
      }, 500)
    }
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  });

  useEffect(() => {
    setInitial(true);
    resize();
    hideSidebar();
    setLoginUserId(String(localStorage.getItem('userId')))

    let userInfo: any = Utility.getUserInfo();
    var loginDealerId = userInfo.userId;

    if (roles.PLATFORM_ADMIN === localStorage ?.UserType) {
      setValue(1);
      setRolesList(platform_roles);
    } else if (roles.DEALER_ADMIN === localStorage ?.UserType) {
      setValue(2);
      setRolesList(dealer_roles);
    } else if (roles.COMPANY_ADMIN === localStorage ?.UserType) {
      setValue(3);
      setRolesList(company_roles);
    } else if (roles.LOCATION_ADMIN === localStorage ?.UserType) {
      setValue(4);
      setRolesList(location_roles);
    } else if (roles.SITE_ADMIN === localStorage ?.UserType) {
      setValue(5);
      setRolesList(site_roles);
    }

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let orgId = data ?.orgId > -1 ? data ?.orgId : userId;

    getPermissions();

    if (roles.PLATFORM_ADMIN === localStorage ?.UserType) {
      getOrganization(0, 0, 0, 0);
    } else if (roles.DEALER_ADMIN === localStorage ?.UserType) {
      getOrganization(loginDealerId, 0, 0, 0);
    } else if (roles.LOCATION_ADMIN === localStorage ?.UserType) {
      Buildings(Number(localStorage ?.accountId), Number(localStorage ?.divisionId), Number(localStorage ?.locationId))
    } else if (roles.COMPANY_ADMIN === localStorage ?.UserType) {
      Locations(Number(localStorage ?.accountId), Number(localStorage ?.divisionId))
    }

    setTableReqBody({ ...tableReqBody, pageOffset: 1, pageLimit: 10, orgId: location.state ?.orgID > -1 ? location.state ?.orgID : orgId, divisionId: data ?.divId, locationId: data ?.locationId, buildingId: data ?.buildingId })
    setCountReqBody({ ...countReqBody, pageOffset: 1, pageLimit: 10, orgId: location.state ?.orgID > -1 ? location.state ?.orgID : orgId, divisionId: data ?.divId, locationId: data ?.locationId, buildingId: data ?.buildingId })
    getUsertable({ ...tableReqBody, pageOffset: 1, pageLimit: 10, orgId: location.state ?.orgID > -1 ? location.state ?.orgID : orgId, divisionId: data ?.divId, locationId: data ?.locationId, buildingId: data ?.buildingId })
    getCount({ ...tableReqBody, pageOffset: 1, pageLimit: 10, orgId: location.state ?.orgID > -1 ? location.state ?.orgID : orgId, divisionId: data ?.divId, locationId: data ?.locationId, buildingId: data ?.buildingId })

    if (roles.PLATFORM_ADMIN === localStorage ?.UserType) {
      setAddPayload({ ...addpayload, selectedRole: roles.DEALER_ADMIN })
    } else {
      setAddPayload({ ...addpayload, selectedRole: localStorage ?.UserType })
    }

    setInitial(false);
  }, [])

  const Buildings = async (orgid: any, divid: any, locid: any) => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    let url = constant1.api.HeaderFilters.getSites;

    let payload = {
      orgId: orgid,
      divisionId: divid,
      locationId: locid
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setSiteList(response)
    } catch (error) {
      console.log("siteDropdown:", error);
    }
  }

  useEffect(() => {
    if (initial === false) {
      setTableReqBody({ ...tableReqBody, pageOffset: 1, pageLimit: 10, orgId: globalData ?.orgId, divisionId: globalData ?.divId, locationId: globalData ?.locationId, buildingId: globalData ?.buildingId })
      setCountReqBody({ ...countReqBody, pageOffset: 1, pageLimit: 10, orgId: globalData ?.orgId, divisionId: globalData ?.divId, locationId: globalData ?.locationId, buildingId: globalData ?.buildingId })
      getUsertable({ ...tableReqBody, pageOffset: 1, pageLimit: 10, orgId: globalData ?.orgId, divisionId: globalData ?.divId, locationId: globalData ?.locationId, buildingId: globalData ?.buildingId })
      getCount({ ...tableReqBody, pageOffset: 1, pageLimit: 10, orgId: globalData ?.orgId, divisionId: globalData ?.divId, locationId: globalData ?.locationId, buildingId: globalData ?.buildingId })
      window.history.replaceState({}, document.title)
      setCurrent(1)
    }
  }, [globalData ?.orgId, globalData ?.divId, globalData ?.locationId, globalData ?.buildingId])

  useEffect(() => {
    if (refresh === 0) return
    getUsertable({ ...tableReqBody })
    getCount({ ...tableReqBody })
  }, [refresh])

  async function getUsertable(load: any) {
    let userInfo: any = Utility.getUserInfo();
    var gettoken = userInfo.authToken;
    let payload = load;

    setIsShowSpinner(true)
    var url = constant1.api.dashboard.getUsers;

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + gettoken },
        data: payload,
      });

      const getUserList = await response;
      if (getUserList.status === "SUCCESS" && getUserList.errorCode === null) {
        let getTableData = getUserList.userDTOS
        setTableData(getTableData)
      }
      setIsShowSpinner(false)
    } catch (error) {
      console.error("getOrg:", error);
      setIsShowSpinner(false)
    }
  }

  const getCount = async (load: any) => {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    var url = constant1.api.dashboard.getUsersCount;
    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: load
      });
      setTotalCount(response)
    } catch (error) {
      console.log(error);
    }
  }

  async function getOrganization(orgid: any, divid: any, locid: any, siteid: any) {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    let payload = {
      "orgId": orgid,
      "divisionId": divid,
      "locationId": locid,
      "buildingId": siteid
    }

    try {
      const response = await service({
        url: constant1.api.dashboard.getOrganizations,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });

      setOrgList(response);
    } catch (error) {
      console.log("getOrg:", error);
    }
  }

  useEffect(() => {
    if (initial === false) {
      if (addpayload ?.selectedOrgId !== 0) {
        getDivisions();
        getLocations();
      }
    }
  }, [addpayload ?.selectedOrgId])

  useEffect(() => {
    if (initial === false) {
      if (addpayload ?.division > 0) {
        getLocations();
      }
    }
  }, [addpayload ?.division])

  useEffect(() => {
    if (initial === false) {
      if (locid > 0) {
        getBuildings();
      }
    }
  }, [locid])

  useEffect(() => {
    if (initial === false) {
      if (addpayload.location > 0) {
        getBuildings1();
      }
    }
  }, [addpayload ?.location])

  async function getDivisions() {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    let userId = userInfo.userId;

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let orgId = addpayload ?.selectedOrgId > 0 ? addpayload ?.selectedOrgId : data ?.orgId > -1 ? data ?.orgId : userId;

    var url = constant1.api.location.getDivisions;
    let payload = { orgId: orgId };

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setDivList(response)
    } catch (error) {
      console.log("getDivisions:", error);
    }
  }

  async function getLocations() {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    let userId: number = Number(userInfo.userId);

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let url = constant1.api.dashboard.getLocations;

    let orgId = addpayload ?.selectedOrgId > 0 ? addpayload ?.selectedOrgId : data ?.orgId > -1 ? data ?.orgId : userId;

    let divId = addpayload ?.division > -1 ? addpayload ?.division : -1;
    let payload = {
      "orgId": orgId,
      "divisionId": divId,
      "locationId": data ?.locationId,
      "buildingId": data ?.buildingId
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setLocList(response)
    } catch (error) {
      console.log("getLocations:", error);
    }
  }

  async function Locations(orgid: any, divid: any) {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;

    let url = constant1.api.dashboard.getLocations;

    let payload = {
      "orgId": orgid,
      "divisionId": divid,
      "locationId": -1,
      "buildingId": -1
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setLocList(response)
    } catch (error) {
      console.log("getLocations:", error);
    }
  }

  async function getBuildings1() {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    let userId = userInfo.userId;
    let url = constant1.api.HeaderFilters.getSites;

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    let orgId = addpayload ?.selectedOrgId > 0 ? addpayload ?.selectedOrgId : data ?.orgId > -1 ? data ?.orgId : userId
    let divId = addpayload ?.division > -1 ? addpayload ?.division : -1;
    let locId = addpayload ?.location > -1 ? addpayload ?.location : -1;

    if (roles.COMPANY_ADMIN === localStorage ?.UserType) {
      orgId = Number(localStorage ?.accountId);
      divId = Number(localStorage ?.divisionId)
    } else if (roles.LOCATION_ADMIN === localStorage ?.UserType) {
      orgId = Number(localStorage ?.accountId);
      divId = Number(localStorage ?.divisionId)
      locId = Number(localStorage ?.locationId)
    }

    let payload = {
      orgId: orgId,
      divisionId: divId,
      locationId: locId
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setSiteList(response)
    } catch (error) {
      console.log("siteDropdown:", error);
    }
  }

  async function getPermissions() {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    let url = constant1.api.user.getPermissions + localStorage ?.UserType;

    try {
      const response = await service({
        url: url,
        method: "GET",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
      });

      setPermissions(response ?.data)
    } catch (error) {
      console.log("permissionsError:", error);
    }
  }

  async function getBuildings() {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    let userId = userInfo.userId;
    let org = orgid ? orgid : userId

    let url = constant1.api.HeaderFilters.getSites;

    let payload = {
      orgId: org,
      divisionId: divid,
      locationId: locid
    }

    try {
      const response = await service({
        url: url,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: payload
      });
      setSiteList(response)
    } catch (error) {
      console.log("siteDropdown:", error);
    }
  }

  const showAddUser = () => {
    setShow(!show)
    form.resetFields();

    if (roles.PLATFORM_ADMIN === localStorage ?.UserType) {
      setValue(1);
      setRolesList(platform_roles)
    } else if (roles.DEALER_ADMIN === localStorage ?.UserType) {
      setValue(2);
      setRolesList(dealer_roles)
    } else if (roles.COMPANY_ADMIN === localStorage ?.UserType) {
      setValue(3);
      setRolesList(company_roles)
    } else if (roles.LOCATION_ADMIN == localStorage ?.UserType) {
      setValue(4);
      setRolesList(location_roles)
    } else if (roles.SITE_ADMIN == localStorage ?.UserType) {
      setValue(5);
      setRolesList(site_roles)
    }

    setAddPayload({
      ...addpayload, firstname: "", lastName: "", email: "", selectedOrgId: Number(localStorage ?.accountId),
      home: "", photo: "", temp: "", selectedRole: ""
    })

    if (roles.PLATFORM_ADMIN === localStorage ?.UserType) {
      setAddPayload({ ...addpayload, selectedRole: roles.DEALER_ADMIN })
    } else {
      setAddPayload({ ...addpayload, selectedRole: localStorage ?.UserType })
    }
  }

  const showUpdateUser = (tenant: string, role: string, firstName: string, lastName: string, email: string, mobileNumber: string, homePhone: string, photoURL: string, temperatureFormat: string, organisation: any, locationId: number, divisionId: number, buildingId: number, locationName: string, buildingName: string, divisionName: string, orgName: string, orgId: number) => {
    setToUpdate({ ...toUpdate, tenant, role, firstName, lastName, email, mobileNumber, homePhone, organisation, locationId, divisionId, buildingId, locationName, buildingName, divisionName, orgName, orgId, photoURL, temperatureFormat })
    setIsModalOpen(true);
    updateForm.setFieldsValue({
      u_selectRole: role,
      u_orgName: orgName,
      u_divisionName: divisionName,
      u_locationName: locationName,
      u_siteName: buildingName,
      u_firstName: firstName,
      u_lastName: lastName,
      u_mobileNumber: mobileNumber,
      u_homePhone: homePhone,
      email: email,
      u_photoUrl: photoURL,
      u_temp: temperatureFormat
    })
  };

  const selectLocation = (value: any) => {
    updateForm.setFieldsValue({ u_locationName: value })
    let u_locList: any = locList ?.find((item: any) => item ?.locationName === value)
    setToUpdate({ ...toUpdate, locationId: u_locList ?.['locId'], locationName: u_locList ?.['locationName'] })
    u_locList ?.['locId'] && setLocId(u_locList['locId'])
  };

  const handleOk = async (value: any) => {
    let userInfo: any = Utility.getUserInfo();
    var gettoken = userInfo.authToken;

    const { u_selectRole, u_orgName, u_divisionName, u_locationName, u_siteName, u_firstName, u_lastName, u_mobileNumber,
      u_homePhone, email, u_photoUrl, u_temp } = value

    const { tenant, locationId, divisionId, buildingId, orgId } = toUpdate

    let toUpdateData = {
      tenant: tenant,
      role: u_selectRole,
      firstName: u_firstName,
      lastName: u_lastName,
      email: email,
      mobileNumber: u_mobileNumber,
      homePhone: u_homePhone,
      photoURL: u_photoUrl,
      temperatureFormat: u_temp,
      organisationId: null,
      locationId: locationId,
      divisionId: divisionId,
      buildingId: buildingId,
      locationName: u_locationName,
      buildingName: u_siteName,
      divisionName: u_divisionName,
      orgName: u_orgName,
      orgId: orgId
    }
    try {
      const response = await service({
        url: constant1.api.user.toUpdateUser,
        method: "PUT",
        headers: {
          accept: "*/*",
          Authorization: "Bearer " + gettoken
        },
        data: toUpdateData
      });
      const data = await response;
      if (data.status === "SUCCESS" && data.errorCode === null) {
        setIsModalOpen(false);
        getUsertable({ ...tableReqBody })
        setIsUserUpdated(true)
      }
    } catch (error) {
      console.log("updateUserError:", error);
    }
  }

  const deleteUserList = (Id: number) => {
    setDeleteUser(!deleteUser)
    setUserId(Id)
  }

  const DeleteOk = async () => {
    let userInfo: any = Utility.getUserInfo();
    var gettoken = userInfo.authToken;

    try {
      const response = await service({
        url: constant1.api.user.deleteUser + userId,
        method: "DELETE",
        headers: { accept: "*/*", Authorization: "Bearer " + gettoken },
      });
      const data = await response;
      setDeleteResponse("User Deactivated Successfully")
      setDeleteUser(!deleteUser);
      setIsUserDeleted(!isUserDeleted)
    } catch (error) {
      console.warn("Errors:", error)
    }
  };

  const CancelDeleteUser = () => setDeleteUser(false);

  const resetPassword = (email: string) => {
    setReset(!reset)
    setEmailForDelete(email)
  }

  const resetOk = () => {
    setReset(false);
    isResetPassword()
  }

  async function isResetPassword() {
    let userInfo: any = Utility.getUserInfo();
    var gettoken = userInfo.authToken;
    let payload = {
      emailAddress: emailForDelete
    }
    try {
      const response = await service({
        url: constant1.api.resetPwd.reset,
        method: "POST",
        headers: {
          accept: "*/*",
          Authorization: "Bearer " + gettoken,
        },
        data: payload
      });
      const data = await response;
      if (data.status === "SUCCESS") {
        setEmailCountValidation("Temporary password is sent to the mail Id. Please reset your password after login.")
        setIsResetPwd(true)
      } else if (data.status === "FAILURE" && data.errorCode === 4112 && data.errorMessage === "Maximum reset password request count reached per day") {
        setEmailCountValidation(data.errorMessage)
        setIsResetPwd(true)
      }
    } catch (error) {
      console.warn(error);
    }
  }

  const resetCancle = () => setReset(false);

  async function onAddFinish() {
    let userInfo: any = Utility.getUserInfo();
    var gettoken = userInfo.authToken;

    let payload: any = {
      "firstName": addpayload ?.firstname,
      "lastName": addpayload ?.lastname,
      "email": addpayload ?.email,
      "mobileNumber": addpayload ?.mobile,
      "orgId": addpayload ?.selectedOrgId,
      "homePhone": addpayload ?.home,
      "photoURL": addpayload ?.photo,
      "role": addpayload ?.selectedRole,
      "temperatureFormat": addpayload ?.temp,
      "divisionId": addpayload ?.division > 0 ? addpayload ?.division : -1,
      "locationId": addpayload ?.location > 0 ? addpayload ?.location : -1,
      "buildingId": addpayload.site > 0 ? addpayload.site : -1
    }

    if (roles.COMPANY_ADMIN === localStorage ?.UserType) {
      payload.orgId = Number(localStorage ?.accountId);
      payload.divisionId = Number(localStorage ?.divisionId);
    }

    if (roles.LOCATION_ADMIN === localStorage ?.UserType) {
      payload.orgId = Number(localStorage ?.accountId);
      payload.divisionId = Number(localStorage ?.divisionId);
      payload.locationId = Number(localStorage ?.locationId);
    }

    let valid: boolean = true;

    if (value === 1 || value === 2) {
      // payload["divisionId"] = -1;
      // payload["locationId"] = -1;
      // payload["buildingId"] = -1;
    } else if (value === 3) {
      // payload["divisionId"] = addpayload.division;
      // payload["locationId"] = -1;
      // payload["buildingId"] = -1;
      if (payload.divisionId > 0) { valid = true } else { valid = false }
    } else if (value === 4) {
      // payload["divisionId"] = addpayload.division;
      // payload["locationId"] = addpayload?.location;
      // payload["buildingId"] = -1;
      if (payload.divisionId > 0 && payload ?.locationId > 0) { valid = true } else { valid = false }
    } else if (value === 5) {
      // payload["divisionId"] = addpayload.division;
      // payload["locationId"] = addpayload.location;
      // payload["buildingId"] = addpayload.site;
      if (payload.divisionId > 0 && payload.locationId > 0 && payload.buildingId > 0) { valid = true } else { valid = false }
    }

    console.log("payload", payload)

    if (valid && addpayload ?.email && addpayload ?.firstname && addpayload ?.selectedRole && payload.orgId) {
      try {
        const response = await service({
          url: constant1.api.user.addUserAll,
          method: "POST",
          headers: {
            accept: "*/*",
            "content-type": "application/json",
            Authorization: "Bearer " + gettoken,
          },
          data: payload,
        });
        const data = await response;
        if (data.status === "SUCCESS" && data.errorMessage === null) {
          getUsertable({ ...tableReqBody })
          setAddMsg("User Submitted Successfully")
          setShow(false)
          setSuccessAddUser(true)
          setAddPayload({
            ...addpayload, firstname: "", lastName: "", email: "", selectedOrgId: Number(localStorage ?.accountId),
            home: "", photo: "", temp: "", selectedRole: ""
          })
        }
        if (data.status === "FAILURE" && data.errorMessage === "User already exists") {
          form.setFields([{ name: 'email', errors: [data.errorMessage] }]);
        }
        if (data.status === "FAILURE" && data.errorMessage === "Invalid firstName") {
          form.setFields([{ name: 'firstName', errors: [data.errorMessage] }]);
        }
        if (data.status === "FAILURE" && data.errorCode === 5002) {
          setAddMsg(data.errorMessage)
          setSuccessAddUser(true)
          setShow(false)
        }


      } catch (error) {
        console.warn(error);
      }
    }
  }

  const validateMessages = {
    required: "Please Enter Email Address",
    types: { email: "Email is invalid" }
  }

  const updateValidateMessages = {
    required: "Please Enter Email Address",
    types: { email: "Email is invalid" }
  }

  const submitUserOk = () => {
    setSuccessAddUser(false);
    window.location.reload()
  }

  const okPwdResetSuccess = () => setIsResetPwd(!isResetPwd)

  const onSelect = (getPageSize: string) => {
    const numPageSize = Number(getPageSize)
    setCallBySearch(!callBySearch)
    setPageSize(numPageSize)
    setCurrent(1);
    setTableReqBody({ ...tableReqBody, pageLimit: Number(getPageSize), pageOffset: 1 })
    getUsertable({ ...tableReqBody, pageLimit: Number(getPageSize), pageOffset: 1 })
  }

  const handlePagination = (pageNumber: number) => {
    setCallBySearch(!callBySearch)
    setTableReqBody({ ...tableReqBody, pageOffset: pageNumber })
    getUsertable({ ...tableReqBody, pageOffset: pageNumber })
    setCurrent(pageNumber);
  }

  const okDeletedUser = () => { setIsUserDeleted(false); window.location.reload() }
  const okUpdateUser = () => { setIsUserUpdated(false); window.location.reload() }

  const sortBy = (id: string) => {
    var getIdboolean
    if (id) getIdboolean = !sort[id]
    for (let sortLabel in sort) {
      switch (id) {
        case sortLabel:
          if (sort[sortLabel]) {
            setTableReqBody({ ...tableReqBody, sortColumns: id, sortDirection: "ASC", pageOffset: 1 })
            getUsertable({ ...tableReqBody, sortColumns: id, sortDirection: "ASC", pageOffset: 1 })
            setCurrent(1)
            setSort({ ...sort, [id]: getIdboolean })
          } else {
            setTableReqBody({ ...tableReqBody, sortColumns: id, sortDirection: "DESC", pageOffset: 1 })
            getUsertable({ ...tableReqBody, sortColumns: id, sortDirection: "DESC", pageOffset: 1 })
            setCurrent(1)
            setSort({ ...sort, [id]: getIdboolean })
          }
      }
    }
  };

  const applyAndRemoveFilters = (tableData?: []) => {
    tableData ? setTableData(tableData) : getUsertable({ ...tableReqBody })
  }

  var timer: any;

  const searchBy = (particalvalue: string, value: string) => {
    let val = value ? value ?.trim() : "";
    setEmailEmpty(false)
    setFirstEmpty(false)
    setLastEmpty(false)
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (particalvalue === 'email') {
        if (val === "") {
          setCountReqBody({ ...countReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          setTableReqBody({ ...tableReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          setSearchResult([]);
          getCount({ ...tableReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          getUsertable({ ...tableReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          setCallBySearch(!callBySearch)
        } else {
          setTableReqBody({ ...tableReqBody, email: val, firstName: "", lastName: "", pageOffset: 1 })
          getUsertable({ ...tableReqBody, email: val, firstName: "", lastName: "", pageOffset: 1 })
          setCountReqBody({ ...tableReqBody, email: val, firstName: "", lastName: "", pageOffset: 1 })
          getCount({ ...tableReqBody, email: val, firstName: "", lastName: "", pageOffset: 1 })
        }
      } else if (particalvalue === 'firstName') {
        if (val === "") {
          setCountReqBody({ ...countReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          setTableReqBody({ ...tableReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          setSearchResult([]);
          getCount({ ...tableReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          getUsertable({ ...tableReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          setCallBySearch(!callBySearch)
        } else {
          setTableReqBody({ ...tableReqBody, email: '', firstName: val, lastName: "", pageOffset: 1 })
          getUsertable({ ...tableReqBody, email: '', firstName: val, lastName: "", pageOffset: 1 })
          setCountReqBody({ ...tableReqBody, email: '', firstName: val, lastName: "", pageOffset: 1 })
          getCount({ ...tableReqBody, email: '', firstName: val, lastName: "", pageOffset: 1 })
        }
      } else if (particalvalue === 'lastName') {
        if (val === "") {
          setCountReqBody({ ...countReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          setTableReqBody({ ...tableReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          setSearchResult([]);
          getCount({ ...tableReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          getUsertable({ ...tableReqBody, email: "", firstName: "", lastName: "", pageOffset: 1 })
          setCallBySearch(!callBySearch)
        } else {
          setTableReqBody({ ...tableReqBody, email: '', firstName: '', lastName: val, pageOffset: 1 })
          getUsertable({ ...tableReqBody, email: "", firstName: "", lastName: val, pageOffset: 1 })
          setCountReqBody({ ...tableReqBody, email: '', firstName: '', lastName: val, pageOffset: 1 })
          getCount({ ...tableReqBody, email: '', firstName: '', lastName: val, pageOffset: 1 })
        }
      }
      setCurrent(1)
    }, 1000)
  };

  const callOnClick = (particalvalue: string, value: string) => {
    if (particalvalue === 'email') {
      if (value === "") {
        setEmailEmpty(true)
        setFirstEmpty(false)
        setLastEmpty(false)
      } else {
        setCountReqBody({ ...countReqBody, email: value, firstName: "", lastName: "", pageOffset: 1 })
        setTableReqBody({ ...tableReqBody, email: value, firstName: "", lastName: "", pageOffset: 1 })
        getUsertable({ ...tableReqBody, email: value, firstName: "", lastName: "", pageOffset: 1 })
        getCount({ ...tableReqBody, email: value, firstName: "", lastName: "", pageOffset: 1 })
      }
    } else if (particalvalue === 'firstName') {
      if (value === "") {
        setEmailEmpty(false)
        setFirstEmpty(true)
        setLastEmpty(false)
      } else {
        setCountReqBody({ ...countReqBody, email: "", firstName: value, lastName: "", pageOffset: 1 })
        setTableReqBody({ ...tableReqBody, email: "", firstName: value, lastName: "", pageOffset: 1 })
        getUsertable({ ...tableReqBody, email: "", firstName: value, lastName: "", pageOffset: 1 })
        getCount({ ...tableReqBody, email: "", firstName: value, lastName: "", pageOffset: 1 })
      }
    } else if (particalvalue === 'lastName') {
      if (value === "") {
        setEmailEmpty(false)
        setFirstEmpty(false)
        setLastEmpty(true)
      } else {
        setCountReqBody({ ...countReqBody, email: "", firstName: "", lastName: value, pageOffset: 1 })
        setTableReqBody({ ...tableReqBody, email: "", firstName: "", lastName: value, pageOffset: 1 })
        getUsertable({ ...tableReqBody, email: "", firstName: "", lastName: value, pageOffset: 1 })
        getCount({ ...tableReqBody, email: "", firstName: "", lastName: value, pageOffset: 1 })
      }
    }
    setCurrent(1);
    setCallBySearch(!callBySearch)
  }

  const onClear = () => {
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    setCountReqBody({ ...countReqBody, pageOffset: 1, orgId: data ?.dealerId })
    setTableReqBody({ ...tableReqBody, pageOffset: 1, orgId: data ?.dealerId })
    getCount({ ...tableReqBody, pageOffset: 1, orgId: data ?.dealerId })
    getUsertable({ ...tableReqBody, pageOffset: 1, orgId: data ?.dealerId })
    setCurrent(1)
  }

  const SearchOnOptionSelect = (optionValue: string, value: string) => {
    if (optionValue === 'email') {
      setCountReqBody({ ...countReqBody, email: value, firstName: "", lastName: "", pageOffset: 1 })
      setTableReqBody({ ...tableReqBody, email: value, firstName: "", lastName: "", pageOffset: 1 })
      getUsertable({ ...tableReqBody, email: value, firstName: "", lastName: "", pageOffset: 1 })
      getCount({ ...tableReqBody, email: value, firstName: "", lastName: "", pageOffset: 1 })
    } else if (optionValue === 'firstName') {
      setCountReqBody({ ...countReqBody, email: "", firstName: value, lastName: "", pageOffset: 1 })
      setTableReqBody({ ...tableReqBody, email: "", firstName: value, lastName: "", pageOffset: 1 })
      getUsertable({ ...tableReqBody, email: "", firstName: value, lastName: "", pageOffset: 1 })
      getCount({ ...tableReqBody, email: "", firstName: value, lastName: "", pageOffset: 1 })
    } else if (optionValue === 'lastName') {
      setCountReqBody({ ...countReqBody, email: "", firstName: "", lastName: value, pageOffset: 1 })
      setTableReqBody({ ...tableReqBody, email: "", firstName: "", lastName: value, pageOffset: 1 })
      getUsertable({ ...tableReqBody, email: "", firstName: "", lastName: value, pageOffset: 1 })
      getCount({ ...tableReqBody, email: "", firstName: "", lastName: value, pageOffset: 1 })
    }
    setCurrent(1);
    setCallBySearch(!callBySearch)
  }

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    form.resetFields();
    if (e.target.value === 1) {
      setRolesList(platform_roles);
    } else if (e.target.value === 2) {
      setRolesList(dealer_roles);
    } else if (e.target.value === 3) {
      setRolesList(company_roles);
    } else if (e.target.value === 4) {
      setRolesList(location_roles);
    } else if (e.target.value === 5) {
      setRolesList(site_roles);
    }
  };

  const handleSelectChange = (value: any) => {
    setToUpdate({ ...toUpdate, temperatureFormat: value });
  };

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>

      <div className='container-fluid px-0 '>
        {localStorage.getItem('permissions') ?.includes('PlatformAdminDealerUser_W') ? <>
          <div className=" row nav-filter align-items-center">
            <div className="col-lg-2 col-md-4 col-sm-12  tab_content-battery">
              <span onClick={showAddUser} className="d-flex align-items-center  ">
                {show ? <MinusCircleOutlined /> : <PlusCircleOutlined />}    <span className="pl-2"> Add New Users</span> </span>
            </div>
          </div>
        </> : <></>}

        {show ? <>
          <div className={show ? "show-user  add-user" : 'hide-user add-user'} >
            <ConfigProvider theme={{ token: { colorPrimary: '#004f9f' } }}>
              <Form layout='vertical' form={form} validateMessages={validateMessages}>
                <div className='row'>
                  <div className='col-6'>
                    <Form.Item style={{ marginBottom: "5px" }}
                      label="Select User Type">
                      <Radio.Group onChange={onChange} value={value}>
                        {(localStorage ?.UserType === roles.PLATFORM_ADMIN) ? <Radio value={1}>Power</Radio> : <></>}
                        {(localStorage ?.UserType === roles.PLATFORM_ADMIN || localStorage ?.UserType === roles.DEALER_ADMIN) ? <Radio value={2}>Dealer</Radio> : <></>}
                        {(localStorage ?.UserType === roles.PLATFORM_ADMIN || localStorage ?.UserType === roles.DEALER_ADMIN || localStorage ?.UserType === roles.COMPANY_ADMIN) ? <Radio value={3}>Company</Radio> : <></>}
                        {(localStorage ?.UserType === roles.PLATFORM_ADMIN || localStorage ?.UserType === roles.DEALER_ADMIN || localStorage ?.UserType === roles.COMPANY_ADMIN || localStorage ?.UserType === roles.LOCATION_ADMIN) ? <Radio value={4}>Location</Radio> : <></>}
                        {(localStorage ?.UserType === roles.PLATFORM_ADMIN || localStorage ?.UserType === roles.DEALER_ADMIN || localStorage ?.UserType === roles.COMPANY_ADMIN || localStorage ?.UserType === roles.LOCATION_ADMIN || localStorage ?.UserType === roles.SITE_ADMIN) ? <Radio value={5}>Site</Radio> : <></>}
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className='col-6'>
                    <Form.Item style={{ marginBottom: "5px" }}
                      label="Select Role"
                      rules={[{ required: true, message: 'Please Select Role' }]}
                      name="selectedRole"
                    >
                      <Select
                        dropdownStyle={{ zIndex: 900 }}
                        placeholder="Select Role"
                        value={addpayload.selectedRole}
                        onSelect={(value) => { setAddPayload({ ...addpayload, selectedRole: value }); }}>
                        {rolesList && rolesList.map((item: any, index: any) => {
                          return <Select.Option key={index} value={item}>{item}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                  {
                    (roles.PLATFORM_ADMIN === localStorage ?.UserType ||
                      roles.DEALER_ADMIN === localStorage ?.UserType) ?
                      <div className='col-6'>
                        <Form.Item style={{ marginBottom: "5px" }}
                          name='orgId'
                          label="Organization"
                          rules={[{ required: true, message: 'Please Select Organization' }]}
                        >
                          <Select
                            dropdownStyle={{ zIndex: 900 }}
                            placeholder="Select Organization" value={addpayload ?.selectedOrgId} onSelect={(value) => { setAddPayload({ ...addpayload, selectedOrgId: value, division: "", location: "", site: "" }); form.setFieldsValue({ division: null, location: null, site: null }) }}>
                            {orgList && orgList.map((item: any, index: any) => {
                              return <Select.Option key={index} value={item.orgId}>{item.orgName}</Select.Option>
                            })}
                          </Select>
                        </Form.Item>
                      </div> :
                      <div className='col-6'>
                        <Form.Item style={{ marginBottom: "5px" }} label="Organization">
                          <Select value={localStorage ?.accountName} disabled></Select>
                        </Form.Item>
                      </div>
                  }
                  {
                    (value === 3 || value === 4 || value === 5) ?
                      <>
                        {
                          (roles.PLATFORM_ADMIN === localStorage ?.UserType ||
                            roles.DEALER_ADMIN === localStorage ?.UserType) ?
                            <div className='col-6'>
                              <Form.Item
                                style={{ marginBottom: "5px" }}
                                label="Division"
                                name="division"
                                rules={[{ required: true, message: 'Please Select Division' }]}
                              >
                                <Select
                                  dropdownStyle={{ zIndex: 900 }}
                                  placeholder="Select Division" onSelect={(value) => { setAddPayload({ ...addpayload, division: value, location: "", site: "" }); form.setFieldsValue({ location: null, site: null }) }}>
                                  {divList ?.map((item: any, index: any) => {
                                    return <Select.Option key={index + 'c'} value={item ?.divisionId}>{item ?.divisionName}</Select.Option>
                                  })}
                                </Select>
                              </Form.Item>
                            </div> : <div className='col-6'>
                              <Form.Item
                                style={{ marginBottom: "5px" }}
                                label="Division">
                                <Select disabled value={localStorage ?.divisionName}>

                                </Select>
                              </Form.Item>
                            </div>


                        }

                      </>
                      : <></>
                  }
                  {
                    (value == 4 || value == 5) ?
                      <>
                        {
                          (roles.PLATFORM_ADMIN === localStorage ?.UserType ||
                            roles.DEALER_ADMIN === localStorage ?.UserType || roles.COMPANY_ADMIN === localStorage ?.UserType) ?
                            <div className='col-6'>
                              <Form.Item
                                style={{ marginBottom: "5px" }}
                                label="Location"
                                name="location"
                                rules={[{ required: true, message: 'Please Select Location' }]}
                                required>
                                <Select
                                  dropdownStyle={{ zIndex: 900 }}
                                  placeholder="Select Location" onSelect={(value) => { setAddPayload({ ...addpayload, location: value, site: "" }); form.setFieldsValue({ site: null }) }}>
                                  {locList ?.map((item: any, index: any) => {
                                    return <Select.Option key={index} value={item ?.locId}>{item ?.locationName}</Select.Option>
                                  })}
                                </Select>
                              </Form.Item>
                            </div> :
                            <div className='col-6'>
                              <Form.Item
                                style={{ marginBottom: "5px" }}
                                label="Location">
                                <Select disabled value={localStorage ?.locationName}>

                                </Select>
                              </Form.Item>
                            </div>
                        }

                      </>
                      : <></>
                  }
                  {
                    (value == 5) ?
                      <div className='col-6'>
                        <Form.Item style={{ marginBottom: "5px" }}
                          label="Site"
                          name="site"
                          rules={[{ required: true, message: 'Please Select Site' }]}>
                          <Select
                            dropdownStyle={{ zIndex: 900 }}
                            placeholder="Select Site" onSelect={(value) => { setAddPayload({ ...addpayload, site: value }) }}>
                            {siteList ?.map((item: any, index: any) => {
                              return <Select.Option key={index} value={item ?.id}>{item ?.buildingName}</Select.Option>
                            })}
                          </Select>
                        </Form.Item>
                      </div> : <></>
                  }
                  <div className='col-6'>
                    <Form.Item
                      name='email'
                      label="Email"
                      rules={[{ type: 'email', required: true }]}
                      style={{ marginBottom: "5px" }}
                      required
                    >
                      <Input placeholder="Enter Email Address" onChange={(e) => { setAddPayload({ ...addpayload, email: e.target.value }) }} />
                    </Form.Item>
                  </div>
                  <div className='col-6'>
                    <Form.Item style={{ marginBottom: "5px" }}
                      label="First Name"
                      name="firstName"
                      rules={[{ required: true, message: 'Please Enter Your First Name' }]}
                      required
                    >
                      <Input placeholder="Enter First Name" onChange={(e) => { setAddPayload({ ...addpayload, firstname: e.target.value }) }} />
                    </Form.Item>
                  </div>
                  <div className='col-6'>
                    <Form.Item style={{ marginBottom: "5px" }}
                      label="Last Name"
                      name="lastName"
                    >
                      <Input placeholder="Enter Last Name" onChange={(e) => { setAddPayload({ ...addpayload, lastname: e.target.value }) }} />
                    </Form.Item>
                  </div>
                  <div className='col-6'>
                    <Form.Item
                      name="mobileNumber"
                      label="Mobile No"
                      rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: "Only numbers are allowed" }]}
                      style={{ marginBottom: "5px" }}
                    >
                      <Input type="tel" placeholder="Enter Mobile No" maxLength={10} onChange={(e) => { setAddPayload({ ...addpayload, mobile: e.target.value }) }} />
                    </Form.Item>
                  </div>
                  <div className='col-6'>
                    <Form.Item
                      name="homePhone"
                      label="Telephone No"
                      rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: "Only numbers are allowed" }]}
                      style={{ marginBottom: "5px" }}
                    >
                      <Input type="tel" placeholder="Enter Phone No" maxLength={10} onChange={(e) => { setAddPayload({ ...addpayload, home: e.target.value }) }} />
                    </Form.Item>
                  </div>
                  <div className='col-6'>
                    <Form.Item
                      name="photoURL"
                      label="Photo URL"
                      style={{ marginBottom: "5px" }}
                    >
                      <Input placeholder="Enter Photo URL" onChange={(e) => { setAddPayload({ ...addpayload, photo: e.target.value }) }} />
                    </Form.Item>
                  </div>
                  <div className='col-6'>
                    <Form.Item
                      name="temperatureFormat"
                      label="Temperature"
                      style={{ marginBottom: "5px" }}>
                      <Select
                        defaultValue="F"
                        onSelect={(value) => { setAddPayload({ ...addpayload, temp: value }) }}>
                        <Select.Option value="F">Fahrenheit</Select.Option>
                        <Select.Option value="C">Celsius</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='d-flex mt-3'>
                  <div>
                    <Form.Item style={{ marginBottom: "0px" }}>
                      <Button onClick={showAddUser} style={{ background: '#004f9f', borderBlockColor: "#004f9f", color: "white" }}>Cancel</Button>
                    </Form.Item>
                  </div>
                  <div className='pl-3'>
                    <Form.Item style={{ marginBottom: "0px" }}>
                      <Button onClick={onAddFinish} style={{ background: '#004f9f', borderBlockColor: "#004f9f", color: "white" }} htmlType='submit'>Add</Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </ConfigProvider>
          </div>
        </> : <></>}
      </div>

      {/* "Modify User Information" */}
      <div className='user'>
        <ConfigProvider
          theme={{ token: { colorPrimary: '#004f9f' } }}>
          <Modal
            className='update-users'
            title="Modify User Information"
            centered open={isModalOpen}
            footer={null}
            onCancel={() => { setIsModalOpen(false) }}
          >
            <Form className='user-form'
              style={{ padding: "0" }}
              form={updateForm}
              layout='vertical'
              onFinish={handleOk}
              validateMessages={updateValidateMessages}
            >
              <div className='row'>
                <div className='col-6'>
                  <Form.Item style={{ marginBottom: "5px" }}
                    label="Select Role"
                    name='u_selectRole'
                  >
                    <Select value={toUpdate.role}
                      disabled
                      onChange={(value: any) => setToUpdate({ ...toUpdate, role: value })}
                      placeholder="Select Role">
                      {rolesList && rolesList.map((item: any, index: any) => {
                        return <Select.Option key={index} value={item}>{item}</Select.Option>
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className='col-6'>
                  <Form.Item
                    style={{ marginBottom: "5px" }}
                    label="Organization"
                    name='u_orgName'
                  >
                    <Select
                      disabled
                      value={toUpdate.orgName}
                      style={{ marginBottom: "10px" }}
                      placeholder="Select Organization" onSelect={(value) => { setOrgID(value) }}>
                      {orgList ?.map((item: any, index: any) => {
                        return <Select.Option key={index} value={item.orgName}>{item.orgName}</Select.Option>
                      })}
                    </Select>
                  </Form.Item>
                </div>
                {
                  (localStorage.UserType === roles.COMPANY_ADMIN || localStorage.UserType === roles.LOCATION_ADMIN ||
                    localStorage.UserType === roles.SITE_ADMIN || localStorage.UserType === roles.COMPANY_MANAGER ||
                    localStorage.UserType === roles.LOCATION_MANAGER || localStorage.UserType === roles.SITE_MANAGER) ?
                    <div className='col-6'>
                      <Form.Item
                        style={{ marginBottom: "5px" }}
                        label="Division"
                        name='u_divisionName'
                      >
                        <Select
                          disabled
                          value={toUpdate.divisionName}
                          style={{ marginBottom: "10px" }}
                          placeholder="Select Division"
                          onSelect={(value) => { setDivId(value) }}
                        >
                          <Select.Option value={0}>Any</Select.Option>
                          {divList ?.map((item: any, index: any) => {
                            return <Select.Option key={index} value={item ?.divisionName}>{item ?.divisionName}</Select.Option>
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    : <></>
                }
                {(
                  localStorage.UserType === roles.LOCATION_MANAGER || localStorage.UserType === roles.SITE_MANAGER ||
                  localStorage.UserType === roles.LOCATION_ADMIN || localStorage.UserType === roles.SITE_ADMIN) ?
                  <div className='col-6'>
                    <Form.Item
                      style={{ marginBottom: "5px" }}
                      label="Location"
                      name='u_locationName'
                    >
                      <Select
                        disabled
                        value={toUpdate.locationName}
                        style={{ marginBottom: "10px" }}
                        placeholder="Select Location"
                        onSelect={selectLocation}>
                        <Select.Option value={0}>Any</Select.Option>
                        {locList ?.map((item: any, index: any) => {
                          return <Select.Option key={index} value={item ?.locationName}>{item ?.locationName}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </div> : <></>
                }
                {
                  (localStorage.UserType === roles.SITE_ADMIN || localStorage.UserType === roles.SITE_MANAGER) ?
                    <div className='col-6'>
                      <Form.Item
                        style={{ marginBottom: "5px" }}
                        label="Site"
                        name='u_siteName'
                        rules={[{ required: true, message: 'Please Select Site' }]}>
                        <Select value={toUpdate.buildingName}
                          disabled
                          style={{ marginBottom: "10px" }}
                          placeholder="Select Site">
                          <Select.Option value={0}>Any</Select.Option>
                          {siteList ?.map((item: any, index: any) => {
                            return <Select.Option key={index} value={item ?.id}>{item ?.buildingName}</Select.Option>
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                    : <></>
                }
                <div className='col-6'>
                  <Form.Item
                    style={{ marginBottom: "5px" }}
                    label="First Name"
                    name='u_firstName'
                    rules={[{ required: true, message: 'Please Enter your First Name' }]}
                  >
                    <Input value={toUpdate.firstName} placeholder="Enter First Name"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setToUpdate({ ...toUpdate, firstName: event.target.value })} />
                  </Form.Item>
                </div>
                <div className='col-6'>
                  <Form.Item
                    style={{ marginBottom: "5px" }}
                    label="Last Name"
                    name='u_lastName'
                  >
                    <Input value={toUpdate.lastName} placeholder="Enter Last Name"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setToUpdate({ ...toUpdate, lastName: event.target.value })}
                    />
                  </Form.Item>
                </div>
                <div className='col-6'>
                  <Form.Item
                    label="Mobile No"
                    name='u_mobileNumber'
                    rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: "Only numbers are allowed" }]}
                    style={{ marginBottom: "5px" }}>
                    <Input type="tel"
                      placeholder="Enter Mobile No"
                      maxLength={10}
                    />
                  </Form.Item>
                </div>
                <div className='col-6'>
                  <Form.Item
                    label="Telephone No"
                    name='u_homePhone'
                    rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: "Only numbers are allowed" }]}
                    style={{ marginBottom: "5px" }}>
                    <Input type="tel"
                      placeholder="Enter Phone No"
                      maxLength={10}
                    />
                  </Form.Item>
                </div>
                <div className='col-6'>
                  <Form.Item label="Email" rules={[{ type: 'email' }]} name='email' style={{ marginBottom: "5px" }} >
                    <Input disabled value={toUpdate.email} placeholder='Enter Email Address'
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setToUpdate({ ...toUpdate, email: event.target.value })}
                    />
                  </Form.Item>
                </div>
                <div className='col-6'>
                  <Form.Item
                    label="Photo URL"
                    name='u_photoUrl'
                    style={{ marginBottom: "5px" }}>
                    <Input placeholder="Enter Photo URL"
                    />
                  </Form.Item>
                </div>
                <div className='col-6'>
                  <Form.Item
                    name="u_temp"
                    label="Temperature"
                    style={{ marginBottom: "5px" }}>
                    <Select
                      value={toUpdate.temperatureFormat}
                      placeholder="Select Temperature"
                      onSelect={handleSelectChange}
                    >
                      <Select.Option value="F">Fahrenheit</Select.Option>
                      <Select.Option value="C">Celsius</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-12 mt-3">
                  <div className="d-flex justify-content-end">
                    <div>
                      <Form.Item style={{ marginBottom: "0px" }}>
                        <Button
                          onClick={() => setIsModalOpen(false)}
                          style={{ color: "#004f9f", }}>
                          Cancel
                        </Button>
                      </Form.Item>
                    </div>
                    <div className="pl-3">
                      <Form.Item style={{ marginBottom: "0px" }}>
                        <Button
                          style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white" }}
                          htmlType="submit">
                          Update
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </Form>
          </Modal>
        </ConfigProvider>

        <ConfigProvider
          theme={{
            token: { colorPrimary: '#004f9f' }
          }}>
          <Modal centered title="Are You Sure Want To Deactivate The User?" open={deleteUser} onOk={DeleteOk} onCancel={CancelDeleteUser} ></Modal>
          <Modal open={isUserDeleted} closable={false} centered title={deleteResponse}
            footer={[
              <Button style={{ background: '#004f9f', color: "white" }} onClick={okDeletedUser}>OK</Button>
            ]}
          ></Modal>

          <Modal open={isUserUpdated} closable={false} centered title="User Updated Successfully"
            footer={[
              <Button style={{ background: '#004f9f', color: "white" }} onClick={okUpdateUser}>OK</Button>
            ]}
          ></Modal>
          <Modal open={successAddUser} closable={false} centered title={addMsg}
            footer={[
              <Button style={{ background: '#004f9f', color: "white" }} onClick={submitUserOk}>OK</Button>
            ]}
          ></Modal>
          <Modal open={reset} onOk={resetOk} onCancel={resetCancle} centered title="Are you sure you want to reset the password?"></Modal>
          <Modal open={isResetPwd} closable={false} centered title={emailCountValidation}
            footer={[
              <Button style={{ background: '#004f9f', color: "white" }} onClick={okPwdResetSuccess}>OK</Button>
            ]}>
          </Modal>
        </ConfigProvider>
      </div>

      <div className='row pt-2  search-org'>
        <div className='col-lg-6 col-xl-5 col-md-8 col-sm-12  select-search'>
          <SelectToSearch
            applyAndRemoveFilters={applyAndRemoveFilters}
            searchBy={searchBy}
            callOnClick={callOnClick}
            searchResult={searchResult}
            onClear={onClear}
            SearchOnOptionSelect={SearchOnOptionSelect}
            setEmailEmpty={setEmailEmpty}
            setFirstEmpty={setFirstEmpty}
            setLastEmpty={setLastEmpty}
          />
        </div>
      </div>

      {emailEmpty && <div style={{ color: "red", marginLeft: "177px" }}>Please Enter Email Address</div>}
      {firstEmpty && <div style={{ color: "red", marginLeft: "177px" }}>Please Enter First Name</div>}
      {lastEmpty && <div style={{ color: "red", marginLeft: "177px" }}>Please Enter Last Name</div>}

      <div className="topTabPanel mb-3 panelShadow">
        <div id="reporting_chart5" className="container-fluid pr-0 pl-0">
          <div className="row justify-content-around">
            <div className="col-md-12 col-lg-12 ">
              <div className="row">
                <div className="col-12 ">
                  <div className="container-daily-uses mt-2">
                    <div className="bgWhite" >
                      <div className="d-flex chartTitle overflow-auto py-2">
                        {
                          (roles.PLATFORM_ADMIN === localStorage ?.UserType || roles.PLATFORM_MANAGER === localStorage ?.UserType) ?
                            <label className="pr-2 mb-0">Users </label> :
                            (roles.DEALER_ADMIN === localStorage ?.UserType || roles.DEALER_MANAGER === localStorage ?.UserType || roles.COMPANY_ADMIN === localStorage ?.UserType || roles.COMPANY_MANAGER === localStorage ?.UserType || roles.LOCATION_ADMIN === localStorage ?.UserType || roles.LOCATION_MANAGER === localStorage ?.UserType || roles.SITE_ADMIN === localStorage ?.UserType || roles.SITE_MANAGER === localStorage ?.UserType) ?
                              <label className="pr-2 mb-0">Users </label> :
                              <></>
                        }
                        <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                          <div className='ml-auto show-entries'>
                            <ShowEntries getPageSize={onSelect} />
                          </div>
                        </ConfigProvider>
                      </div>

                      <div className="tableWrapper-user overflow-auto customScroll" >
                        <table className="billable" style={{ width: "100%" }}>
                          <thead className='tableHeader'>
                            <tr className="head-useMaintenance">
                              <th>Organization</th>
                              <th>Division</th>
                              <th>Location</th>
                              <th>Site</th>
                              <th>
                                <div onClick={() => sortBy('email')} className='d-flex align-items-center justify-content-center' style={{ cursor: 'pointer' }}>
                                  <span>Email Address</span>
                                  {sort.email ? < CaretDownOutlined classID='srNo' className='srNo' style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined className='srNo' style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                </div>
                              </th>
                              <th>
                                <div onClick={() => sortBy('firstName')} className='d-flex align-items-center justify-content-center' style={{ cursor: 'pointer' }}>
                                  <span>First Name</span>
                                  {sort.firstName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                </div>
                              </th>
                              <th>
                                <div onClick={() => sortBy('lastName')} className='d-flex align-items-center justify-content-center' style={{ cursor: 'pointer' }}>
                                  <span>Last Name</span>
                                  {sort.lastName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                </div>
                              </th>
                              <th>
                                <div onClick={() => sortBy('role')} className='d-flex align-items-center justify-content-center' style={{ cursor: 'pointer' }}>
                                  <span>Role</span>
                                  {sort.role ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                </div>
                              </th>
                              <th>
                                <div onClick={() => sortBy('mobileNumber')} className='d-flex align-items-center justify-content-center' style={{ cursor: 'pointer' }}>
                                  <span>Mobile No</span>
                                  {sort.mobileNumber ? < CaretDownOutlined classID='srNo' className='srNo' style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined className='srNo' style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                </div>
                              </th>
                              <th>
                                <div onClick={() => sortBy('homePhone')} className='d-flex align-items-center justify-content-center' style={{ cursor: 'pointer' }}>
                                  <span>Telephone No</span>
                                  {sort.homePhone ? < CaretDownOutlined classID='srNo' className='srNo' style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined className='srNo' style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                </div>
                              </th>
                              <th>
                                <span>Temperature</span>
                              </th>
                              <th className='action'>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {tableData ?.map((item: any, index: any) => {
                              const { tenant, divisionName, role, firstName, lastName, email, mobileNumber, homePhone, photoURL, temperatureFormat, organisation, id, active, locationId, status, buildingId, divisionId, locationName, buildingName, orgName, orgId } = item
                              return (
                                <tr key={index + "i"}>
                                  <td>{orgName}</td>
                                  <td>{divisionName}</td>
                                  <td className='p-1'>{locationName}</td>
                                  <td className='p-1'>{buildingName}</td>
                                  <td>{email}</td>
                                  <td>{firstName}</td>
                                  <td>{lastName}</td>
                                  <td>{role}</td>
                                  <td>{mobileNumber}</td>
                                  <td>{homePhone}</td>
                                  <td>{temperatureFormat == "F" ? "Fahrenheit" : temperatureFormat == "C" ? "Celsius" : ""}</td>
                                  <td className='p-0'>
                                    {getLoginUserId === id && localStorage.getItem('permissions') ?.includes('userMe_U') ?
                                      <Tooltip title="Update" color='#7a8185'>
                                        <EditOutlined onClick={() => showUpdateUser(tenant, role, firstName, lastName, email, mobileNumber, homePhone, photoURL, temperatureFormat, organisation, locationId, divisionId, buildingId, locationName, buildingName, divisionName, orgName, orgId)} style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px', cursor: 'pointer' }} />
                                      </Tooltip> : <></>}
                                    {
                                      permissions ?.includes(role) && <>
                                        {getLoginUserId !== id && localStorage.getItem('permissions') ?.includes('PlatformAdminUser_D') &&
                                          <Tooltip title={`${status ? "Deactivate" : ""}`} color="#7a8185">
                                            <DeleteOutlined onClick={() => { if (!status) { } else { deleteUserList(item.id) } }}
                                              style={{ fontSize: "15px", color: "#004f9f", paddingRight: "0px", cursor: status ? "pointer" : "auto" }} />
                                          </Tooltip>}
                                      </>
                                    }
                                    {getLoginUserId === id && localStorage.getItem('permissions') ?.includes('userMe_U') ?
                                      <Tooltip title={`${status ? "Reset Password" : ""}`} color="#7a8185">
                                        <LockResetTwoToneIcon onClick={() => { if (!status) { } else { resetPassword(item.email) } }}
                                          style={{ fontSize: "15px", color: "red", paddingRight: "0px", cursor: status ? "pointer" : "auto" }} />
                                      </Tooltip> : <></>}
                                  </td>
                                </tr>
                              )
                            })}
                            {
                              tableData ?.length == 0 && <tr><td colSpan={11} className="pt-3" style={{ textAlign: "center" }}>No Data Available</td></tr>
                            }
                          </tbody>
                        </table>
                      </div>

                      <div className='text-center pb-2 pt-1'>
                        <PaginationComponent
                          handlePagination={handlePagination}
                          totalLength={totalCount}
                          pageSize={pageSize}
                          current={current}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SessionWarning />
    </>
  )
}

export default UserMaintenance;