import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from './PrivateRoute';

import Posinet from '../components/main'
//testing

//dashboard controller
import DashBoard from '../components/dashboard/dashboard';
import DealerDashboard from '../components/dashboard/dealerDashboard';
import CompanyDashboard from '../components/dashboard/companyDashboard';
import LocationDashboard2 from '../components/dashboard/locationDashboard2';
import SiteDashboard from '../components/dashboard/siteDashboard';
import FirmwareUpdateResults from '../components/report-management/FirmwareUpdateResultsReport';

//report management controller
import Reports from '../components/report-management/reports';
import Logs from '../components/report-management/logs';
import WarrantyByTimeChart from '../components/report-management/warranty-by-time-report'
import DailyUsageAndPlugInChart from '../components/report-management/daily-usage-and-plug-in-report'
import ChargeLogChart from '../components/report-management/charge-log-report'
import RemainingLifeChart from '../components/report-management/remaining-life-report'
import PlugInComplianceChart from '../components/report-management/plug-in-compliance-report'
import BatteryTemperatureChart from '../components/report-management/battery-temperature-report'
import ChargeCurrentOverTimeChart from '../components/report-management/charge-current-over-time-report'
import ConsumptionBykWhrsChart from '../components/report-management/consumption-by-kWhrs-report'
import ConsumptionByAhrsChart from '../components/report-management/consumption-by-ahrs-report'
import EQLogChart from '../components/report-management/eq-log-report'
import EquivalentBatteryChart from '../components/report-management/equivalent-battery-utilization-report'
import RuntimeVsAhChart from '../components/report-management/runtime-vs-ah-report'
import BillingSummary from '../components/report-management/billing-summary-report';
import IssuesReport from '../components/report-management/issues-report';
import IssuesReport2 from '../components/report-management/issues-report2';
import BatteryHistoryLogReport from '../components/report-management/battery-history-log';
import BatteryHistoryReport from '../components/report-management/battery-history-report';
import LifeTimeBatteryHistory from '../components/report-management/life-time-battery-history'
import EMeterLogReport from '../components/report-management/emeter-log-report'
import ChargeSummaryReport from '../components/report-management/chargeSummary';
import ConfigurationLogReport from '../components/report-management/configuration-log-report'
import EqlizationSummary from '../components/report-management/eqlization-summary';
import ConfigurationSummaryReport from '../components/report-management/configuration-summary';
import SMSEventReport from '../components/report-management/sms-report'
import FirmHistoryReport from '../components/report-management/firmware-history-report';
import FirmUpdateControl from '../components/report-management/firmware-control-panel';
import BatterySocReport from '../components/report-management/BatterySocReport';
import SocReport from '../components/report-management/soc-report'
import DailyBatteryPerformance from '../components/report-management/dailyBatteryPerformanceReport';
import TemparatureReport from '../components/report-management/temparature-report'

//battery management controller
import BMIDInventory from '../components/battery-management/BMIDInventory'
import Batteries from '../components/battery-management/batteries'
import BatteryStatistics from '../components/battery-management/battery-statistics';
import BatteryRXConfiguration from '../components/battery-management/battery-rxconfig';
import BatteryRXUnits from '../components/report-management/BatteryRXUnits';
import BatteryRXTestPage from '../components/report-management/BatteryRXTestPages';

//maintenance controller
import UserMaintenance from '../components/maintenance/userMaintenance';
import OrganizationMaintenance from '../components/maintenance/organizationMaintenance';
import LocationMaintenance from '../components/maintenance/locationMaintenance';
import SiteMaintenance from '../components/maintenance/siteMaintenance';
import DivisionMaintenance from '../components/maintenance/divisionMaintenance';
import ShiftSchedules from '../components/user-management/shiftSchedules';

//user management
import AccountManagement from '../components/user-management/accountManagement';

//login controller
import UpdatePassword from '../components/updatePassword/updatePassword';
import UserLogin from '../components/login/userLogin';
import ForgotPassword from '../components/login/forgotPassword';

//operationStatus
import OperationStatus from '../components/operationStatus/operationStatus';
import HighTempStatus from '../components/operationStatus/highTempStatus'
import EqStatus from '../components/operationStatus/eqStatus'
import AhrsCharged from '../components/operationStatus/ahrsCharged'
import LowWateringStatus from '../components/operationStatus/waterStatus'

interface MyState {
    location: string,
    token: string,
    isRedirectLogin: boolean,
    adminIsPermission: { adminDashBoard_W: boolean },
    dealerIsPermission: { dealerDashBoard_W: boolean },
    companyIsPermission: { companyDashBoard_W: boolean },
    locationIsPermission: { locationDashBoard_W: boolean },
    siteIsPermission: { siteDashBoard_W: boolean },
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    action: string,
    dealer: string,
    company: string,
    loc: string,
    site: string,
    refresh: number,
    email: boolean,
    UserType: string
    hasPermission: any[]
}

export default class Routers extends React.Component<any, MyState>{
    constructor(props: any) {
        super(props);
        this.state = {
            location: "",
            token: "",
            isRedirectLogin: false,
            adminIsPermission: { adminDashBoard_W: false },
            dealerIsPermission: { dealerDashBoard_W: false },
            companyIsPermission: { companyDashBoard_W: false },
            locationIsPermission: { locationDashBoard_W: false },
            siteIsPermission: { siteDashBoard_W: false },
            globalData: {
                orgId: -1,
                orgName: "",
                divId: -1,
                divName: "",
                locationId: -1,
                locationName: "",
                buildingId: -1,
                batteryId: "",
                buildingName: "",
                batteryName: "",
                vehicleId: "",
                vehicleName: "",
                serialId: "",
                serialName: ""
            },
            action: "",
            dealer: "",
            company: "",
            loc: "",
            site: "",
            refresh: 0,
            email: false,
            UserType: "",
            hasPermission: []
        }
        this.setGlobalData = this.setGlobalData.bind(this);
        this.setAction = this.setAction.bind(this);
        this.setLabelName = this.setLabelName.bind(this);
        this.doRefresh = this.doRefresh.bind(this);
        this.setEmail = this.setEmail.bind(this)
    }

    componentDidMount(): void {
        let UserType = localStorage.getItem('UserType')
        UserType && this.setState({ UserType: UserType })
    }

    doRefresh(value: number) {
        this.setState({ refresh: value }, () => { this.setState({ refresh: 0 }) });
    }

    setAction(value: string) {
        this.setState({ action: value }, () => { this.setState({ action: "" }) })
    }

    setGlobalData(key: any, value: any) {
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        data[key] = value;

        this.setState({ globalData: data }, () => {
            sessionStorage.setItem("globalData", JSON.stringify(this.state.globalData))
        })
    }

    setLabelName(key: string, value: string) {
        let obj: any = {}
        if (key === "dealer") {
            obj.company = ""
            obj.loc = ""
            obj.site = ""
        }
        if (key === "company") {
            obj.loc = ""
            obj.site = ""
        }
        if (key === "loc") {
            obj.site = ""
        }

        let state: any = this.state;
        state[key] = value;
        this.setState({ ...state, ...obj })
    }

    setEmail() {
        this.setState({ email: !this.state.email })
    }

    render() {
        return (
            <Routes>
                <Route path='/' element={<Posinet dealer={this.state.dealer} company={this.state.company} loc={this.state.loc} site={this.state.site} globalData={this.state.globalData} setGlobalData={this.setGlobalData} setAction={this.setAction} doRefresh={this.doRefresh} setEmail={this.setEmail} />}>
                    <Route
                        path="/admindashboard"
                        element={<PrivateRoute element={<DashBoard path="/admindashboard" globalData={this.state.globalData} setGlobalData={this.setGlobalData} setLabelName={this.setLabelName} />}
                        accessCode='adminDashBoard_W' />}
                    />
                    <Route
                        path='/dealerdashboard'
                        element={<PrivateRoute element={<DealerDashboard path="/dealerdashboard" globalData={this.state.globalData} setGlobalData={this.setGlobalData} setLabelName={this.setLabelName} />}
                        accessCode='dealerDashBoard_W' />}
                    />
                    <Route
                        path='/companydashboard'
                        element={<PrivateRoute element={<CompanyDashboard globalData={this.state.globalData} setGlobalData={this.setGlobalData} setLabelName={this.setLabelName} />}
                        accessCode='companyDashBoard_W' />}
                    />
                    <Route
                        path='/locationdashboard'
                        element={<PrivateRoute element={<LocationDashboard2 globalData={this.state.globalData} setGlobalData={this.setGlobalData} setLabelName={this.setLabelName} />}
                        accessCode='locationDashBoard_W' />}
                    />
                    <Route
                        path='/sitedashboard'
                        element={<PrivateRoute element={<SiteDashboard globalData={this.state.globalData} />}
                        accessCode='siteDashBoard_W' />}
                    />
                    <Route
                        path='/bmidInventory'
                        // element={<PrivateRoute element={<BMIDInventory globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<BMIDInventory globalData={this.state.globalData} action={this.state.action}/>}
                        accessCode='BbmidInventory_W' />}
                    />
                    <Route
                        path='/account-management'
                        element={<PrivateRoute element={<AccountManagement />}
                        accessCode='Account_Profile_W' />}
                    />
                    <Route
                        path='/warranty-by-time-report'
                        element={<PrivateRoute element={<WarrantyByTimeChart globalData={this.state.globalData} action={this.state.action} />}
                        // element={<PrivateRoute element={<WarrantyByTimeChart globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        accessCode='warrenty_time_W' />}
                    />
                    <Route
                        path="/daily-usage-and-plug-in-report"
                        // element={<PrivateRoute element={<DailyUsageAndPlugInChart globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<DailyUsageAndPlugInChart globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='daily_usage_plugin_W' />}
                    />
                    <Route
                        path="/charge-log-report"
                        // element={<PrivateRoute element={<ChargeLogChart globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<ChargeLogChart globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='charge_Log_Report_W' />}
                    />
                    <Route
                        path="/eq-log-report"
                        // element={<PrivateRoute element={<EQLogChart globalData={this.state.globalData} refresh={this.state.refresh} action={this.state.action} />}
                        element={<PrivateRoute element={<EQLogChart globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='eq_log_report_W' />}
                    />
                    <Route
                        path="/remaining-life-report"
                        element={<PrivateRoute element={<RemainingLifeChart globalData={this.state.globalData} action={this.state.action} />}
                        // element={<PrivateRoute element={<RemainingLifeChart globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        accessCode='remaining_time_W' />}
                    />
                    <Route
                        path="/plug-in-compliance-report"
                        // element={<PrivateRoute element={<PlugInComplianceChart globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<PlugInComplianceChart globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='plugin_compliance_time_W' />}
                    />
                    <Route
                        path="/battery-voltage-report"
                        // element={<PrivateRoute element={<BatterySocReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<BatterySocReport globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='stateOfCharge_W' />}
                    />
                    <Route
                        path="/soc-report"
                        // element={<PrivateRoute element={<SocReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<SocReport globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='stateOfCharge_W' />}
                    />
                    <Route
                        path="/battery-temperature-report"
                        element={<PrivateRoute element={<BatteryTemperatureChart globalData={this.state.globalData} action={this.state.action} />}
                        // element={<PrivateRoute element={<BatteryTemperatureChart globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        accessCode='battery_temperature_W' />}
                    />
                    <Route
                        path="/temperature-report"
                        element={<PrivateRoute element={<TemparatureReport globalData={this.state.globalData} action={this.state.action} />}
                        // element={<PrivateRoute element={<TemparatureReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        accessCode='battery_temperature_W' />}
                    />
                    <Route path="/charge-current-over-time-report" element={<ChargeCurrentOverTimeChart globalData={this.state.globalData} action={this.state.action} />} />
                    <Route
                        path="/consumption-by-kwhrs-report"
                        // element={<PrivateRoute element={<ConsumptionBykWhrsChart globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<ConsumptionBykWhrsChart globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='consumption_kwhrs_W' />}
                    />
                    <Route
                        path="/consumption-by-ahrs-report"
                        // element={<PrivateRoute element={<ConsumptionByAhrsChart globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<ConsumptionByAhrsChart globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='consumption_ahrs_W' />}
                    />
                    <Route
                        path="/equivalent-battery-report"
                        // element={<PrivateRoute element={<EquivalentBatteryChart globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<EquivalentBatteryChart globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='equivalent-battery-utilization_W' />}
                    />
                    <Route
                        path="/runtime-vs-ah-report"
                        // element={<PrivateRoute element={<RuntimeVsAhChart globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<RuntimeVsAhChart globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='equivalent-battery-utilization_W' />}
                    />
                    <Route path="/billing-summary-report" element={<BillingSummary globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />} />
                    <Route
                        path="/issues-report"
                        // element={<PrivateRoute element={<IssuesReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<IssuesReport globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='Issues_report1_W' />}
                    />
                    <Route
                        path="/issues-report2"
                        // element={<PrivateRoute element={<IssuesReport2 globalData={this.state.globalData} action={this.state.action} email={this.state.email} refresh={this.state.refresh} setEmail={this.setEmail} />}
                        element={<PrivateRoute element={<IssuesReport2 globalData={this.state.globalData} action={this.state.action} email={this.state.email} setEmail={this.setEmail} />}
                        accessCode='Issues_report2_W' />}
                    />
                    <Route
                        path="/battert-history-log-report"
                        element={<PrivateRoute element={<BatteryHistoryLogReport globalData={this.state.globalData} action={this.state.action} />}
                        // element={<PrivateRoute element={<BatteryHistoryLogReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        accessCode='battery_history_logs_W' />}
                    />
                    <Route
                        path="/battert-history-report"
                        // element={<PrivateRoute element={<BatteryHistoryReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<BatteryHistoryReport globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='batteryhhistoryReport_W' />}
                    />
                    <Route
                        path="/life-time-battery-history"
                        // element={<PrivateRoute element={<LifeTimeBatteryHistory globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<LifeTimeBatteryHistory globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='lifeTimeBattery_W' />}
                    />
                    <Route
                        path="/emeter-log-report"
                        // element={<PrivateRoute element={<EMeterLogReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<EMeterLogReport globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='emeter_Report_R' />}
                    />
                    <Route
                        path="/chargeSummary"
                        // element={<PrivateRoute element={<ChargeSummaryReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<ChargeSummaryReport globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='chargeSummary_W' />}
                    />
                    <Route
                        path="/configurationlogreport"
                        // element={<PrivateRoute element={<ConfigurationLogReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<ConfigurationLogReport globalData={this.state.globalData} action={this.state.action}/>}
                            accessCode='configuration_Logs_Report_W' />}
                    />
                    <Route
                        path="/configurationsummaryreport"
                        // element={<PrivateRoute element={<ConfigurationSummaryReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<ConfigurationSummaryReport globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='equalizationSummary_W' />}
                    />
                    <Route
                        path="/smsreport"
                        // element={<PrivateRoute element={<SMSEventReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<SMSEventReport globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='smsEventsReport_W' />}
                    />
                    <Route
                        path="/firmhistoryreport"
                        // element={<PrivateRoute element={<FirmHistoryReport globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<FirmHistoryReport globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='firmwarehistory_W' />}
                    />
                    <Route
                        path="/firmcontrolpanel"
                        // element={<PrivateRoute element={<FirmUpdateControl globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<FirmUpdateControl globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='userMaintenanceR_W' />}
                    />
                    <Route
                        path="/firmware-update-report"
                        // element={<PrivateRoute element={<FirmwareUpdateResults globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<FirmwareUpdateResults globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='userMaintenanceR_W' />}
                    />
                    <Route
                        path="/usermaintenance"
                        element={<PrivateRoute element={<UserMaintenance globalData={this.state.globalData} refresh={this.state.refresh} />}
                        accessCode='userMaintenanceR_W' />}
                    />
                    <Route
                        path="/organizationmaintenance"
                        element={<PrivateRoute element={<OrganizationMaintenance globalData={this.state.globalData} refresh={this.state.refresh} />}
                        accessCode='organizationMaintenanceR_W' />}
                    />
                    <Route
                        path="/locationmaintenance"
                        element={<PrivateRoute element={<LocationMaintenance globalData={this.state.globalData} refresh={this.state.refresh} />}
                        accessCode='locationMaintenanceR_W' />}
                    />
                    <Route
                        path="/sitemaintenance"
                        element={<PrivateRoute element={<SiteMaintenance globalData={this.state.globalData} refresh={this.state.refresh} />}
                        accessCode='siteMaintenanceR_W' />}
                    />
                    <Route
                        path="/divisionmaintenance"
                        element={<PrivateRoute element={<DivisionMaintenance globalData={this.state.globalData} refresh={this.state.refresh} />}
                        accessCode='divisionMaintenanceR_W' />}
                    />
                    <Route path="/batteries" element={<Batteries />} />
                    <Route path="/battery-statistics" element={<BatteryStatistics globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />} />
                    <Route path="/battery-rxconfig" element={<BatteryRXConfiguration globalData={this.state.globalData} />} />
                    <Route path='/user-account-management' element={<AccountManagement />} />
                    <Route
                        path="/equalizationSummary"
                        // element={<PrivateRoute element={<EqlizationSummary globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<EqlizationSummary globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='equalizationSummary_W' />}
                    />
                    <Route
                        path="/battery-rx-units"
                        // element={<PrivateRoute element={<BatteryRXUnits globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<BatteryRXUnits globalData={this.state.globalData} action={this.state.action} />}
                        accessCode='OBatteryRxUnits_R' />}
                    />
                    <Route
                        path="/battery-rx-test-page"
                        // element={<PrivateRoute element={<BatteryRXTestPage globalData={this.state.globalData} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<BatteryRXTestPage globalData={this.state.globalData} />}
                        accessCode='OBatteryRxUnits_R' />}
                    />
                    <Route
                        path="/shift-schedules"
                        // element={<PrivateRoute element={<ShiftSchedules globalData={this.state.globalData} refresh={this.state.refresh} />}
                        element={<PrivateRoute element={<ShiftSchedules globalData={this.state.globalData} />}
                        accessCode='shiftschedule_W' />}
                    />
                    <Route path="/dailyBatteryPerformanceReport" element={<DailyBatteryPerformance globalData={this.state.globalData} action={this.state.action} />} />
                    {/* <Route path="/dailyBatteryPerformanceReport" element={<DailyBatteryPerformance globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />} /> */}
                    <Route path='/reports' element={<Reports />} ></Route>
                    <Route path='/logs' element={<Logs />} ></Route>
                    {/* <Route path="/operationStatus" element={<OperationStatus globalData={this.state.globalData} action={this.state.action} refresh={this.state.refresh} />} /> */}
                    <Route path="/operationStatus" element={<OperationStatus globalData={this.state.globalData} action={this.state.action}/>} />
                    {/* <Route path="/operationStatusHighTemp" element={<HighTempStatus globalData={this.state.globalData} action={this.state.action} email={this.state.email} refresh={this.state.refresh} setEmail={this.setEmail} />} /> */}
                    <Route path="/operationStatusHighTemp" element={<HighTempStatus globalData={this.state.globalData} action={this.state.action} email={this.state.email} setEmail={this.setEmail} />} />
                    {/* <Route path="/operationStatusAhrsCharged" element={<AhrsCharged globalData={this.state.globalData} action={this.state.action} email={this.state.email} refresh={this.state.refresh} setEmail={this.setEmail} />} /> */}
                    <Route path="/operationStatusAhrsCharged" element={<AhrsCharged globalData={this.state.globalData} action={this.state.action} email={this.state.email} setEmail={this.setEmail} />} />
                    {/* <Route path="/operationStatusEqOverdue" element={<EqStatus globalData={this.state.globalData} action={this.state.action} email={this.state.email} refresh={this.state.refresh} setEmail={this.setEmail} />} /> */}
                    <Route path="/operationStatusEqOverdue" element={<EqStatus globalData={this.state.globalData} action={this.state.action} email={this.state.email}setEmail={this.setEmail} />} />
                    {/* <Route path="/operationStatusLowWater" element={<LowWateringStatus globalData={this.state.globalData} action={this.state.action} email={this.state.email} refresh={this.state.refresh} setEmail={this.setEmail} />} /> */}
                    <Route path="/operationStatusLowWater" element={<LowWateringStatus globalData={this.state.globalData} action={this.state.action} email={this.state.email} setEmail={this.setEmail} />} />
                    <Route path="/" element={<Navigate to="/login"></Navigate>} />
                </Route>
                <Route path="/updatepassword" element={<UpdatePassword />}></Route>
                <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
                <Route path='/login' element={<UserLogin />}></Route>
            </Routes>
        )
    }
}