import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import LoadingSpinner from '../spinner/loading-spinner'
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { format, subDays } from 'date-fns';
import SelectDays from '../reusableComponent/selectDays';
import SessionWarning from '../reusableComponent/sessionWarning';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { resize, hideSidebar } from '../../settings/resize';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface Props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  action: string
  // refresh:number
}



const EquivalentChart: FC<Props> = ({ globalData, action }) => {
  // const EquivalentChart: FC<Props> = ({globalData, action,refresh}) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [reqBody, setReqBody] = useState<any>({ startDate: format(subDays(new Date(), 6), 'dd-MMM-yyyy'), endDate: format(new Date(), 'dd-MMM-yyyy'), orgId: 0, locationId: -1 })
  const [serialNumber, setSerialNumber] = useState<any[]>([])
  const [updatedDate, setUpdatedDate] = useState<any[]>([])
  const [dischargeAhs, setDischargeAhs] = useState<any[]>([])
  const [runMinutes, setRunMinutes] = useState<any[]>([])
  const [emu, setEmu] = useState<any[]>([])
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [dateLabel, setDateLabel] = useState<any[]>([])
  const [startvalue, setStartvalue] = useState<string>("0")
  const [subcaption, setSubcaption] = useState(format(subDays(new Date(), 6), 'dd-MMMM-yyyy') + " - " + format(new Date(), 'dd-MMMM-yyyy'))
  const ref = useRef<HTMLDivElement>(null)
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [value, onChange] = useState<any>([subDays(new Date(), 7), new Date()]);
  const [initial, setInitial] = useState<boolean>(true);

  // useEffect(()=>{
  //   if (refresh !== 0)setReqBody({ ...reqBody})
  // },[refresh])

  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    if (date1 && date2) {
      daysFilter(date1 ?? format(subDays(new Date(), 7), 'dd-MMMM-yyyy'), date2 ?? format(new Date(), 'dd-MMMM-yyyy'));
    }
  }, [value])

  useEffect(() => {
    // if ((reqBody?.startDate === reqBody?.endDate)) return;
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    if (reqBody?.orgId === 0) return
    setIsShowSpinner(true);
    (async () => {
      try {
        const response = await service({
          url: constantUrl.api.equivalentBatteryUtilization.equivalentBatteryUtilization,
          method: "POST",
          headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
          data: reqBody
        })
        if (!!response.length) {
          frameChart(response)
        } else {
          setDateLabel([{ label: "" }])
          setStartvalue('0')
          setEmu([{ value: '0' }])
          setDischargeAhs([{ value: '0' }])
        }
        setIsShowSpinner(false)
      } catch (error) {
        console.error("equivalentBatteryError:", error)
        setIsShowSpinner(false)
      }
    })();
  }, [reqBody])

  const frameChart = (data: any) => {
    let datelabel = data.map((item: any) => { return { label: item.date } })
    let serialNumber: any = data.map((item: any) => { return { label: item.serialNumber } })
    let dischargeAhs: any = data.map((item: any) => { return { value: item.dischargeAhs } })
    let runMinutes: any = data.map((item: any) => { return { value: item.runMinutes } })
    let emu: any[] = data.map((item: any) => { return { value: item.emu } })

    let sum = 0
    for (let elm of emu) { sum += elm.value }

    let avgValue = sum / emu.length
    setStartvalue(avgValue.toString())
    setSerialNumber(serialNumber)
    setUpdatedDate(updatedDate)
    setDischargeAhs(dischargeAhs)
    setRunMinutes(runMinutes)
    setEmu(emu)
    setIsShowSpinner(false)
    setDateLabel(datelabel)
  }

  const chartConfigs = {
    type: "scrollmsstackedcolumn2dlinedy",
    width: '100%',
    height: '92%',
    dataFormat: 'json',
    dataSource: {
      // yAxis: [
      //   {
      //     format: {
      //       defaultFormat: 0,
      //     }
      //   }
      // ],
      chart: {
        // subcaption: "1 August 2022 - 26 September 2022",
        // subcaption:subcaption,
        //subcaptionFont: "Arial",
        subcaptionFontSize: "14",
        // "subcaptionFontColor": "#993300",
        subcaptionFontBold: "0",
        "xAxisName": "Date",
        "pYAxisName": "Total Ah",
        "sYAxisName": "Total EBU",
        pYAxisNameFontColor: "#4f82b6",
        pYAxisNameFontBold: "1",
        xAxisNameFontColor: "#4f82b6",
        xAxisNameFontBold: "1",
        sYAxisNameFontColor: "#4f82b6",
        sYAxisNameFontBold: "1",
        //"yAxisPosition": "right",
        //"showDivLineValues": "1",
        //"numDivLines": "9",
        /* "adjustDiv": "0",
        "stack100Percent": "1", */
        //yaxisminvalue: "0",
        //yaxismaxvalue: "3",
        setadaptivepYmin: "1",
        setadaptivesYmin: "1",
        //thickness: "10",
        //animation: "1",
        //legendPadding: "400",
        useRoundEdges: "1",
        //formatNumber: "1",
        // decimals: "1",
        //yAxisValueDecimals: "1",
        //forceDecimals: "1",
        //forceYAxisValueDecimals: "1",
        // labelStep: "6",
        plotSpacePercent: "60",
        legendposition: "top",
        legendPadding: "50",
        drawCustomLegendIcon: "2",
        legendIconSides: "0",
        //divlinethickness: "2",
        //divlinealpha: "50",
        //divlinedashed: "1",
        //divlinedashgap: "3",
        // divLineIsDashed: "1",
        paletteColors: "#5a8dc1,#1eaaea",
        showHoverEffect: "1",
        showToolTip: "1",
        showLegend: 1,
        // exportEnabled:"1",
        // exportfilename:"Equivalent_Battery_Utilization",
        showsum: "1",
        //drawcrossline: "1",
        showvalues: "0",
        //"numvisibleplot": "14",
        //"trendValueAlpha": "80",
        //plotToolText: "<div class='__head'><pre>Total Ah     Total EBU</pre></div><div class='__body'><pre>0.8   $dataValue</pre></div><div class='__footer'><pre>$label</pre></div>",
        //toolTipBgAlpha: "100",
        "theme": "fusion"
      },
      "categories": [{
        "category": dateLabel.length ? dateLabel : [{ label: "" }]
      }],
      "dataset": [{
        "dataset": [{
          "seriesname": "Total Ah",
          "parentYAxis": "P",
          plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
          "data": dischargeAhs
        }
        ]
      },
      {
        "dataset": [{
          "seriesname": "Total EBU",
          "parentYAxis": "S",
          plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
          "data": emu
        }
        ]
      }
      ],
      trendlines: [{
        line: [{
          startvalue: startvalue,
          color: "#1fd284",
          valueOnRight: "1",
          displayvalue: ' ',
          // // usehovercolor: "1",
          // hovercolor: "CCCCCC",
          // showHoverEffect: "0.5",
          // plotBorderAlpha: "50",
          "alpha": "100",
          style: {
            "stroke-opacity": "0.5"
          },
          "tooltext": "$startDataValue",
          thickness: "3",
          dashed: "1",
          dashLen: "4",
          dashGap: "7",
          labelFontSize: "3"

        }]
      }]
    }
  };

  const daysFilter = (startDate: string, endDate: string) => {
    setSubcaption(format(new Date(startDate), 'dd-MMMM-yyyy') + ' - ' + format(new Date(endDate), 'dd-MMMM-yyyy'))
    if (haveSameData(reqBody, { ...reqBody, startDate: format(new Date(startDate), 'dd-MMM-yyyy'), endDate: format(new Date(endDate), 'dd-MMM-yyyy') })) {

    } else {
      setReqBody({ ...reqBody, startDate: format(new Date(startDate), 'dd-MMM-yyyy'), endDate: format(new Date(endDate), 'dd-MMM-yyyy') })
    }
  }

  useEffect(() => {
    // check the page permission
    let permission: any = localStorage.getItem('permissions')
    if (!permission.includes('equivalent-battery-utilization_W')) window.history.back()

    setInitial(true);
    window.scrollTo(0, 0)
    resize();
    hideSidebar();
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;
    setReqBody({ ...reqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    setInitial(false);
  }, [])

  useEffect(() => {
    if (initial == false) {
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
      setReqBody({ ...reqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

  const haveSameData = function (obj1: any, obj2: any) {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;

    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every(
        key => obj2.hasOwnProperty(key)
          && obj2[key] === obj1[key]);
    }
    return false;
  }

  const filterByserialNumber = (value: string) => {

  }

  useEffect(() => {
    if (action !== "") {
      onButtonClick(action)
    }
  }, [action])

  const onButtonClick = useCallback((value: string) => {
    if (ref.current === null) {
      return
    }
    setIsShowSpinner(true);
    if (value === 'png') {
      toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'Equivalent_Battery_Utilization.png'
          link.href = dataUrl
          link.click()
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    } else {
      html2canvas(ref.current)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const link = document.createElement('a')
          link.download = 'my-image-name.png';
          const pdf = new jsPDF();
          // pdf.addImage(imgData, 'PNG',2,20,0,99);
          pdf.addImage(imgData, 'PNG', 5, 20, 200, 100);
          pdf.save("Equivalent_Battery_Utilization.pdf");
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    }


  }, [ref])

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div>
        <div className="container-battery-voltage" ref={ref}>
          <div className="chartTitle p-2 text-center d-flex align-items-center">
            <span className='mr-auto ml-auto'>Equivalent Battery Utilization</span>
          </div>
          <span className="export">
            {
              showDateFilter && <span>
                <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
              </span>
            }
            <SelectDays daysFilter={daysFilter} customFilterStatusChange={setShowDateFilter} />
          </span>
          <div className='daily-plugin-chart'>
            <ReactFC {...chartConfigs} />
          </div>
        </div>
      </div>
      <SessionWarning />
    </>
  )
}

export default EquivalentChart;


