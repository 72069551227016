import React, { FC, useState, useEffect, useRef, useCallback,useLayoutEffect } from 'react';
import SessionWarning from '../reusableComponent/sessionWarning';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import AmpureLogo from '../../images/ampureLogo_234x50.png'
import Ampure from '../../images/Ampure234x50.jpg'
import { format, subDays, parseISO} from 'date-fns';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import { resize, hideSidebar } from '../../settings/resize';
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);


const thirtyDaysAgo = format(subDays(new Date(),30), 'dd-MMM-yyyy')
const today = format(new Date(), 'dd-MMM-yyyy')

type reqBody = {
  orgId: number,
  batteryId: string,
  vehicleId: string,
  divisionId: number,
  locationId: number,
  buildingId: number,
  serialNumber:string,
  startDate: string,
  endDate: string,
}

let Body = {
  orgId:0,
  batteryId:"",
  vehicleId:"",
  divisionId:-1,
  locationId:-1,
  buildingId:-1,
  serialNumber:"",
  startDate:thirtyDaysAgo,
  endDate:today,
}

interface Props {
  globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
  action: string,
  refresh:number
}




const BatteryStatistics: FC<Props> = ({ globalData, action,refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const ref = useRef<HTMLDivElement>(null)
  const [tableReport, setTableReport] = useState<any[]>([])
  const [tableReqBody, setTableReqBody] = useState<reqBody>(Body)
  const [batteryId,setBatteryId] = useState<string>("")
  const [vehicleId,setVehicleId] = useState<string>("")
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [dailyDate, setDailyDate] = useState<any[]>([])
  const [totalAhrsIn, setTotalAhrsIn] = useState<any[]>([])
  const [totalAhrsOut, setTotalAhrsOut] = useState<any[]>([])
  const [totalPlugins, setTotalPlugins] = useState<any[]>([])
  const [filterData, setFilterData] = useState<any[]>([]);
  const [dateLabel, setDateLabel] = useState<{}[]>([])
  const [filterDataTemp, setFilterDataTemp] = useState<any[]>([]);
  const [subcaption, setSubcaption] = useState(thirtyDaysAgo + " - " + today)
  const [initial, setinitial] = useState<boolean>(true);
  const [emptyBox1Height,setEmptyBox1Height] = useState(0)
  const [batteryTablecellHeight,setBatteryTablecellHeight] = useState<any>(0)
  const [calculateHeight,setCalculateHeight] = useState(false)
  const [operationCellHeight,setOperationCellHeight] = useState<any>(0)
  const [tempCellHeight,setTempCellHeight] = useState<any>(0)
  const [waterCellHeight,setWaterCellHeight] = useState<any>(0)
  const [idelTimeCellHeight,setIdelTimeCellHeight] = useState<any>(0)
  const [oddColor,setOddcolor] = useState('#b0c8e2')
  const [evenColor,setEvenColor] = useState("#e3edfc")
  const [emptyCellMargin,setEmptyCellMargin] = useState("0px 2px 2px 2px")
  
  const batteryRef = useRef<HTMLDivElement>(null)
  const batteryTableRef = useRef<HTMLTableElement>(null)
  const equalizationTableRef = useRef<HTMLTableElement>(null)
  const voltageRef = useRef<HTMLDivElement>(null)
  const tempRef = useRef<HTMLDivElement>(null)
  const dischargeTableRef=useRef<HTMLTableElement>(null)
  const capacityRef = useRef<HTMLTableElement>(null)
  const emptyBox1 = useRef<HTMLTableCellElement>(null)
  const emptyBox2 = useRef<HTMLTableCellElement>(null)
  const headingRef = useRef<HTMLDivElement>(null)
  const operationalDivRef = useRef<HTMLDivElement>(null)
  const tempTableRef = useRef<HTMLTableElement>(null)
  const batteryLifeTableRef = useRef<HTMLTableElement>(null)
  const WaterTableRef = useRef<HTMLTableElement>(null)
  const idleTimeRef = useRef<HTMLTableRowElement>(null)  




  useLayoutEffect(()=>{
    let timer:any
    const debouncedHandleResize = ()=>{
     clearTimeout(timer)
     timer=setTimeout(()=>{
      resize()
      getBoxHeight()
     },1000)
    }
    window.addEventListener("resize",debouncedHandleResize)
    return()=>{
     window.removeEventListener("resize",debouncedHandleResize)       }
  });

  useEffect(() => {
    window.scrollTo(0, 0)
    hideSidebar()
  }, []);

  useEffect(()=>{
    if(!calculateHeight) return 
    getBoxHeight()
  },[tableReport])

  const getBoxHeight=()=>{
    if(window.innerWidth >= 1024){
      const batteryDivHeight = batteryRef.current?.offsetHeight  
      const voltageDivHeight = voltageRef.current?.offsetHeight
      const marginTop = 14
      const dischargeTableHeight = dischargeTableRef.current?.offsetHeight 
      const capacityHeight = capacityRef.current?.offsetHeight
      const headerHeight = headingRef.current?.offsetHeight

      const equalizationTableHeight = equalizationTableRef.current?.offsetHeight
      const batteryTableHeight = batteryTableRef.current?.offsetHeight
      const operationalDivHeight = operationalDivRef.current?.offsetHeight


      const tempTableHeight = tempTableRef.current?.offsetHeight
      const batteryLifeTableHeight = batteryLifeTableRef.current?.offsetHeight
      const WaterTableHeight = WaterTableRef.current?.offsetHeight

      // calculate idelcell height
      const idleTimeCellHeight = idleTimeRef.current?.offsetHeight
      setEmptyCellMargin("0px 2px 2px 2px") 
      if(batteryDivHeight && dischargeTableHeight && capacityHeight && headerHeight && 
          equalizationTableHeight && batteryTableHeight && voltageDivHeight && 
          operationalDivHeight &&batteryLifeTableHeight && tempTableHeight && 
          WaterTableHeight && idleTimeCellHeight){
          // it is for fill the empty height battery cell height
          const batteryCellHeight  = equalizationTableHeight-batteryTableHeight
          setBatteryTablecellHeight(batteryCellHeight)
          // it is for fill the Charge Vs Discharge Data empty cell height
          setEmptyBox1Height((batteryTableHeight+batteryCellHeight+voltageDivHeight+marginTop)-dischargeTableHeight)
          // it is for fill the Operational vs Non-operational Loadempty cell height
          setOperationCellHeight(voltageDivHeight- operationalDivHeight)
          // set empty temperaturecell height
          setTempCellHeight(batteryLifeTableHeight - tempTableHeight-2)
          // set empty watercell height
          setWaterCellHeight(batteryLifeTableHeight - WaterTableHeight)
          // set idelcell height
          setIdelTimeCellHeight(idleTimeCellHeight)
      }
    }else{
      if(emptyBox1.current)emptyBox1.current.style.height='0'
      if(emptyBox2.current)emptyBox2.current.style.height='0'
      setTempCellHeight(0)
      setOperationCellHeight(0)
      setWaterCellHeight(0)
      setEmptyCellMargin("0px 0px 0px 0px")
    }
     
  } 

  useEffect(() => {
    if (action !== "") {
      downloadPdf()
    }
  }, [action])

  useEffect(() => {
    if (initial === false) {
        let org = globalData?.orgId > -1 ? globalData?.orgId : userId;

        const {divId,locationId,buildingId,batteryId,vehicleId,serialId} = globalData
        let objData = {orgId:org, divisionId:divId, locationId, buildingId, batteryId, vehicleId, serialNumber:serialId}
       
        setTableReqBody((prev)=>({...prev,...objData}))
        setBatteryId(batteryId)
        setVehicleId(vehicleId)
        getStaticsReport({...tableReqBody,...objData})

        if(batteryId){
          getSOCTemparature({...tableReqBody,...objData})
          getDailyUsage({...tableReqBody,...objData})
        }else{
          setFilterData([])
          setDateLabel([])
          
          setDailyDate([])
          setTotalAhrsIn([])
          setTotalAhrsOut([])
          setTotalPlugins([])
        }
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId,globalData?.serialId])


   useEffect(()=>{
      let data: any = sessionStorage.getItem('globalData');
      data = data && JSON.parse(data);
      let org = data?.orgId > -1 ? data?.orgId : userId;
      const {divId,locationId,buildingId,batteryId,vehicleId,serialId} = data
      let objData = {orgId:org, divisionId:divId, locationId, buildingId, batteryId, vehicleId, serialNumber:serialId}
      
      setTableReqBody((prev)=>({...prev,...objData}))
      setBatteryId(batteryId)
      setVehicleId(vehicleId)
      setinitial(false)
      getStaticsReport({...tableReqBody,...objData})
      if(batteryId){
        getSOCTemparature({...tableReqBody,...objData})
        getDailyUsage({...tableReqBody,...objData})
      }
   },[])  
 

  useEffect(()=>{
   if(refresh !==0 && batteryId){
    getSOCTemparature({...tableReqBody})
    getDailyUsage({...tableReqBody})
    getStaticsReport({...tableReqBody})
   }
  },[refresh])


  const getStaticsReport = async (payloads:any) => {
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    setIsShowSpinner(true)
    try {
      const response = await service({
        url: constantUrl.api.batteryStatiscs.getStastics,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data:payloads
      });
      setTableReport(response)
    } catch (error) {
      console.log(error)
    }finally{
      setCalculateHeight(true)
      setIsShowSpinner(false)

    }
  };

  const formatDate = (date: any) => {
    let date1 = new Date(date);
    var totalMinutes = date1.getTimezoneOffset();
    var hours = 0;
    if(totalMinutes > 0) {
      hours = Math.floor(totalMinutes / 60);
    } else {
      hours = Math.ceil(totalMinutes / 60);
    }         
    var minutes = totalMinutes % 60;
    date1.setHours(date1.getHours() + hours);
    date1.setMinutes(date1.getMinutes() + minutes);

    return format(date1, 'dd-MMM yy')
  };

  const socDataPoints = (data: any[]) => {
    if (!data.length) return
    let record = data.flatMap((item: any) => { return [{ value: item.startSoc,color: '#e8e368' }, { value: item.endSoc,color: '#5083b7',dashed:1 }] });
    let recordTemp = data.flatMap((item: any) => { return [{ value: item.startTemp,color: '#000066' }, { value: item.endTemp,color: '#e44a00' }] });
    let response = data.flatMap((item: any) => { return [formatDate(item.startDate), formatDate(item.endDate) ] });
    let labels: any = [];
    let values: string[] = [];

    for (let index = 0; index < response.length; index++) {
      if(values.includes(response[index])) {
        labels.push({ label: ""});
      } else {
        values.push(response[index]);
        labels.push({ label: response[index]});
      }
    }   

    setFilterData(record)
    setFilterDataTemp(recordTemp)
    setDateLabel(labels)
  }
   
  const getSOCTemparature = async (payload: any) => {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    setIsShowSpinner(true)
    try {
        const response = await service({
            url: constantUrl.api.batteryRXConfig.getSocTemparature,
            method: "POST",
            headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
            data: payload
        })

        if (response.length > 0) {
            socDataPoints(response)
            setIsShowSpinner(false)
        } else {
            setDateLabel([{ label: "" }])
            setFilterData([{ value: "0" }])
            setFilterDataTemp([{ value: "0" }])
        }
    } catch (error) {
        console.error("socTemparatureError:", error)
    }finally{
      setIsShowSpinner(false)

    }
  };

  const chartConfigs_line = {
    type: 'scrollcombidy2d',
    dataFormat: 'json',
    width: '100%',
    height: '92%',
    dataSource: {
      "chart": {
        "theme": "fusion",
        "subCaption": subcaption,
        "xAxisname": "Date",
        "pYAxisName": "STATE OF CHARGE %",
        "sYAxisName": "BATTERY TEMPERATURE",
        xAxisNameFontColor: "#4f82b6",
        xAxisNameFontBold: "1",
        pyAxisNameFontColor: "#4f82b6",
        pyAxisNameFontBold: "1",
        syAxisNameFontColor: "#4f82b6",
        syAxisNameFontBold: "1",
        "numberSuffix": "%",
        "sNumberSuffix": "°",
        "showValues": "1",
        "numVisiblePlot": "9",
        "flatScrollBars": "1",
        "scrollheight": "10",
        "formatNumberScale": "0",
        "formatNumber": "0",
        "decimals": "0",
        //  isTrendZone: "1",
        rotatelabels: "0",
        lineThickness: "3",
        setadaptiveymin: "1",
        showToolTip: '1',
        drawAnchors: '1',
        "legendposition": "top",
        "legendPadding": "50",
        "drawCustomLegendIcon": "2",
        "legendIconSides": "0",
        "paletteColors": "#e8e368,#000066",
        labelFontSize: "12",
        labelDisplay: "none",
        //legendPadding: "400",
        //  chartTopMargin: "70",
        useEllipsesWhenOverflow: "1",
        toolTipBgAlpha: "100",
        "labelStep": "2",
      },
      "categories": [{
        "category": dateLabel.length ? dateLabel : [{ label: "" }]
      }],
      "dataset": [{
        "seriesName": "State Of Charge",
        "renderAs": "line",
        "showValues": "0",
        "data": filterData.length ? filterData : [{ value: "0" }]
      },
      {
        "seriesName": "Battery Temperature",
        "parentYAxis": "S",
        "renderAs": "line",
        "showValues": "0",
        "data": filterDataTemp.length ? filterDataTemp : [{ value: "0" }]
      }

      ]
    }
  };


  const chartConfigs2 = {
    type: "scrollmsstackedcolumn2dlinedy",
    width: '100%',
    height: '92%',
    dataFormat: 'json',
    dataSource: {
        chart: {
            "xAxisName": "Date",
            "YAxisName": "Total Ahrs",
            "subCaption": subcaption,
            // "sYAxisName": "Over/Under Charge",
            "xAxisNameFontColor": "#4f82b6",
            "YAxisNameFontColor": "#4f82b6",
            // "sYAxisNameFontColor": "#4f82b6",
            xAxisNameFontBold: "1",
            YAxisNameFontBold: "1",
            // sYAxisNameFontBold: "1",
            setadaptiveYmin: "1",
            //setadaptivesYmin: "1",
            "formatNumberScale": "0",
            "formatNumber": "0",
            "decimals": "0",
            thickness: "10",
            //"animation": "1",
            //"numdivlines": "4",
            "legendposition": "top",
            "legendPadding": "50",
			      // numvisibleplot: "3",
            "drawCustomLegendIcon": "2",
            "legendIconSides": "0",
            "paletteColors": "#e8e368,#4f82b6,#e20a15",
            "showvalues": "0",
            showDivLineSecondaryValue:"0",
            showSecondaryLimits:"0",
            showsum: "1",
            // plotSpacePercent: 100,
            // chartTopMargin: "70",
            "theme": "fusion"
        },
        "categories": [{
            "category": dailyDate
        }],
        "dataset": [{
            "dataset": [{
                "seriesname": "Total Ahrs In",
                plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
                "data": totalAhrsIn
            }
            ]
        },
        {
            "dataset": [{
                "seriesname": "Total Ahrs Out",
                plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
                "data": totalAhrsOut
            }
            ]
        },
        {
            "dataset": [{
                "seriesname": "Total Plugins",
                plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
                "data": totalPlugins
            }
            ]
        }
        ]
    }
  }

  const getDailyUsage = async (payload: any) => {
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;

    try {
        const response = await service({
            url: constantUrl.api.dailyUsagePlugin.dailyUsagePlugin,
            method: "POST",
            headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
            data: payload
        })
        dailyUsageDataPoints(response)
    } catch (error) {
        console.error("dailyUsesError:", error)
    }
  };

  const dailyUsageDataPoints = (data: any) => {
    let dateLable: any = data.map((item: any) => {
        return {
            label:item.formatDailyDate
        }
    })

    let totalHoursIn: any = data.map((item: any) => {
        return {
            value: item.totalAhrsIn
        }
    })

    let totalAhrsOut: any = data.map((item: any) => {
        return {
            value: item.totalAhrsOut
        }
    })

    let totalPlugins: any = data.map((item: any) => {
        return {
            value: item.totalPlugins
        }
    })
    setDailyDate(dateLable)
    setTotalAhrsIn(totalHoursIn)
    setTotalAhrsOut(totalAhrsOut)
    setTotalPlugins(totalPlugins)

    let totalHoursInMax = Math.max(...totalHoursIn.map((o: any) => o.value))
    let totalHoursOutMax = Math.max(...totalAhrsOut.map((o: any) => o.value))
    let totalPluginsMAx = Math.max(...totalPlugins.map((o: any) => o.value))

    let final: any = [totalHoursInMax, totalHoursOutMax, totalPluginsMAx];
    let maxValue = Math.max(...final.map((o: any) => o));
    maxValue = maxValue + 100;
  };

  const downloadPdf = useCallback(() => {
    if (ref.current === null) {
      return
    }

    html2canvas(ref.current)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a')
        link.download = 'my-image-name.png';
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 5, 5, 200, 180);
        pdf.save("Battery_Statistics.pdf");
      })
  }, [ref])

  const formattedDate = (date: string) => {
    const date1 = date;
    const parsedDate = parseISO(date1);
    const formatDate = format(parsedDate, 'M/d/yyyy h:mm a');
    return formatDate
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      {/* <div className="container-scroller"> */}
      <div className="">
        <div className="page-battery-body-wrapper">
          <div className="battery-batteries" ref={ref}>
            <div className='ml-3 mr-3'>
              <div className='justify-content-around'>
                <div className="battery-Statistics-top-logo">
                  <img className='battery-Statistics-logo' src={AmpureLogo} alt=""></img>
                  <img className='battery-Statistics-logo' src={Ampure} alt=""></img>
                </div>
                <div className='statisticsheader'>Battery Statistics  {batteryId && vehicleId ? `- ${batteryId}/${vehicleId}`:
                                                                       batteryId && tableReport[0]?.vehicleId ? `- ${batteryId}/${tableReport[0]?.vehicleId}`:
                                                                       batteryId?`- ${batteryId}`:
                                                                       vehicleId ? `- ${vehicleId}` : <></>}
                </div>
                  
                {/* </div> */}
                <div className='statisticscaption'>{subcaption}</div>
              </div>
              <div className="row justify-content-around pd15">
                <div className="col-md-12  col-lg-4 pr-1 pl-1">
                  <div ref={batteryRef} className="row">
                    <div className="col-12">
                      <div className="row statistics-details">
                        <div className='col-12 statistics-title'>Battery</div>
                        <div className=" col-12  px-0">
                          <div>
                            <div className="statisticsb-text">
                              <table ref={batteryTableRef} className='statistics-table'>
                                <tbody>
                                  <tr>
                                    <th>Battery Id</th>
                                    <td>{tableReport[0]?.batteryId}</td>
                                  </tr>
                                  <tr>
                                    <th>Vehicle Id</th>
                                    <td>{tableReport[0]?.vehicleId}</td>
                                  </tr>
                                  <tr>
                                    <th>BATTERY MFG Serial Number</th>
                                    <td>{tableReport[0]?.serialNumber}</td>
                                  </tr>
                                  <tr>
                                    <th>Initialization Date</th>
                                    <td>{tableReport[0]?.formatInitializationDate}</td>
                                  </tr>
                                  <tr>
                                    <th>Days in Operation</th>
                                    <td>{(tableReport[0]?.daysInOperation || tableReport[0]?.daysInOperation == 0) ? tableReport[0]?.daysInOperation + " Days" : ""}</td>
                                  </tr>
                                  {/* <tr className='cellheight1'> */}
                                  <tr>
                                    <th>Date as of</th>
                                    <td>{tableReport[0]?.formatLogTimestamp}</td>
                                  </tr>
                                  {/* <tr>
                                    <th style={{height:batteryTablecellHeight}}></th>
                                    <td></td>
                                  </tr> */}
                                </tbody>
                              </table>
                              <div style={{height:batteryTablecellHeight,background:oddColor,margin:emptyCellMargin}}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div ref={voltageRef} className="row mt-3 statistics-details">
                        <div className='col-12 statistics-title'>Voltage</div>
                        <div className=" col-12  px-0">
                          <div>
                            <div className="statisticsb-text">
                              <table className='statistics-table'>
                                <tbody>
                                  <tr>
                                    <th>Number Low Voltage Events</th>
                                    <td>{tableReport[0]?.daysLowVoltage}</td>
                                  </tr>
                                  <tr>
                                    <th>Number High Voltage Events</th>
                                    <td>{tableReport[0]?.daysHighVoltage}</td>
                                  </tr>
                                  <tr>
                                    <th>Min Recorded Voltage</th>
                                    <td>{(tableReport[0]?.minRecordedVoltage || tableReport[0]?.minRecordedVoltage == 0) ? tableReport[0]?.minRecordedVoltage + " V" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Max Recorded Voltage</th>
                                    <td>{(tableReport[0]?.maxRecordedVoltage || tableReport[0]?.maxRecordedVoltage == 0) ? tableReport[0]?.maxRecordedVoltage + " V" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Nominal Battery Voltage</th>
                                    <td>{(tableReport[0]?.nominalBatteryVoltage || tableReport[0]?.nominalBatteryVoltage == 0) ? tableReport[0]?.nominalBatteryVoltage + " V" : ""}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div ref={tempRef} className="row mt-3 mb-3 statistics-details">
                        <div className='col-12 statistics-title'>Temperature</div>
                        <div className=" col-12  px-0">
                          <div>
                            <div className="statisticsb-text">
                              <table ref={tempTableRef}className='statistics-table'>
                                <tbody>
                                  <tr>
                                    <th>Days High Temperature Events</th>
                                    <td>{(tableReport[0]?.daysHighTemperature || tableReport[0]?.daysHighTemperature == 0) ? tableReport[0]?.daysHighTemperature + " Days" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Min Recorded Temperature</th>
                                    <td>{(tableReport[0]?.formatMinBatteryTemp && tableReport[0]?.formatMinBatteryTemp !=="(n/a)")  ? tableReport[0]?.formatMinBatteryTemp + " °" :
                                         tableReport[0]?.formatMinBatteryTemp =="(n/a)"?tableReport[0]?.formatMinBatteryTemp:
                                         ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Max Recorded Temperature</th>
                                    <td>{(tableReport[0]?.formatMaxRecordedTemp && tableReport[0]?.formatMaxRecordedTemp !=="(n/a)")  ? tableReport[0]?.formatMaxRecordedTemp + " °" :
                                         tableReport[0]?.formatMaxRecordedTemp =="(n/a)"?tableReport[0]?.formatMaxRecordedTemp:
                                         ""}</td>
                                  </tr>
                                  {/* <tr>
                                    <th style={{visibility:"hidden"}}>Max Recorded Temperature</th>
                                    <td style={{visibility:"hidden"}}>{(tableReport[0]?.formatMaxRecordedTemp && tableReport[0]?.formatMaxRecordedTemp !=="(n/a)")  ? tableReport[0]?.formatMaxRecordedTemp + " °" :
                                         tableReport[0]?.formatMaxRecordedTemp =="(n/a)"?tableReport[0]?.formatMaxRecordedTemp:
                                         ""}</td>
                                  </tr> */}
                                </tbody>
                              </table>
                              <div style={{height:tempCellHeight,background:evenColor,margin:emptyCellMargin}}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3 pr-1 pl-1">
                  <div className="row ">
                    <div className="col-12 ">
                      <div className="row statistics-details">
                        <div className='col-12 statistics-title'>Charge Vs Discharge Data</div>
                        <div className=" col-12  px-0">
                          <div>
                            <div className="statisticsb-text">
                              <table ref={dischargeTableRef} className='statistics-table'>
                                <tbody>
                                  <tr>
                                    <th>Total Charge Events</th>
                                    <td>{(tableReport[0]?.totalChargeEvents || tableReport[0]?.totalChargeEvents == 0) ? tableReport[0]?.totalChargeEvents + " Events" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Cumulative Amp-hours Charged</th>
                                    <td>{(tableReport[0]?.cumulativeChargeAhs || tableReport[0]?.cumulativeChargeAhs == 0) ? tableReport[0]?.cumulativeChargeAhs + " Ahrs" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Cumulative Kilowatt-hours Charged</th>
                                    <td>{(tableReport[0]?.cumulativeChargeKwh || tableReport[0]?.cumulativeChargeKwh == 0) ? tableReport[0]?.cumulativeChargeKwh + " KWhrs" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Cumulative Discharge Amp-hours</th>
                                    <td>{(tableReport[0]?.totalDischargeAh || tableReport[0]?.cumulativeChargeKwh == 0) ? tableReport[0]?.totalDischargeAh + " Ahrs" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Cumulative Discharge KWhrs</th>
                                    <td>{(tableReport[0]?.totalDischargeKwh || tableReport[0]?.cumulativeChargeKwh == 0) ? tableReport[0]?.totalDischargeKwh + " KWhrs" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Charge vs Discharge KWhrs</th>
                                    <td>{(tableReport[0]?.chargeVsDischargeKWhrs || tableReport[0]?.chargeVsDischargeKWhrs == 0) ? tableReport[0]?.chargeVsDischargeKWhrs + " KWhrs" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Cumulative Overcharge %</th>
                                    <td>{(tableReport[0]?.cumulativeOvercharge || tableReport[0]?.cumulativeOvercharge == 0 ) ? tableReport[0]?.cumulativeOvercharge + " %" : ""}</td>
                                  </tr>
                                  {/* <tr>
                                    <th ref={emptyBox1} className='chargeheight'></th>
                                    <td></td>
                                  </tr> */}
                                </tbody>
                              </table>
                              <div style={{height:emptyBox1Height+'px',background:evenColor,margin:emptyCellMargin}} ref={emptyBox1}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div ref={capacityRef} className="row mt-3 mb-3 statistics-details">
                        <div  className='col-12 statistics-title'>Battery Life / Capacity</div>
                        <div className=" col-12 px-0 ">
                          <div>
                            <div className="statisticsb-text">
                              <table ref={batteryLifeTableRef} className='statistics-table'>
                                <tbody>
                                  <tr>
                                    <th>Remaining Battery Life (months)</th>
                                    <td>{(tableReport[0]?.remainingBatteryLifeMonths || tableReport[0]?.remainingBatteryLifeMonths == 0) ? tableReport[0]?.remainingBatteryLifeMonths + " months" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Battery Consumption (Ahrs)</th>
                                    <td>{(tableReport[0]?.batteryConsumptionAhrs || tableReport[0]?.batteryConsumptionAhrs == 0) ? tableReport[0]?.batteryConsumptionAhrs + " Ahrs" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Rated Battery Capacity</th>
                                    <td>{(tableReport[0]?.ratedBatteryCapacity || tableReport[0]?.ratedBatteryCapacity == 0) ? tableReport[0]?.ratedBatteryCapacity + " Ahrs" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Estimated Battery Capacity</th>
                                    <td>{tableReport[0]?.estimatedBatteryCapacity ? tableReport[0]?.estimatedBatteryCapacity + " Ahrs" : "TBD"}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4 pl-1 pr-1">
                  <div className="row ">
                    <div className="col-12 ">
                      <div className="row statistics-details">
                        <div className='col-12 statistics-title'>Equalization</div>
                        <div className=" col-12  px-0">
                          <div>
                            <div className="statisticsb-text">
                              <table ref={equalizationTableRef} className='statistics-table'>
                                <tbody>
                                  <tr>
                                    <th>Total Equalization hours</th>
                                    <td>{(tableReport[0]?.eqHours || tableReport[0]?.eqHours == 0) ? tableReport[0]?.eqHours + " Hours" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Total Equalization events</th>
                                    <td>{(tableReport[0]?.completeEqEvents || tableReport[0]?.completeEqEvents == 0) ? tableReport[0]?.completeEqEvents + " Events" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Days since last Complete Equalization</th>
                                    <td>{(tableReport[0]?.daysSinceCompEq || tableReport[0]?.daysSinceCompEq == 0 ) ? tableReport[0]?.daysSinceCompEq + " Days" :""}</td>
                                  </tr>
                                  <tr>
                                    <th>Max Rec Days between Equalization</th>
                                    <td>{(tableReport[0]?.maxDaysBetweenEqs || tableReport[0]?.maxDaysBetweenEqs == 0) ? tableReport[0]?.maxDaysBetweenEqs + " Days" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Max Rec Amp hours between Equalizations</th>
                                    <td>{(tableReport[0]?.maxAhsBetweenEqs || tableReport[0]?.maxAhsBetweenEqs == 0) ? tableReport[0]?.maxAhsBetweenEqs + " Ahrs" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Last Complete EQ Date</th>
                                    <td>{tableReport[0]?.formatDateLastCompEq}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div ref={operationalDivRef} className="row mt-3 statistics-details">
                        <div className='col-12 statistics-title'>Operational vs Non-operational Load</div>
                        <div className=" col-12  px-0">
                          <div>
                            <div className="statisticsb-text">
                              <table className='statistics-table'>
                                <tbody>
                                  <tr>
                                    <th>Run Time to Operational Time</th>
                                    <td>{(tableReport[0]?.runHours || tableReport[0]?.runHours == 0) ? tableReport[0]?.runHours + " hrs" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Key-On Time to Non-Operational Time</th>
                                    <td>{(tableReport[0]?.keyOnHours || tableReport[0]?.keyOnHours == 0) ? tableReport[0]?.keyOnHours + " hrs" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th>Cumulative Charge Time</th>
                                    <td>{(tableReport[0]?.cumulativeChargeTime || tableReport[0]?.cumulativeChargeTime == 0) ? tableReport[0]?.cumulativeChargeTime + " hrs" : ""}</td>
                                  </tr>
                                  <tr ref={idleTimeRef}>
                                    <th>Cumulative Idle Time</th>
                                    <td>{(tableReport[0]?.cumulativeIdleTime || tableReport[0]?.cumulativeIdleTime == 0) ? tableReport[0]?.cumulativeIdleTime + " hrs" : ""}</td>
                                  </tr>
                                  <tr>
                                    <th style={{height:idelTimeCellHeight}}></th>
                                    <th></th>
                                  </tr>
                                  {/* <tr style={{background:oddColor}}>
                                    <th style={{visibility:"hidden"}}>Cumulative Idle Time</th>
                                    <td style={{visibility:"hidden"}}></td>
                                  </tr> */}
                                </tbody>
                              </table>
                              {/* <div style={{height:operationCellHeight}}></div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3 mb-3 statistics-details">
                        <div ref={headingRef} className='col-12 statistics-title'>Water</div>
                        <div className=" col-12  px-0">
                          <div>
                            <div className="statisticsb-text">
                              <table ref={WaterTableRef} className='statistics-table'>
                                <tbody>
                                  <tr >
                                    <th>Number of Days with Low Water</th>
                                    <td>{(tableReport[0]?.lowWaterDays || tableReport[0]?.lowWaterDays == 0) ? tableReport[0]?.lowWaterDays + " Days" : ""}</td>
                                  </tr>
                                  {/* <tr>
                                    <th ref={emptyBox2} className='waterheight'></th>
                                    <td></td>
                                  </tr> */}
                                </tbody>
                              </table>
                              <div style={{height:waterCellHeight,background:evenColor,margin:emptyCellMargin}}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-around pd15">
                <div className="col-md-6  col-lg-6 pr-1 pl-1">
                  <div className="row">
                    <div className="col-12">
                      {/* <div className="container-battery-voltage bordeclr"> */}
                      <div className="container-battery-voltage">
                        <div className="chartTitle p-2 text-center d-flex align-items-center">
                          <span className='mr-auto ml-auto '>State of Charge & Temperature</span>
                        </div>
                        <div className='daily-plugin-chart'>
                          <ReactFC {...chartConfigs_line} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6  col-lg-6 pr-1 pl-1">
                  <div className="row">
                    <div className="col-12">
                      {/* <div className="container-battery-voltage bordeclr"> */}
                      <div className="container-battery-voltage">
                        <div className="chartTitle p-2 text-center d-flex align-items-center">
                          <span className='mr-auto ml-auto '>Usage Summary</span>
                        </div>
                        <div className='daily-plugin-chart'>
                          <ReactFC {...chartConfigs2} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SessionWarning />
    </>
  )
}

export default BatteryStatistics;