import React, { FC, useState, useEffect, useDeferredValue, useLayoutEffect } from 'react';
// import CountryChart from '../fusionChart/countryChart';
import { Input, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { format, startOfWeek } from 'date-fns';
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import TaskSummary from '../reusableComponent/TaskSummary';
import Summary from '../reusableComponent/summary';
import OperationStatus from '../reusableComponent/operations-status';
import Donut from '../fusionChart/donutChart';
import Batteries from '../../images/Battery Dashboard.png';
import SiteIcon from '../../images/Companies Icon@2x.png';
import LocationIcon from '../../images/location@2x.png';
import UserIcon from '../../images/GlobalUser@2x.png';
import * as roles from '../../settings/roles';
import CountryMap from '../../images/CountryMap.png';
import { resize, hideSidebar } from '../../settings/resize';
import mapBannerIcon from "../../images/mapBanner.svg";
import posiBanneLogo from "../../images/posilinkBannerLogo.svg";


const { Search } = Input;
interface props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    setGlobalData: (key: any, value: any) => void,
    setLabelName: (key: any, value: any) => void
}

const CompanyDashboard: FC<props> = ({ globalData, setGlobalData, setLabelName }) => {
    const [current, setCurrent] = useState<number>(1);
    const [minIndex, setMindex] = useState<number>(0);
    const [maxIndex, setMaxindex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [search, setSearch] = useState<string>("");
    const [showSizeChanger, setShowSizeChanger] = useState<boolean>(false);
    const [pageSizeOptions, setPageSizeOptions] = useState<[number, number, number, number]>([10, 20, 50, 100]);
    const [locationAlerts, setLocationAlerts] = useState<any[]>([]);
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
    const [orgId, setOrgId] = useState<number>(0);
    const [taskSummary, setTaskSummary] = useState<any>({ severeFaults: 0, overdueTasks: 0, upcomingTasks: 0 });
    const [summary, setSummary] = useState<any>({ divicesCount: 0, locationsCount: 0, siteCount: 0, usersCount: 0 });
    const [soc, setSoc] = useState<any>({ highSOCUnits: 0, lowSOCUnits: 0, totalChargeCount: 0, totalDisconnectedCount: 0, totalInstalledUnits: 0, totalNoChargeUnits: 0 });
    const [operationStatus, setOperationStatus] = useState<any>({
        highTempBatteries: 0,
        totalAhsCharged: 0,
        totalCompletedUnits: 0,
        totalOverdueUnits: 0,
        totalUpcomingUnits: 0,
        totalWateringEvents: 0
    });
    const [date, setDate] = useState<{ startDate: string, endDate: string }>({ startDate: "", endDate: "" });
    const [countryRes, setCountryRes] = useState<any[]>([]);
    const [initial, setInitial] = useState<boolean>(true);
    const defervalue = useDeferredValue(search);
    const [count, setCount] = useState<any>(0);

    useLayoutEffect(() => {
        let mapHeight = document.getElementById('country2Chart')?.offsetHeight
        let searchAlertHeight: any = document.getElementById('search-alerts')?.offsetHeight
        let alertHeaderTitle: any = document.getElementById('location-card-title')?.offsetHeight
        let locationtitleAndPaginationMargin = 35
        var rightSideHeight: any
        var remaningHeight: any
        var getLocalData: any
        let cardHeight = document.getElementById('dealer-card')?.offsetHeight
        let paginationHeight: any = document.getElementById('locationAlertsPagination')?.offsetHeight

        if (cardHeight) {
            let cardCount = 10
            rightSideHeight = mapHeight + alertHeaderTitle + (cardHeight + 12) * cardCount
            getLocalData = localStorage.getItem('leftsideheight')
            if (getLocalData) { remaningHeight = parseInt(getLocalData) - (mapHeight + alertHeaderTitle + paginationHeight + searchAlertHeight + locationtitleAndPaginationMargin) }
        }

        if (rightSideHeight > parseInt(getLocalData) && window.innerWidth < 1025) {
            let getDiv = document.querySelector("[class='location-alerts-container']") as HTMLElement
            getDiv.style.height = (remaningHeight - paginationHeight) + 'px'
        }

        if (rightSideHeight > parseInt(getLocalData) && window.innerWidth > 1900) {
            let getDiv = document.querySelector("[class='location-alerts-container']") as HTMLElement
            getDiv.style.height = (remaningHeight) + 'px'
        }

    });

    useEffect(() => {       
        let userInfo: any = Utility.getUserInfo();
        var userId = userInfo.userId;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        resize();
        hideSidebar();

        setOrgId(data?.orgId)

        let org = data?.orgId > -1 ? data?.orgId : userId;

        const today = new Date();
        let lastDayOfWeek = format(today, 'dd-MMM-yyyy')
        let firstDayOfWeek = format(startOfWeek(today, { weekStartsOn: 0 }), 'dd-MMM-yyyy');
        // setDate({ ...date, startDate: firstDayOfWeek, endDate: lastDayOfWeek })

        getTaskSummary(org, data?.divId, data?.locationId, data?.buildingId)
        getCompanySummary(org, data?.divId, data?.locationId, data?.buildingId);
        socCompanyDashboard(firstDayOfWeek, lastDayOfWeek, org, data?.divId, data?.locationId, data?.buildingId)
        // getOperationStatusData(firstDayOfWeek, lastDayOfWeek, org, data?.divId, data?.locationId, data?.buildingId)
        countrySummary(org, data?.divId, data?.locationId, data?.buildingId)
        alerts(org, data?.divId, data?.locationId, data?.buildingId)
        setInitial(false)
    }, []);

    useEffect(()=>{
        let userInfo: any = Utility.getUserInfo();
        if(!initial) {
            const { startDate, endDate } = date;
            let orgid = globalData?.orgId > -1 ? globalData?.orgId : userInfo.userId;

            getTaskSummary(orgid, globalData?.divId, globalData?.locationId, globalData?.buildingId);
            getCompanySummary(orgid, globalData?.divId, globalData?.locationId, globalData?.buildingId);
            socCompanyDashboard(startDate, endDate, orgid, globalData?.divId, globalData?.locationId, globalData?.buildingId)
            // if(startDate && endDate) {
                getOperationStatusData(startDate, endDate, orgid, globalData?.divId, globalData?.locationId, globalData?.buildingId)
            // }
            countrySummary(orgid, globalData?.divId, globalData?.locationId, globalData?.buildingId)
            alerts(orgid, globalData?.divId, globalData?.locationId,globalData?.buildingId)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId])

    const getTaskSummary = async (orgId: number, divId: number, locId: number, buildingId: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;
        let url = constantUrl.api.dashboard.getTaskSummary;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locId,
            buildingId: buildingId
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });

            const { severeFaults, overdueTasks, upcomingTasks } = response
            setTaskSummary({ ...taskSummary, severeFaults, overdueTasks, upcomingTasks })
        } catch (error) {
            console.log(error)
        }
    }

    const getCompanySummary = async (orgId: any, divId:any, loId: any, building: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        let url = constantUrl.api.dashboard.getCompanySummary;

        let payload = {
            orgId: orgId,
            divisionId: divId > -1 ? divId : -1,
            locationId: loId > -1 ? loId : -1,
            buildingId: building > -1 ? building : -1
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "content-type": "application/json", Authorization: "Bearer " + token, },
                data: payload
            });
            const { divicesCount, locationsCount, siteCount, usersCount } = response
            setSummary({ ...summary, divicesCount, siteCount, locationsCount, usersCount })
        } catch (error) {
            console.warn(error);
        }
    }

    const getOperationStatusData = async (startDate: string, endDate: string, orgId: any, divId: any, loId: any, buildingId: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        var url = constantUrl.api.dashboard.getOperationStatus;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: loId,
            buildingId: buildingId,
            startDate: startDate,
            endDate: endDate,
            weekStartDay: localStorage.getItem("weeklyStartDay")
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { Authorization: "Bearer " + token },
                data: payload
            });

            const { highTempBatteries, totalAhsCharged, totalCompletedUnits, totalOverdueUnits, totalUpcomingUnits, totalWateringEvents } = response
            setOperationStatus({ ...operationStatus, highTempBatteries, totalAhsCharged, totalCompletedUnits, totalOverdueUnits, totalUpcomingUnits, totalWateringEvents })
        } catch (error) {
            console.warn(error);
        }
    }

    const socCompanyDashboard = async (startDate: string, endDate: string, orgId: any, divId:any, loId: any, buildingId: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        let url = constantUrl.api.dashboard.getOperationSoc;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: loId,
            buildingId: buildingId,
            startDate: startDate,
            endDate: endDate
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { "content-type": "application/json", Authorization: "Bearer " + token },
                data: payload
            });

            const { highSOCUnits, lowSOCUnits, totalChargeCount, totalDisconnectedCount, totalInstalledUnits, totalNoChargeUnits } = response
            setSoc({ ...soc, highSOCUnits, lowSOCUnits, totalChargeCount, totalDisconnectedCount, totalInstalledUnits, totalNoChargeUnits })
        } catch (error) {
            console.warn(error);
        }
    }

    const countrySummary = async (orgId: any, divId: any, locId: any, building: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let url = constantUrl.api.dashboard.getCountrySummary;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locId,
            buildingId: building,
            countryCode: "USA"
        }
        
        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });

            setCountryRes(response)
        } catch (error) {
            console.log(error)
        }
    }

    const alerts = async (orgId: number, divId:any, locationId: number, buildingId: number) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let url = constantUrl.api.dashboard.getLocationAlerts;
        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locationId,
            buildingId: buildingId
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { Authorization: "Bearer " + token, },
                data: payload
            });

            if (response) {
                setLocationAlerts(response)
                setMindex(0)
                setMaxindex(pageSize)
                setCount(response.length)
                response.length > 10 && setShowSizeChanger(true)
            }
        } catch (error) {
            console.warn(error);
        }
    }

    const handleChange = (current: number, pageSize: number) => {
        setCurrent(current);
        setMindex((current - 1) * pageSize)
        setMaxindex(current * pageSize);
        setPageSize(pageSize);
    }

    const filterLocationName = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value
        value.length < 3 ? setSearch("") : setSearch(value)
    }

    const dateFilter = (startDate: string, endDate: string) => {
        setDate({ ...date, startDate, endDate })
        getOperationStatusData(startDate, endDate, globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId)
    }

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div className="main-panel">
                <div className="ChartPanel">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-7 left-main-col" id='leftCol'>
                                <TaskSummary
                                    summaryData={taskSummary}
                                />
                                <div className="row left-admin-row justify-content-between mt-3  ">
                                    <div className="col-md-12 col-lg-12 col-xl-6">
                                        {/* SYSTEM SUMMARY   */}
                                        <Summary
                                            heading='COMPANY SUMMARY'
                                            summaryData={summary}
                                            orgID={orgId}
                                            SiteIcon={SiteIcon}
                                            batteryIcon={Batteries}
                                            LocationIcon={LocationIcon}
                                            UserIcon={UserIcon}
                                            siteLable={'SITES'}
                                            deviceLabel={'DEVICES'}
                                            locationLabel={'LOCATIONS'}
                                            userLabel={'USERS'}
                                        />
                                        {/* STATE OF CHARGE  */}
                                        {/* <div className="row state-charge" id='state-charge'>
                                            <div className="col-12 px-2 mt-2 mb-2">
                                                <div className="col-12  chartTitle">STATE OF CHARGE</div>
                                            </div> */}
                                            {/* doughnut chart  */}
                                            {/* <div className="col-md-12 pb-2 px-2 pie-chart" id="pie-chart">
                                                <Donut
                                                    pieRaidus={70}
                                                    doughnutRadius={60}
                                                    soc={soc}
                                                />
                                            </div> */}
                                            {/* doughnut chart  */}
                                        {/* </div> */}
                                    </div>
                                    {/* OPERATIONS STATUS  */}
                                    <div className="col-md-12 col-lg-12 col-xl-6 operation-status-container" id='operation-status-container'>
                                        <OperationStatus
                                            statusData={operationStatus}
                                            dateFilter={dateFilter}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5  pr-0" id="company-dashboard">
                                 {/* <div className="row">
                                    <div className="col-md-12 pl-0 country-chart-company pie-chart" id="country2Chart" style={{ height: "196px" }}>
                                    <img src={CountryMap} style={{ width: "inherit", height: "200px" }} alt='img' />
                                       <CountryChart data={countryRes} /> 
                                    </div>
                                </div>*/}
                                   <div className="row">
                                  
                                    <div className="col-md-12 pl-0 pie-chart" id="country2Chart" style={{ height: "180px" }}>
                                     <div className="mapBannerDiv">
				    			 		{/* <img src={WorldMap} style={{ width: "inherit", height: "200px" }} alt='img' /> */}
				                                        <div className="row h-100">
								            <div className="col-md-5 mapBannerImgDiv h-100">
								              <img
								                src={mapBannerIcon}
								                className="mapBannerImg"
								                alt="mapBanner"
								              />
								            </div>
								            <div className="col-md-6">
								              <div className="mapBannerLogoDiv">
								                <img
								                  src={posiBanneLogo}
								                  alt="posilinkLogo"
								                  className="mapBannerDivLogo"
								                />
								              </div>
								              <label className="mapBannerDivText">
								                Welcome to Posilink, a<br></br> comprehensive
								                battery<br></br> management tool
								              </label>
								            </div>
								          </div>
								        </div>
                                       </div>

                                </div>
                                <div className="row" id='alerts'>
                                    <div className="col-12 pl-0">
                                        <div>
                                            <div className="chartTitle mt-2 mb-2" id='location-card-title'>LOCATION ALERTS</div>
                                            {/* <div className='search-alerts mb-1' id='search-alerts'>
                                                <Search placeholder="Search locations alerts"
                                                    onChange={filterLocationName}
                                                    style={{}}
                                                    allowClear
                                                    enterButton />
                                            </div> */}
                                            <div id="locationCards">
                                                <ul className='location-alerts-container'>
                                                    {locationAlerts && locationAlerts.filter((item1: any, index: any) => {
                                                        return defervalue.toLowerCase() === "" ? item1 : item1.name.toLowerCase().includes(defervalue.toLowerCase())
                                                    }).map((item: any, index: number) => {
                                                        const { name, locationsCount, severeFaults, overdueTasks, upcomingTasks, orderId } = item
                                                        return index >= minIndex &&
                                                            index < maxIndex && (
                                                                <div id='dealer-card' key={index} className="row dealer-card">
                                                                    <div className="col-md-12">
                                                                        <div className="dealer-card-body">
                                                                            <div className="delear-body-right">
                                                                                <div>
                                                                                    <img src={require('../../images/User.png')} className="dealear-img" alt='img' />
                                                                                </div>
                                                                                <div>
                                                                                    <div className="dealaer-no"  onClick={() => {
                                                                                    let data: any = sessionStorage.getItem('globalData');
                                                                                    data = data && JSON.parse(data);
                                                                                    data["locationId"] = orderId;
                                                                                    sessionStorage.setItem("globalData", JSON.stringify(data))
                                                                                    setLabelName("loc", name)
                                                                                    setGlobalData("locationName", name);
                                                                                }}><Link to={'/locationdashboard'}>{name?.length > 15 ? name?.substring(0, 15) + "..." : name}</Link> </div>
                                                                                    <div className="fontBlue ">{locationsCount}&nbsp; Sites</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="delear-body-left ">
                                                                                <div>
                                                                                    <div className="dot-red"></div>
                                                                                    <div className="dot-yellow"></div>
                                                                                    <div className="dot-green"></div>
                                                                                </div>
                                                                                <div className="count">
                                                                                    <div>{severeFaults}</div>
                                                                                    <div>{overdueTasks}</div>
                                                                                    <div>{upcomingTasks}</div>
                                                                                </div>
                                                                                <div className="delaler-status">
                                                                                    <div>SEVERE FAULTS</div>
                                                                                    <div>OVERDUE TASKS</div>
                                                                                    <div>UPCOMING TASKS</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <div className='pagination-div mt-2' id="locationAlertsPagination">
                                            <Pagination
                                                pageSize={pageSize}
                                                current={current}
                                                total={count}
                                                onChange={handleChange}
                                                pageSizeOptions={pageSizeOptions}
                                                showSizeChanger={showSizeChanger}
                                                showLessItems={true}
                                                style={{ bottom: "0px", textAlign: "center" }}
                                            ></Pagination>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* for Comapny Dashboard End  */}
                        </div>
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    )
}

export default CompanyDashboard