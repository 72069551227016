import React,{useLayoutEffect,useEffect} from "react";
import PosinetHeader from "../../layout/header";
import { Link } from 'react-router-dom';
import SessionWarning from "../reusableComponent/sessionWarning";
import { resize, hideSidebar } from "../../settings/resize";

const Reports = () => {

    useLayoutEffect(()=>{
       let timer:any
       const debouncedHandleResize = ()=>{
        clearTimeout(timer)
        timer=setTimeout(()=>{
         resize()
        },500)
       }
       window.addEventListener("resize",debouncedHandleResize)
       return()=>{
        window.removeEventListener("resize",debouncedHandleResize)       }
    })

    useLayoutEffect(()=>{
      resize();
      hideSidebar();
    },[])

    return (
        <>
            {/* <PosinetHeader /> */}
            {/* <div className="container report-mt p-2"> */}
            <div className="container p-2">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="row">
                            <h5>Most popular Reports</h5>
                        </div>
                    </div>
                    <div className="col-12 pt-2 pb-3">
                        <div className="row justify-center">
                            {
                                localStorage.permissions.includes('stateOfCharge_W') &&
                                <Link to="/soc-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Battery SOC Report</div>
                                        <br />
                                        <div className="flex-item-body">Battery State of Charge by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('battery_temperature_W') &&
                                <Link to="/temperature-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Battery Temperature Report</div>
                                        <br />
                                        <div className="flex-item-body">Battery Temperature by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('daily_usage_plugin_W') &&
                                <Link to="/daily-usage-and-plug-in-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Daily Usage Report</div>
                                        <br />
                                        <div className="flex-item-body">Daily Usage Charge and Discharge Report</div>
                                    </div>
                                </Link>
                            }
                            {/* <Link to="/billing-summary-report" aria-expanded="false">
                                <div className="flex-item">
                                    <div className="flex-item-head">Billing Summary</div>
                                    <br />
                                    <div className="flex-item-body">Billable and Energy Usage Summary by Date Range</div>
                                </div>
                            </Link> */}
                            {
                                localStorage.permissions.includes('plugin_compliance_time_W') &&
                                <Link to="/plug-in-compliance-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Plug In Compliance Report</div>
                                        <br />
                                        <div className="flex-item-body">Plug In Compliance Report by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('warrenty_time_W') &&
                                <Link to="/warranty-by-time-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Warranty by time Report</div>
                                        <br />
                                        <div className="flex-item-body">Warranty by time Report by Battery Id</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('remaining_time_W') &&
                                <Link to="/remaining-life-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Remaining Life Report</div>
                                        <br />
                                        <div className="flex-item-body">Remaining Life Report by Battery Id</div>
                                    </div>
                                </Link>
                            }
                            {/* <Link to="/charge-current-over-time-report" aria-expanded="false">
                                <div className="flex-item">
                                    <div className="flex-item-head">Charge Current Over Time</div>
                                    <br />
                                    <div className="flex-item-body">Charge Current Over Time Report by Date Range</div>
                                </div>
                            </Link> */}
                            {
                                localStorage.permissions.includes('consumption_ahrs_W') &&
                                <Link to="/consumption-by-ahrs-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Consumption by Ahrs</div>
                                        <br />
                                        <div className="flex-item-body">Consumption by Ahrs by Battery Id</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('consumption_kwhrs_W') &&
                                <Link to="/consumption-by-kwhrs-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Consumption by kWhrs</div>
                                        <br />
                                        <div className="flex-item-body">Consumption by kWhrs by Battery Id</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('equivalent-battery-utilization_W') &&
                                <Link to="/equivalent-battery-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Equivalent Battery Utilization</div>
                                        <br />
                                        <div className="flex-item-body">Equivalent Battery Utilization by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('equivalent-battery-utilization_W') &&
                                <Link to="/runtime-vs-ah-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Run Time Vs Ah Report</div>
                                        <br />
                                        <div className="flex-item-body">Graphical Representation of Run Time Vs Ah Report</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('Issues_report1_W') &&
                                <Link to="/issues-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Weekly Issues Report</div>
                                        <br />
                                        <div className="flex-item-body">Weekly Issues Report by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('Issues_report2_W') &&
                                <Link to="/issues-report2" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Weekly Issues Report 2</div>
                                        <br />
                                        <div className="flex-item-body">Weekly Issues Report 2 by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('batteryhhistoryReport_W') &&
                                <Link to="/battert-history-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Battery History Report</div>
                                        <br />
                                        <div className="flex-item-body">Battery History Report by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('lifeTimeBattery_W') &&
                                <Link to="/life-time-battery-history" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Lifetime Battery History Report</div>
                                        <br />
                                        <div className="flex-item-body">Lifetime Battery History Report by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('chargeSummary_W') &&
                                <Link to="/chargeSummary" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Charge Summary</div>
                                        <br />
                                        <div className="flex-item-body">Charge Summary by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('equalizationSummary_W') &&
                                <Link to="/configurationsummaryreport" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Configuration Summary Report</div>
                                        <br />
                                        <div className="flex-item-body">Configuration Summary Report by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('equalizationSummary_W') &&
                                <Link to="/equalizationSummary" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Equalization Summary</div>
                                        <br />
                                        <div className="flex-item-body">Equalization Summary by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('OBatteryRxUnits_R') &&
                                <Link to="/battery-rx-units" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Battery RX Units</div>
                                        <br />
                                        <div className="flex-item-body">Battery RX Units by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('OBatteryRxUnits_R') &&
                                <Link to="/battery-rx-test-page" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Battery RX Test Page</div>
                                        <br />
                                        <div className="flex-item-body">Battery RX Test Page</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('smsEventsReport_W') &&
                                <Link to="/smsreport" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">SMS Events Report</div>
                                        <br />
                                        <div className="flex-item-body">SMS Events Report by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('firmwarehistory_W') &&
                                <Link to="/firmhistoryreport" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Firmware History Report</div>
                                        <br />
                                        <div className="flex-item-body">Firmware History Report by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('firmwareUpdateController_W') &&
                                <Link to="/firmcontrolpanel" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Firmware Update Control Panel</div>
                                        <br />
                                        <div className="flex-item-body">Firmware Update Control Panel by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('shiftschedule_W') &&
                                <Link to="/shift-schedules" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Shift Schedules</div>
                                        <br />
                                        <div className="flex-item-body">Shift Schedules by Date Range</div>
                                    </div>
                                </Link>
                            }
                            <Link to="/dailyBatteryPerformanceReport" aria-expanded="false">
                                <div className="flex-item">
                                    <div className="flex-item-head">Daily Battery Performance</div>
                                    <br />
                                    <div className="flex-item-body">Daily Battery Performance by Date Range</div>
                                </div>
                            </Link>
                            <Link to="/battery-statistics" aria-expanded="false">
                                <div className="flex-item">
                                    <div className="flex-item-head">Battery Statistics</div>
                                    <br />
                                    <div className="flex-item-body">Battery Statistics by Date Range</div>
                                </div>
                            </Link>
                            {/* {
                                localStorage.permissions.includes('stateOfCharge_W') &&
                                <Link to="/battery-voltage-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Battery SOC Report - New</div>
                                        <br />
                                        <div className="flex-item-body">Battery State of Charge by Date Range</div>
                                    </div>
                                </Link>
                            }
                            {
                                localStorage.permissions.includes('battery_temperature_W') &&
                                <Link to="/battery-temperature-report" aria-expanded="false">
                                    <div className="flex-item">
                                        <div className="flex-item-head">Battery Temperature Report - New</div>
                                        <br />
                                        <div className="flex-item-body">Battery Temperature by Date Range</div>
                                    </div>
                                </Link>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    )
}

export default Reports;