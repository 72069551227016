import React,{FC,useState,useEffect} from 'react'
import { Select, ConfigProvider } from 'antd';
import { format,lastDayOfWeek,subDays,startOfMonth,endOfMonth,startOfWeek,subMonths,startOfYear} from 'date-fns'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

interface Props{
  daysFilter:(startdate:string,enddate:string,value:string)=> void
  customFilterStatusChange:(status: boolean) => void
  option7Days?:boolean
  option14Days?:boolean
  default14days?:boolean
}


const SelectDays:FC<Props> = ({daysFilter, customFilterStatusChange,option7Days,option14Days,default14days}) => {
    const [dateValue,setDateValue] = useState<any>([ 
    { value: 'Last 7 Days', label:'Last 7 Days'},    
    { value: 'Last 14 Days', label:'Last 14 Days'},
    { value: 'Custom Date Range', label:'Custom Date Range'}])

    useEffect(()=>{
      if(option7Days===false && option14Days===false){
        setDateValue(dateValue.slice(2)) 
      }
    },[])

    const handleChange = (value:string)=>{
        let today = new Date()
        if(value==="Last 7 Days"){
            const lastSevenDaysAgo = format(subDays(today,6),'dd-MMM-yyyy');
            const currentDate = format(today,'dd-MMM-yyyy')
            customFilterStatusChange(false)
            daysFilter(lastSevenDaysAgo,currentDate,value)
 
            return
         }else  if(value==="Last 14 Days"){
             const lastFourteenDaysAgo = format(subDays(today,13),'dd-MMM-yyyy') 
             const currentDate = format(today,'dd-MMM-yyyy') 
             customFilterStatusChange(false)
             daysFilter(lastFourteenDaysAgo,currentDate,value)
 
             return
         }else if(value === "Custom Date Range"){
            customFilterStatusChange(true)
        }
    }

    return (
        <>
            {/* <div className='position-relative'>  */}
            <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                <Select
                    defaultValue={(default14days === true) ? 'Last 14 Days' : 'Last 7 Days'} 
                    size={'small'}
                    style={{ width: 160 }}
                    dropdownStyle={{ zIndex: 900 }}
                    onChange={handleChange}
                    options={dateValue}>
                </Select>
            </ConfigProvider>
            {/* </div> */}
        </>
    )
}

export default SelectDays;