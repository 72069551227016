import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import FusionCharts from 'fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import LoadingSpinner from '../spinner/loading-spinner'
import { Utility } from "../../helper/index";
import constantUrl from "../../settings/constants";
import service from '../../service/api/common';
import { format, startOfMonth, subDays } from 'date-fns';
import SelectDays from '../reusableComponent/selectDays';
import SessionWarning from '../reusableComponent/sessionWarning';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import { resize, hideSidebar } from '../../settings/resize';
import DatePicker from 'react-date-picker';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const startOfCurrentMonth: any = format(subDays(new Date(), 7), 'dd-MMM-yyyy')
const today: any = format(new Date(), 'dd-MMM-yyyy');
const fusionDataStore = new FusionCharts.DataStore();

ReactFC.fcRoot(FusionCharts, TimeSeries, FusionTheme);

interface Props {
  globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
  action: string
  // refresh:number
}

const PlugInComplianceChart: FC<Props> = ({ globalData, action }) => {
  // const PlugInComplianceChart: FC<Props> = ({ globalData, action, refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [reqBody, setReqBody] = useState<any>({ startDate: today, orgId: 0, batteryId: "", vehicleId: "", divisionId: -1, locationId: -1, buildingId: -1, userName: localStorage?.userName })
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  // const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [value, onChange] = useState<any>(new Date());
  const [initial, setInitial] = useState<boolean>(true)
  // const [fusionTable, setFusionTable] = useState<any>();
  // const [breakmarkers, setBreakmarkers] = useState<any []>([]);
  const colors = ["#E81D13", "#0D8312", "#0A51D0"];
  const [chartConfig, setChartConfig] = useState<any>({
    type: "timeseries",
    width: "100%",
    height: '92%',
    dataFormat: 'json', dataSource: {}
  })
  
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setInitial(true);
    window.scrollTo(0, 0)
    resize();
    hideSidebar();

    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;
    setReqBody({ ...reqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    getPluginComplianceData({ ...reqBody, orgId: org,divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    setInitial(false)
  }, [])

  useEffect(() => {
    if (initial === false) {
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
      setReqBody({ ...reqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
      getPluginComplianceData({ ...reqBody, orgId: org,divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
    }
  }, [globalData?.orgId,globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

  useEffect(() => {
    if (action !== "") { onButtonClick(action) }
  }, [action])

  // useEffect(()=>{
  //   if(refresh !==0){
  //     getPluginComplianceData(reqBody)
  //   }
  // },[refresh])

  const daysFilter = (startDate: string) => {
    if (initial === false) {
      setReqBody({ ...reqBody, startDate })
      getPluginComplianceData({ ...reqBody, startDate })
    }
  }

  useEffect(() => {
    let date1: any = value && format(value, 'dd-MMM-yyyy');
    if (date1) {
      daysFilter(date1 ?? today);
    }
  }, [value])

  const onButtonClick = useCallback((value: string) => {
    if (ref.current === null) return
    setIsShowSpinner(true);
    
    if (value === 'png') {
      toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'Plug_In_Compliance.png'
          link.href = dataUrl
          link.click()
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    } else {
      html2canvas(ref.current)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const link = document.createElement('a')
          link.download = 'my-image-name.png';
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'PNG', 5, 20, 200, 100);
          pdf.save("Plug_In_Compliance.pdf");
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    }
  }, [ref])

  const getPluginComplianceData = async (payload: any) => {
    if(payload.orgId===0) return
    let userInfo: any = Utility.getUserInfo();
    var token = userInfo.authToken;
    setIsShowSpinner(true)
    try {
      const response = await service({
        url: constantUrl.api.plugInCompliance.plugInCompliance,
        method: "POST",
        headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
        data: payload
      })
      frameChart(response)
      setIsShowSpinner(false)
    } catch (error) {
      console.error("dailyUsesError:", error)
      setIsShowSpinner(false)
    }
  }

  const dateformat = (date: any) => {
    let date2 = date;

    let hours = new Date(date2).getHours();
    let minutes = new Date(date2).getMinutes();

    return hours + ":" + minutes;
  }

  const frameChart = (data: any) => {
    let schema = [{ "name": "Time", "type": "date", "format": "%d/%m/%Y %-H:%M" }, { "name": "SOC", "type": "number" }];  
    let socDataPoints = [];
    let breakmarkers = [];

    let month = data?.shiftStartDate?.split(" ")[0]?.split("/")[0];
    let day = data?.shiftStartDate?.split(" ")[0]?.split("/")[1];
    let day1 = Number(data?.shiftStartDate?.split(" ")[0]?.split("/")[1]) + 1;
    let year = data?.shiftStartDate?.split(" ")[0]?.split("/")[2];

    const dates: any = day + "/" + month + "/" + year;
    const dates1: any = day1 + "/" + month + "/" + year;

    if(data?.socDetails?.length > 0) {
      socDataPoints.push([dates + " 00:01", 0])
      for (let index = 0; index < data?.socDetails?.length; index++) {
        let date: any = dates + " " + dateformat(data?.socDetails[index]?.startDate)
        let date1: any = dates + " " + dateformat(data?.socDetails[index]?.endDate)

        if (date) { socDataPoints.push([date, data?.socDetails[index]?.startSoc ]) }
        if (date1) { socDataPoints.push([date1, data?.socDetails[index]?.endSoc ]) }
      }
      socDataPoints.push([dates + " 23:59", 0])
    } else {
      socDataPoints = [[dates + " 00:01", 0], [dates + " 22:00", 0], [dates + " 23:59", 0]];
    }

    if(data?.shift) {
      if(data?.shift?.shift1 == "Y") {
        if(data?.shift?.shift1StartBreak1 && data?.shift?.shift1EndBreak1) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift1StartBreak1), "end": dates + " " + dateformat(data?.shift?.shift1EndBreak1), "label": "Break 1 {br} shift 1 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[0] } } })
        }
        if(data?.shift?.shift1StartBreak2 && data?.shift?.shift1EndBreak2) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift1StartBreak2), "end": dates + " " + dateformat(data?.shift?.shift1EndBreak2), "label": "Break 2 {br} shift 1 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[0] } } })
        }
        if(data?.shift?.shift1StartBreak3 && data?.shift?.shift1EndBreak3) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift1StartBreak3), "end": dates + " " + dateformat(data?.shift?.shift1EndBreak3), "label": "Break 3 {br} shift 1 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[0] } } })
        }
        if(data?.shift?.shift1StartBreak4 && data?.shift?.shift1EndBreak4) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift1StartBreak4), "end": dates + " " + dateformat(data?.shift?.shift1EndBreak4), "label": "Break 4 {br} shift 1 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[0] } } })
        }
        if(data?.shift?.shift1StartBreak5 && data?.shift?.shift1EndBreak5) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift1StartBreak5), "end": dates + " " + dateformat(data?.shift?.shift1EndBreak5), "label": "Break 5 {br} shift 1 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[0] } } })
        }
      }

      if(data?.shift?.shift2 == "Y") {
        if(data?.shift?.shift2StartBreak1 && data?.shift?.shift2EndBreak1) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift2StartBreak1), "end": dates + " " + dateformat(data?.shift?.shift2EndBreak1), "label": "Break 1 {br} shift 2 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[1] } } })
        }
        if(data?.shift?.shift2StartBreak2 && data?.shift?.shift2EndBreak2) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift2StartBreak2), "end": dates + " " + dateformat(data?.shift?.shift2EndBreak2), "label": "Break 2 {br} shift 2 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[1] } } })
        }
        if(data?.shift?.shift2StartBreak3 && data?.shift?.shift2EndBreak3) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift2StartBreak3), "end": dates + " " + dateformat(data?.shift?.shift2EndBreak3), "label": "Break 3 {br} shift 2 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[1] } } })
        }
        if(data?.shift?.shift2StartBreak4 && data?.shift?.shift2EndBreak4) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift2StartBreak4), "end": dates + " " + dateformat(data?.shift?.shift2EndBreak4), "label": "Break 4 {br} shift 2 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[1] } } })
        }
        if(data?.shift?.shift2StartBreak5 && data?.shift?.shift2EndBreak5) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift2StartBreak5), "end": dates + " " + dateformat(data?.shift?.shift2EndBreak5), "label": "Break 5 {br} shift 2 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[1] } } })
        }
      }

      if(data?.shift?.shift3 == "Y") {
        if(data?.shift?.shift3StartBreak1 && data?.shift?.shift3EndBreak1) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift3StartBreak1), "end": dates + " " + dateformat(data?.shift?.shift3EndBreak1), "label": "Break 1 {br} shift 3 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[2] } } })
        }
        if(data?.shift?.shift3StartBreak2 && data?.shift?.shift3EndBreak2) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift3StartBreak2), "end": dates + " " + dateformat(data?.shift?.shift3EndBreak2), "label": "Break 2 {br} shift 3 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[2] } } })
        }
        if(data?.shift?.shift3StartBreak3 && data?.shift?.shift3EndBreak3) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift3StartBreak3), "end": dates + " " + dateformat(data?.shift?.shift3EndBreak3), "label": "Break 3 {br} shift 3 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[2] } } })
        }
        if(data?.shift?.shift3StartBreak4 && data?.shift?.shift3EndBreak4) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift3StartBreak4), "end": dates + " " + dateformat(data?.shift?.shift3EndBreak4), "label": "Break 4 {br} shift 3 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[2] } } })
        }
        if(data?.shift?.shift3StartBreak5 && data?.shift?.shift3EndBreak5) {
          breakmarkers.push({ "start": dates + " " + dateformat(data?.shift?.shift3StartBreak5), "end": dates + " " + dateformat(data?.shift?.shift3EndBreak5), "label": "Break 5 {br} shift 3 ", "type": "full", "timeformat": "%d/%m/%Y %-H:%M", "style": { "marker": { "fill": colors[2] } } })
        }
      }
    }

    let fusionTable = fusionDataStore.createDataTable(socDataPoints, schema);

    const chartConfiguration = {
      type: "timeseries",
      width: "100%",
      height: '92%',
      dataFormat: 'json',
      dataSource: {
        chart: {
          showLegend: 0
        },
        'yaxis': [{
          "plot": {
            "value": "SOC",
            "connectnulldata": true,
            "style": {
              "plot.null": { "stroke-dasharray": "none", "stroke": "#6166b7" }
            }
          },
          "title": "SOC %"
        }],
        'xaxis': {
          "plot": "Time",
          "title": "SHIFT TIME",
          "timemarker": breakmarkers,
          "outputtimeformat": { "hour": "%a %H:%M %p", "minute": "%a %H:%M %p" },
          "initialinterval": { "from": dates + " 00:00", "to": dates1 + " 00:00" }
        },
        tooltip: {
          outputtimeformat: { "hour": "%m/%d/%Y %H:%M %p" },
        },
        extensions: {
          standardRangeSelector: {
            enabled: "0"
          },
          customRangeSelector: {
            enabled: "0"
          }
        },
        navigator: {
          enabled: "0"
        },
        data: fusionTable
      }
    }

    setChartConfig(chartConfiguration)
  }

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div className="container-battery-voltage" ref={ref}>
        <div className="chartTitle p-2 text-center d-flex align-items-center">
          <span className='mr-auto ml-auto '>Plug In Compliance Report</span>
        </div>
        <span className="export">
        <DatePicker onChange={onChange} className="calenderStyles1" value={value} />
          {/* {showDateFilter && <span>
            <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
          </span>
          }
          <SelectDays daysFilter={daysFilter} customFilterStatusChange={setShowDateFilter} /> */}
        </span>
        <div className='daily-plugin-chart'>
          <ReactFC {...chartConfig} />
        </div>
      </div>
      <SessionWarning />
    </>
  )
}

export default PlugInComplianceChart;