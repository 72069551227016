import React, { FC, useState, useEffect,useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Input, Space, Button, Select,Modal } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import { format, startOfMonth, subDays } from 'date-fns';

import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import { Utility } from "../../helper/index";
import { resize , hideSidebar} from '../../settings/resize';

import BatteryDetails from '../reusableComponent/batteryDetalis';
import getBatterySummary from '../../api-call/battery-summary';
import SessionWarning from '../reusableComponent/sessionWarning';
import LoadingSpinner from '../spinner/loading-spinner';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const batteryData = { batteryId: "", vehicleId: "", serialNumber: "", mfgdate: "", inServiceDate: "", daysInOperation: 0, batteryType: 0, batteryVoltage: 0, capacity: 0 };
// const startOfCurrentMonth: any = format(startOfMonth(new Date(),), 'dd-MMM-yyyy')
const startOfCurrentMonth: any = "20-Aug-2023"
const today: any = format(new Date(), 'dd-MMM-yyyy');


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    }
        
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const CustomMiniTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-minitabpanel-${index}`}
            aria-labelledby={`simple-minitab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const a11yProps = (index: number) => {
    return {
        id: `simple-minitab-${index}`,
        'aria-controls': `simple-minitabpanel-${index}`,
    };
}

const minia11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const minia11yProps1 = (index: number) => {
    return {
        id: `simple-tab1-${index}`,
        'aria-controls': `simple-tabpanel1-${index}`,
    };
}

const BatteryRXConfiguration: FC<props> = ({ globalData }) => {
    const [value, setValue] = React.useState(0);
    const [minivalue, setMiniValue] = React.useState(0);
    const [minivalue1, setMiniValue1] = React.useState(0);
    const [summaryBattery, setSummaryBattery] = useState<any>([batteryData])
    const [avgVolt, setAvgVolt] = useState<number | string>();
    const [filterData, setFilterData] = useState<any[]>([]);
    const [dateLabel, setDateLabel] = useState<{}[]>([])
    const [dailyDate, setDailyDate] = useState<any[]>([])
    const [totalAhrsIn, setTotalAhrsIn] = useState<any[]>([])
    const [totalAhrsOut, setTotalAhrsOut] = useState<any[]>([])
    const [totalOver, setTotalOver] = useState<any[]>([])
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [warrantydata, setWarrantyData] = useState<any[]>([])
    const [timezones, setTimezones] = useState<any[]>([]);
    const [batterytypes, setBatteryTypes] = useState<any []>([]);
    const [keyOnDetectTypes, setKeyOnDetectTypes] = useState<any []>([]);
    const [keyOnLogicTypes, setKeyOnLogicTypes] = useState<any []>([]);
    const [daysofweek, setDaysOfWeek] = useState<any []>([]);
    const [eqstarthr, setEQStartHr] = useState<number>(0);
    const [eqstartmin, setEQStartMin] = useState<number>(0);
    const [eqendhr, setEQEndHr] = useState<number>(0);
    const [eqendmin, setEQEndMin] = useState<number>(0);
    const [dailycallhr, setDailyCallHr] = useState<number>(0);
    const [dailycallmin, setDailyCallMin] = useState<number>(0);

    const location = useLocation();
    const propsData = location.state;

    const [batteryrx, setBatteryRX] = useState<any>({})
    const [newbatteryrx, setNewBatteryRX] = useState<any>({})
    const [neweqstarthr, setNewEQStartHr] = useState<number>(0);
    const [neweqstartmin, setNewEQStartMin] = useState<number>(0);
    const [neweqendhr, setNewEQEndHr] = useState<number>(0);
    const [neweqendmin, setNewEQEndMin] = useState<number>(0);
    const [newdailycallhr, setNewDailyCallHr] = useState<number>(0);
    const [newdailycallmin, setNewDailyCallMin] = useState<number>(0);
    const [titleMsg,setTitleMsg] = useState<string>("")
    const [isOpen,setIsOpen] = useState<boolean>(false)

    useEffect(() => {    
        let userInfo: any = Utility.getUserInfo();
        var userId = userInfo.userId;
        
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        
        let org = data?.orgId > -1 ? data?.orgId : userId;
        window.scrollTo(0, 0) 
        resize();
        hideSidebar();
        getSummary({ orgId: org, batteryId: propsData?.batterrid ? propsData?.batterrid : "", vehicleId: propsData?.vehicleid ? propsData?.vehicleid : "", serialNumber: propsData?.sno });
        getTimezones();
        getBatteryTypes();
        getKeyOnDetectTypes();
        getKeyOnLogicTypes();
        getDaysOfWeek();
        getDailyUsage({ orgId: org, batteryId: propsData?.batterrid ? propsData?.batterrid : "", vehicleId: propsData?.vehicleid ? propsData?.vehicleid : "", serialNumber: propsData?.sno, startDate: startOfCurrentMonth, endDate: today })
        getSOCTemparature({ orgId: org, batteryId: propsData?.batterrid ? propsData?.batterrid : "", vehicleId: propsData?.vehicleid ? propsData?.vehicleid : "", serialNumber: propsData?.sno, startDate: startOfCurrentMonth, endDate: today })
        getWarrantyPerformance({ orgId: org, batteryId: propsData?.batterrid ? propsData?.batterrid : "", vehicleId: propsData?.vehicleid ? propsData?.vehicleid : "", serialNumber: propsData?.sno })
        getData(propsData?.sno)
        ResetConfiguration();
    }, [])

    useLayoutEffect(()=>{
        let timer:any
        const debouncedHandleResize = ()=>{
         clearTimeout(timer)
         timer=setTimeout(()=>{
          resize()
         },500)
        }
        window.addEventListener("resize",debouncedHandleResize)
        return()=>{
         window.removeEventListener("resize",debouncedHandleResize)       }
     });

    const getBatteryData = () => {
        let userInfo: any = Utility.getUserInfo();
        var userId = userInfo.userId;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let org = data?.orgId > -1 ? data?.orgId : userId;

        getDailyUsage({ orgId: org, batteryId: propsData?.batterrid ? propsData?.batterrid : "", vehicleId: propsData?.vehicleid ? propsData?.vehicleid : "", serialNumber: propsData?.sno, startDate: startOfCurrentMonth, endDate: today })
        getSOCTemparature({ orgId: org, batteryId: propsData?.batterrid ? propsData?.batterrid : "", vehicleId: propsData?.vehicleid ? propsData?.vehicleid : "", serialNumber: propsData?.sno, startDate: startOfCurrentMonth, endDate: today })
        getWarrantyPerformance({ orgId: org, batteryId: propsData?.batterrid ? propsData?.batterrid : "", vehicleId: propsData?.vehicleid ? propsData?.vehicleid : "", serialNumber: propsData?.sno })
    }

    const getRXConfig = () => {
        minia11yProps(0)
        getData(propsData?.sno)
    }

    const getSummary = (payload: any) => {
        getBatterySummary.batterySummary(payload)
            .then((response) => {
                setSummaryBattery(response)
            }).catch((error) => {
                console.log('batterySummary:', error)
            })
    }

    const getTimezones = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        try {
            const response = await service({
                url: constantUrl.api.batteryRXUnits.getTimezones,
                method: "GET",
                headers: { Authorization: 'Bearer ' + token },
            })

            setTimezones(response)
        } catch (error) {
            console.log("locations:", error)
        }
    }

    const getBatteryTypes = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        try {
            const response = await service({
                url: constantUrl.api.batteryRXConfig.batteryTypes,
                method: "GET",
                headers: { Authorization: 'Bearer ' + token },
            })

            setBatteryTypes(response)
        } catch (error) {
            console.log("locations:", error)
        }
    }

    const getKeyOnDetectTypes = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        try {
            const response = await service({
                url: constantUrl.api.batteryRXConfig.getKeyOnDetectTypes,
                method: "GET",
                headers: { Authorization: 'Bearer ' + token },
            })

            setKeyOnDetectTypes(response)
        } catch (error) {
            console.log("locations:", error)
        }
    }

    const getKeyOnLogicTypes = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        try {
            const response = await service({
                url: constantUrl.api.batteryRXConfig.getKeyOnLogicTypes,
                method: "GET",
                headers: { Authorization: 'Bearer ' + token },
            })

            setKeyOnLogicTypes(response)
        } catch (error) {
            console.log("locations:", error)
        }
    }

    const getDaysOfWeek = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        try {
            const response = await service({
                url: constantUrl.api.batteryRXConfig.daysOfWeek,
                method: "GET",
                headers: { Authorization: 'Bearer ' + token },
            })

            setDaysOfWeek(response)
        } catch (error) {
            console.log("locations:", error)
        }
    }

    const ByteToBCD = (ByVal: number) => {
        return (Math.round(ByVal / 10) << 4) + (ByVal % 10)
    }

    const BCDToByte = (Value: any) => {
        let ReturnValue = 0;
        let value1: number;
        let value2: number;

        value1 = Value >> 4;
        value2 = Value & 0xF;

        ReturnValue += ((value1 * 10) + value2)

        return ReturnValue;
    }

    const getDailyUsage = async (payload: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        try {
            const response = await service({
                url: constantUrl.api.dailyUsagePlugin.dailyUsagePlugin,
                method: "POST",
                headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
                data: payload
            })
            dailyUsageDataPoints(response)
        } catch (error) {
            console.error("dailyUsesError:", error)
        }
    }

    const getSOCTemparature = async (payload: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        setIsShowSpinner(true)

        try {
            const response = await service({
                url: constantUrl.api.batteryRXConfig.getSocTemparature,
                method: "POST",
                headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
                data: payload
            })

            if (response.length > 0) {
                socDataPoints(response)
                setIsShowSpinner(false)
            } else {
                setDateLabel([{ label: "" }])
                setFilterData([{ value: "0" }])
                setAvgVolt("")
                setIsShowSpinner(false)
            }
        } catch (error) {
            console.error("socTemparatureError:", error)
            setIsShowSpinner(false)
        }
    }

    const getWarrantyPerformance = async (payload: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        try {
            const response = await service({
                url: constantUrl.api.batteryRXConfig.getWarrenty,
                method: "POST",
                headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
                data: payload
            })

            setWarrantyData(response)
        } catch (error) {
            console.error("dailyUsesError:", error)
        }
    }

    const dailyUsageDataPoints = (data: any) => {
        let dateLable: any = data.map((item: any) => {
            return {
                label: item.dailyDate
            }
        })

        let totalHoursIn: any = data.map((item: any) => {
            return {
                value: item.totalAhrsIn
            }
        })

        let totalAhrsOut: any = data.map((item: any) => {
            return {
                value: item.totalAhrsOut
            }
        })

        let totalOver: any = data.map((item: any) => {
            return {
                value: item.totalOver
            }
        })
        setDailyDate(dateLable)
        setTotalAhrsIn(totalHoursIn)
        setTotalAhrsOut(totalAhrsOut)
        setTotalOver(totalOver)
  
        let totalHoursInMax = Math.max(...totalHoursIn.map((o: any) => o.value))
        let totalHoursOutMax = Math.max(...totalAhrsOut.map((o: any) => o.value))
        let totaloverMAx = Math.max(...totalOver.map((o: any) => o.value))

        let final: any = [totalHoursInMax, totalHoursOutMax, totaloverMAx];
        let maxValue = Math.max(...final.map((o: any) => o));
        maxValue = maxValue + 100;
    }

    const socDataPoints = (data: any[]) => {
        if (!data.length) return
        let labels = data.flatMap((item: any) => { return [{ label: format(new Date(item.startDate), 'dd-MMM-yyyy HH:MM') }, { label: format(new Date(item.endDate), 'dd-MMM-yyyy HH:MM') }] })
        let record = data.flatMap((item: any) => { return [{ value: item.startSoc }, { value: item.endSoc }] })

        let newRecord = []
        for (let i = 0; i < record.length; i++) {
            let data1 = 0
            let data2 = 0
            data1 = Number(record[i]['value'])
            if (record.length > i + 1) { data2 = Number(record[i + 1]['value']) }
            if (data1 !== data2) { newRecord.push({ value: data1 }) }
        }

        let newRecord1 = []
        let prev_vlaue = 0;
        for (let i = 0; i < newRecord.length; i++) {
            let currValue = Number(newRecord[i]['value'])
            if (prev_vlaue > 20 && currValue < 20) {
                newRecord1.push({ value: 20, color: '#e20a15' }, { value: currValue })
            } else {
                newRecord1.push({ value: currValue })
            }
            prev_vlaue = Number(newRecord[i]['value'])
        }

        let filterdata: any = newRecord1.map((obj: any, index: any) => {
            if (Number(obj.value) >= 20) {
                return obj.color ? { ...obj } : { ...obj, color: '#004f9f' }
            } else {
                return obj.color ? { obj } : { ...obj, color: '#e20a15' }
            }
        })

        setFilterData(filterdata)
        setDateLabel(labels)
        setAvgVolt(20)
    }

    const chartConfigs_line = {
        type: 'scrollline2d',
        dataFormat: 'json',
        width: '100%',
        height: '98%',
        dataSource: {
            chart: {
                yaxisname: "SOC %",
                yaxisminvalue: "0",
                yaxismaxvalue: "100",
                xAxisName: "DATE",
                xAxisNameFontColor: "#4f82b6",
                xAxisNameFontBold: "1",
                yAxisNameFontColor: "#4f82b6",
                yAxisNameFontBold: "1",
                lineThickness: "3",
                setadaptiveymin: "1",
                showToolTip: '1',
                drawAnchors: '0',
                labelFontSize: "12",
                labelDisplay: "none",
                chartTopMargin: "70",
                isTrendZone: "1",
                labelStep: "1",
                numvisibleplot: "3",
                plotSpacePercent: 100,
                plotToolText: "<div class='__head'>SOC %</div><div class='__body'>$dataValue</div><div class='__footer'>$label</div>",
                theme: "fusion"
            },
            trendlines: [{
                line: [{
                    startvalue: avgVolt ? avgVolt : 0,
                    color: "#e20a15",
                    valueOnRight: "1",
                    displayvalue: `<b>${avgVolt ? avgVolt : 0}% <br>Low Threshold</b>`,
                    thickness: "3",
                    dashed: "1",
                    dashLen: "4",
                    dashGap: "2",
                    labelFontSize: "3"
                }]
            }],
            "categories": [{
                "category": dateLabel.length ? dateLabel : [{ label: "" }]
            }],
            "dataset": [{
                "data": filterData.length ? filterData : [{ value: "0" }]
            }]
        }
    }

    const chartConfigs2 = {
        type: "scrollmsstackedcolumn2dlinedy",
        width: '100%',
        height: '92%',
        dataFormat: 'json',
        dataSource: {
            chart: {
                "xAxisName": "Date",
                "pYAxisName": "Total Ahrs",
                "sYAxisName": "Over/Under Charge",
                "xAxisNameFontColor": "#4f82b6",
                "pYAxisNameFontColor": "#4f82b6",
                "sYAxisNameFontColor": "#4f82b6",
                xAxisNameFontBold: "1",
                pYAxisNameFontBold: "1",
                sYAxisNameFontBold: "1",
                setadaptivepYmin: "1",
                setadaptivesYmin: "1",
                "formatNumberScale": "0",
                "formatNumber": "0",
                "decimals": "0",
                thickness: "10",
                "animation": "1",
                "numdivlines": "4",
                "legendposition": "top",
                "legendPadding": "50",
                "drawCustomLegendIcon": "2",
                "legendIconSides": "0",
                "paletteColors": "#e8e368,#4f82b6,#e20a15",
                "showvalues": "0",
                showsum: "1",
                plotSpacePercent: 60,

                "theme": "fusion"
            },
            "categories": [{
                "category": dailyDate
            }],
            "dataset": [{
                "dataset": [{
                    "seriesname": "Total Ahrs In",
                    plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
                    "data": totalAhrsIn
                }
                ]
            },
            {
                "dataset": [{
                    "seriesname": "Total Ahrs Out",
                    plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div>",
                    "data": totalAhrsOut
                }
                ]
            },
            {
                "dataset": [{
                    "seriesname": "Over / Under Charge",
                    plotToolText: "<div class='small_font'>$seriesName</div><div class='big_font'>$dataValue</div><div class='date_style'>$label</div>",
                    "data": totalOver
                }
                ]
            }
            ]
        }
    }

    const handleMiniChange = (event: React.SyntheticEvent, newValue: number) => {
        setMiniValue(newValue);
    }

    const handleMiniChange1 = (event: React.SyntheticEvent, newValue: number) => {
        setMiniValue1(newValue);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    async function getData(serialNumber: any) {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)

        try {
            const response = await service({
                url: constantUrl.api.batteryRXConfig.getData + serialNumber,
                method: "GET",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });

            if (response) {
                if (response?.serialNumber){
                    setBatteryRX(response)
                    let data = response ;
                    // data.dateEntered = format(new Date(response?.dateEntered), 'mm-dd-yyyy hh:mm a');
                    data.dateEntered = format(new Date(response?.timesStamp), 'mm-dd-yyyy hh:mm a');

                    let startTimeHour = 0;
                    let startTimeMinute = 0;
                    let endTimeHour = 0;
                    let endTimeMinute = 0;
                    let dailyCallHour = 0;
                    let dailyCallMin = 0;

                    if (data?.eqStartTime) {
                        startTimeHour = data?.eqStartTime & 0xFF;
                        startTimeHour = BCDToByte(startTimeHour);
                        startTimeMinute = (data?.eqStartTime & 0xFF00) >> 8;
                        startTimeMinute = BCDToByte(startTimeMinute);
                    }

                    if (data?.eqEndTime) {
                        endTimeHour = data?.eqEndTime & 0xFF;
                        endTimeHour = BCDToByte(endTimeHour);
                        endTimeMinute = (data?.eqEndTime & 0xFF00) >> 8;
                        endTimeMinute = BCDToByte(endTimeMinute);
                    }

                    if (data?.dailyCallTime) {
                        dailyCallHour = data?.dailyCallTime & 0xFF;
                        dailyCallHour = BCDToByte(dailyCallHour);
                        dailyCallMin = (data?.dailyCallTime & 0xFF00) >> 8;
                        dailyCallMin = BCDToByte(dailyCallMin);
                    }

                    setEQStartHr(startTimeHour);
                    setEQStartMin(startTimeMinute);
                    setEQEndHr(endTimeHour);
                    setEQEndMin(endTimeMinute);
                    setDailyCallHr(dailyCallHour);
                    setDailyCallMin(dailyCallMin);

                } else {
                    //ResetConfiguration1();
                }
            } else {
                ResetConfiguration1(); 
            }

            setIsShowSpinner(false);
        } catch (error) {
            console.error("getBatteryInfo:", error);
            setIsShowSpinner(false);
        }
    }

    async function updateData() {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        let payload = { ...batteryrx };

        let eqstarttime = 0;
        let eqendtime = 0;
        let dailycalltime = 0;

        eqstarttime = ByteToBCD(eqstarthr);
        eqstarttime += ByteToBCD(eqstartmin) * 256;

        eqendtime = ByteToBCD(eqendhr);
        eqendtime += ByteToBCD(eqendmin) * 256;

        dailycalltime = ByteToBCD(dailycallhr);
        dailycalltime += ByteToBCD(dailycallmin) * 256;

        payload.eqStartTime = eqstarttime;
        payload.eqEndTime = eqendtime;
        payload.dailyCallTime = dailycalltime;

        payload.userName = localStorage.userName;

        setIsShowSpinner(true)

        try {
            const response = await service({
                url: constantUrl.api.batteryRXConfig.updateData,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });
            if(response===1){
                setTitleMsg("Rx Configuration updated successfully")
                setIsOpen(true)  
            }else if(response===-1){
                setTitleMsg("Unable to update Rx Configuration")
                setIsOpen(true)    
            }            

            setIsShowSpinner(false);
        } catch (error) {
            console.error("updateBatteryRxConfig:", error);
            setIsShowSpinner(false);
        }
    }

    async function submitData() {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        let payload = { ...newbatteryrx };

        let eqstarttime = 0;
        let eqendtime = 0;
        let dailycalltime = 0;

        eqstarttime = ByteToBCD(neweqstarthr);
        eqstarttime += ByteToBCD(neweqstartmin) * 256;

        eqendtime = ByteToBCD(neweqendhr);
        eqendtime += ByteToBCD(neweqendmin) * 256;

        dailycalltime = ByteToBCD(newdailycallhr);
        dailycalltime += ByteToBCD(newdailycallmin) * 256;

        payload.eqStartTime = eqstarttime;
        payload.eqEndTime = eqendtime;
        payload.dailyCallTime = dailycalltime;
        payload.userName = localStorage.userName;

        setIsShowSpinner(true)

        try {
            const response = await service({
                url: constantUrl.api.batteryRXConfig.updateData,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });
            if(response===1){
              setTitleMsg("New Rx Configuration added successfully")  
              setIsOpen(true)
            } else if(response===-1){
                setTitleMsg("Unable to add new Rx Configuration")  
                setIsOpen(true)
              }
            setIsShowSpinner(false);
        } catch (error) {
            console.error("createBatteryRxConfig:", error);
            setIsShowSpinner(false);
        }
    }

    const ResetConfiguration = () => {
        minia11yProps1(0)
        setNewEQStartHr(12);
        setNewEQStartMin(0);
        setNewEQEndHr(0);
        setNewEQEndMin(0);
        setNewDailyCallHr(1);
        setNewDailyCallMin(5);

        setNewBatteryRX({
            "serialNumber": propsData?.sno,
            "batteryId": propsData?.batterrid,
            "vehicleId": propsData?.vehicleid,
            "batteryType": 0,
            "totalCells": 6,
            "capacity": 100,
            "startCurrentLimit": 40,
            "socLimit": 100,
            "maxAhBetwenEQ": 2500,
            "maxDaysBetweenEQS": 6,
            "eqStartDay": 0,
            "eqEndDay": 0,
            "batteryInternalOhms": 0,
            "targetVoltageLimit": 2048,
            "targetVoltage": 3000,
            "temperatureFoldBack": 0,
            "runCurrentThreshold": 0,
            "keyOnDetection": 1,
            "keyOnLogic": 1,
            "keyOnThreshold": 0,
            "nonPosiChargeThreshold": 3000,
            "nonPosiEqThreshold": 2800,
            "parasiticCurrent": 0,
            "highVoltageLimit": 2750,
            "lowVoltageLimit": 1500,
            "highTempLimit": 75,
            "lowTempLimit": 20,
            "lowSocLimit": 20,
            "daySinceCompEqLimit": 14,
            "socSlope": 30,
            "socOffSet": 0,
            "tempCompensation": 0,
            "finishCurrent": 3.2,
            "coulombicEfficiency": 98,
            "weeklyEqMinDuration": 50,
            "dailyEqMinDuration": 0,
            "packVoltageImbalLimit": 250,
            "timezoneOffset": -8,
            "sosChargeAmpLimit": 30,
            "eqCurrRangeLimit": 20,
            "weeklyEqMaxDuration": 350,
            "numCellsFromNegative": 25,
            "maxDaysNoCharge": 10,
            "daylightSavingsTime": 1,
            "batteryConstruction": 0,
            "batteryRxSimNumber": "",
            "softwareVersion": propsData?.appVer,
            "maxChargeCurrentLimit": 0
        })

    }

    const ResetConfiguration1 = () => {
        minia11yProps(0)
        setEQStartHr(12);
        setEQStartMin(0);
        setEQEndHr(0);
        setEQEndMin(0);
        setDailyCallHr(1);
        setDailyCallMin(5);

        setBatteryRX({
            "serialNumber": propsData?.sno,
            "batteryId": propsData?.batterrid,
            "vehicleId": propsData?.vehicleid,
            "batteryType": 0,
            "totalCells": 6,
            "capacity": 100,
            "startCurrentLimit": 40,
            "socLimit": 100,
            "maxAhBetwenEQ": 2500,
            "maxDaysBetweenEQS": 6,
            "eqStartDay": 0,
            "eqEndDay": 0,
            "batteryInternalOhms": 0,
            "targetVoltageLimit": 2048,
            "targetVoltage": 3000,
            "temperatureFoldBack": 0,
            "runCurrentThreshold": 0,
            "keyOnDetection": 1,
            "keyOnLogic": 1,
            "keyOnThreshold": 0,
            "nonPosiChargeThreshold": 3000,
            "nonPosiEqThreshold": 2800,
            "parasiticCurrent": 0,
            "highVoltageLimit": 2750,
            "lowVoltageLimit": 1500,
            "highTempLimit": 75,
            "lowTempLimit": 20,
            "lowSocLimit": 20,
            "daySinceCompEqLimit": 14,
            "socSlope": 30,
            "socOffSet": 0,
            "tempCompensation": 0,
            "finishCurrent": 3.2,
            "coulombicEfficiency": 98,
            "weeklyEqMinDuration": 50,
            "dailyEqMinDuration": 0,
            "packVoltageImbalLimit": 250,
            "timezoneOffset": -8,
            "sosChargeAmpLimit": 30,
            "eqCurrRangeLimit": 20,
            "weeklyEqMaxDuration": 350,
            "numCellsFromNegative": 25,
            "maxDaysNoCharge": 10,
            "daylightSavingsTime": 1,
            "batteryConstruction": 0,
            "batteryRxSimNumber": "",
            "softwareVersion": propsData?.appVer,
            "maxChargeCurrentLimit": 0
        })
    }

    //handle on change total cells
    const onChangeProperty = (property: string, defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setBatteryRX({ ...batteryrx, [property]: parseInt(value) })
            } else if(parseInt(value) > maxValue) {
                setBatteryRX({ ...batteryrx, [property]: maxValue })
            } else if(parseInt(value) < minValue) {
                setBatteryRX({ ...batteryrx, [property]: minValue })
            }
        } else {
            setBatteryRX({ ...batteryrx, [property]: defaultValue })
        }
    }

    const onNewChangeProperty = (property: string, defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setNewBatteryRX({ ...newbatteryrx, [property]: parseInt(value) })
            } else if(parseInt(value) > maxValue) {
                setNewBatteryRX({ ...newbatteryrx, [property]: maxValue })
            } else if(parseInt(value) < minValue) {
                setNewBatteryRX({ ...newbatteryrx, [property]: minValue })
            }
        } else {
            setNewBatteryRX({ ...newbatteryrx, [property]: defaultValue })
        }
    }

    const onChangeProperty1 = (property: string, defaultValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > maxValue) {
                setBatteryRX({ ...batteryrx, [property]: maxValue })
            } else {
                setBatteryRX({ ...batteryrx, [property]: parseInt(value) })
            }
        } else {
            setBatteryRX({ ...batteryrx, [property]: defaultValue })
        }
    }

    const onNewChangeProperty1 = (property: string, defaultValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > maxValue) {
                setNewBatteryRX({ ...newbatteryrx, [property]: maxValue })
            } else {
                setNewBatteryRX({ ...newbatteryrx, [property]: parseInt(value) })
            }
        } else {
            setNewBatteryRX({ ...newbatteryrx, [property]: defaultValue })
        }
    }

    const onChangeProperty2 = (property: string, defaultValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            setBatteryRX({ ...batteryrx, [property]: parseInt(value) })
        } else {
            setBatteryRX({ ...batteryrx, [property]: defaultValue })
        }
    }

    const onNewChangeProperty2 = (property: string, defaultValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            setNewBatteryRX({ ...newbatteryrx, [property]: parseInt(value) })
        } else {
            setNewBatteryRX({ ...newbatteryrx, [property]: defaultValue })
        }
    }

    const onChangeEQStartHr = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setEQStartHr(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setEQStartHr(maxValue)
            } else if(parseInt(value) < minValue) {
                setEQStartHr(minValue)
            }
        } else {
            setEQStartHr(defaultValue)
        }
    }

    const onNewChangeEQStartHr = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setNewEQStartHr(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setNewEQStartHr(maxValue)
            } else if(parseInt(value) < minValue) {
                setNewEQStartHr(minValue)
            }
        } else {
            setNewEQStartHr(defaultValue)
        }
    }

    const onChangeEQStartMin = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setEQStartMin(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setEQStartMin(maxValue)
            } else if(parseInt(value) < minValue) {
                setEQStartMin(minValue)
            }
        } else {
            setEQStartMin(defaultValue)
        }
    }

    const onNewChangeEQStartMin = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setNewEQStartMin(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setNewEQStartMin(maxValue)
            } else if(parseInt(value) < minValue) {
                setNewEQStartMin(minValue)
            }
        } else {
            setNewEQStartMin(defaultValue)
        }
    }

    const onChangeEQEndHr = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setEQEndHr(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setEQEndHr(maxValue)
            } else if(parseInt(value) < minValue) {
                setEQEndHr(minValue)
            }
        } else {
            setEQEndHr(defaultValue)
        }
    }

    const onNewChangeEQEndHr = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setNewEQEndHr(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setNewEQEndHr(maxValue)
            } else if(parseInt(value) < minValue) {
                setNewEQEndHr(minValue)
            }
        } else {
            setNewEQEndHr(defaultValue)
        }
    }

    const onChangeEQEndMin = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setEQEndMin(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setEQEndMin(maxValue)
            } else if(parseInt(value) < minValue) {
                setEQEndMin(minValue)
            }
        } else {
            setEQEndMin(defaultValue)
        }
    }

    const onNewChangeEQEndMin = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setNewEQEndMin(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setNewEQEndMin(maxValue)
            } else if(parseInt(value) < minValue) {
                setNewEQEndMin(minValue)
            }
        } else {
            setNewEQEndMin(defaultValue)
        }
    }

    const onChangeDailyCallHr = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setDailyCallHr(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setDailyCallHr(maxValue)
            } else if(parseInt(value) < minValue) {
                setDailyCallHr(minValue)
            }
        } else {
            setDailyCallHr(defaultValue)
        }
    }

    const onNewChangeDailyCallHr = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setNewDailyCallHr(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setNewDailyCallHr(maxValue)
            } else if(parseInt(value) < minValue) {
                setNewDailyCallHr(minValue)
            }
        } else {
            setNewDailyCallHr(defaultValue)
        }
    }

    const onChangeDailyCallMin = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setDailyCallMin(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setDailyCallMin(maxValue)
            } else if(parseInt(value) < minValue) {
                setDailyCallMin(minValue)
            }
        } else {
            setDailyCallMin(defaultValue)
        }
    }

    const onNewChangeDailyCallMin = (defaultValue: number, minValue: number, maxValue: number, value: any ) => {
        if(!isNaN(parseInt(value))) {
            if(parseInt(value) > minValue -1 && parseInt(value) < maxValue + 1) {
                setNewDailyCallMin(parseInt(value))
            } else if(parseInt(value) > maxValue) {
                setNewDailyCallMin(maxValue)
            } else if(parseInt(value) < minValue) {
                setNewDailyCallMin(minValue)
            }
        } else {
            setNewDailyCallMin(defaultValue)
        }
    }

    const toggleModal = ()=>{
        setIsOpen(false)
        window.location.reload();
    };

    return <>
        <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
        {/* <div className="container-scroller battey-config"> */}
        <div className="container-scroller">
            <div className="container-fluid box-relative">
                <Box sx={{ width: '100%'}}>
                   <div className='row'>
                    <div className='col-12 px-0'>
                    <Box>
                        <Tabs  variant="scrollable"
                            scrollButtons={false} 
                            className="box2" 
                            value={value}
                            onChange={handleChange} 
                            aria-label="basic tabs example"
                            >
                            <Tab label="Battery Performance" {...a11yProps(0)} onClick={getBatteryData} />
                            <Tab label="Battery RX Configuration" {...a11yProps(1)} onClick={getRXConfig} />
                            <Tab label="New Configuration" {...a11yProps(2)} onClick={ResetConfiguration} />
                        </Tabs>
                    </Box>
                    </div>
                    </div> 
                    <CustomTabPanel value={value} index={0}>
                        <div>
                            <div className='row' style={{ paddingTop: "15px" }}>
                                <div className='col-12 px-0'>
                                <BatteryDetails batteryData={summaryBattery[0]} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-6 col-lg-6 col-xl-5 mt-4 colBackGroundColor bgWhite mt-2 pt-2">
                                    <div className="container-battery-voltage">
                                        <div className="chartTitle p-2 text-center d-flex align-items-center">
                                            <span className='mr-auto ml-auto '>Daily Usage Summary</span>
                                        </div>
                                        <div className='daily-plugin-chart'>
                                            <ReactFC {...chartConfigs2} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-xl-5 mt-4 colBackGroundColor bgWhite mt-2 pt-2">
                                    <div className="container-battery-voltage">
                                        <div className="chartTitle p-2 text-center d-flex align-items-center">
                                            <span className='mr-auto ml-auto '>State of Charge & Temperature</span>
                                        </div>
                                        <div className='daily-plugin-chart'>
                                            <ReactFC {...chartConfigs_line} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-2 mt-4 colBackGroundColor bgWhite pt-2">
                                    <div className="container-battery-voltage">
                                        <div className="chartTitle p-2 text-center d-flex align-items-center">
                                            <span className='mr-auto ml-auto '>WARRANTY</span>
                                        </div>
                                        <div className='mt-2 mb-2'>
                                            <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                                <div>Service Life</div>
                                                <div>{warrantydata[0]?.serviceLife ?? 0}</div>
                                            </div>
                                            <div className="progress-main-red">
                                                <div className="progress-container-red height radius">
                                                    <div id="progress1" className="progress1 radius"></div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                                <div>Total Ah Throughput</div>
                                                <div>{warrantydata[0]?.serviceLife ?? 0} Ah</div>
                                            </div>
                                            <div className="progress-main-green">
                                                <div className="progress-container-green height radius  ">
                                                    <div id="progress2" className="progress2 height"></div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                                <div>Max Daily Usage Ah</div>
                                                <div>{warrantydata[0]?.maxDailyUsageAh ?? 0} Ah</div>
                                            </div>
                                            <div className="progress-main-green">
                                                <div className="progress-container-green height radius  ">
                                                    <div id="progress2" className="progress2 height"></div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                                <div>Max Weekly Usage Ah</div>
                                                <div>{warrantydata[0]?.maxWeeklyUsageAh ?? 0} Ah</div>
                                            </div>
                                            <div className="progress-main-green">
                                                <div className="progress-container-green height radius  ">
                                                    <div id="progress2" className="progress2 height"></div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                                <div>Complete EQs</div>
                                                <div>{warrantydata[0]?.completedEqs ?? 0} Events</div>
                                            </div>
                                            <div className="progress-main-green">
                                                <div className="progress-container-green height radius  ">
                                                    <div id="progress2" className="progress2 height"></div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                                <div>Total EQ Hours</div>
                                                <div>{warrantydata[0]?.totalEqHours ?? 0} Hours</div>
                                            </div>
                                            <div className="progress-main-green">
                                                <div className="progress-container-green height radius  ">
                                                    <div id="progress2" className="progress2 height"></div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                                <div>Low Water Days</div>
                                                <div>{warrantydata[0]?.lowWaterDays ?? 0} Events</div>
                                            </div>
                                            <div className="progress-main-green">
                                                <div className="progress-container-green height radius  ">
                                                    <div id="progress2" className="progress2 height"></div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                                <div>Max Battery Temp</div>
                                                <div>{warrantydata[0]?.maxBatteryTemp ?? 0}°</div>
                                            </div>
                                            <div className="progress-main-green">
                                                <div className="progress-container-green height radius  ">
                                                    <div id="progress2" className="progress2 height"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-battery-voltage">
                                        <div className="chartTitle p-2 text-center d-flex align-items-center">
                                            <span className='mr-auto ml-auto '>PERFORMANCE</span>
                                        </div>
                                        <div className='mt-2 mb-2'>
                                            <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                                <div>Equivalent Battery Usage (EBU)</div>
                                                <div>{warrantydata[0]?.ebu ?? 0} EBU</div>
                                            </div>
                                            <div className="progress-main-red">
                                                <div className="progress-container-red height radius">
                                                    <div id="progress1" className="progress1 radius"></div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                                <div>Completed EQs</div>
                                                <div>{warrantydata[0]?.performanceCompletedEqs ?? 0} Events</div>
                                            </div>
                                            <div className="progress-main-green">
                                                <div className="progress-container-green height radius  ">
                                                    <div id="progress2" className="progress2 height"></div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                                                <div>EQ Hours</div>
                                                <div>{warrantydata[0]?.performanceEqHours ?? 0} Hrs</div>
                                            </div>
                                            <div className="progress-main-green">
                                                <div className="progress-container-green height radius  ">
                                                    <div id="progress2" className="progress2 height"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <div className='row' style={{ paddingTop: "15px" }}>
                                <div className='col-12 px-0'>
                                <BatteryDetails
                                    batteryData={summaryBattery[0]}
                                />
                            </div>
                            <div className='col-md-12 col-lg-12 col-xl-12 mt-4 colBackGroundColor bgWhite'>
                                <Box sx={{ width: '100%' }}>
                                    <Box>
                                        <Tabs value={minivalue} onChange={handleMiniChange} aria-label="basic tabs example">
                                            <Tab label="Parameters" {...minia11yProps(0)} />
                                            <Tab label="Alert Settings" {...minia11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <CustomMiniTabPanel value={minivalue} index={0}>
                                        {/* <div className="container-fluid"> */}
                                            <div className="row">
                                                <div className='col-md-12 col-lg-6 mt-3'>
                                                    <div className="grid-body">
                                                        <div className='font-color fw-600'>Battery Rx Serial Number</div>
                                                        <Input className='input_Size' style={{margin:9}} size="small" readOnly value={batteryrx?.partNbr} />
                                                        <div  className='font-color fw-600'>Battery Rx SIM Number</div>
                                                        <Input className='input_Size' style={{margin:9}} size="small" readOnly value={batteryrx?.serialNumber} />
                                                        <div className='font-color fw-600'>Software Version</div>
                                                        <Input className='input_Size' style={{margin:9}} size="small" readOnly value={batteryrx?.softwareVersion} />
                                                        <div className='font-color fw-600'>Timestamp</div>
                                                        <Input className='input_Size' style={{margin:9}} size="small" readOnly value={batteryrx?.dateEntered} />
                                                        <div className='font-color fw-600'>Battery ID</div>
                                                        <Input onChange={(event) => setBatteryRX({ ...batteryrx, batteryId: event.target.value })} size="small" value={batteryrx?.batteryId} />
                                                        <div className='font-color fw-600'>Vehicle ID</div>
                                                        <Input  onChange={(event) => setBatteryRX({ ...batteryrx, vehicleId: event.target.value })} size="small" value={batteryrx?.vehicleId} />
                                                        <div className='font-color fw-600 grid-item'>Battery Type</div>
                                                            <Select
                                                                showSearch
                                                                size={'middle'}
                                                                onSelect={(valk) => { setBatteryRX({ ...batteryrx, batteryType: valk }) }}
                                                                placeholder="Select Battery Type"
                                                                value={batteryrx?.batteryType}
                                                                className='grid-item'
                                                                dropdownStyle={{ zIndex: 900 }}
                                                                
                                                            >
                                                                {batterytypes && batterytypes.map((item: any, index: any) => {
                                                                    return <Select.Option value={item?.batteryTypeId} key={index}>{item?.typeDescription}</Select.Option>
                                                                })}
                                                            </Select>
                                                        <div className='font-color grid-item fw-600'>Key on Logic</div>
                                                            <Select
                                                                showSearch
                                                                size={'middle'}
                                                                onSelect={(valk) => { setBatteryRX({ ...batteryrx, keyOnLogic: valk }) }}
                                                                placeholder="Select Key On Logic"
                                                                value={batteryrx?.keyOnLogic}
                                                                className='grid-item'
                                                                dropdownStyle={{ zIndex: 900 }}
                                                            >
                                                                {keyOnLogicTypes && keyOnLogicTypes.map((item: any, index: any) => {
                                                                    return <Select.Option value={item?.logicTypeId} key={index}>{item?.logicDescription}</Select.Option>
                                                                })}
                                                            </Select>
                                                        <div className='font-color fw-600 grid-item'>Key on Detect</div>
                                                            <Select
                                                                showSearch
                                                                size={'middle'}
                                                                onSelect={(valk) => { setBatteryRX({ ...batteryrx, keyOnDetection: valk }) }}
                                                                placeholder="Select Key On Detect"
                                                                value={batteryrx?.keyOnDetection}
                                                                className='grid-item'
                                                                dropdownStyle={{ zIndex: 900 }}
                                                            >
                                                                {keyOnDetectTypes && keyOnDetectTypes.map((item: any, index: any) => {
                                                                    return <Select.Option value={item?.detectionTypeId} key={index}>{item?.detectionDescription}</Select.Option>
                                                                })}
                                                            </Select>
                                                        <div className='font-color grid-item fw-600'>GMT Timezone Offset</div>
                                                            <Select
                                                                showSearch
                                                                size={'middle'}
                                                                onSelect={(valk) => { setBatteryRX({ ...batteryrx, timezoneOffset: valk }) }}
                                                                placeholder="Select Device Timezone"
                                                                value={batteryrx?.timezoneOffset}
                                                                className='grid-item'
                                                                dropdownStyle={{ zIndex: 900 }}
                                                            >
                                                                {timezones && timezones.map((item: any, index: any) => {
                                                                    return <Select.Option  value={item?.offset} key={index}>{"(" + item?.timeZone + ") " + item?.mstimeZoneID}</Select.Option>
                                                                })}
                                                            </Select>
                                                        <div className='font-color fw-600'>EQ Start Day/Time</div>
                                                        <Space direction="horizontal">
                                                            <Select
                                                                showSearch
                                                                size={'middle'}
                                                                onSelect={(valk) => { setBatteryRX({ ...batteryrx, eqStartDay: valk }) }}
                                                                placeholder="Select Day"
                                                                value={batteryrx?.eqStartDay}
                                                                dropdownStyle={{ zIndex: 900 }}
                                                            >
                                                                {daysofweek && daysofweek.map((item: any, index: any) => {
                                                                    return <Select.Option value={item?.dayId} key={'EQ Start Day/Times'+index}>{item?.dayDescription}</Select.Option>
                                                                })}
                                                            </Select>
                                                            <Input placeholder='Hr' value={eqstarthr} style={{ width: 50 }} onChange={(event) => onChangeEQStartHr(0, 0, 23, event.target.value)} />
                                                            <Input placeholder='Min' value={eqstartmin} style={{ width: 50 }} onChange={(event) => onChangeEQStartMin(0, 0, 59, event.target.value)} />
                                                        </Space>
                                                        <div className='font-color  fw-600'>EQ End Day/Time</div>
                                                        <Space direction="horizontal">
                                                        <Select
                                                                showSearch
                                                                size={'middle'}
                                                                style={{ width: 90 }}
                                                                onSelect={(valk) => { setBatteryRX({ ...batteryrx, eqEndDay: valk }) }}
                                                                placeholder="Select Day"
                                                                value={batteryrx?.eqEndDay}
                                                                dropdownStyle={{ zIndex: 900 }}
                                                            >
                                                                {daysofweek && daysofweek.map((item: any, index: any) => {
                                                                    return <Select.Option value={item?.dayId}>{item?.dayDescription}</Select.Option>
                                                                })}
                                                            </Select>
                                                            <Input placeholder='Hr' value={eqendhr} style={{ width: 50 }} onChange={(event) => onChangeEQEndHr(0, 0, 23, event.target.value)} />
                                                            <Input placeholder='Min' value={eqendmin} style={{ width: 50 }} onChange={(event) => onChangeEQEndMin(0, 0, 59, event.target.value)} />
                                                        </Space>
                                                        <div className='font-color fw-600'>SOC Limit</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("socLimit", 1, 1, 100, (batteryrx?.socLimit ?? 0) - 1 )}></Button>
                                                            <Input suffix="%" style={{ width: 62 }} size="small" value={batteryrx?.socLimit} onChange={(event) => onChangeProperty("socLimit", 1, 1, 100, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("socLimit", 1, 1, 100, (batteryrx?.socLimit ?? 0) + 1 )}></Button>
                                                        </Space>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 col-lg-6 mt-3'>
                                                    <div className="grid-body">
                                                        <div className='font-color fw-600'>Battery Total Cells</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("totalCells", 1, 1, 99, (batteryrx?.totalCells ?? 0) - 1 )}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={batteryrx?.totalCells} onChange={(event) => onChangeProperty("totalCells", 1, 1, 99, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("totalCells", 1, 1, 99, (batteryrx?.totalCells ?? 0) + 1 )}></Button>
                                                        </Space>
                                                        <div className='font-color fw-600'>Max Das Btw Any EQ</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("maxDaysBetweenEQS", 1, 1, 30, (batteryrx?.maxDaysBetweenEQS ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} value={batteryrx?.maxDaysBetweenEQS} onChange={(event) => onChangeProperty("maxDaysBetweenEQS", 1, 1, 30, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("maxDaysBetweenEQS", 1, 1, 30, (batteryrx?.maxDaysBetweenEQS ?? 0) + 1)}></Button> Days
                                                        </Space>
                                                        <div className='font-color fw-600'>Run Time Current</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("runCurrentThreshold", 1, 1, 10000, (batteryrx?.runCurrentThreshold ?? 0) - 5)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onChangeProperty("runCurrentThreshold", 1, 1, 10000, event.target.value)} size="small" value={batteryrx?.runCurrentThreshold} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("runCurrentThreshold", 1, 1, 10000, (batteryrx?.runCurrentThreshold ?? 0) + 5)}></Button> mA
                                                        </Space>
                                                        <div className='font-color fw-600'>Target Limit (mV/Cell)</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty1("targetVoltageLimit", 1, 5000, (batteryrx?.targetVoltageLimit ?? 0) - 1)}></Button>
                                                            <Input size="small" style={{ width: 62 }} value={batteryrx?.targetVoltageLimit} onChange={(event) => onChangeProperty1("targetVoltageLimit", 1, 5000, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty1("targetVoltageLimit", 1, 5000, (batteryrx?.targetVoltageLimit ?? 0) + 1)}></Button>
                                                        </Space>
                                                        <div className='font-color fw-600'>Temp Foldback</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty1("temperatureFoldBack", 0, 3, (batteryrx?.temperatureFoldBack ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onChangeProperty1("temperatureFoldBack", 0, 3, event.target.value)} size="small" value={batteryrx?.temperatureFoldBack} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty1("temperatureFoldBack", 0, 3, (batteryrx?.temperatureFoldBack ?? 0) + 1)}></Button> A/°C/100AHr
                                                        </Space>
                                                        <div className='font-color fw-600'>Parasitic Current</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("parasiticCurrent", 0, 0, 10000, (batteryrx?.parasiticCurrent ?? 0) - 5)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onChangeProperty("parasiticCurrent", 0, 0, 10000, event.target.value)} size="small" value={batteryrx?.parasiticCurrent} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("parasiticCurrent", 0, 0, 10000, (batteryrx?.parasiticCurrent ?? 0) + 5)}></Button> mA
                                                        </Space>
                                                        <div className='font-color fw-600'>Start Current Limit</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("startCurrentLimit", 1, 1, 200, (batteryrx?.startCurrentLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={batteryrx?.startCurrentLimit} onChange={(event) => onChangeProperty("startCurrentLimit", 1, 1, 200, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("startCurrentLimit", 1, 1, 200, (batteryrx?.startCurrentLimit ?? 0) + 1)}></Button> A/100AHr
                                                        </Space>
                                                        <div className='font-color fw-600'>Internal Resistance</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("batteryInternalOhms", 0, 0, 200, (batteryrx?.batteryInternalOhms ?? 0) - 5)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onChangeProperty("batteryInternalOhms", 0, 0, 200, event.target.value)} size="small" value={batteryrx?.batteryInternalOhms} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("batteryInternalOhms", 0, 0, 200, (batteryrx?.batteryInternalOhms ?? 0) + 5)}></Button> mOhms
                                                        </Space>
                                                        <div className='font-color fw-600'>Max AHr Btw EQ</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("maxAhBetweenEQ", 1, 1, 10000, (batteryrx?.maxAhBetweenEQ ?? 0) - 5)}></Button>
                                                            {/* <Input style={{ width: 62 }} size="small" value={batteryrx?.maxAhBetwenEQ} onChange={(event) => onChangeProperty("maxAhBetwenEQ", 1, 1, 10000, event.target.value)} /> */}
                                                            <Input style={{ width: 62 }} size="small" value={batteryrx?.maxAhBetweenEQ} onChange={(event) => onChangeProperty("maxAhBetweenEQ", 1, 1, 10000, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("maxAhBetweenEQ", 1, 1, 10000, (batteryrx?.maxAhBetweenEQ ?? 0) + 5)}></Button> AHr
                                                        </Space>
                                                        <div className='font-color fw-600'>Key On Current</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => setBatteryRX({ ...batteryrx, keyOnThreshold: (batteryrx?.keyOnThreshold ?? 0) - 1 })}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => {
                                                                if(!isNaN(parseInt(event.target.value))) {
                                                                    setBatteryRX({ ...batteryrx, keyOnThreshold: parseInt(event.target.value) })
                                                                } else {
                                                                    setBatteryRX({ ...batteryrx, keyOnThreshold: 0 })
                                                                }
                                                            }} size="small" value={batteryrx?.keyOnThreshold} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => setBatteryRX({ ...batteryrx, keyOnThreshold: (batteryrx?.keyOnThreshold ?? 0) + 1 })}></Button> mA
                                                        </Space>
                                                        <div className='font-color fw-600'>Battery Capacity</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("capacity", 0, 0, 9999, (batteryrx?.capacity ?? 0) - 5)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={batteryrx?.capacity} onChange={(event) => onChangeProperty("capacity", 0, 0, 9999, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("capacity", 0, 0, 9999, (batteryrx?.capacity ?? 0) + 5)}></Button> AHr
                                                        </Space>
                                                        <div className='font-color fw-600'>Daily Call Time(24 hr)</div>
                                                        <Space style={{ columnGap: '2px' }}  direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeDailyCallHr(0, 0, 23, (dailycallhr ?? 0) - 1)} ></Button>
                                                            <Input value={dailycallhr} style={{ width: 50 }} onChange={(event) => onChangeDailyCallHr(0, 0, 23, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeDailyCallHr(0, 0, 23, (dailycallhr ?? 0) + 1)}></Button>
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeDailyCallMin(0, 0, 59, (dailycallmin ?? 0) - 1)}></Button>
                                                            <Input value={dailycallmin} style={{ width: 50 }} onChange={(event) => onChangeDailyCallMin(0, 0, 59, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined /> } onClick={() => onChangeDailyCallMin(0, 0, 59, (dailycallmin ?? 0) + 1)}></Button>
                                                        </Space>
                                                    </div>
                                                </div>
                                            </div>
                                        {/* </div> */}
                                    </CustomMiniTabPanel>
                                    <CustomMiniTabPanel value={minivalue} index={1}>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-md-12 col-lg-6 mt-3'>
                                                    <div className="grid-body">
                                                        <div className='font-color fw-600'>Low SOC Limit</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => setBatteryRX({ ...batteryrx, lowSocLimit: (batteryrx?.lowSocLimit ?? 0) - 1 })}></Button>
                                                            <Input suffix="%" style={{ width: 62 }} onChange={(event) => {
                                                                if(!isNaN(parseInt(event.target.value))) {
                                                                    setBatteryRX({ ...batteryrx, lowSocLimit: parseInt(event.target.value) })
                                                                } else {
                                                                    setBatteryRX({ ...batteryrx, lowSocLimit: 0 })
                                                                }
                                                            }} size="small" value={batteryrx?.lowSocLimit} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => setBatteryRX({ ...batteryrx, lowSocLimit: (batteryrx?.lowSocLimit ?? 0) + 1 })}></Button>
                                                        </Space>
                                                        <div className='font-color fw-600'>H/Lo Limit (mV/Cell)</div>
                                                        <Space style={{ columnGap: '2px' }} direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("highVoltageLimit", 1000, 1000, 5000, (batteryrx?.highVoltageLimit ?? 0) - 50)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onChangeProperty("highVoltageLimit", 1000, 1000, 5000, event.target.value) } size="small" value={batteryrx?.highVoltageLimit} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("highVoltageLimit", 1000, 1000, 5000, (batteryrx?.highVoltageLimit ?? 0) + 50)}></Button>
                                                            <span style={{ fontSize: "24px" }}> / </span>
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("lowVoltageLimit", 200, 200, 5000, (batteryrx?.lowVoltageLimit ?? 0) - 50)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onChangeProperty("lowVoltageLimit", 200, 200, 5000, event.target.value) } size="small" value={batteryrx?.lowVoltageLimit} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("lowVoltageLimit", 200, 200, 5000, (batteryrx?.lowVoltageLimit ?? 0) + 50)}></Button>
                                                        </Space>
                                                        <div className='font-color fw-600'>Pack Voltage Imbal Limit</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("packVoltageImbalLimit", 1, 1, 1000, (batteryrx?.packVoltageImbalLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onChangeProperty("packVoltageImbalLimit", 1, 1, 1000, event.target.value)} size="small" value={batteryrx?.packVoltageImbalLimit} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("packVoltageImbalLimit", 1, 1, 1000, (batteryrx?.packVoltageImbalLimit ?? 0) + 1)}></Button> mV/Cell
                                                        </Space>
                                                        <div className='font-color fw-600'>H/Low °C Limit</div>
                                                        <Space style={{ columnGap: '2px' }} direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("highTempLimit", 0, 0, 300, (batteryrx?.highTempLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={batteryrx?.highTempLimit} onChange={(event) => onChangeProperty("highTempLimit", 0, 0, 300, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("highTempLimit", 0, 0, 300, (batteryrx?.highTempLimit ?? 0) + 1)}></Button>
                                                            <span style={{ fontSize: "24px" }}> / </span>
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("lowTempLimit", 0, 0, 300, (batteryrx?.lowTempLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={batteryrx?.lowTempLimit} onChange={(event) => onChangeProperty("lowTempLimit", 0, 0, 300, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("lowTempLimit", 0, 0, 300, (batteryrx?.lowTempLimit ?? 0) + 1)}></Button>
                                                        </Space>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 col-lg-6 mt-3'>
                                                    <div className="grid-body">
                                                        <div className='font-color fw-600'>Max Charge Current Limit</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("maxChargeCurrentLimit", 5, 5, 65535, (batteryrx?.maxChargeCurrentLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={batteryrx?.maxChargeCurrentLimit} onChange={(event) => onChangeProperty("maxChargeCurrentLimit", 5, 5, 65535, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("maxChargeCurrentLimit", 5, 5, 65535, (batteryrx?.maxChargeCurrentLimit ?? 0) + 1)}></Button> Amp/AHr
                                                        </Space>
                                                        <div className='font-color fw-600'>Max Days Since Cmplt EQ</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty2("daySinceCompEqLimit", 1, (batteryrx?.daySinceCompEqLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={batteryrx?.daySinceCompEqLimit} onChange={(event) => onChangeProperty2("daySinceCompEqLimit", 1, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty2("daySinceCompEqLimit", 1, (batteryrx?.daySinceCompEqLimit ?? 0) + 1)}></Button> Days
                                                        </Space>
                                                        <div className='font-color fw-600'>EQ Current Range</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("eqCurrRangeLimit", 1, 1, 50, (batteryrx?.eqCurrRangeLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={batteryrx?.eqCurrRangeLimit} onChange={(event) => onChangeProperty("eqCurrRangeLimit", 1, 1, 50, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("eqCurrRangeLimit", 1, 1, 50, (batteryrx?.eqCurrRangeLimit ?? 0) + 1)}></Button> +/-
                                                        </Space>
                                                        <div className='font-color fw-600'>Max Days Btw Charge</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onChangeProperty("maxDaysNoCharge", 1, 1, 14, (batteryrx?.maxDaysNoCharge ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={batteryrx?.maxDaysNoCharge} onChange={(event) => onChangeProperty("maxDaysNoCharge", 1, 1, 14, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onChangeProperty("maxDaysNoCharge", 1, 1, 14, (batteryrx?.maxDaysNoCharge ?? 0) + 1)}></Button> Days
                                                        </Space>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CustomMiniTabPanel>
                                </Box>
                                <div className="d-flex justify-content-end p-3">
                                    <Button
                                        onClick={() => { updateData() }}
                                        style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white", }}>
                                        UPDATE
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <div className='row' style={{ paddingTop: "15px" }}>
                                <div className='col-12 px-0'>
                                <BatteryDetails batteryData={summaryBattery[0]} />
                               </div>
                            <div className='col-md-12 col-lg-12 col-xl-12 mt-4 colBackGroundColor bgWhite'>
                                <Box sx={{ width: '100%' }}>
                                    <Box>
                                        <Tabs value={minivalue1} onChange={handleMiniChange1} aria-label="basic tabs example">
                                            <Tab label="Parameters" {...minia11yProps1(0)} />
                                            <Tab label="Alert Settings" {...minia11yProps1(1)} />
                                        </Tabs>
                                    </Box>
                                    <CustomMiniTabPanel value={minivalue1} index={0}>
                                            <div className="row">
                                                <div className='col-md-12 col-lg-6 mt-3'>
                                                    <div className="grid-body">
                                                        <div className='font-color fw-600'>Battery Rx Serial Number</div>
                                                        <Input className='input_Size' style={{margin: 9}} size="small" readOnly value={newbatteryrx?.serialNumber} />
                                                        <div className='font-color fw-600'>Battery Rx SIM Number</div>
                                                        <Input className='input_Size' style={{margin: 9}} size="small" readOnly value={newbatteryrx?.batteryRxSimNumber} />
                                                        <div className='font-color fw-600'>Software Version</div>
                                                        <Input className='input_Size' style={{margin: 9}} size="small" readOnly value={newbatteryrx?.softwareVersion} />
                                                        <div className='font-color fw-600'>Timestamp</div>
                                                        {
                                                            (newbatteryrx?.dateEntered) ? 
                                                            <Input className='input_Size' style={{margin: 9}} size="small" readOnly value={newbatteryrx?.dateEntered} />
                                                            :
                                                            <div style={{ color: "red", margin: 9 }}>(new configuration)</div>
                                                        }
                                                        <div className='font-color fw-600'>Battery ID</div>
                                                        <Input className='input_Size' style={{margin: 9}} readOnly size="small" value={newbatteryrx?.batteryId} />
                                                        <div className='font-color fw-600'>Vehicle ID</div>
                                                        <Input className='input_Size' style={{margin: 9}} readOnly size="small" value={newbatteryrx?.vehicleId} />
                                                        <div className='font-color fw-600 grid-item'>Battery Type</div>
                                                            <Select
                                                                showSearch
                                                                size={'middle'}
                                                                onSelect={(valk) => { setNewBatteryRX({ ...newbatteryrx, batteryType: valk }) }}
                                                                placeholder="Select Battery Type"
                                                                value={newbatteryrx?.batteryType}
                                                                className='grid-item'
                                                                dropdownStyle={{ zIndex: 900 }}
                                                            >
                                                                {batterytypes && batterytypes.map((item: any, index: any) => {
                                                                    return <Select.Option value={item?.batteryTypeId} key={index}>{item?.typeDescription}</Select.Option>
                                                                })}
                                                            </Select>
                                                        <div className='font-color fw-600 grid-item'>Key on Logic</div>
                                                            <Select
                                                                showSearch
                                                                size={'middle'}
                                                                dropdownStyle={{ zIndex: 900 }}
                                                                onSelect={(valk) => { setNewBatteryRX({ ...newbatteryrx, keyOnLogic: valk }) }}
                                                                placeholder="Select Key On Logic"
                                                                value={newbatteryrx?.keyOnLogic}
                                                                className='grid-item'
                                                            >
                                                                {keyOnLogicTypes && keyOnLogicTypes.map((item: any, index: any) => {
                                                                    return <Select.Option value={item?.logicTypeId} key={index}>{item?.logicDescription}</Select.Option>
                                                                })}
                                                            </Select>
                                                        <div className='font-color fw-600 grid-item'>Key on Detect</div>
                                                            <Select
                                                                showSearch
                                                                size={'middle'}
                                                                dropdownStyle={{ zIndex: 900 }}
                                                                onSelect={(valk) => { setNewBatteryRX({ ...newbatteryrx, keyOnDetection: valk }) }}
                                                                placeholder="Select Key On Detect"
                                                                value={newbatteryrx?.keyOnDetection}
                                                                className='grid-item'
                                                            >
                                                                {keyOnDetectTypes && keyOnDetectTypes.map((item: any, index: any) => {
                                                                    return <Select.Option value={item?.detectionTypeId} key={index}>{item?.detectionDescription}</Select.Option>
                                                                })}
                                                            </Select>
                                                        <div className='font-color fw-600 grid-item'>GMT Timezone Offset</div>
                                                            <Select
                                                                showSearch
                                                                size={'middle'}
                                                                dropdownStyle={{ zIndex: 900 }}
                                                                onSelect={(valk) => { setNewBatteryRX({ ...newbatteryrx, timezoneOffset: valk }) }}
                                                                placeholder="Select Device Timezone"
                                                                value={newbatteryrx?.timezoneOffset}
                                                                className='grid-item'
                                                            >
                                                                {timezones && timezones.map((item: any, index: any) => {
                                                                    return <Select.Option value={item?.offset} key={index}>{"(" + item?.timeZone + ") " + item?.mstimeZoneID}</Select.Option>
                                                                })}
                                                            </Select>
                                                        <div className='font-color fw-600 grid-item'>EQ Start Day/Time</div>
                                                        <Space direction="horizontal">
                                                            <Select
                                                                showSearch
                                                                size={'middle'}
                                                                style={{ width: 90 }}
                                                                dropdownStyle={{ zIndex: 900 }}
                                                                onSelect={(valk) => { setNewBatteryRX({ ...newbatteryrx, eqStartDay: valk }) }}
                                                                placeholder="Select Day"
                                                                value={newbatteryrx?.eqStartDay}
                                                            >
                                                                {daysofweek && daysofweek.map((item: any, index: any) => {
                                                                    return <Select.Option value={item?.dayId}>{item?.dayDescription}</Select.Option>
                                                                })}
                                                            </Select>
                                                            <Input placeholder='Hr' value={neweqstarthr} style={{ width: 50 }} onChange={(event) => onNewChangeEQStartHr(0, 0, 23, event.target.value)} />
                                                            <Input placeholder='Min' value={neweqendmin} style={{ width: 50 }} onChange={(event) => onNewChangeEQStartMin(0, 0, 59, event.target.value)} />
                                                        </Space>
                                                        <div className='font-color fw-600'>EQ End Day/Time</div>
                                                        <Space direction="horizontal">
                                                            <Select
                                                                showSearch
                                                                size={'middle'}
                                                                style={{ width: 90 }}
                                                                dropdownStyle={{ zIndex: 900 }}
                                                                onSelect={(valk) => { setNewBatteryRX({ ...newbatteryrx, eqEndDay: valk }) }}
                                                                placeholder="Select Day"
                                                                value={newbatteryrx?.eqEndDay}
                                                            >
                                                                {daysofweek && daysofweek.map((item: any, index: any) => {
                                                                    return <Select.Option value={item?.dayId}>{item?.dayDescription}</Select.Option>
                                                                })}
                                                            </Select>
                                                            <Input placeholder='Hr' value={neweqendhr} style={{ width: 50 }} onChange={(event) => onNewChangeEQEndHr(0, 0, 23, event.target.value)} />
                                                            <Input placeholder='Min' value={neweqendmin} style={{ width: 50 }} onChange={(event) => onNewChangeEQEndMin(0, 0, 59, event.target.value)} />
                                                        </Space>
                                                        <div className='font-color fw-600'>SOC Limit</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("socLimit", 1, 1, 100, (newbatteryrx?.socLimit ?? 0) - 1)}></Button>
                                                            <Input suffix="%" style={{ width: 62 }} size="small" value={newbatteryrx?.socLimit} onChange={(event) => onNewChangeProperty("socLimit", 1, 1, 100, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("socLimit", 1, 1, 100, (newbatteryrx?.socLimit ?? 0) + 1)}></Button>
                                                        </Space>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 col-lg-6 mt-3'>
                                                    <div className="grid-body">
                                                        <div className='font-color fw-600'>Battery Total Cells</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("totalCells", 1, 1, 99, (newbatteryrx?.totalCells ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={newbatteryrx?.totalCells} onChange={(event) => onNewChangeProperty("totalCells", 1, 1, 99, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("totalCells", 1, 1, 99, (newbatteryrx?.totalCells ?? 0) + 1)}></Button>
                                                        </Space>
                                                        <div className='font-color fw-600'>Max Das Btw Any EQ</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("maxDaysBetweenEQS", 1, 1, 30, (newbatteryrx?.maxDaysBetweenEQS ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} value={newbatteryrx?.maxDaysBetweenEQS} onChange={(event) => onNewChangeProperty("maxDaysBetweenEQS", 1, 1, 30, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("maxDaysBetweenEQS", 1, 1, 30, (newbatteryrx?.maxDaysBetweenEQS ?? 0) + 1)}></Button> Days
                                                        </Space>
                                                        <div className='font-color fw-600'>Run Time Current</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("runCurrentThreshold", 1, 1, 10000, (newbatteryrx?.runCurrentThreshold ?? 0) - 5)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onNewChangeProperty("runCurrentThreshold", 1, 1, 10000, event.target.value)} size="small" value={newbatteryrx?.runCurrentThreshold} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("runCurrentThreshold", 1, 1, 10000, (newbatteryrx?.runCurrentThreshold ?? 0) + 5)}></Button> mA
                                                        </Space>
                                                        <div className='font-color fw-600'>Target Limit (mV/Cell)</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty1("targetVoltageLimit", 1, 5000, (newbatteryrx?.targetVoltageLimit ?? 0) - 1)}></Button>
                                                            <Input size="small" style={{ width: 62 }} value={newbatteryrx?.targetVoltageLimit} onChange={(event) => onNewChangeProperty1("targetVoltageLimit", 1, 5000, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty1("targetVoltageLimit", 1, 5000, (newbatteryrx?.targetVoltageLimit ?? 0) + 1)}></Button>
                                                        </Space>
                                                        <div className='font-color fw-600'>Temp Foldback</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty1("temperatureFoldBack", 0, 3, (newbatteryrx?.temperatureFoldBack ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onNewChangeProperty1("temperatureFoldBack", 0, 3, event.target.value)} size="small" value={newbatteryrx?.temperatureFoldBack} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty1("temperatureFoldBack", 0, 3, (newbatteryrx?.temperatureFoldBack ?? 0) + 1)}></Button> A/°C/100AHr
                                                        </Space>
                                                        <div className='font-color fw-600'>Parasitic Current</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("parasiticCurrent", 0, 0, 10000, (newbatteryrx?.parasiticCurrent ?? 0) - 5)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onNewChangeProperty("parasiticCurrent", 0, 0, 10000, event.target.value)} size="small" value={newbatteryrx?.parasiticCurrent} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("parasiticCurrent", 0, 0, 10000, (newbatteryrx?.parasiticCurrent ?? 0) + 5)}></Button> mA
                                                        </Space>
                                                        <div className='font-color fw-600'>Start Current Limit</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("startCurrentLimit", 1, 1, 200, (newbatteryrx?.startCurrentLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={newbatteryrx?.startCurrentLimit} onChange={(event) => onNewChangeProperty("startCurrentLimit", 1, 1, 200, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("startCurrentLimit", 1, 1, 200, (newbatteryrx?.startCurrentLimit ?? 0) + 1)}></Button> A/100AHr
                                                        </Space>
                                                        <div className='font-color fw-600'>Internal Resistance</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("batteryInternalOhms", 0, 0, 200, (newbatteryrx?.batteryInternalOhms ?? 0) - 5)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onNewChangeProperty("batteryInternalOhms", 0, 0, 200, event.target.value)} size="small" value={newbatteryrx?.batteryInternalOhms} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("batteryInternalOhms", 0, 0, 200, (newbatteryrx?.batteryInternalOhms ?? 0) + 5)}></Button> mOhms
                                                        </Space>
                                                        <div className='font-color fw-600'>Max AHr Btw EQ</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("maxAhBetwenEQ", 1, 1, 10000, (newbatteryrx?.maxAhBetwenEQ ?? 0) - 5)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={newbatteryrx?.maxAhBetwenEQ} onChange={(event) => onNewChangeProperty("maxAhBetwenEQ", 1, 1, 10000, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("maxAhBetwenEQ", 1, 1, 10000, (newbatteryrx?.maxAhBetwenEQ ?? 0) + 5)}></Button> AHr
                                                        </Space>
                                                        <div className='font-color fw-600'>Key On Current</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => setNewBatteryRX({ ...newbatteryrx, keyOnThreshold: (newbatteryrx?.keyOnThreshold ?? 0) - 1 })}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => {
                                                                if (!isNaN(parseInt(event.target.value))) {
                                                                    setNewBatteryRX({ ...newbatteryrx, keyOnThreshold: parseInt(event.target.value) })
                                                                } else {
                                                                    setNewBatteryRX({ ...newbatteryrx, keyOnThreshold: 0 })
                                                                }
                                                            }} size="small" value={newbatteryrx?.keyOnThreshold} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => setNewBatteryRX({ ...newbatteryrx, keyOnThreshold: (newbatteryrx?.keyOnThreshold ?? 0) + 1 })}></Button> mA
                                                        </Space>
                                                        <div className='font-color fw-600'>Battery Capacity</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("capacity", 0, 0, 9999, (newbatteryrx?.capacity ?? 0) - 5)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={newbatteryrx?.capacity} onChange={(event) => onNewChangeProperty("capacity", 0, 0, 9999, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("capacity", 0, 0, 9999, (newbatteryrx?.capacity ?? 0) + 5)}></Button> AHr
                                                        </Space>
                                                        <div className='font-color fw-600'>Daily Call Time(24 hr)</div>
                                                        <Space style={{ columnGap: '2px' }}  direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeDailyCallHr(0, 0, 23, (newdailycallhr ?? 0) - 1)} ></Button>
                                                            <Input value={newdailycallhr} style={{ width: 50 }} onChange={(event) => onNewChangeDailyCallHr(0, 0, 23, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeDailyCallHr(0, 0, 23, (newdailycallhr ?? 0) + 1)}></Button>
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeDailyCallMin(0, 0, 59, (newdailycallmin ?? 0) - 1)}></Button>
                                                            <Input value={newdailycallmin} style={{ width: 50 }} onChange={(event) => onNewChangeDailyCallMin(0, 0, 59, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeDailyCallMin(0, 0, 59, (newdailycallmin ?? 0) + 1)}></Button>
                                                        </Space>
                                                    </div>
                                                </div>
                                            </div>
                                        {/* </div> */}
                                    </CustomMiniTabPanel>
                                    <CustomMiniTabPanel value={minivalue1} index={1}>
                                        <div className='container-fluid'>
                                            <div className='row'>
                                                <div className='col-md-12 col-lg-6 mt-3'>
                                                    <div className="grid-body">
                                                        <div className='font-color fw-600'>Low SOC Limit</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => setNewBatteryRX({ ...newbatteryrx, lowSocLimit: (newbatteryrx?.lowSocLimit ?? 0) - 1 })}></Button>
                                                            <Input suffix="%" style={{ width: 62 }} onChange={(event) => {
                                                                if (!isNaN(parseInt(event.target.value))) {
                                                                    setNewBatteryRX({ ...newbatteryrx, lowSocLimit: parseInt(event.target.value) })
                                                                } else {
                                                                    setNewBatteryRX({ ...newbatteryrx, lowSocLimit: 0 })
                                                                }
                                                            }} size="small" value={newbatteryrx?.lowSocLimit} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => setNewBatteryRX({ ...newbatteryrx, lowSocLimit: (newbatteryrx?.lowSocLimit ?? 0) + 1 })}></Button>
                                                        </Space>
                                                        <div className='font-color fw-600'>H/Lo Limit (mV/Cell)</div>
                                                        <Space style={{ columnGap: '2px' }} direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("highVoltageLimit", 1000, 1000, 5000, (newbatteryrx?.highVoltageLimit ?? 0) - 50)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onNewChangeProperty("highVoltageLimit", 1000, 1000, 5000, event.target.value)} size="small" value={newbatteryrx?.highVoltageLimit} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("highVoltageLimit", 1000, 1000, 5000, (newbatteryrx?.highVoltageLimit ?? 0) + 50)}></Button>
                                                            <span style={{ fontSize: "24px" }}> / </span>
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("lowVoltageLimit", 200, 200, 5000, (newbatteryrx?.lowVoltageLimit ?? 0) - 50)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onNewChangeProperty("lowVoltageLimit", 200, 200, 5000, event.target.value)} size="small" value={newbatteryrx?.lowVoltageLimit} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("lowVoltageLimit", 200, 200, 5000, (newbatteryrx?.lowVoltageLimit ?? 0) + 50)}></Button>
                                                        </Space>
                                                        <div className='font-color fw-600'>Pack Voltage Imbal Limit</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("packVoltageImbalLimit", 1, 1, 1000, (newbatteryrx?.packVoltageImbalLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} onChange={(event) => onNewChangeProperty("packVoltageImbalLimit", 1, 1, 1000, event.target.value)} size="small" value={newbatteryrx?.packVoltageImbalLimit} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("packVoltageImbalLimit", 1, 1, 1000, (newbatteryrx?.packVoltageImbalLimit ?? 0) + 1)}></Button> mV/Cell
                                                        </Space>
                                                        <div className='font-color fw-600'>H/Low °C Limit</div>
                                                        <Space style={{ columnGap: '2px' }} direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("highTempLimit", 0, 0, 300, (newbatteryrx?.highTempLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={newbatteryrx?.highTempLimit} onChange={(event) => onNewChangeProperty("highTempLimit", 0, 0, 300, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("highTempLimit", 0, 0, 300, (newbatteryrx?.highTempLimit ?? 0) + 1)}></Button>
                                                            <span style={{ fontSize: "24px" }}> / </span>
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("lowTempLimit", 0, 0, 300, (newbatteryrx?.lowTempLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={newbatteryrx?.lowTempLimit} onChange={(event) => onNewChangeProperty("lowTempLimit", 0, 0, 300, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("lowTempLimit", 0, 0, 300, (newbatteryrx?.lowTempLimit ?? 0) + 1)}></Button>
                                                        </Space>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 col-lg-6 mt-3'>
                                                    <div className="grid-body">
                                                        <div className='font-color fw-600'>Max Charge Current Limit</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("maxChargeCurrentLimit", 5, 5, 65535, (newbatteryrx?.maxChargeCurrentLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={newbatteryrx?.maxChargeCurrentLimit} onChange={(event) => onNewChangeProperty("maxChargeCurrentLimit", 5, 5, 65535, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("maxChargeCurrentLimit", 5, 5, 65535, (newbatteryrx?.maxChargeCurrentLimit ?? 0) + 1)}></Button> Amp/AHr
                                                        </Space>
                                                        <div className='font-color fw-600'>Max Days Since Cmplt EQ</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty2("daySinceCompEqLimit", 1, (newbatteryrx?.daySinceCompEqLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={newbatteryrx?.daySinceCompEqLimit} onChange={(event) => onNewChangeProperty2("daySinceCompEqLimit", 1, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty2("daySinceCompEqLimit", 1, (newbatteryrx?.daySinceCompEqLimit ?? 0) + 1)}></Button> Days
                                                        </Space>
                                                        <div className='font-color fw-600'>EQ Current Range</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("eqCurrRangeLimit", 1, 1, 50, (newbatteryrx?.eqCurrRangeLimit ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={newbatteryrx?.eqCurrRangeLimit} onChange={(event) => onNewChangeProperty("eqCurrRangeLimit", 1, 1, 50, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("eqCurrRangeLimit", 1, 1, 50, (newbatteryrx?.eqCurrRangeLimit ?? 0) + 1)}></Button> +/-
                                                        </Space>
                                                        <div className='font-color fw-600'>Max Days Btw Charge</div>
                                                        <Space direction="horizontal">
                                                            <Button type="text" shape="circle" icon={<MinusOutlined />} onClick={() => onNewChangeProperty("maxDaysNoCharge", 1, 1, 14, (newbatteryrx?.maxDaysNoCharge ?? 0) - 1)}></Button>
                                                            <Input style={{ width: 62 }} size="small" value={newbatteryrx?.maxDaysNoCharge} onChange={(event) => onNewChangeProperty("maxDaysNoCharge", 1, 1, 14, event.target.value)} />
                                                            <Button type="text" shape="circle" icon={<PlusOutlined />} onClick={() => onNewChangeProperty("maxDaysNoCharge", 1, 1, 14, (newbatteryrx?.maxDaysNoCharge ?? 0) + 1)}></Button> Days
                                                        </Space>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CustomMiniTabPanel>
                                </Box>
                                <div className="d-flex justify-content-end p-3">
                                    <Button
                                        onClick={() => { submitData() }}
                                        style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white", }}>
                                        UPDATE
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </CustomTabPanel>
                </Box>
            </div>
            <Modal open={isOpen} closable={false} centered title={titleMsg}
            footer={[
              <Button style={{ background: '#004f9f', color: "white" }} onClick={toggleModal}>OK</Button>
            ]}
          ></Modal>
        </div>
        <SessionWarning />
    </>
}

export default BatteryRXConfiguration;