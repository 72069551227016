import React,{useLayoutEffect} from "react";
import PosinetHeader from "../../layout/header";
import { Link } from 'react-router-dom';
import SessionWarning from "../reusableComponent/sessionWarning";
import { resize, hideSidebar } from "../../settings/resize";


const Logs = () => {

    useLayoutEffect(()=>{
        let timer:any
        const debouncedHandleResize = ()=>{
         clearTimeout(timer)
         timer=setTimeout(()=>{
          resize()
         },500)
        }
        window.addEventListener("resize",debouncedHandleResize)
        return()=>{
         window.removeEventListener("resize",debouncedHandleResize)       }
     })

     useLayoutEffect(()=>{
        resize()
        hideSidebar();
      },[])
      
    return (
        <>
            {/* <PosinetHeader /> */}
            {/* <div className="container report-mt p-2"> */}
            <div className="container p-2">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="row">
                            <h5>Most popular Logs</h5>
                        </div>
                    </div>
                    <div className="col-12 pt-2 pb-3">
                        <div className="row justify-center">
                        {
                                localStorage.permissions.includes('eq_log_report_W') &&
                            <Link to="/eq-log-report" aria-expanded="false">
                                <div className="flex-item">
                                    <div className="flex-item-head">Equalization Log</div>
                                    <br />
                                    <div className="flex-item-body">EQ Logs by Date Range</div>
                                </div>
                            </Link>
                            }
                            {
                                localStorage.permissions.includes('charge_Log_Report_W') &&
                            <Link to="/charge-log-report" aria-expanded="false">
                                <div className="flex-item">
                                    <div className="flex-item-head">Charge Log Report</div>
                                    <br />
                                    <div className="flex-item-body">Charge Log Report by Date Range</div>
                                </div>
                            </Link>
                            }
                            {
                                localStorage.permissions.includes('battery_history_logs_W') && 
                            <Link to="/battert-history-log-report" aria-expanded="false">
                                <div className="flex-item">
                                    <div className="flex-item-head">Battery History Log Report</div>
                                    <br />
                                    <div className="flex-item-body">Battery History Log Report by Date Range</div>
                                </div>
                            </Link>
                            }   
                            {
                                localStorage.permissions.includes('emeter_Report_R') && 

                            <Link to="/emeter-log-report" aria-expanded="false">
                                <div className="flex-item">
                                    <div className="flex-item-head">E-Meter Log Report</div>
                                    <br />
                                    <div className="flex-item-body">E-Meter Log Report by Date Range</div>
                                </div>
                            </Link>
                            }
                            {
                            localStorage.permissions.includes('configuration_Logs_Report_W') &&
                            <Link to="/configurationlogreport" aria-expanded="false">
                                <div className="flex-item">
                                    <div className="flex-item-head">Configuration Log Report</div>
                                    <br />
                                    <div className="flex-item-body">Configuration Log Report</div>
                                </div>
                            </Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    )
}

export default Logs;