import React, { FC, useEffect, useRef, useState, useLayoutEffect } from "react";
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import {format,subDays } from 'date-fns';
import { Input, Select, ConfigProvider, Space, Button, Modal, Form } from "antd";
import log from '../../images/right_arrow.gif';
import { resize, hideSidebar } from '../../settings/resize';
import { CaretDownOutlined } from '@ant-design/icons';
import { CaretUpOutlined } from '@ant-design/icons';

const today = format(new Date(), 'dd-MMM-yyyy')
const fromdate = format(subDays(new Date(), 14), 'dd-MMM-yyyy')

type reqBody = {
    orgId: number,
    divisionId: number,
    locationId: number,
    buildingId: number,
    startDate: string,
    endDate: string,
    pageOffset: number,
    pageLimit: number,
    identifier: string,
    batteryId: string,
    emeterSessionId: string,
    batteryRxSimNum: string,
    serialNumber: string,
    vehicleId: string,
    chargeAH: string,
    chargeWH: string,
    dischargeAH: string,
    dischargeWH: string,
    logNumber: any,
    temperature: string,
    log: any,
    csq: string,
    voltage: string,
    sortColumns: string,
    sortDirection: string,
    timeZone: string
}

let Body = {
    orgId: 0,
    divisionId: -1,
    locationId: -1,
    buildingId: -1,
    startDate: fromdate,
    endDate: today,
    pageOffset: 1,
    pageLimit: 10,
    identifier: "",
    batteryId: "",
    emeterSessionId: "",
    batteryRxSimNum: "",
    serialNumber: "",
    vehicleId: "",
    chargeAH: "",
    chargeWH: "",
    dischargeAH: "",
    dischargeWH: "",
    logNumber: -1,
    temperature: "",
    csq: "",
    log: -1,
    voltage: "",
    sortColumns: "",
    sortDirection: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    action: string,
    // refresh: number
}

const EMeterLogReport: FC<Props> = ({ globalData, action }) => {
    // const EMeterLogReport: FC<Props> = ({ globalData, action, refresh }) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    const selectorRef = useRef<HTMLDivElement>(null)
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [inputvalue, setInput] = useState<any>("")
    const [selector, setSelector] = useState(false)
    const [tableReport, setTableReport] = useState<any[]>([])
    const [emeterlogs, setEmeterLogs] = useState<any[]>([])
    const [meterReq, setMeterReq] = useState<any>({ orgId: 0, divisionId: -1, locationId: -1, logNumber: -1, startDate: fromdate, endDate: today })
    const [disabled, setDisabled] = useState<boolean>(false)
    const [emeterIdentifier, setemeterIdentifier] = useState<any>("");
    const [tableReqBody, setTableReqBody] = useState<reqBody>(Body)
    const [current, setCurrent] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [count, setCount] = useState<number>(0)
    const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
    const [clearAll, setClearAll] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [alert, setAlert] = useState<boolean>(false);
    const [sort, setSort] = useState<any>({
        batteryId: false, vehicleId: false, Emetersession: false, lognumber: false, timestamp: false, temperature: false, csq: false,
        current: false, voltage: false, chargeahrs: false, chargewhrs: false, dischargeahrs: false, dischargewhrs: false, batterysim: false
    })
    const [searchObject, setSearchObject] = useState<any>({
        batteryId: '',
        rxSim: '',
        emetersessionid: '',
        chargeahrs: '',
        chargewhrs: '',
        csq: '',
        dischargeahrs: '',
        dischargewhrs: '',
        log: '',
        temp: '',
        vehicleid: '',
        voltage: ''
    })
    const [emptyError, setemptyError] = useState<string>("");
    const [enableStatus, setEnableStatus] = useState<any>(false)
    const [phonehomeactive, setphonehomeactive] = useState<any>(false)
    let [sortname, setSortName] = useState<any>("");
    let [directionname, setDirectionName] = useState<any>("");

    const DropList = [
        { label: "Battery ID" },
        { label: "Battery Rx SIM #" },
        { label: "E-Meter Session ID" },
        { label: "Charge Ahrs" },
        { label: "Charge Whrs" },
        { label: "CSQ" },
        { label: "Discharge Ahrs" },
        { label: "Discharge Whrs" },
        { label: "Log Number" },
        // { label: "Temperature" },
        { label: "Vehicle ID" },
        // { label: "Voltage" }
    ];

    const [dropdownvalue, setDropDownValue] = useState<string>("Battery ID");
    const [emetervalue, setEmeterValue] = useState<string>("");
    const [serial, setSerial] = useState<string>("");
    const [sessions, setSessions] = useState<any[]>([])
    const [enablestring, setenableString] = useState<boolean>(false)
    const [sessionid, setsessionId] = useState<string>("")
    const [isCreateSession, setIsCreateSession] = useState<boolean>(false);
    const [session, setSession] = useState<string>("");
    const [session1, setSession1] = useState<string>("");
    const [deletesession, setdeletesession] = useState<any>("");
    const [ipaddress, setipaddress] = useState<any>("");
    const [openalert, setOpenAlert] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [successAlert, setSuccessAlert] = useState<boolean>(false);
    const [sessionAlert, setSessionAlert] = useState<boolean>(false);
    const [showEmeter, setShowEmeter] = useState<boolean>(false);
    const [showEmeter1, setShowEmeter1] = useState<boolean>(false);
    const [createSessionAlert, setCreateSessionAlert] = useState<boolean>(false);
    const [initial, setInitial] = useState<boolean>(true);
    const [column, setColumn] = useState<any[]>([])
    const [selectorLabel, setSelectorLabel] = useState<any[]>([])
    const [sessionErr, setSessionErr] = useState('')
    const [sessionErr1, setSessionErr1] = useState('')
    const [mount, setMount] = useState<boolean>(false)
    const [confirmClearLogs, setConfirmClearLogs] = useState<boolean>(false);
    const [createSessionClearLogs, setCreateSessionClearLogs] = useState<boolean>(false);
    const [tableHeight, setTableHeight] = useState<any>(445)
    const [emeterSessionId, setEmeterSessionId] = useState<string>("")
    const filtersRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
                callDebounce()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useLayoutEffect(() => {
        resize();
        hideSidebar();
        callDebounce()
    }, []);

    const callDebounce = () => {
        //if(window.innerHeight<=568) return
        let marginAndPaddings: any = 65
        let navHeight = localStorage.getItem('navHeight')
        let filtersHeight
        let paginationHeight
        if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
        if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
        let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
        let tableHeight = window.innerHeight - subHeight
        tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
    };

    useEffect(() => {
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('emeter_Report_R')) window.history.back()
        }
        setInitial(true)
        getReportSettings();
    }, [])

    useEffect(() => {
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
        if (!initial) {
            setMeterReq({ ...meterReq, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId })
            setTableReqBody({...tableReqBody,pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId })
            getMeters({ ...tableReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId])

    useEffect(() => {
        if (action !== "") {
            dwnFormat(action)
        }
    }, [action])

    // useEffect(() => {
    //     if (refresh !== 0) {
    //         getEMeterReport(tableReqBody)
    //         dataCount(tableReqBody)
    //     }
    // }, [refresh])

    const getMeters = async (payload: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        try {
            const response = await service({
                url: constantUrl.api.user.emeterids + payload?.orgId,
                method: "GET",
                headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
                data: payload,
            })
            setEmeterLogs(response)
            if (response && response.length > 0) {
                setSerial(response[0]?.serialNumber)
                setEnableStatus(response[0]?.enabled)
                setemeterIdentifier(response[0]?.emeterIdentifier)
                setDisabled(true)
                setEmeterValue(response[0]?.emeterIdentifier)
                getSessions(response[0]?.serialNumber, response[0]?.emeterIdentifier)
                bmidEmeterSession(response[0]?.serialNumber)
            } else {
                setSerial("")
                setEmeterValue("")
                setsessionId("")
                setdeletesession("")
                setemeterIdentifier("")
                setEmeterSessionId("")
                setCount(0)
                setenableString(false)
                setDisabled(false)
                setTableReport([])
                setSessions([])
                setTableReqBody({...payload,emeterSessionId:"", startDate:"", endDate:"", serialNumber:"",identifier:""})
            }
        } catch (error) {
            console.warn(error)
        }
    }

    const bmidEmeterSession = async (seral: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        try {
            const response = await service({
                url: constantUrl.api.user.bmidEmeterSession + seral,
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token }
            })
            setEmeterSessionId(response.emeterSessionId)
        } catch (err) {
            console.log(err)
        }
    }

    const getSessions = async (seral: any, identifier: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let org = data?.orgId > -1 ? data?.orgId : userId;

        try {
            const response = await service({
                url: constantUrl.api.user.emetersessions + seral,
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token }
            })
            setSessions(response)
            const{emeterSessionId}= response?.length > 0 ? response[0].emeterSessionId: ""
            if (response?.length > 0) {
                setdeletesession(response[0].emeterSessionId)
                setTableReqBody((prev) => ({
                    ...prev, emeterSessionId: response[0].emeterSessionId, startDate: response[0].formatTimestamp, endDate: response[0].formatEndTimestamp, serialNumber: seral, orgId: org, divisionId: data?.divId, locationId: data?.locationId > -1 ? data?.locationId : -1, buildingId: data?.buildingId > -1 ? data?.buildingId : -1, identifier: identifier
                }))

                if (initial == false) {
                    setsessionId(response[0].emeterSessionId)
                    getEMeterReport({ ...tableReqBody, emeterSessionId: response[0].emeterSessionId, startDate: response[0].formatTimestamp, endDate: response[0].formatEndTimestamp, serialNumber: seral, orgId: org, divisionId: data?.divId, locationId: data?.locationId > -1 ? data?.locationId : -1, buildingId: data?.buildingId > -1 ? data?.buildingId : -1, identifier: identifier })
                    dataCount({ ...tableReqBody, emeterSessionId: response[0].emeterSessionId, startDate: response[0].formatTimestamp, endDate: response[0].formatEndTimestamp, serialNumber: seral, orgId: org, divisionId: data?.divId, locationId: data?.locationId > -1 ? data?.locationId : -1, buildingId: data?.buildingId > -1 ? data?.buildingId : -1, identifier: identifier })
                }
            } else {
                setTableReqBody({ ...tableReqBody, serialNumber: seral, orgId: org, divisionId: data?.divId, locationId: data?.locationId > -1 ? data?.locationId : -1, buildingId: data?.buildingId > -1 ? data?.buildingId : -1, identifier: identifier,emeterSessionId,startDate:"",endDate:"" })
                if (initial == false) {
                    getEMeterReport({ ...tableReqBody, serialNumber: seral, orgId: org, divisionId: data?.divId, locationId: data?.locationId > -1 ? data?.locationId : -1, buildingId: data?.buildingId > -1 ? data?.buildingId : -1, identifier: identifier,emeterSessionId,startDate:"",endDate:""})
                    dataCount({ ...tableReqBody, serialNumber: seral, orgId: org, divisionId: data?.divId, locationId: data?.locationId > -1 ? data?.locationId : -1, buildingId: data?.buildingId > -1 ? data?.buildingId : -1, identifier: identifier,emeterSessionId,startDate:"",endDate:""})
                }
            }
            setenableString(true)
        } catch (error) {
            console.log(error)
        }
    }

    //dropdown for search filter
    const onChangedropdown = (value: string) => {
        setemptyError("")
        setDropDownValue(value);
        setPlaceholder(`Search ${value}`)
        setInput("");
        setSearchObject({
            batteryId: '',
            rxSim: '',
            emetersessionid: '',
            vehicleid: '',
            chargeahrs: '',
            chargewhrs: '',
            csq: '',
            dischargeahrs: '',
            dischargewhrs: '',
            log: '',
            temp: '',
            voltage: ''
        })

    }

    //E Meter Identifier dropdown
    const changeDropdown = (value: string) => {
        setsessionId("")
        setdeletesession("")
        var list1 = emeterlogs.filter((item) => {
            return item?.emeterIdentifier == value
        })
        setSerial(list1[0]?.serialNumber)
        setEnableStatus(list1[0]?.emeterEnabled == "Y" ? false : true)
        setemeterIdentifier(value)
        // setDisabled(true)
        setDisabled(list1[0]?.emeterEnabled == "Y" ?  true : false)
        setEmeterValue(value)
        setIsShowSpinner(true)
        getSessions(list1[0]?.serialNumber, value)
        bmidEmeterSession(list1[0]?.serialNumber)
    }

    //Session Dropdown
    const changeDropdown1 = (value: string) => {
        setenableString(true)
        setsessionId(value)

        var list1 = sessions.filter((item) => {
            return item?.emeterSessionId == value
        })

        setTableReqBody({ ...tableReqBody, emeterSessionId: value, startDate: list1[0].formatTimestamp, endDate: list1[0].formatEndTimestamp })

        getEMeterReport({ ...tableReqBody, emeterSessionId: value, startDate: list1[0].formatTimestamp, endDate: list1[0].formatEndTimestamp })
        dataCount({ ...tableReqBody, emeterSessionId: value, startDate: list1[0].formatTimestamp, endDate: list1[0].formatEndTimestamp })
    }

    //Session Delete Dropdown
    const changeDropdown2 = (value: string) => {
        setdeletesession(value)
        setShowAlert(false)
    }

    //Search Find Click
    const onChangePayload = (values: any) => {
        let value = values
        let obj = { ...searchObject };
        var regex = new RegExp("^[0-9.-]*$");
        if (dropdownvalue === "Battery ID") {
            obj.batteryId = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Battery Rx SIM #") {
            obj.rxSim = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "E-Meter Session ID") {
            obj.emetersessionid = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Charge Ahrs") {
            if (!regex.test(value)) {
                return false;
            } else {
                obj.chargeahrs = value.trim();
                setSearchObject({ ...obj })
                setInput(value);
                !value && callCountAndReport({ ...obj, pageOffset: 1 })
                setCurrent(1)
            }
        } else if (dropdownvalue === "Charge Whrs") {
            if (!regex.test(value)) {
                return false;
            } else {
                obj.chargewhrs = value.trim();
                setSearchObject({ ...obj })
                setInput(value);
                !value && callCountAndReport({ ...obj, pageOffset: 1 })
                setCurrent(1)
            }
        } else if (dropdownvalue === "CSQ") {
            if (!regex.test(value)) {
                return false;
            } else {
                obj.csq = value.trim();
                setSearchObject({ ...obj })
                setInput(value);
                !value && callCountAndReport({ ...obj, pageOffset: 1 })
                setCurrent(1)
            }
        } else if (dropdownvalue === "Discharge Ahrs") {
            if (!regex.test(value)) {
                return false;
            } else {
                obj.dischargeahrs = value.trim();
                setSearchObject({ ...obj })
                setInput(value);
                !value && callCountAndReport({ ...obj, pageOffset: 1 })
                setCurrent(1)
            }
        } else if (dropdownvalue === "Discharge Whrs") {
            if (!regex.test(value)) {
                return false;
            } else {
                obj.dischargewhrs = value.trim();
                setSearchObject({ ...obj })
                setInput(value);
                !value && callCountAndReport({ ...obj, pageOffset: 1 })
                setCurrent(1)
            }
        } else if (dropdownvalue === "Log Number") {
            if (!regex.test(value)) {
                return false;
            } else {
                obj.log = value.trim();
                setSearchObject({ ...obj })
                setInput(value);
                !value && callCountAndReport({ ...obj, pageOffset: 1 })
                setCurrent(1)
            }
        } else if (dropdownvalue === "Temperature") {
            if (!regex.test(value)) {
                return false;
            } else {
                obj.temp = value.trim();
                setSearchObject({ ...obj })
                setInput(value);
                !value && callCountAndReport({ ...obj, pageOffset: 1 })
                setCurrent(1)
            }
        } else if (dropdownvalue === "Vehicle ID") {
            obj.vehicleid = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Voltage") {
            if (!regex.test(value)) {
                return false;
            } else {
                obj.voltage = value;
                setSearchObject({ ...obj })
                setInput(value);
                !value && callCountAndReport({ ...obj, pageOffset: 1 })
                setCurrent(1)
            }
        }
        setemptyError("")
    }

    const callCountAndReport = (obj: any) => {
        setMount(true)
        setTableReqBody({ ...tableReqBody, ...obj })
    };

    // Search Enter Button
    const onPressEnter = (event: any) => {
        let value = event.target.value
        let obj = { ...searchObject };
        switch (dropdownvalue) {
            case 'Battery ID':
                obj.batteryId = value;
                break;
            case 'Battery Rx SIM #':
                obj.rxSim = value;
                break;
            case 'E-Meter Session ID':
                obj.emetersessionid = value;
                break;
            case 'Charge Ahrs':
                obj.chargeahrs = value;
                break;
            case 'CSQ':
                obj.csq = value;
                break;
            case 'Discharge Ahrs':
                obj.dischargeahrs = value;
                break;
            case 'Discharge Whrs':
                obj.dischargewhrs = value;
                break;
            case 'Log Number':
                obj.log = value;
                break;
            case 'Temperature':
                obj.temp = value;
                break;
            case 'Vehicle ID':
                obj.vehicleid = value;
                break;
            case 'Voltage':
                obj.voltage = value;
                break;
        }
        setSearchObject({ ...obj })
        setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
        getEMeterReport({ ...tableReqBody, ...obj, pageOffset: 1 })
        dataCount({ ...tableReqBody, ...obj, pageOffset: 1 })
    };

    const handleSearch = () => {
        const { batteryId, vehicleid, rxSim, emetersessionid, chargeahrs, chargewhrs, csq, dischargeahrs, dischargewhrs,
            log, temp, voltage } = searchObject
        let obj = { ...searchObject }
        switch (dropdownvalue) {
            case 'Battery ID':
                if (!batteryId) {
                    setemptyError("Please Enter Battery ID")
                    return
                } else {
                    obj.batteryId = inputvalue.trim();
                }
                break;
            case 'Battery Rx SIM #':
                if (!rxSim) {
                    setemptyError("Please Enter Battery Rx SIM #")
                    return
                } else {
                    obj.rxSim = inputvalue.trim();
                }
                break;
            case 'E-Meter Session ID':
                if (!emetersessionid) {
                    setemptyError("Please Enter E-Meter Session ID")
                    return
                } else {
                    obj.emetersessionid = inputvalue.trim();
                }
                break;
            case 'Charge Ahrs':
                if (!chargeahrs) {
                    setemptyError("Please Enter Charge Ahrs")
                    return
                } else {
                    obj.chargeahrs = inputvalue.trim();
                }
                break;
            case 'Charge Whrs':
                if (!chargewhrs) {
                    setemptyError("Please Enter Charge Whrs")
                    return
                } else {
                    obj.chargewhrs = inputvalue.trim();
                }
                break;
            case 'CSQ':
                if (!csq) {
                    setemptyError("Please Enter CSQ")
                    return
                } else {
                    obj.csq = inputvalue.trim();
                }
                break;
            case 'Discharge Ahrs':
                if (!dischargeahrs) {
                    setemptyError("Please Enter Discharge Ahrs")
                    return
                } else {
                    obj.dischargeahrs = inputvalue.trim();
                }
                break;
            case 'Discharge Whrs':
                if (!dischargewhrs) {
                    setemptyError("Please Enter Discharge Whrs")
                    return
                } else {
                    obj.dischargewhrs = inputvalue.trim();
                }
                break;
            case 'Log Number':
                if (!log) {
                    setemptyError("Please Enter Log Number")
                    return
                } else {
                    obj.log = inputvalue.trim();
                }
                break;
            case 'Temperature':
                if (!temp) {
                    setemptyError("Please Enter Temperature")
                    return
                } else {
                    obj.temp = inputvalue.trim();
                }
                break;
            case 'Vehicle ID':
                if (!vehicleid) {
                    setemptyError("Please Enter Vehicle ID")
                    return
                } else {
                    obj.vehicleid = inputvalue.trim();
                }
                break;
            case 'Voltage':
                if (!voltage) {
                    setemptyError("Please Enter Voltage")
                    return
                } else {
                    obj.voltage = inputvalue.trim();
                }
                break;
            default:
                setemptyError("")
        }
        setSearchObject({ ...obj })
        setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
        getEMeterReport({ ...tableReqBody, ...obj, pageOffset: 1 })
        dataCount({ ...tableReqBody, ...obj, pageOffset: 1 })
    }

    useEffect(() => {
        if (mount) {
            setMount(false)
            getEMeterReport(tableReqBody)
            dataCount(tableReqBody)
        }
    }, [tableReqBody, searchObject, mount === true])

    //get records
    const getEMeterReport = async (payload: any) => {
        payload.batteryRxSimNum = payload.rxSim;
        payload.vehicleId = payload.vehicleid;
        payload.chargeAH = payload.chargeahrs && parseFloat(payload.chargeahrs)
        payload.chargeWH = payload.chargewhrs && parseFloat(payload.chargewhrs)
        payload.dischargeAH = payload.dischargeahrs && parseFloat(payload.dischargeahrs)
        payload.dischargeWH = payload.dischargewhrs && parseFloat(payload.dischargewhrs)
        payload.logNumber = payload.log ? parseInt(payload.log) : meterReq.logNumber;
        payload.temperature = payload.temp && parseFloat(payload.temp)
        payload.csq = payload.csq && parseFloat(payload.csq);
        payload.voltage = payload.voltage && parseFloat(payload.voltage);

        if (payload.sortColumns !== "" && payload.sortDirection !== "") {

        } else {
            payload.sortColumns = sortname;
            payload.sortDirection = directionname;
        }

        if (payload.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.eMeterLogReport.getEMeterLogReport,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });
            setTableReport(response)
            setIsShowSpinner(false)
        } catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    }

    //get record count
    const dataCount = async (payload: any) => {
        payload.batteryRxSimNum = payload.rxSim;
        payload.vehicleId = payload.vehicleid;
        payload.chargeAH = payload.chargeahrs && parseFloat(payload.chargeahrs)
        payload.chargeWH = payload.chargewhrs && parseFloat(payload.chargewhrs)
        payload.dischargeAH = payload.dischargeahrs && parseFloat(payload.dischargeahrs)
        payload.dischargeWH = payload.dischargewhrs && parseFloat(payload.dischargewhrs)
        payload.logNumber = payload.log ? parseInt(payload.log) : meterReq.logNumber;
        payload.temperature = payload.temp
        payload.csq = payload.csq;
        payload.voltage = payload.voltage && parseFloat(payload.voltage);

        if (payload.orgId === 0) return

        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        try {
            const response = await service({
                url: constantUrl.api.eMeterLogReport.eMeterCount,
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
                data: payload
            })
            setCount(response)
        } catch (error) {
            console.log(error)
        }
    }

    //on show entries change
    const onSelect = (pageLimit: any) => {
        const numPageSize = Number(pageLimit)
        setPageSize(numPageSize)
        setCurrent(1)
        setTableReqBody({ ...tableReqBody, pageLimit: numPageSize, pageOffset: 1 })
        getEMeterReport({ ...tableReqBody, pageLimit: numPageSize, pageOffset: 1 })
    };

    //on page change
    const handlePagination = (pageOffset: number, c: number, maxIndex: number) => {
        setCurrent(pageOffset)
        setTableReqBody({ ...tableReqBody, pageOffset })
        getEMeterReport({ ...tableReqBody, pageOffset })
    }

    const selectColumn = () => {
        if (selectorRef.current && selector) {
            selectorRef.current.style.right = "-370px";
        } else {
            if (selectorRef.current) { selectorRef.current.style.right = "0px" }
        }
        setSelector(!selector);
    };

    const applyChanges = async () => {
        selectColumn()
        var columnPayload = {
            reportId: 'E-METER_LOG_REPORT',
            reportName: 'E-Meter Log Report',
            columnSettings: selectorLabel,
            sortColumn: tableReqBody?.sortColumns,
            sortDirection: tableReqBody?.sortDirection
        };
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;
        setIsShowSpinner(true);
        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}`,
                method: "PUT",
                headers: { Authorization: "Bearer " + authToken },
                data: columnPayload
            })
            setIsShowSpinner(false)
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn([...columnData])
            setSelectorLabel([...selectorData])
        }
        catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    };

    const onchange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let columndata: any = selectorLabel
        let index: number = parseInt(event.target.name);
        let checked: boolean = event.target.checked;
        columndata[index].enabled = checked
        setSelectorLabel([...columndata])
        setClearAll(false)
        setSelectAll(false)
    };

    const clearall = () => {
        let columnLable = selectorLabel
        let lengths = columnLable.length
        for (let i = 0; i < lengths; i++) {
            columnLable[i]['enabled'] = false;
        }
        setSelectorLabel([...columnLable])
        setClearAll(true)
        setSelectAll(false)
    };

    const selectall = () => {
        let columnLable = selectorLabel
        let lengths = columnLable.length
        for (let i = 0; i < lengths; i++) {
            columnLable[i]['enabled'] = true;
        }
        setSelectorLabel(columnLable)
        setClearAll(false)
        setSelectAll(true)
    };

    const cancelSelection = () => {
        let columnData = selectorLabel;
        let lengths = columnData.length
        for (let i = 0; i < lengths; i++) {
            columnData[i]['enabled'] = columnData[i].enabled;
        }
        setSelectorLabel(columnData)
        selectColumn()
    };

    const getReportSettings = async () => {
        const reportId = 'E-METER_LOG_REPORT'
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let org = data?.orgId > -1 ? data?.orgId : userId;

        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
                method: "GET",
                headers: { Authorization: "Bearer " + authToken },
            })
            sortname = response?.data?.sortColumn;
            directionname = response?.data?.sortDirection;
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn(columnData)
            setSelectorLabel(selectorData)

            let sortColumns = response.data['sortColumn']

            setTableReqBody({ ...tableReqBody,orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1,sortColumns, sortDirection: 'ASC' })

            setMeterReq({ ...meterReq, orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1 })
            getMeters({ ...tableReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1 })
            getIpAddress()
            setInitial(false)
        }
        catch (error) {
            console.log(error)
        }
    };

    const dwnFormat = async (format: string) => {
        if (selectorLabel.some((item: any) => item.enabled == true)) {
            let userInfo: any = Utility.getUserInfo();
            let token = userInfo.authToken
            setIsShowSpinner(true);
            let payload =
            {
                orgId: tableReqBody?.orgId,
                locationId: tableReqBody?.locationId,
                startDate: tableReqBody?.startDate,
                endDate: tableReqBody?.endDate,
                pageOffset: 1,
                pageLimit: count + 1,
                identifier: tableReqBody?.identifier,
                batteryId: tableReqBody?.batteryId,
                emeterSessionId: tableReqBody?.emeterSessionId,
                batteryRxSimNum: tableReqBody?.batteryRxSimNum,
                serialNumber: tableReqBody?.serialNumber,
                vehicleId: tableReqBody?.vehicleId,
                chargeAH: tableReqBody?.chargeAH,
                chargeWH: tableReqBody?.chargeWH,
                dischargeAH: tableReqBody?.dischargeAH,
                dischargeWH: tableReqBody?.dischargeWH,
                logNumber: tableReqBody?.log ? parseInt(tableReqBody?.log) : -1,
                temperature: tableReqBody?.temperature,
                csq: tableReqBody?.csq,
                voltage: tableReqBody?.voltage,
                sortColumns: tableReqBody?.sortColumns,
                sortDirection: tableReqBody?.sortDirection,
                timeZone: tableReqBody?.timeZone
            }
            try {
                const response = await service({
                    url: constantUrl.api.eMeterLogReport.eMeterExport + '/' + format,
                    method: 'POST',
                    responseType: 'blob',
                    headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                    data: payload
                })
                if (response) {
                    let url = window.URL.createObjectURL(response);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'EMeterLogReport.' + format;
                    a.click();
                }
                setIsShowSpinner(false);
            } catch (error) {
                setIsShowSpinner(false);
                console.log(error)
            }
        }
        else {
            setAlert(true)
        }
    }

    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]
        switch (id) {

            case "batteryId":
                if (sort.batteryId) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "batteryId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "vehicleId":
                if (sort.vehicleId) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "vehicleId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "Emetersession":
                if (sort.Emetersession) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "eMeterSessionId", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "eMeterSessionId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "eMeterSessionId", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "eMeterSessionId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "lognumber":
                if (sort.lognumber) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "logNumber", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "logNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "logNumber", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "logNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "timestamp":
                if (sort.timestamp) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "timestamp", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "timestamp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "timestamp", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "timestamp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "temperature":
                if (sort.temperature) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "temperature", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "temperature", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "temperature", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "temperature", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "csq":
                if (sort.csq) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "csq", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "csq", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "csq", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "csq", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "current":
                if (sort.current) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "current", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "current", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "current", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "current", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "voltage":
                if (sort.voltage) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "voltage", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "voltage", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "voltage", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "voltage", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "chargeahrs":
                if (sort.chargeahrs) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "chargeAH", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "chargeAH", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "chargeAH", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "chargeAH", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "chargewhrs":
                if (sort.chargewhrs) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "chargeWH", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "chargeWH", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "chargeWH", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "chargeWH", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "dischargeahrs":
                if (sort.dischargeahrs) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "dischargeAH", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "dischargeAH", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "dischargeAH", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "dischargeAH", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "dischargewhrs":
                if (sort.dischargewhrs) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "dischargeWH", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "dischargeWH", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "dischargeWH", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "dischargeWH", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "batterysim":
                if (sort.batterysim) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "serialNumber", sortDirection: "ASC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "serialNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "serialNumber", sortDirection: "DESC", pageOffset: 1 })
                    getEMeterReport({ ...tableReqBody, sortColumns: "serialNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
        }
    };

    const formattedDate2 = (date: any) => {
        const formatDate = format(date, 'M/d/yyyy');
        let n = date.toLocaleString([], {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });

        return formatDate + " " + n
    }

    const phoneHome = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        let payload = {
            "userName": localStorage.userName,
            "serialNumber": serial,
            "ipAddress": ipaddress,
            "isClearLogs": "N",
            "sessionID": ""
        }

        try {
            const response = await service({
                url: constantUrl.api.emeter.phonehome,
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
                data: payload
            })

            setphonehomeactive(true)
            setTimeout(() => {
                setphonehomeactive(false)
            }, 10000)
        } catch (error) {
            console.log(error)
        }
    }

    const enableSession = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        let payload = { "serialNumber": serial, "enabledState": enableStatus ? "Y" : "N" }
        try {
            const response = await service({
                url: constantUrl.api.user.enableCapture,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
                data: JSON.stringify(payload)
            })

            if (response) {
                if (response?.enabled == "Y") {
                    setDisabled(true)
                    setEnableStatus(false)
                } else {
                    setDisabled(false)
                    setEnableStatus(true)
                }
            }

            console.log('count:', response)
        } catch (error) {
            console.log(error)
        }
    }

    const createSession = async () => {
        if (session) {
            let userInfo: any = Utility.getUserInfo();
            let token: string = userInfo.authToken;

            let payload = {
                "serialNumber": serial,
                "sessionId": session
            }
            try {
                const response = await service({
                    url: constantUrl.api.emeter.createSession1,
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
                    data: payload
                })
                if (response) {
                    setCreateSessionAlert(true)
                    setIsCreateSession(false)
                    setSession("")
                    setShowEmeter(false)
                } else {
                    setShowEmeter(true)
                    setSessionErr('E-Meter Session ID already exist')
                }
            } catch (error) {
                console.log(error)
                setIsShowSpinner(false)
            }
        } else {
            setShowEmeter(true)
            setSessionErr('Please Enter E-Meter Session ID')
        }
    }

    const getIpAddress = async () => {
        try {
            fetch('https://api.ipify.org/')
                .then(obj => obj.text())
                .then(res => {
                    setipaddress(res)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const deleteSession = async () => {
        setOpenAlert(false)
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        let payload = {
            "serialNumber": serial,
            "sessionID": deletesession,
            "ipAddress": ipaddress,
            "isClearLogs": "",
            "userName": localStorage.userName
        }

        setIsShowSpinner(true)

        try {
            const response = await service({
                url: constantUrl.api.emeter.deleteSession,
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
                data: JSON.stringify(payload)
            })

            if (response) {
                setIsShowSpinner(false)
                setSuccessAlert(true)
            } else {
                setIsShowSpinner(false)
            }
        } catch (error) {
            setIsShowSpinner(false)
            console.log(error)
        }
    }

    const clearLogs = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        let payload = {
            "userName": localStorage.userName,
            "serialNumber": serial,
            "ipAddress": ipaddress,
            "isClearLogs": "Y",
            "sessionID": session1
        }
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.user.clearlogs,
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
                data: payload
            })

            if (response.status === "FAILURE") {
                setShowEmeter1(true)
                setSessionErr1(response.errorMessage)
                setIsShowSpinner(false)
            } else {
                setIsShowSpinner(false)
                window.location.reload();
            }
        } catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    };

    const createLogsSession = async () => {
        if (session1) {
            clearLogs();
        } else {
            setShowEmeter1(true)
            setSessionErr1('Please Enter E-Meter Session ID')
        }
    }

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div className="container-fluid" ref={filtersRef}>
                <div className="row align-items-center">
                    <div className="col-md-6 px-0 drop_search-main">
                        <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                            <div className='ant-design-select'>
                                <Select
                                    value={dropdownvalue}
                                    size={'middle'}
                                    onChange={onChangedropdown}
                                    className='drop-filter'
                                    dropdownStyle={{ zIndex: 900 }}
                                >
                                    {DropList && DropList.map((item: any, index: any) => {
                                        return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                                    })}
                                </Select>
                            </div>
                            <div>
                                <Space direction="vertical" size="middle" className='search-input'>
                                    <Space.Compact className='posi_bmid_search_width'>
                                        <Input allowClear placeholder={placeholder} value={inputvalue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                                            onPressEnter={onPressEnter} />
                                        <Button type="primary" onClick={handleSearch}>Find</Button>
                                    </Space.Compact>
                                </Space>
                                <div className="errorColor">{emptyError}</div>
                            </div>
                        </ConfigProvider>
                    </div>
                    <div className="col-md-6 col-lg-6 gap3  px-0">
                        <button
                            onClick={selectColumn}
                            type="button"
                            className="btn btn-light selector-btn"
                            style={{ marginRight: "1px" }}
                        >
                            Column Selector
                        </button>
                    </div>
                </div>
                <div className="row e-meter-identifer-row">
                    <div className="col-md-6 px-0 mr-auto">
                        <div className="e-meter-identifer-div">
                            <div style={{ fontWeight: "bold" }}>E-Meter Identifier</div>
                            <div className="select-capture">
                                <Select
                                    value={emetervalue}
                                    size={'middle'}
                                    style={{ maxWidth: 175, width: 197 }}
                                    dropdownStyle={{ zIndex: 900 }}
                                    onChange={changeDropdown}
                                >
                                    {emeterlogs && emeterlogs?.map((item: any, index: any) => {
                                        return <Select.Option name={item?.emeterIdentifier ?? ""} value={item?.emeterIdentifier ?? ""} key={index}>{item?.emeterIdentifier ?? ""}</Select.Option>
                                    })}
                                </Select>
                                <img alt="" src={log} style={{ marginLeft: 10 }} />
                                {!disabled ? <span style={{ color: "red", marginLeft: 10, fontWeight: 700 }}>Capture Disabled</span> : <span style={{ color: "green", marginLeft: 10, fontWeight: 700 }}>Capture Enabled</span>}
                            </div>
                            <div>
                            </div>
                        </div>
                        <span>
                            {
                                enablestring &&
                                <span style={{ textAlign: "start", fontWeight: 700 }}>E-Meter [{emeterIdentifier}] {emeterSessionId ? `has a current Session ID of[${emeterSessionId}]` : 'does not have a current Session ID'}</span>
                            }
                        </span>
                    </div>
                    <div className="col-md-6 px-0 disable-create-log-btn">
                        <div className="pr-2">
                            {
                                enableStatus ?
                                    <button onClick={enableSession} type="button" className="e-meter-btn" style={{ color: "#09851b" }}>Enable Capture</button> :
                                    <button onClick={enableSession} type="button" className="e-meter-btn" style={{ color: "#fa1d17" }}>Disable Capture</button>
                            }
                        </div>
                        <div className="pr-2">
                            <button onClick={() => { setSessionAlert(true) }} type="button" className="e-meter-btn" style={{ color: "#2e3ad1" }}>Create Session</button>
                        </div>
                        <div>
                            <button onClick={() => { setConfirmClearLogs(true); }} type="button" className="e-meter-btn" style={{ color: "#fa1d17" }}>Clear Logs</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="gap_4 item-5">
                        <span style={{ fontWeight: "bold" }}>Session</span>
                        <Select
                            value={sessionid}
                            size={'middle'}
                            onChange={changeDropdown1}
                            className="e-meter-session-select"
                            dropdownStyle={{ zIndex: 900 }}

                        >
                            {sessions && sessions?.map((item: any, index: any) => {
                                return <Select.Option name={item?.emeterSessionId ?? ""} value={item?.emeterSessionId ?? ""} key={index}>{item?.sessionDescription ?? ""}</Select.Option>
                            })}
                        </Select>
                    </div>
                </div>
                <div className="row gap_5">
                    <div className="item-1">
                        <span style={{ fontWeight: "bold" }} className="ml-auto">Session</span>
                        <Select
                            value={deletesession}
                            size={'middle'}
                            onChange={changeDropdown2}
                            className="e-meter-session-select"
                            dropdownStyle={{ zIndex: 900 }}
                        >
                            {sessions && sessions?.map((item: any, index: any) => {
                                return <Select.Option name={item?.emeterSessionId ?? ""} value={item?.emeterSessionId ?? ""} key={index}>{item?.sessionDescription ?? ""}</Select.Option>
                            })}
                        </Select>
                    </div>

                    <div className="item-2">
                        <button onClick={phoneHome} style={{ float: "left" }} type="button" className="btn btn-light sort-btn phoneHome">Phone Home</button>
                        {
                            phonehomeactive &&
                            <div style={{ float: "left", textAlign: "right", color: "blue", padding: 10, fontWeight: 600 }}>Phone Home request sent to EMeter [ {emeterIdentifier} ] at {formattedDate2(new Date())}.</div>
                        }
                    </div>
                    <div className="item-3">
                        <button onClick={() => {
                            if (deletesession) {
                                setOpenAlert(true)
                            } else {
                                setShowAlert(true)
                            }
                        }} type="button" className="btn btn-light sort-btn" style={{ fontWeight: 500 }}>Delete Session</button>
                    </div>
                    {/* </div> */}
                </div>
            </div>
            {showAlert && <div style={{ position: "relative", textAlign: "end", color: "red", fontWeight: 600, right: 190 }}>Session should be selected</div>}
            <div className="main-panel pt-1 position-relative" style={{ top: 0 }}>
                <div className="ChartPanel panelShadow bg-white">
                    <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                        <div className='table_title'>E-Meter Log Report</div>
                        <div className='show-entries'>
                            <ShowEntries getPageSize={onSelect} />
                        </div>
                    </div>
                    <div id="table-container">
                        <div style={{ height: tableHeight + 'px' }} className="emeter_report overflow-auto scrollbar">
                            <table className="billable width100">
                                <thead className='tableHeader'>
                                    <tr className="billable-head">
                                        {column?.[0]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[0]?.['columnName']}</span>
                                                {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[1]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[1]?.['columnName']}</span>
                                                {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[2]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('Emetersession')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[2]?.['columnName']}</span>
                                                {sort.Emetersession ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[3]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('lognumber')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[3]?.['columnName']}</span>
                                                {sort.lognumber ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[4]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('timestamp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[4]?.['columnName']}</span>
                                                {sort.timestamp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[5]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('current')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[5]?.['columnName']}</span>
                                                {sort.current ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[6]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('voltage')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[6]?.['columnName']}</span>
                                                {sort.voltage ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[7]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('chargeahrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[7]?.['columnName']}</span>
                                                {sort.chargeahrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[8]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('chargewhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[8]?.['columnName']}</span>
                                                {sort.chargewhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[9]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('dischargeahrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[9]?.['columnName']}</span>
                                                {sort.dischargeahrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[10]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('dischargewhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[10]?.['columnName']}</span>
                                                {sort.dischargewhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[11]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('batterysim')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[11]?.['columnName']}</span>
                                                {sort.batterysim ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[12]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('temperature')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[12]?.['columnName']}</span>
                                                {sort.temperature ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[13]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('csq')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[13]?.['columnName']}</span>
                                                {sort.csq ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableReport?.map((row: any, index: any) => {
                                            const { serialNumber, dateEntered, timestamp, logNumber, current, voltage, chargeAH, chargeWH, dischargeAH,
                                                dischargeWH, formatTemperature, csq, formatVoltage, batteryRxSimNum, emeterSessionId, batteryId, vehicleId, formatTimestamp } = row
                                            return (
                                                <React.Fragment key={index - 1}>
                                                    <tr>
                                                        {column?.[0]?.['enabled'] ? <td>{batteryId}</td> : ""}
                                                        {column?.[1]?.['enabled'] ? <td>{vehicleId}</td> : ""}
                                                        {column?.[2]?.['enabled'] ? <td>{emeterSessionId}</td> : ""}
                                                        {column?.[3]?.['enabled'] ? <td>{logNumber}</td> : ""}
                                                        {column?.[4]?.['enabled'] ? <td>{formatTimestamp}</td> : ""}
                                                        {column?.[5]?.['enabled'] ? <td>{current}</td> : ""}
                                                        {column?.[6]?.['enabled'] ? <td>{formatVoltage}</td> : ""}
                                                        {column?.[7]?.['enabled'] ? <td>{chargeAH}</td> : ""}
                                                        {column?.[8]?.['enabled'] ? <td>{chargeWH}</td> : ""}
                                                        {column?.[9]?.['enabled'] ? <td>{dischargeAH}</td> : ""}
                                                        {column?.[10]?.['enabled'] ? <td>{dischargeWH}</td> : ""}
                                                        {column?.[11]?.['enabled'] ? <td>{serialNumber}</td> : ""}
                                                        {column?.[12]?.['enabled'] ? <td>{formatTemperature}</td> : ""}
                                                        {column?.[13]?.['enabled'] ? <td>{csq}</td> : ""}
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                        <div ref={paginationRef}>
                            <PaginationComponent
                                handlePagination={handlePagination}
                                totalLength={count}
                                pageSize={pageSize}
                                current={current}
                            />
                        </div>
                    </div>

                </div>
                <div ref={selectorRef} style={{ top: '44px' }} className="column-selector selector ">
                    <div className="column-title">Column Selector</div>
                    <div className="slector-body">
                        <label>
                            <input
                                checked={clearAll}
                                value="clearAll"
                                type="checkbox"
                                aria-label="checkbox"
                                onChange={clearall}
                            />
                            <span></span>
                        </label>
                        <p>Clear All</p>

                        <label>
                            <input
                                checked={selectAll}
                                value="selectAll"
                                type="checkbox"
                                aria-label="checkbox"
                                onChange={selectall}
                            />
                            <span></span>
                        </label>
                        <p>Select All</p>
                    </div>
                    <div style={{ height: tableHeight - 126 + 'px' }} className="selector-body-height scrollbar">
                        {selectorLabel?.map((item: any, index: any) => {
                            return (
                                <div key={index + 2} className="slector-body">
                                    <label>
                                        <input
                                            key={index}
                                            name={index}
                                            onChange={onchange}
                                            checked={item['enabled']}
                                            value={item.value}
                                            type="checkbox"
                                            aria-label="checkbox"
                                        />
                                        <span></span>
                                    </label>
                                    <p>{item['columnName']}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className="m-flex">
                        <button onClick={cancelSelection} className="cancel">
                            Cancel
                        </button>
                        <button onClick={applyChanges} className="apply-changes mr-3">
                            Apply Changes
                        </button>
                    </div>
                </div>
            </div>
            <ConfigProvider
                theme={{ token: { colorPrimary: '#004f9f' } }}>
                <Modal
                    footer={[
                        <Button key="back" onClick={() => { setShowEmeter(false); setIsCreateSession(false); setSession(""); }}>Cancel</Button>,
                        <Button key="submit" style={{ background: '#004f9f', color: "white" }} onClick={createSession}>OK</Button>
                    ]}
                    onCancel={() => { setShowEmeter(false); setIsCreateSession(false); setSession(""); }}
                    title="Enter new E-Meter Session ID"
                    open={isCreateSession}
                >
                    <Form className='user-form' style={{ padding: "0", marginLeft: "15px" }} layout='vertical'>
                        <div className="d-flex current-date mt-2 pt-2 pb-2" style={{ justifyContent: "flex-start" }}>
                            <div>E-Meter Identifier</div>
                            <div style={{ marginLeft: 50 }}>{emeterIdentifier}</div>
                        </div>
                        <div className="d-flex current-date mt-2 pt-2 pb-2" style={{ justifyContent: "flex-start" }}>
                            <div>Battery RX SIM #</div>
                            <div style={{ marginLeft: 54 }}>{serial}</div>
                        </div>
                        <div className="d-flex current-date mt-2 pt-2 pb-2" style={{ justifyContent: "flex-start" }}>
                            <div>E-Meter Session ID</div>
                            <div style={{ marginLeft: 40 }}>
                                <Input style={{ width: 230 }} onChange={(event) => { setShowEmeter(false); setSession(event.target.value) }} size="small" value={session} />
                            </div>
                        </div>
                        {showEmeter && <div style={{ color: "red", marginLeft: 156 }}>{sessionErr}</div>}
                    </Form>
                </Modal>
            </ConfigProvider>
            <ConfigProvider theme={{ token: { colorPrimary: '#004f9f' } }}>
                <Modal
                    footer={[
                        <Button key="back" onClick={() => { setOpenAlert(false) }}>NO</Button>,
                        <Button key="submit" style={{ background: '#004f9f', color: "white" }} onClick={deleteSession}>YES</Button>
                    ]}
                    onCancel={() => { setOpenAlert(false) }}
                    open={openalert}
                >
                    <Form className='user-form' style={{ padding: "0", marginLeft: "15px" }} layout='vertical'>
                        <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                            <div>Are you sure you want to delete all logs for Session [ {deletesession} ]?</div>
                        </div>
                    </Form>
                </Modal>
            </ConfigProvider>
            <ConfigProvider theme={{ token: { colorPrimary: '#004f9f' } }}>
                <Modal
                    footer={[
                        <Button key="submit" style={{ background: '#004f9f', color: "white" }} onClick={() => { window.location.reload() }}>OK</Button>
                    ]}
                    onCancel={() => { setCreateSessionAlert(false) }}
                    open={createSessionAlert}
                >
                    <Form className='user-form' style={{ padding: "0", marginLeft: "15px" }} layout='vertical'>
                        <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                            <div>New Session Created Successfully</div>
                        </div>
                    </Form>
                </Modal>
            </ConfigProvider>
            <ConfigProvider theme={{ token: { colorPrimary: '#004f9f' } }}>
                <Modal
                    footer={[
                        <Button key="back" onClick={() => { setSessionAlert(false) }}>NO</Button>,
                        <Button key="submit" style={{ background: '#004f9f', color: "white" }} onClick={() => { setSessionAlert(false); setIsCreateSession(true) }}>YES</Button>
                    ]}
                    onCancel={() => { setSessionAlert(false) }}
                    open={sessionAlert}
                >
                    <Form className='user-form' style={{ padding: "0", marginLeft: "15px" }} layout='vertical'>
                        <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                            <div>Are you sure you want to create a new Session for this E-Meter?</div>
                        </div>
                    </Form>
                </Modal>
            </ConfigProvider>
            <ConfigProvider theme={{ token: { colorPrimary: '#004f9f' } }}>
                <Modal
                    footer={[<Button key="submit" style={{ background: '#004f9f', color: "white" }} onClick={() => { setSuccessAlert(false); window.location.reload() }}>OK</Button>]}
                    onCancel={() => { setSuccessAlert(false); window.location.reload() }}
                    title="Success"
                    centered
                    open={successAlert}
                >
                    <Form className='user-form' style={{ padding: "0", marginLeft: "15px" }} layout='vertical'>
                        <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                            <div>Session deleted successfully</div>
                        </div>
                    </Form>
                </Modal>
            </ConfigProvider>

            <ConfigProvider theme={{ token: { colorPrimary: '#004f9f' } }}>
                <Modal
                    footer={[
                        <Button key="submit" style={{ background: '#004f9f', color: "white" }} onClick={() => { setConfirmClearLogs(false); setCreateSessionClearLogs(true); }}>YES</Button>,
                        <Button key="submit" style={{ background: '#004f9f', color: "white" }} onClick={() => { setConfirmClearLogs(false); setCreateSessionClearLogs(false); }}>NO</Button>
                    ]}
                    onCancel={() => { setConfirmClearLogs(false); setCreateSessionClearLogs(false); }}
                    centered
                    open={confirmClearLogs}
                >
                    <Form className='user-form' style={{ padding: "0", marginLeft: "15px" }} layout='vertical'>
                        <div className="d-flex justify-content-between current-date mt-2 pt-2 pb-2">
                            <div>Are you sure you want to clear all Logs for this E-Meter?</div>
                        </div>
                    </Form>
                </Modal>
            </ConfigProvider>

            <ConfigProvider
                theme={{ token: { colorPrimary: '#004f9f' } }}>
                <Modal
                    footer={[
                        <Button key="back" onClick={() => { setShowEmeter1(false); setCreateSessionClearLogs(false); setSession1(""); }}>Cancel</Button>,
                        <Button key="submit" style={{ background: '#004f9f', color: "white" }} onClick={() => { createLogsSession(); }}>OK</Button>
                    ]}
                    onCancel={() => { setShowEmeter1(false); setCreateSessionClearLogs(false); setSession1(""); }}
                    title="Enter new E-Meter Session ID"
                    open={createSessionClearLogs}
                >
                    <Form className='user-form' style={{ padding: "0", marginLeft: "15px" }} layout='vertical'>
                        <div className="d-flex current-date mt-2 pt-2 pb-2" style={{ justifyContent: "flex-start" }}>
                            <div>E-Meter Identifier</div>
                            <div style={{ marginLeft: 50 }}>{emeterIdentifier}</div>
                        </div>
                        <div className="d-flex current-date mt-2 pt-2 pb-2" style={{ justifyContent: "flex-start" }}>
                            <div>Battery RX SIM #</div>
                            <div style={{ marginLeft: 54 }}>{serial}</div>
                        </div>
                        <div className="d-flex current-date mt-2 pt-2 pb-2" style={{ justifyContent: "flex-start" }}>
                            <div>E-Meter Session ID</div>
                            <div style={{ marginLeft: 40 }}>
                                <Input style={{ width: 230 }} onChange={(event) => { setShowEmeter1(false); setSession1(event.target.value) }} size="small" value={session1} />
                            </div>
                        </div>
                        {showEmeter1 && <div style={{ color: "red", marginLeft: 156 }}>{sessionErr1}</div>}
                    </Form>
                </Modal>
            </ConfigProvider>
            <Modal
                open={alert}
                closable={false}
                centered title={"Atleast Select 1 Column"}
                footer={[
                    <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
                ]}
            ></Modal>
            <SessionWarning />
        </>
    )
}
export default EMeterLogReport