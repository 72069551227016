import React, { FC, useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Popover } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import Apply from '../reusableComponent/apply';
import DownloadIcon from '../../images/Download.png';
import emailICon from '../../images/EmailIcon.svg';

import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import * as roles from '../../settings/roles';
import service from '../../service/api/common';

interface props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    setGlobalData: (key: string, value: any) => void,
    setAction: (value: any) => void,
    doRefresh: (value: number) => void,
    sendEmail: () => void,
    dealer: string,
    company: string,
    loc: string,
    site: string
}

const CommonHeader: FC<props> = ({ setGlobalData, setAction, doRefresh, sendEmail, dealer, company, loc, site }) => {
    const [dowOption, setDowOption] = useState<boolean>(false)
    const dealerFilter = [
        "/admindashboard",
        "/dealerdashboard",
        "/companydashboard",
        "/locationdashboard",
        "/sitedashboard",
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/issues-report",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/issues-report2",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/shift-schedules",
        "/configurationlogreport",
        "/emeter-log-report",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/usermaintenance",
        "/locationmaintenance",
        "/sitemaintenance",
        "/organizationmaintenance",
        "/divisionmaintenance",
        "/soc-report",
        "/temperature-report",
        "/operationStatus",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/battery-rx-test-page",
        "/firmcontrolpanel"
    ]
    const companyFilter = [
        "/admindashboard",
        "/dealerdashboard",
        "/companydashboard",
        "/locationdashboard",
        "/sitedashboard",
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/issues-report",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/issues-report2",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/shift-schedules",
        "/configurationlogreport",
        "/emeter-log-report",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/usermaintenance",
        "/locationmaintenance",
        "/sitemaintenance",
        "/soc-report",
        "/temperature-report",
        "/operationStatus",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/battery-rx-test-page",
        "/firmcontrolpanel"
    ]
    const locationFilter = [
        "/admindashboard",
        "/dealerdashboard",
        "/companydashboard",
        "/locationdashboard",
        "/sitedashboard",
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/issues-report",
        "/issues-report2",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/shift-schedules",
        "/emeter-log-report",
        "/configurationlogreport",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/sitemaintenance",
        "/usermaintenance",
        "/locationmaintenance",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/soc-report",
        "/temperature-report",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/battery-rx-test-page",
        "/firmcontrolpanel"
    ]
    const siteFilter = [
        "/admindashboard",
        "/dealerdashboard",
        "/companydashboard",
        "/locationdashboard",
        "/sitedashboard",
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/issues-report",
        "/issues-report2",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/shift-schedules",
        "/emeter-log-report",
        "/configurationlogreport",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/usermaintenance",
        "/sitemaintenance",
        "/shift-schedules",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/locationmaintenance",
        "/soc-report",
        "/temperature-report",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/battery-rx-test-page",
        "/firmcontrolpanel"
    ]
    const batteryFilter = [
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/configurationlogreport",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/temperature-report",
        "/soc-report",
        "/battery-rx-test-page"
    ]
    const vehicleFilter = [
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/configurationlogreport",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/temperature-report",
        "/soc-report",
        "/battery-rx-test-page"
    ]
    const serialFilter = [
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/battery-statistics",
        "/dailyBatteryPerformanceReport",
        "/battery-rx-test-page"
    ]
    const apply = [
        "/sitemaintenance",
        "/usermaintenance",
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/eq-log-report",
        "/issues-report",
        "/billing-summary-report",
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/issues-report2",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/emeter-log-report",
        "/configurationlogreport",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/shift-schedules",
        "/firmhistoryreport",
        "/dailyBatteryPerformanceReport",
        "/locationmaintenance",
        "/battery-statistics",
        "/organizationmaintenance",
        "/divisionmaintenance",
        "/soc-report",
        "/temperature-report",
        "/operationStatus",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/battery-rx-test-page",
        "/firmcontrolpanel"
    ];
    const EmailFilter = [
        "/issues-report2",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp"
    ];
    const DownloadTableFilter = [
        "/bmidInventory",
        "/charge-log-report",
        "/battert-history-log-report",
        "/emeter-log-report",
        "/eq-log-report",
        "/issues-report",
        "/billing-summary-report",
        "/issues-report2",
        "/battert-history-report",
        "/life-time-battery-history",
        "/chargeSummary",
        "/configurationsummaryreport",
        "/equalizationSummary",
        "/battery-rx-units",
        "/smsreport",
        "/firmhistoryreport",
        "/firmcontrolpanel",
        "/configurationlogreport",
        "/dailyBatteryPerformanceReport",
        "/battery-statistics",
        "/operationStatus",
        "/operationStatusLowWater",
        "/operationStatusEqOverdue",
        "/operationStatusAhrsCharged",
        "/operationStatusHighTemp",
        "/firmcontrolpanel",
        "/firmware-update-report"
    ];
    const DownloadGraphFilter = [
        "/battery-voltage-report",
        "/battery-temperature-report",
        "/daily-usage-and-plug-in-report",
        "/plug-in-compliance-report",
        "/warranty-by-time-report",
        "/remaining-life-report",
        "/charge-current-over-time-report",
        "/consumption-by-ahrs-report",
        "/consumption-by-kwhrs-report",
        "/equivalent-battery-report",
        "/runtime-vs-ah-report",
        "/charge-ideal-use-report",
        "/temperature-report",
        "/soc-report"
    ];
    const dealerRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER
    ];
    const companyRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER
    ];
    const locationRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER,
        roles.COMPANY_ADMIN,
        roles.COMPANY_MANAGER
    ];
    const siteRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER,
        roles.COMPANY_ADMIN,
        roles.COMPANY_MANAGER,
        roles.LOCATION_ADMIN,
        roles.LOCATION_MANAGER
    ]
    const batteryRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER,
        roles.COMPANY_ADMIN,
        roles.COMPANY_MANAGER,
        roles.LOCATION_ADMIN,
        roles.LOCATION_MANAGER,
        roles.SITE_ADMIN,
        roles.SITE_MANAGER
    ]
    const vehicleRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER,
        roles.COMPANY_ADMIN,
        roles.COMPANY_MANAGER,
        roles.LOCATION_ADMIN,
        roles.LOCATION_MANAGER,
        roles.SITE_ADMIN,
        roles.SITE_MANAGER
    ]
    const serialRoles = [
        roles.PLATFORM_ADMIN,
        roles.PLATFORM_MANAGER,
        roles.DEALER_ADMIN,
        roles.DEALER_MANAGER,
        roles.COMPANY_ADMIN,
        roles.COMPANY_MANAGER,
        roles.LOCATION_ADMIN,
        roles.LOCATION_MANAGER,
        roles.SITE_ADMIN,
        roles.SITE_MANAGER
    ]

    //dropdowns list
    const [orgsList, setOrgsList] = useState<any[]>([]);
    const [divisionsList, setDivisionsList] = useState<any[]>([]);
    const [locationsList, setLocationsList] = useState<any[]>([]);
    const [sitesList, setSitesList] = useState<any[]>([]);
    const [batteriesList, setBatteriesList] = useState<any[]>([]);
    const [vehiclesList, setVehiclesList] = useState<any[]>([]);
    const [serialNumbers, setSerialNumbers] = useState<any[]>([]);

    //selected dropdown ids
    const [orgId, setOrgId] = useState<number>(-1);
    const [divisionId, setDivisionId] = useState<number>(-1);
    const [locationid, setLocationId] = useState<number>(-1);
    const [buildingid, setBuildingId] = useState<number>(-1);
    const [batteryid, setBatteryId] = useState<string>("");
    const [vehicleid, setVehicleId] = useState<string>("");
    const [serialnumber, setSerialNumber] = useState<string>("");

    //selected dropdown labels
    const [orgname, setOrgName] = useState<string>("All");
    const [divisionname, setDivisionName] = useState<string>("All");
    const [locationname, setLocationName] = useState<string>("All");
    const [buildingname, setBuildingName] = useState<string>("All");
    const [batteryname, setBatteryName] = useState<string>("All");
    const [vehiclename, setVehicleName] = useState<string>("All");
    const [serialname, setSerialName] = useState<string>("All");

    const currentPage = useLocation();

    useEffect(() => {
        var userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;

        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        if (roles.PLATFORM_ADMIN === localStorage?.UserType || roles.PLATFORM_MANAGER === localStorage?.UserType) {
            data?.orgId > 0 ? setOrgId(data?.orgId) : setOrgId(-1);
            data?.orgName !== "" ? setOrgName(data?.orgName) : setOrgName("All");
            data?.divId > 0 ? setDivisionId(data?.divId) : setDivisionId(-1);
            data?.divName !== "" ? setDivisionName(data?.divName) : setDivisionName("All");
            data?.locationId > 0 ? setLocationId(data?.locationId) : setLocationId(-1);
            data?.locationName !== "" ? setLocationName(data?.locationName) : setLocationName("All");
            data?.buildingId > 0 ? setBuildingId(data?.buildingId) : setBuildingId(-1);
            data?.buildingName !== "" ? setBuildingName(data?.buildingName) : setBuildingName("All");
            data?.batteryId !== "" ? setBatteryId(data?.batteryId) : setBatteryId("");
            data?.batteryName !== "" ? setBatteryName(data?.batteryName) : setBatteryName("All");
            data?.vehicleId !== "" ? setVehicleId(data?.vehicleId) : setVehicleId("");
            data?.vehicleName !== "" ? setVehicleName(data?.vehicleName) : setVehicleName("All");
            data?.serialId !== "" ? setSerialNumber(data?.serialId) : setSerialNumber("");
            data?.serialName !== "" ? setSerialName(data?.serialName) : setSerialName("All");

            getDealers(0)
        } else if (roles.DEALER_ADMIN === localStorage?.UserType || roles.DEALER_MANAGER === localStorage?.UserType) {
            setOrgId(loginDealerId);
            setOrgName(localStorage?.accountName);

            data?.divId > 0 ? setDivisionId(data?.divId) : setDivisionId(-1);
            data?.divName !== "" ? setDivisionName(data?.divName) : setDivisionName("All");
            data?.locationId > 0 ? setLocationId(data?.locationId) : setLocationId(-1);
            data?.locationName !== "" ? setLocationName(data?.locationName) : setLocationName("All");
            data?.buildingId > 0 ? setBuildingId(data?.buildingId) : setBuildingId(-1);
            data?.buildingName !== "" ? setBuildingName(data?.buildingName) : setBuildingName("All");
            data?.batteryId !== "" ? setBatteryId(data?.batteryId) : setBatteryId("");
            data?.batteryName !== "" ? setBatteryName(data?.batteryName) : setBatteryName("All");
            data?.vehicleId !== "" ? setVehicleId(data?.vehicleId) : setVehicleId("");
            data?.vehicleName !== "" ? setVehicleName(data?.vehicleName) : setVehicleName("All");
            data?.serialId !== "" ? setSerialNumber(data?.serialId) : setSerialNumber("");
            data?.serialName !== "" ? setSerialName(data?.serialName) : setSerialName("All");

            getDealers(loginDealerId)
            // getCompanies(loginDealerId);
        } else if (roles.COMPANY_ADMIN === localStorage?.UserType || roles.COMPANY_MANAGER === localStorage?.UserType) {
            setOrgId(loginDealerId);
            setOrgName(localStorage?.accountName);

            data?.divId > 0 ? setDivisionId(data?.divId) : setDivisionId(-1);
            data?.divName !== "" ? setDivisionName(data?.divName) : setDivisionName("All");
            data?.locationId > 0 ? setLocationId(data?.locationId) : setLocationId(-1);
            data?.locationName !== "" ? setLocationName(data?.locationName) : setLocationName("All");
            data?.buildingId > 0 ? setBuildingId(data?.buildingId) : setBuildingId(-1);
            data?.buildingName !== "" ? setBuildingName(data?.buildingName) : setBuildingName("All");
            data?.batteryId !== "" ? setBatteryId(data?.batteryId) : setBatteryId("");
            data?.batteryName !== "" ? setBatteryName(data?.batteryName) : setBatteryName("All");
            data?.vehicleId !== "" ? setVehicleId(data?.vehicleId) : setVehicleId("");
            data?.vehicleName !== "" ? setVehicleName(data?.vehicleName) : setVehicleName("All");
            data?.serialId !== "" ? setSerialNumber(data?.serialId) : setSerialNumber("");
            data?.serialName !== "" ? setSerialName(data?.serialName) : setSerialName("All");

            getLocations(loginDealerId, data?.divId);
            getSites(loginDealerId, data?.divId, data?.locationId,data?.buildingId);
            getBatteries(loginDealerId, data?.divId, data?.locationId);
            getVehicles(loginDealerId, data?.divId, data?.locationId, data?.batteryId);
            getSerialNumbers(loginDealerId, data?.divId, data?.locationId, data?.batteryId, data?.vehicleId);
        } else if (roles.LOCATION_ADMIN === localStorage?.UserType || roles.LOCATION_MANAGER === localStorage?.UserType) {
            setOrgId(loginDealerId);
            setOrgName(localStorage?.accountName);

            data?.divId > 0 ? setDivisionId(data?.divId) : setDivisionId(-1);
            data?.divName !== "" ? setDivisionName(data?.divName) : setDivisionName("All");
            data?.locationId > -1 ? setLocationId(data?.locationId) : setLocationId(-1);
            data?.locationName !== "" ? setLocationName(data?.locationName) : setLocationName("All");
            data?.buildingId > -1 ? setBuildingId(data?.buildingId) : setBuildingId(-1);
            data?.buildingName !== "" ? setBuildingName(data?.buildingName) : setBuildingName("All");
            data?.batteryId !== "" ? setBatteryId(data?.batteryId) : setBatteryId("");
            data?.batteryName !== "" ? setBatteryName(data?.batteryName) : setBatteryName("All");
            data?.vehicleId !== "" ? setVehicleId(data?.vehicleId) : setVehicleId("");
            data?.vehicleName !== "" ? setVehicleName(data?.vehicleName) : setVehicleName("All");
            data?.serialId !== "" ? setSerialNumber(data?.serialId) : setSerialNumber("");
            data?.serialName !== "" ? setSerialName(data?.serialName) : setSerialName("All");

            getLocations(loginDealerId, data?.divId);
        } else if (roles.SITE_ADMIN === localStorage?.UserType || roles.SITE_MANAGER === localStorage?.UserType) {
            setOrgId(loginDealerId);
            setOrgName(localStorage?.accountName);

            data?.divId > 0 ? setDivisionId(data?.divId) : setDivisionId(-1);
            data?.divName !== "" ? setDivisionName(data?.divName) : setDivisionName("All");
            data?.locationId > -1 ? setLocationId(data?.locationId) : setLocationId(-1);
            data?.locationName !== "" ? setLocationName(data?.locationName) : setLocationName("All");
            data?.buildingId > -1 ? setBuildingId(data?.buildingId) : setBuildingId(-1);
            data?.buildingName !== "" ? setBuildingName(data?.buildingName) : setBuildingName("All");
            data?.batteryId !== "" ? setBatteryId(data?.batteryId) : setBatteryId("");
            data?.batteryName !== "" ? setBatteryName(data?.batteryName) : setBatteryName("All");
            data?.vehicleId !== "" ? setVehicleId(data?.vehicleId) : setVehicleId("");
            data?.vehicleName !== "" ? setVehicleName(data?.vehicleName) : setVehicleName("All");
            data?.serialId !== "" ? setSerialNumber(data?.serialId) : setSerialNumber("");
            data?.serialName !== "" ? setSerialName(data?.serialName) : setSerialName("All");

            getSites(loginDealerId, data?.divId, data?.locationId,data?.buildingId);
            getBatteries(loginDealerId, data?.divId, data?.locationId);
            getVehicles(loginDealerId, data?.divId, data?.locationId, data?.batteryId);
            getSerialNumbers(loginDealerId, data?.divId, data?.locationId, data?.batteryId, data?.vehicleId);
        }
    }, [])

    useEffect(() => {
        if (dealer !== "") { setOrgName(dealer) }
    }, [dealer])

    useEffect(() => {
        if (company !== "") { setDivisionName(company) }
    }, [company])

    useEffect(() => {
        if (loc !== "") { setLocationName(loc) }
    }, [loc])

    useEffect(() => {
        if (site !== "") { setBuildingName(site) }
    }, [site])

    const getDealers = async (orgid: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = {
            "orgId": orgid,
            "divisionId": 0,
            "locationId": 0,
            "buildingId": 0
        }

        try {
            const response = await service({
                url: constantUrl.api.dashboard.getOrganizations,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });

            setOrgsList(response);

            if (response.length > 0) {
                if (data?.orgId === -1 || data?.orgId === 0) {
                    setOrgId(response[0]['orgId']);
                    setOrgName(response[0]['orgName']);

                    getCompanies(response[0]['orgId'])
                    getLocations(response[0]['orgId'], -1)
                    getSites(response[0]['orgId'], -1, -1,-1)
                    getBatteries(response[0]['orgId'], -1, -1)
                    getVehicles(response[0]['orgId'], -1, -1, "")
                    getSerialNumbers(response[0]['orgId'], -1, -1, "", "")

                    data["orgName"] = response[0]['orgName'];
                    sessionStorage.setItem("globalData", JSON.stringify(data));
                    setGlobalData("orgId", response[0]['orgId']);
                } else if (data?.orgId > 0) {
                    setOrgId(data?.orgId);
                    setOrgName(data?.orgName);

                    getCompanies(data?.orgId)
                    getLocations(data?.orgId, data?.divId)
                    getSites(data?.orgId, data?.divId, data?.locationId, data?.buildingId)
                    getBatteries(data?.orgId, data?.divId, data?.locationId)
                    getVehicles(data?.orgId, data?.divId, data?.locationId, data?.batteryId)
                    getSerialNumbers(data?.orgId, data?.divId, data?.locationId, data?.batteryId, data?.vehicleId)
                }
            }
        } catch (error) {
            console.log("getCompaniesError:", error)
        }
    }

    const handleDealer = (item: any) => {
        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        setOrgId(item?.orgId);
        setOrgName(item?.orgName);
        setDivisionId(-1);
        setDivisionName("All");
        setLocationId(-1);
        setLocationName("All");
        setBuildingId(-1);
        setBuildingName("All");
        setBatteryId("");
        setBatteryName("All");
        setVehicleId("");
        setVehicleName("All");
        setSerialNumber("");
        setSerialName("All");

        getCompanies(item?.orgId)
        if (roles.DEALER_ADMIN === localStorage?.UserType || roles.DEALER_MANAGER === localStorage?.UserType)  {
            getLocations(item?.orgId, -1,-1)
            getSites(item?.orgId, -1, -1, -1)
            getBatteries(item?.orgId, -1, -1);
            getVehicles(item?.orgId, -1, -1, "");
            getSerialNumbers(item?.orgId, -1, -1, "", "")
        } else {
            getLocations(item?.orgId, -1,-1)
            getSites(item?.orgId, -1, -1, -1)
            getBatteries(item?.orgId, -1, -1);
            getVehicles(item?.orgId, -1, -1, "");
            getSerialNumbers(item?.orgId, -1, -1, "", "")
        }

        if (currentPage?.pathname == "/admindashboard" ||
            currentPage?.pathname == "/dealerdashboard" ||
            currentPage?.pathname == "/companydashboard" ||
            currentPage?.pathname == "/locationdashboard" ||
            currentPage?.pathname == "/sitedashboard") {
            data["orgName"] = item?.orgName;
            data["divId"] = -1;
            data["divName"] = "All";
            data["locationId"] = -1;
            data["locationName"] = "All";
            data["buildingId"] = -1;
            data["buildingName"] = "All";
            data["batteryId"] = "";
            data["batteryName"] = "All";
            data["vehicleId"] = "";
            data["vehicleName"] = "All";
            data["serialId"] = "";
            data["serialName"] = "All";
            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("orgId", item?.orgId);
        }
    }

    const getCompanies = async (orgid: number) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        var url = constantUrl.api.location.getDivisions;
        let payload = { orgId: orgid };

        if (url) {
            try {
                const response = await service({
                    url: url,
                    method: "POST",
                    headers: { accept: "*/*", Authorization: "Bearer " + token },
                    data: payload
                });

                if (response?.length > 0) {
                    let companies: any = [{ "divisionId": -1, "divisionName": "All" }, ...response];
                    setDivisionsList(companies);
                } else {
                    setDivisionsList(response);
                }
            }
            catch (error) {
                console.log("getDivisionsError:", error)
            }
        }
    }

    const handleCompany = (item: any) => {
        var userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;

        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let divid = item?.divisionId;
        let divName = item?.divisionName;
        let org_id = orgId > -1 ? orgId : data?.orgId > -1 ? data?.orgId : loginDealerId;

        setDivisionId(divid);
        setDivisionName(divName);
        setLocationId(-1);
        setLocationName("All");
        setBuildingId(-1);
        setBuildingName("All");
        setBatteryId("");
        setBatteryName("All");
        setVehicleId("");
        setVehicleName("All");
        setSerialNumber("");
        setSerialName("All");

        getLocations(org_id, divid)
        getSites(org_id, divid, -1, -1)
        getBatteries(org_id, divid, -1)
        getVehicles(org_id, divid, -1, "")
        getSerialNumbers(org_id, divid, -1, "", "")

        if (currentPage?.pathname == "/admindashboard" ||
            currentPage?.pathname == "/dealerdashboard" ||
            currentPage?.pathname == "/companydashboard" ||
            currentPage?.pathname == "/locationdashboard" ||
            currentPage?.pathname == "/sitedashboard") {
            data["divName"] = divName;
            data["locationId"] = -1;
            data["locationName"] = "All";
            data["buildingId"] = -1;
            data["buildingName"] = "All";
            data["batteryId"] = "";
            data["batteryName"] = "All";
            data["vehicleId"] = "";
            data["vehicleName"] = "All";
            data["serialId"] = "";
            data["serialName"] = "All";
            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("divId", divid)
        }
    }

    const getLocations = async (orgId: any, divId: any, locId?: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;
        let loginDealerId = userInfo.userId;

        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let locationId = (locId === -1) ? locId : data?.locationId

        let url = constantUrl.api.dashboard.getLocations;
        let payload = {
            "orgId": orgId,
            "divisionId": divId,
            "locationId": locationId,
            "buildingId": data?.buildingId
        }

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { Authorization: 'Bearer ' + token },
                data: payload
            })
            if (response?.length > 0) {
                let locations: any = [{ "locId": -1, "locationName": "All" }, ...response];
                setLocationsList(locations);

                if (roles.LOCATION_ADMIN === localStorage?.UserType || roles.LOCATION_MANAGER === localStorage?.UserType) {
                    let locid = data?.locationId > 0 ? data?.locationId : locations[1]?.locId;
                    let locname = data?.locationName !== "" ? data?.locationName : locations[1]?.locationName;

                    getSites(loginDealerId, divId, locid,buildingid);
                    getBatteries(loginDealerId, divId, locid);
                    getVehicles(loginDealerId, divId, locid, data?.batteryId);
                    getSerialNumbers(loginDealerId, divId, locid, data?.batteryId, data?.vehicleId);

                    setLocationId(locid);
                    setLocationName(locname);
                    data["locationName"] = locname;
                    sessionStorage.setItem("globalData", JSON.stringify(data));
                    setGlobalData("locationId", locid);
                }
            } else {
                setLocationsList([])
            }
        } catch (error) {
            console.error('locationList:', error)
        }
    }

    const handleLocation = (item: any) => {
        var userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;

        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let orgid = orgId > 0 ? orgId : data?.orgId > -1 ? data?.orgId : loginDealerId;

        setLocationId(item?.locId);
        setLocationName(item?.locationName);
        setBuildingId(-1);
        setBuildingName("All");
        setBatteryId("");
        setBatteryName("All");
        setVehicleId("");
        setVehicleName("All");
        setSerialNumber("");
        setSerialName("All");

        getSites(orgid, data?.divId, item?.locId,item?.buildingid)
        getBatteries(orgid, data?.divId, item?.locId)
        getVehicles(orgid, data?.divId, item?.locId, "")
        getSerialNumbers(orgid, data?.divId, item?.locId, "", "")

        if (currentPage?.pathname == "/admindashboard" ||
            currentPage?.pathname == "/dealerdashboard" ||
            currentPage?.pathname == "/companydashboard" ||
            currentPage?.pathname == "/locationdashboard" ||
            currentPage?.pathname == "/sitedashboard") {
            data["locationName"] = item?.locationName;
            data["buildingId"] = -1;
            data["buildingName"] = "All";
            data["batteryId"] = "";
            data["batteryName"] = "All";
            data["vehicleId"] = "";
            data["vehicleName"] = "All";
            data["serialId"] = "";
            data["serialName"] = "All";

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("locationId", item?.locId)
        }
    }

    const getSites = async (orgId: any, divId: any, locId: any , buildId :any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let buildingId = (buildId ===-1)?buildId : data?.buildingId

        var data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locId,
            buildingId: buildingId
        }

        let url = constantUrl.api.HeaderFilters.getSites;

        try {
            const response = await service({
                url: url,
                method: "POST",
                headers: { Authorization: 'Bearer ' + token },
                data: payload
            })

            if (response?.length > 0) {
                let buildings: any = [];
                if (localStorage?.UserType === roles.SITE_ADMIN || localStorage?.UserType === roles.SITE_MANAGER) {
                    buildings = [...response];

                    setLocationId(Number(localStorage?.locationId));
                    setLocationName(localStorage?.locationName);
                    setSitesList(buildings);
                    setBuildingName(buildings[0]?.buildingName);
                    setBuildingId(buildings[0]?.id);

                    data["locationId"] = Number(localStorage?.locationId);
                    data["locationName"] = localStorage?.locationName;
                    sessionStorage.setItem("globalData", JSON.stringify(data))
                    setGlobalData("buildingId", buildings[0]?.id)
                } else {
                    buildings = [{ "id": -1, "buildingName": "All" }, ...response];
                    setSitesList(buildings);
                }
            } else {
                setSitesList([])
            }
        } catch (error) {
            console.error('sitesList:', error)
        }
    }

    const handleSite = (item: any) => {
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        setBuildingId(item?.id);
        setBuildingName(item?.buildingName);
        setBatteryId("");
        setBatteryName("All");
        setVehicleId("");
        setVehicleName("All");
        setSerialNumber("");
        setSerialName("All");

        if (currentPage?.pathname == "/admindashboard" ||
            currentPage?.pathname == "/dealerdashboard" ||
            currentPage?.pathname == "/companydashboard" ||
            currentPage?.pathname == "/locationdashboard" ||
            currentPage?.pathname == "/sitedashboard") {
            data["buildingName"] = item?.buildingName;
            data["batteryId"] = "";
            data["batteryName"] = "All";
            data["vehicleId"] = "";
            data["vehicleName"] = "All";
            data["serialId"] = "";
            data["serialName"] = "All";
            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("buildingId", item?.id)
        }
    }

    const getBatteries = async (orgid: number, divid: number, locid: number) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;
        let payload = {
            "orgId": orgid,
            "divisionId": divid,
            "locationId": locid,
            "batteryId": "",
            "vehicleId": ""
        }
        try {
            const response = await service({
                url: constantUrl.api.HeaderFilters.getBatteries,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token }
            });
            setBatteriesList(response)
        } catch (error) {
            console.log("getBatteriesError:", error)
        }
    }

    const handleBattery = (item: any) => {
        let userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let orgid = orgId > 0 ? orgId : data?.orgId > 0 ? data?.orgId : loginDealerId;
        let locid = locationid > 0 ? locationid : data?.locationId;
        let divid = divisionId > 0 ? divisionId : data?.divId;

        item ? setBatteryId(item) : setBatteryId("")
        item ? setBatteryName(item) : setBatteryName("All")
        getVehicles(orgid, divid, locid, item);
    }

    const getVehicles = async (orgid: number, divid: number, locid: number, bid: string) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let payload = {
            "orgId": orgid,
            "divisionId": divid,
            "locationId": locid,
            "batteryId": bid,
            "vehicleId": ""
        }

        try {
            const response = await service({
                url: constantUrl.api.HeaderFilters.getVehicles,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token }
            });
            const oneIndex=response[0]
            if(bid && response?.length === 1){
                setVehicleId(oneIndex);
                setVehicleName(oneIndex);
                getSerialNumbers(orgid, divid, locid,bid,oneIndex)
            }else{
                setVehicleId("");
                setVehicleName("All");
                getSerialNumbers(orgid, divid, locid,bid,"")
            }
            setVehiclesList(response);
        } catch (error) {
            console.log("getVehiclesError:", error)
        }
    }

    const handleVehicle = (item: any) => {
        setVehicleId(item)
        item ? setVehicleName(item) : setVehicleName("All")

        let userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let orgid = orgId > 0 ? orgId : data?.orgId > 0 ? data?.orgId : loginDealerId;
        let locid = locationid > 0 ? locationid : data?.locationId;
        let divid = divisionId > 0 ? divisionId : data?.divId;
        let batId = batteryid ? batteryid : data['batteryId']
        
        getSerialNumbers(orgid, divid, locid, batId, item)
    }

    const getSerialNumbers = async (orgId: any, divId: any, locationId: any, batteryId: any, vehicleId: any) => {
        let userInfo: any = Utility.getUserInfo();
        let token = userInfo.authToken;

        let payload = {
            orgId: orgId,
            divisionId: divId,
            locationId: locationId,
            batteryId: batteryId,
            vehicleId: vehicleId,
        }

        try {
            const response = await service({
                url: constantUrl.api.HeaderFilters.getSerialNumbers,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token }
            });
            if(batteryId && response.length===1){
                setSerialNumber(response[0]);
                setSerialName(response[0]);
            }else{
                setSerialNumber("");
                setSerialName('All'); 
            }
            setSerialNumbers(response)
        } catch (error) {
            console.log("getSerialNumbersError:", error)
        }
    }

    const handleSerialNumber = (item: any) => {
        setSerialNumber(item);
        item ? setSerialName(item) : setSerialName("All");
    }

    const onApply = () => {
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        if (serialnumber !== data["serialId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationId"] = locationid;
            data["locationName"] = locationname;
            data["buildingId"] = buildingid;
            data["buildingName"] = buildingname;
            data["batteryId"] = batteryid;
            data["batteryName"] = batteryname;
            data["vehicleId"] = vehicleid;
            data["vehicleName"] = vehiclename;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("serialId", serialnumber)
        } else if (vehicleid !== data["vehicleId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationId"] = locationid;
            data["locationName"] = locationname;
            data["buildingId"] = buildingid;
            data["buildingName"] = buildingname;
            data["batteryId"] = batteryid;
            data["batteryName"] = batteryname;
            data["vehicleName"] = vehiclename;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("vehicleId", vehicleid)
        } else if (batteryid !== data["batteryId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationName"] = locationname;
            data["buildingName"] = buildingname;
            data["vehicleName"] = vehiclename;
            data["locationId"] = locationid;
            data["batteryId"] = batteryid;
            data["vehicleId"] = vehicleid;
            data["buildingId"] = buildingid;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("batteryName", batteryname)
        } else if (buildingid !== data["buildingId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationName"] = locationname;
            data["buildingName"] = buildingname;
            data["batteryName"] = batteryname;
            data["vehicleName"] = vehiclename;
            data["locationId"] = locationid;
            data["batteryId"] = batteryid;
            data["vehicleId"] = vehicleid;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("buildingId", buildingid)
        } else if (locationid !== data["locationId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationName"] = locationname;
            data["buildingName"] = buildingname;
            data["batteryName"] = batteryname;
            data["vehicleName"] = vehiclename;
            data["buildingId"] = buildingid;
            data["batteryId"] = batteryid;
            data["vehicleId"] = vehicleid;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("locationId", locationid);
        } else if (divisionId !== data["divId"]) {
            data["orgName"] = orgname;
            data["orgId"] = orgId;
            data["locationId"] = locationid;
            data["divName"] = divisionname;
            data["locationName"] = locationname;
            data["buildingName"] = buildingname;
            data["batteryName"] = batteryname;
            data["vehicleName"] = vehiclename;
            data["buildingId"] = buildingid;
            data["batteryId"] = batteryid;
            data["vehicleId"] = vehicleid;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("divId", divisionId);
        } else if (orgId !== data["orgId"]) {
            data["orgName"] = orgname;
            data["divId"] = divisionId;
            data["divName"] = divisionname;
            data["locationId"] = locationid;
            data["locationName"] = locationname;
            data["buildingId"] = buildingid;
            data["buildingName"] = buildingname;
            data["batteryId"] = batteryid;
            data["batteryName"] = batteryname;
            data["vehicleId"] = vehicleid;
            data["vehicleName"] = vehiclename;
            data["serialId"] = serialnumber;
            data["serialName"] = serialname;

            sessionStorage.setItem("globalData", JSON.stringify(data))
            setGlobalData("orgId", orgId);
        }
    }

    const emailToggle = () => { sendEmail() };

    return (
            <div className="dropList alignChargeLogHeader col-12 px-0 posi_mobile_header">
                {
                    (localStorage?.UserType === roles.COMPANY_ADMIN || localStorage?.UserType === roles.COMPANY_MANAGER) &&
                    <>
                        <div className='width71px'>
                            <span className="pageTitle font-weight-bold mt-1">Dealer:</span>
                            <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.accountName >= 5) ? localStorage?.accountName?.substring(0, 3) + ".." : localStorage?.accountName}</div>
                        </div>

                        <div className='width71px'>
                            <span className="pageTitle font-weight-bold mt-1">Company:</span>
                            <div id="brandName" className="btn-group  brandName pl-2 pb-1">{localStorage?.divisionName}</div>
                        </div>
                    </>
                }
                {
                    (localStorage?.UserType === roles.LOCATION_ADMIN || localStorage?.UserType === roles.LOCATION_MANAGER) &&
                    <>
                        <div className='width71px'>
                            <span className="pageTitle font-weight-bold mt-1">Dealer:</span>
                            <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.accountName >= 5) ? localStorage?.accountName?.substring(0, 3) + ".." : localStorage?.accountName}</div>
                        </div>

                        <div className='width71px'>
                            <span className="pageTitle font-weight-bold mt-1">Company:</span>
                            <div id="brandName" className="btn-group  brandName pl-2 pb-1">{localStorage?.divisionName !== "" ? localStorage?.divisionName : "All"}</div>
                        </div>

                        <div className='width71px'>
                            <span className="pageTitle font-weight-bold mt-1">Location:</span>
                            <div id="brandName" className="btn-group  brandName pl-2 pb-1">{localStorage?.locationName !== "" ? localStorage?.locationName : "All"}</div>
                        </div>
                    </>
                }
                {
                    (localStorage?.UserType === roles.SITE_ADMIN || localStorage?.UserType === roles.SITE_MANAGER) &&
                    <>
                        <div className='width71px'>
                            <span className="pageTitle font-weight-bold mt-1">Dealer:</span>
                            <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.accountName >= 5) ? localStorage?.accountName?.substring(0, 3) + ".." : localStorage?.accountName}</div>
                        </div>

                        <div className='width71px'>
                            <span className="pageTitle font-weight-bold mt-1">Company:</span>
                            <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.divisionName !== "" && localStorage?.divisionName >= 5) ? localStorage?.divisionName?.substring(0, 3) + ".." : localStorage?.divisionName !== "" ? localStorage?.divisionName : "All"}</div>
                        </div>

                        <div className='width71px'>
                            <span className="pageTitle font-weight-bold mt-1">Location:</span>
                            <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.locationName !== "" && localStorage?.locationName >= 5) ? localStorage?.locationName?.substring(0, 3) + ".." : localStorage?.locationName !== "" ? localStorage?.locationName : "All"}</div>
                        </div>

                        <div className='width71px'>
                            <span className="pageTitle font-weight-bold mt-1">Site:</span>
                            <div id="brandName" className="btn-group  brandName pl-2 pb-1">{(localStorage?.buildingName !== "" && localStorage?.buildingName >= 5) ? localStorage?.buildingName?.substring(0, 3) + ".." : localStorage?.buildingName !== "" ? localStorage?.buildingName : "All"}</div>
                        </div>
                    </>
                }
                {
                    dealerRoles?.includes(localStorage?.UserType) && dealerFilter?.includes(currentPage?.pathname) &&
                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Dealer:</span>
                        <span className="dropdown">
                            <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" title={orgname} aria-expanded="false" style={{ marginTop: -3 }}>
                                {(orgname?.length >= 5) ? orgname?.substring(0, 3) + ".." : orgname}
                                <span><CaretDownOutlined className='arrowDown' /> </span>
                            </button>
                            <div className="dropdown-menu dropdown-itemsList">
                                {orgsList?.map((item: any, index: any) => {
                                    return <span key={index} className="dropdown-item" onClick={() => { handleDealer(item) }}>{item?.orgName}</span>
                                })}
                            </div>
                        </span>
                    </div>
                }
                {
                    companyRoles?.includes(localStorage?.UserType) && companyFilter?.includes(currentPage?.pathname) &&
                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Company:</span>
                        {
                            (divisionsList?.length > 0) ?
                                <span className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" title={divisionname} aria-expanded="false" style={{ marginTop: -3 }}>
                                        {(divisionname?.length >= 5) ? divisionname?.substring(0, 3) + ".." : divisionname}
                                        <span><CaretDownOutlined className='arrowDown' /> </span>
                                    </button>
                                    <div className="dropdown-menu dropdown-itemsList">
                                        {divisionsList?.map((item: any, index: any) => {
                                            return <span key={index} className="dropdown-item" onClick={() => { handleCompany(item) }}>{item?.divisionName}</span>
                                        })}
                                    </div>
                                </span> :
                                <div id="brandName" className="btn-group  brandName pl-2 pb-1">All</div>
                        }
                    </div>
                }
                {
                    locationRoles?.includes(localStorage?.UserType) && locationFilter?.includes(currentPage?.pathname) &&
                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-1">Location:</span>
                        {
                            (locationsList?.length > 0) ?
                                <span className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" title={locationname} aria-expanded="false" style={{ marginTop: -3 }}>
                                        {(locationname?.length >= 5) ? locationname?.substring(0, 3) + ".." : locationname}
                                        <span><CaretDownOutlined className='arrowDown' /> </span>
                                    </button>
                                    <div className="dropdown-menu dropdown-itemsList">
                                        {locationsList?.map((item: any, index: any) => {
                                            return <span key={index} className="dropdown-item" onClick={() => { handleLocation(item) }}>{item?.locationName}</span>
                                        })}
                                    </div>
                                </span> :
                                <div id="brandName" className="btn-group  brandName pl-2 pb-1">All</div>
                        }

                    </div>
                }
                {
                    siteRoles?.includes(localStorage?.UserType) && siteFilter?.includes(currentPage?.pathname) &&
                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-2">Site:</span>
                        {
                            (sitesList?.length > 0) ?
                                <span className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" title={buildingname} aria-expanded="false" style={{ marginTop: -3 }}>
                                        {(buildingname?.length >= 5) ? buildingname?.substring(0, 3) + ".." : buildingname}
                                        <div><CaretDownOutlined className='arrowDown' /></div>
                                    </button>
                                    <div className="dropdown-menu dropdown-itemsList">
                                        {sitesList?.map((item: any, index: any) => {
                                            return <span key={index} className="dropdown-item" onClick={() => handleSite(item)}>{item.buildingName}</span>
                                        })}
                                    </div>
                                </span> :
                                <div id="brandName" className="btn-group  brandName pl-2 pb-1">All</div>
                        }
                    </div>
                }
                {
                    batteryRoles?.includes(localStorage?.UserType) && batteryFilter?.includes(currentPage?.pathname) &&
                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-2">Battery ID:</span>
                        {
                            (batteriesList?.length > 0) ?
                                <span className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" title={batteryname} aria-expanded="false" style={{ marginTop: -3 }}>
                                        {(batteryname?.length >= 5) ? batteryname?.substring(0, 3) + ".." : batteryname}
                                        <div><CaretDownOutlined className='arrowDown' /></div>
                                    </button>
                                    <div className="dropdown-menu dropdown-itemsList">
                                        <span className="dropdown-item" onClick={() => handleBattery("")}>All</span>
                                        {batteriesList?.map((item: any, index: any) => {
                                            return <span key={index} className="dropdown-item" onClick={() => handleBattery(item)}>{item}</span>
                                        })}
                                    </div>
                                </span> :
                                <div id="brandName" className="btn-group  brandName pl-2 pb-1">All</div>
                        }
                    </div>
                }
                {
                    vehicleRoles?.includes(localStorage?.UserType) && vehicleFilter?.includes(currentPage?.pathname) &&
                    <div className='width71px'>
                        <span className="pageTitle font-weight-bold mt-2">Vehicle ID:</span>
                        {
                            (vehiclesList?.length > 0) ?
                                <span className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" title={vehiclename} aria-expanded="false" style={{ marginTop: -3 }}>
                                        {(vehiclename?.length >= 5) ? vehiclename?.substring(0, 3) + ".." : vehiclename}
                                        <div><CaretDownOutlined className='arrowDown' /></div>
                                    </button>
                                    <div className="dropdown-menu dropdown-itemsList">
                                        <span className="dropdown-item" onClick={() => handleVehicle("")}>All</span>
                                        {vehiclesList?.map((item: any, index: any) => {
                                            return <span key={index} className="dropdown-item" onClick={() => handleVehicle(item)}>{item}</span>
                                        })}
                                    </div>
                                </span> :
                                <div id="brandName" className="btn-group  brandName pl-2 pb-1">All</div>
                        }
                    </div>
                }
                {
                    serialRoles?.includes(localStorage?.UserType) && serialFilter?.includes(currentPage?.pathname) &&
                    <div className='width100px'>
                        <span className="pageTitle font-weight-bold mt-2">Serial Number:</span>
                        {
                            (serialNumbers.length > 0) ?
                                <span className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-toggle="dropdown" title={serialname} aria-expanded="false" style={{ marginTop: -3 }}>
                                        {(serialname?.length >= 5) ? serialname?.substring(0, 3) + ".." : serialname}
                                        <div><CaretDownOutlined className='arrowDown' /></div>
                                    </button>
                                    <div className="dropdown-menu dropdown-itemsList">
                                        <span className="dropdown-item" onClick={() => handleSerialNumber("")}>All</span>
                                        {serialNumbers?.map((item: any, index: any) => {
                                            return <span key={index} className="dropdown-item" onClick={() => handleSerialNumber(item)}>{item}</span>
                                        })}
                                    </div>
                                </span> :
                                <div id="brandName" className="btn-group  brandName pl-2 pb-1">All</div>
                        }
                    </div>
                }
                {
                    apply?.includes(currentPage?.pathname) && <span className='header_button' onClick={onApply}><Apply /></span>
                }
                {/* {
                    apply?.includes(currentPage?.pathname) &&
                    <span className="refresh header_button" onClick={() => { doRefresh(1) }}>
                        <i className="fa fa-repeat" aria-hidden="true"></i>
                        <div className="modify">Refresh for latest</div>
                    </span>
                } */}
                <span className='position2'>
                    {
                        EmailFilter?.includes(currentPage?.pathname) &&
                        <span className='tips'>
                            <img onClick={emailToggle} src={emailICon} className="dow-icon" alt="" />
                            <span className="tooltips emailPosition2">Email Report</span>
                        </span>
                    }
                    {
                        DownloadGraphFilter?.includes(currentPage?.pathname) &&
                        <span className='tips'>
                            <Popover
                                trigger="click"
                                open={dowOption}
                                onOpenChange={() => setDowOption(!dowOption)}
                                placement="bottomRight"
                                style={{ padding: 8 }}
                                content={<div className='dow-options'>
                                    <div onClick={() => setAction('pdf')}>Pdf</div>
                                    <div onClick={() => setAction('png')}>Png</div>
                                </div>}
                            >
                                <img onClick={() => setDowOption(!dowOption)} className='dow-icon' src={DownloadIcon} alt="" />
                            </Popover>
                        </span>
                    }
                    {
                        DownloadTableFilter?.includes(currentPage?.pathname) &&
                        <span className='tips'>
                            <Popover
                                trigger="click"
                                open={dowOption}
                                onOpenChange={() => setDowOption(!dowOption)}
                                placement="bottomRight"
                                style={{ padding: 8 }}
                                content={<div className='dow-options'>
                                    {!["/battery-statistics"]?.includes(currentPage?.pathname) && <div onClick={() => setAction('xls')}>Excel</div>}
                                    {!["/battery-statistics"]?.includes(currentPage?.pathname) && <div onClick={() => setAction('csv')}>CSV</div>}
                                    {["/issues-report2", "/battery-statistics"]?.includes(currentPage?.pathname) && <div onClick={() => setAction('pdf')}>PDF</div>}
                                </div>}
                            >
                                <img onClick={() => setDowOption(!dowOption)} className='dow-icon' src={DownloadIcon} alt="" />
                            </Popover>
                        </span>
                    }
                </span>
            </div>
    )
}

export default CommonHeader;