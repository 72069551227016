import React, { FC, useState, useEffect, useDeferredValue } from 'react'
import SelectFilter from '../reusableComponent/selectFilter'
import { Input, AutoComplete } from 'antd';
import { Utility } from "../../helper/index";
import constant1 from '../../settings/constants';
import service from '../../service/api/common';
import type { SelectProps } from 'antd/es/select';
import { SearchOutlined } from '@ant-design/icons';



interface Props {
  applyAndRemoveFilters: (tableData?: []) => void
  searchBy: (partialSearcBy: string, value: string) => void
  callOnClick: (partialSearcBy: string, value: string) => void
  SearchOnOptionSelect: (partialSearcBy: string, value: string) => void
  searchResult: any[]
  onClear: () => void,
  setEmailEmpty: (value: any) => void,
  setFirstEmpty: (value: any) => void,
  setLastEmpty: (value: any) => void
}

const SelectToSearch: FC<Props> = ({ applyAndRemoveFilters, searchBy, callOnClick, searchResult, onClear, SearchOnOptionSelect, setEmailEmpty, setFirstEmpty, setLastEmpty }) => {
  const [placeholder, setPlaceholder] = useState("Search by email")
  const [getaccountId, setGetaccountId] = useState<number>(0)
  const [options, setOptions] = useState<SelectProps<object>['options']>([]);
  const [firsNametoptions, setFirstNameOptions] = useState<SelectProps<object>['options']>([]);
  const [lastNameOptions, setLastNameOptions] = useState<SelectProps<object>['options']>([]);
  const [emailOptions, setEmailOptions] = useState<SelectProps<object>['options']>([]);
  const [firstSearchValue, setFirstSearchValue] = useState<string>("");
  const [lastSearchValue, setLastSearchValue] = useState<string>("");
  const [emailSearchValue, setEmailSearchValue] = useState<string>("");
  // const [filteredFirstName,setFilteredFirstName] = useState<string>("");
  // const [filteredLastName,setFilteredLastName] = useState<string>("");
  // const [filteredEmailName,setFilteredEmailName] = useState<string>("");

  const deferFirst = useDeferredValue(firstSearchValue)
  const deferLast = useDeferredValue(lastSearchValue)
  const deferEmail = useDeferredValue(emailSearchValue)




  const DropList = [{ label: "Search by email" },{ label: "Search by first name" }, { label: "Search by last name" }]

  const filterById = (value: string) => {
    setPlaceholder(value)
    setEmailEmpty(false)
    setFirstEmpty(false)
    setLastEmpty(false)
  }

  useEffect(() => {
    setGetaccountId(Number(localStorage.getItem('accountId')))
  }, [])


  const emailSearch = async (value: string) => {
    // setFilteredEmailName(value)
    setEmailSearchValue(value)
    setFirstSearchValue('')
    setLastSearchValue('')
    searchBy('email', value)
  }

  const firstNameSearch = async (value: string) => {
    // setFilteredFirstName(value)
    setFirstSearchValue(value)
    setEmailSearchValue('')
    setLastSearchValue('')
    searchBy('firstName', value)
  }

  const lastNameSearch = async (value: string) => {
    // setFilteredLastName(value)
    setLastSearchValue(value)
    setFirstSearchValue('')
    setEmailSearchValue('')
    searchBy('lastName', value)
  }

  const firstNameOnSelect = async (value: string) => {
    searchBy('firstName', value)
  }

  const lastNameOnSelect = async (value: string) => {
    searchBy('lastName', value)
  }

  const emailSelect = async (value: string) => {
    searchBy('email', value)
  }

  const clearFilters = () => {
    onClear()
    applyAndRemoveFilters()

  }

  const callonApi = () => {
    if (placeholder === "Search by first name") {
      callOnClick('firstName', firstSearchValue)
    } else if (placeholder === "Search by email") {
      callOnClick('email', emailSearchValue)
    } else {
      callOnClick('lastName', lastSearchValue)
    }
  }
  useEffect(() => {
    if (searchResult.length > 0) {
      searchOptions()
    } else {
      setOptions([])
    }
  }, [searchResult]);

  const searchOptions = () => {
    const data1 = searchResult?.map((item: any, index: any) => {
      return (
        {
          value: emailSearchValue ? item?.email : firstSearchValue ? item?.firstName : item?.lastName,
          label: (
            <span key={index}>{emailSearchValue ? item?.email : firstSearchValue ? item?.firstName : item?.lastName}</span>
          )
        }
      )
    })
    setOptions(data1)
  };

  const searchOnSelect = (value: string) => {
    if (firstSearchValue) {
      SearchOnOptionSelect('firstName', value)
    } else if (lastSearchValue) {
      SearchOnOptionSelect('lastName', value)
    } else if (emailSearchValue) {
      SearchOnOptionSelect('email', value)
    }
  };


  return (
    <>
      <SelectFilter
        placeholder={placeholder}
        dropDownList={DropList}
        filterById={filterById}
        allowClear={false}
      />
      <div className='auto_complete position-relative'>
        <AutoComplete
          style={{ width: 300 }}
          options={options}
          // options={placeholder  ==="Search by email" ? emailOptions:placeholder ==="Search by first name"?firsNametoptions:lastNameOptions}
          //onSelect={placeholder ==="Search by email" ? emailSelect:placeholder ==="Search by first name"?firstNameOnSelect:lastNameOnSelect}
          onSelect={searchOnSelect}
          onSearch={placeholder === "Search by email" ? emailSearch : placeholder === "Search by first name" ? firstNameSearch : lastNameSearch}
          allowClear
          placeholder={placeholder}
          onClear={clearFilters}
        >
          {/* <Input.Search size="middle"  placeholder={placeholder} enterButton className="input-searcOrg" /> */}
        </AutoComplete>
      <span onClick={callonApi} className="user-search_icon position-absolute"><SearchOutlined style={{ color: 'white' }} /></span>
      </div>

    </>
  )
}

export default SelectToSearch;