import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import LoadingSpinner from '../spinner/loading-spinner'
import { Utility } from "../../helper/index";
import constantUrl from "../../settings/constants";
import service from '../../service/api/common';
import { format, startOfMonth, subDays } from 'date-fns';
import SelectDays from '../reusableComponent/selectDays';
import SessionWarning from '../reusableComponent/sessionWarning';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DownloadIcon from '../../images/Download.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {resize, hideSidebar} from '../../settings/resize';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface Props {
  globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
  action: string
  // refresh:number
}

const RunTimeVsAhChart: FC<Props> = ({globalData, action }) => {
  // const RunTimeVsAhChart: FC<Props> = ({globalData, action,refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [reqBody, setReqBody] = useState<any>({ startDate: format(subDays(new Date(), 29), 'dd-MMM-yyyy') , endDate: format(new Date(), 'dd-MMM-yyyy'), orgId: 0, locationId: -1 })
  const [week, setWeek] = useState<any[]>([])
  const [dischargeAhs, setDischargeAhs] = useState<any[]>([])
  const [runMinutes, setRunMinutes] = useState<any[]>([])
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [value, onChange] = useState<any>([subDays(new Date(), 29), new Date()]);
  const [initial, setInitial] = useState<boolean>(true);

  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    if(date1 && date2) {
    daysFilter(date1 ?? format(subDays(new Date(), 29), 'dd-MMM-yyyy'), date2 ?? format(new Date(), 'dd-MMM-yyyy'));
    }
  }, [value])

  const haveSameData = function (obj1: any, obj2: any) {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;

    if (obj1Length === obj2Length) {
        return Object.keys(obj1).every(
            key => obj2.hasOwnProperty(key)
                && obj2[key] === obj1[key]);
    }
    return false;
  };

      // useEffect(()=>{
      //   if (refresh === 0) return 
      //   setReqBody({...reqBody})
      // },[refresh]);
   
      useEffect(() => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        if((reqBody?.startDate === reqBody?.endDate) || reqBody?.orgId == 0) return
        setIsShowSpinner(true);

        (async () => {
          try {
            const response = await service({
            url: constantUrl.api.runtimeVsAh.runtimeVsAh,
            method: "POST",
            headers: { 'Content-Type': "application/json", Authorization: 'Bearer ' + token },
            data: reqBody
            })
            frameChart(response)
            setIsShowSpinner(false)
          } catch (error) {
            console.error("runtimeError:", error)
            setIsShowSpinner(false)
          }
        })();
      }, [reqBody])

    const frameChart = (data: any) => {
      let week: any = data.map((item: any) => {
        return {
          label: item.week
        }
      })

      let dischargeAhs: any = data.map((item: any) => {
        return {
          value: item.dischargeAhs
        }
      })

      let runMinutes: any = data.map((item: any) => {
        return {
          value: item.runMinutes
        }
      })

      
      setWeek(week)
      setDischargeAhs(dischargeAhs)
      setRunMinutes(runMinutes)
      setIsShowSpinner(false)
      let dischargeAhsMax = Math.max(...dischargeAhs.map((o: any) => o.value))
      let runMinutesMax = Math.max(...runMinutes.map((o: any) => o.value))

      let final: any = [dischargeAhsMax, runMinutesMax];
      let maxValue = Math.max(...final.map((o: any) => o));
      maxValue = maxValue + 100;
    }

    const chartConfigs = {
      type: "scrollcombi2d",
      width: '100%',
      height: '92%',
      dataFormat: 'json',
      dataSource: {
        chart: {
          //"xAxisName": "Duration",
        "YAxisName": "RUN TIME",
        yAxisNameFontColor: "#4f82b6",
        yAxisNameFontBold: "1",
        setadaptivepYmin: "1",
        formatNumberScale: "0",
        formatNumber: "0",
        decimals: "0",
        numDivLines: "5",
        labelDisplay: "none",
        // useRoundEdges: "1",
        //"animation": "0",
        //"showDivLineValues": "1",
        "showvalues": "0",
        plotSpacePercent: "100",
        legendposition: "top-left",
        legendPadding: "70",
        drawCustomLegendIcon: "2",
        legendIconSides: "0",
        drawAnchors: '0',
        //legendIconStartAngle: "60",
        paletteColors: "#5a8dc1,#e8e368",
        divlineColor: "#707070",
        divLineIsDashed: "1",
        "numvisibleplot": "9",
        showHoverEffect: "1",
        showToolTip: "1",
        // exportEnabled:"1",
        // exportfilename:"Runtime_Vs_Ah",
        showLegend: "1",
        showsum: "1",
        plotHoverEffect : "0",
        //"stack100Percent": "1",
        "theme": "fusion"
        },
        "categories": [{
          "category": week
          //[{label: "W1"}]
        }],
        "dataset": [{
          "seriesName": "AH",
          "data": dischargeAhs
          // [{"value": "16000"}]
        },
        {
          "seriesName": "Run Time",
          "renderAs": "line",
          "showValues": "0",
          "data": runMinutes
          // [{"value": "15000"}]
        }
      ]
      }
    };

    const daysFilter = (startDate: string, endDate: string) => {
      setReqBody({ ...reqBody, startDate: format(new Date(startDate), 'dd-MMM-yyyy'), endDate: format(new Date(endDate), 'dd-MMM-yyyy') })
    }

    useEffect(()=>{
    // check the page permission
     let permission:any =  localStorage.getItem('permissions')
     if(permission !== null){
      if(!permission.includes('equivalent-battery-utilization_W'))window.history.back()  
     }

      setInitial(true)
      window.scrollTo(0, 0)
      resize();
      hideSidebar();
      let data: any = sessionStorage.getItem('globalData');
      data = data && JSON.parse(data);
      let org = data?.orgId > -1 ? data?.orgId : userId;
      setReqBody({ ...reqBody,orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1,buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId})
      setInitial(false)
    }, [])

    useEffect(() => {
      if(initial == false) {
        let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
          setReqBody({ ...reqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId,buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId})
      }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

    // const filterByserialNumber = (value: string) => {
    //   // setReqBody({ ...reqBody, serialNumber: value })
    // }

    useEffect(() => {
      if(action !== "") {
        onButtonClick(action)
      }
    }, [action])

    const onButtonClick = useCallback((value:string) => {
      if (ref.current === null) {
        return
      }
      setIsShowSpinner(true);
      if(value==='png'){
        toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'Runtime_Vs_Ah.png'
          link.href = dataUrl
          link.click()
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
      }else{
        html2canvas(ref.current)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const link = document.createElement('a')
            link.download = 'my-image-name.png';
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG',2,20,0,99);
            pdf.save("Runtime_Vs_Ah.pdf");
            setIsShowSpinner(false);
          })
          .catch((err) => {
            console.log(err)
            setIsShowSpinner(false);
          })
      }
  
    
    }, [ref])


  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      {/* <PosinetHeader 
        billing={<HeaderFilter
        getId={getIds}
        vId={true}
        noinitialfilter={true}
        onButtonClick={onButtonClick}
        />}
        /> */}
      <div> 
      <div className="container-battery-voltage" ref={ref}>
          <div className="chartTitle p-2 text-center d-flex align-items-center">
            <span className='mr-auto ml-auto '>Run Time Vs Ah Report</span>
            {/* <span>
              <SelectFilter
                placeholder={"Serial Number"}
                dropDownList={serialNo}
                sizeType={"small"}
                filterById={filterByserialNumber}
                width={200}
                allowClear={false}

              />
            </span> */}
          </div>
          {/* <span className="filter">
            <SelectDays daysFilter={daysFilter} />
          </span> */}
          <span className="export">
          {
                    showDateFilter && <span>
                        <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
                    </span>
                }
            <SelectDays
             daysFilter={daysFilter} 
             customFilterStatusChange={setShowDateFilter}
             option7Days={false}
             option14Days={false}
              />
            {/* <button className='mx-2 exportstyle' type='button' onClick={onButtonClick}>Export as PNG</button> */}
            {/* <img onClick={onButtonClick} className='dow-icon ml-2 mb-2' src={DownloadIcon} alt=""/> */}
          </span>
          <div className='daily-plugin-chart'>
            <ReactFC {...chartConfigs} />
          </div>
        </div>
      </div>
      <SessionWarning/>
    </>
  )
}

export default RunTimeVsAhChart;


