import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import ZoomLine from 'fusioncharts/fusioncharts.zoomline';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import LoadingSpinner from '../spinner/loading-spinner'
import PosinetHeader from '../../layout/header';
import BatteryDetails from '../reusableComponent/batteryDetalis';
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import SelectFilter from '../reusableComponent/selectFilter';
import HeaderFilter from '../reusableComponent/headerFilter';
import { format, startOfWeek, startOfMonth, subDays } from 'date-fns';
import SelectDays from '../reusableComponent/selectCustomDays';
import SessionWarning from '../reusableComponent/sessionWarning';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import getBatterySummary from '../../api-call/battery-summary';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { resize, hideSidebar } from '../../settings/resize';
import { Button, Modal } from 'antd';

ReactFC.fcRoot(FusionCharts, Charts, ZoomLine, FusionTheme);

const startOfCurrentMonth: any = format(subDays(new Date(), 13), 'dd-MMM-yyyy')
const today: any = format(new Date(), 'dd-MMM-yyyy')

type reqType = {
  startDate: string,
  endDate: string,
  orgId: number,
  divisionId: number,
  locationId: number,
  batteryId: string,
  buildingId: number,
  pageOffset: number,
  pageLimit: number,
  identifier: string,
  vehicleId: string,
  timeZone: string
}

const req = {
  "startDate": startOfCurrentMonth,
  "endDate": today,
  "orgId": 0,
  "divisionId": -1,
  "batteryId": "",
  "locationId": -1,
  "buildingId": -1,
  "pageOffset": 1,
  "pageLimit": 10,
  "identifier": "",
  "vehicleId": "",
  "timeZone": Intl.DateTimeFormat().resolvedOptions().timeZone
}

interface Props {
  globalData: {
    orgId: number,
    orgName: string,
    divId: number,
    divName: string,
    locationId: number,
    locationName: string,
    buildingId: number,
    batteryId: string,
    buildingName: string,
    batteryName: string,
    vehicleId: string,
    vehicleName: string,
    serialId: string,
    serialName: string
  },
  action: string
  // refresh: number
}

const BatteryTemperatureChart: FC<Props> = ({ globalData, action }) => {
  // const BatteryTemperatureChart: FC<Props> = ({ globalData, action, refresh }) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [reqBody, setReqBody] = useState<reqType>(req)
  const [chartData, setChartData] = useState<any[]>([])
  const [chartHeight, setChartHeight] = useState(window.innerHeight * 0.76);
  const [chartType, setChartType] = useState<string>("zoomline");
  const [avgTemp, setAvgTemp] = useState<number | string>(0)
  const [highestTemp, setHighestTemp] = useState<number | string>()
  const [lowestTemp, setLowestTemp] = useState<number | string>()
  const [normalTemp, setNormalTemp] = useState<number | string>()
  const [label, setLabel] = useState<any[]>([])
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [showSelectedDays, setShowSelectedDays] = useState<string>("This Month")
  const [callBatteryId, setCallBatteryId] = useState<boolean>(false)
  const [summaryBatteryReq, setSummaryBatteryReq] = useState<reqType>(req)
  const [summaryBattery, setSummaryBattery] = useState<any>([])
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(true);
  const [temperatureFormat, setTemperatureFormat] = useState(null);
  const [points, setPoints] = useState<string>("50");
  const [value2, setValue] = useState<[Date, Date] | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const ref = useRef<HTMLDivElement>(null)

  // useEffect(() => {
  //   if (refresh === 0) return
  //   let globalData: any = sessionStorage.getItem('globalData')
  //   let parseData = JSON.parse(globalData)
  //   // parseData.batteryId && setSummaryBatteryReq({...summaryBatteryReq})
  //   if (parseData.batteryId || parseData.vehicleId) setSummaryBatteryReq({ ...summaryBatteryReq })

  //   setReqBody({ ...reqBody })
  // }, [refresh])

  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
    if (date1 && date2) {
      daysFilter(date1 ?? startOfCurrentMonth, date2 ?? today, "Custom Date Range");
    }
  }, [value])

  useEffect(() => {
    batterySummary()
  }, [summaryBatteryReq])

  const batterySummary = () => {
    if (summaryBatteryReq.orgId === 0 || (!summaryBatteryReq?.batteryId && !summaryBatteryReq?.vehicleId)) return
    // if (summaryBatteryReq?.batteryId !== "") {

    getBatterySummary.batterySummary(summaryBatteryReq)
      .then((response) => {
        setSummaryBattery(response)
        setShowSummary(true)
      }).catch((error) => {
        console.log('batterySummary:', error)
      })
    // }
  }

  useEffect(() => {
    if (reqBody.orgId === 0 || reqBody.batteryId === "") {
      setAvgTemp("")
      setHighestTemp("")
      setLowestTemp("")
      setNormalTemp("")
      setChartData([{ value: "0" }])
      setLabel([{ label: "0" }])
    } else {
      let userInfo: any = Utility.getUserInfo();
      let token = userInfo.authToken;
      setIsShowSpinner(true);
      (async () => {
        try {
          const response = await service({
            url: constantUrl.api.socreport.socreport,
            method: "POST",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
            data: reqBody,
          })
          if (response.length > 0) {
            filterData(response)
            setIsShowSpinner(false)
          } else {
            setAvgTemp("")
            setHighestTemp("")
            setLowestTemp("")
            setChartData([{ value: "0" }])
            setLabel([{ label: "0" }])
            setIsShowSpinner(false)
          }
        } catch (error) {
          console.log("error:", error)
          setIsShowSpinner(false);
        }
      })();
    }
  }, [reqBody])

  const celsiusToFahrenheat = (celsius: any) => {
    return (celsius * (9 / 5)) + 32
  }

  const formatDate = (date: any) => {
    let date1 = new Date(date);
    var totalMinutes = date1.getTimezoneOffset();
    var hours = 0;
    if (totalMinutes > 0) {
      hours = Math.floor(totalMinutes / 60);
    } else {
      hours = Math.ceil(totalMinutes / 60);
    }
    var minutes = totalMinutes % 60;
    date1.setHours(date1.getHours() + hours);
    date1.setMinutes(date1.getMinutes() + minutes);

    return format(date1, 'MM/dd/yyyy')
  }

  const filterData = (response: any) => {
    // let labels = response.flatMap((item: any) => {
    //   return [{ label: formatDate(item.startDate) }, { label: formatDate(item.endDate) }]
    // })
    let lineChartData = response.flatMap((item: any) => {
      return [{ value: (item.startTemp * (9 / 5)) + 32 }, { value: (item.endTemp * (9 / 5)) + 32 }]
    })

    let flattenedArr: any = lineChartData.reduce((result: any, obj: any) => {
      return result.concat(Object.values(obj));
    }, []);

    let responseM = response.flatMap((item: any) => { return [item.mmddStart, item.mmddEnd] });
    let labels: any = [];
    let values: string[] = [];

    for (let index = 0; index < responseM.length; index++) {
      if (values.includes(responseM[index])) {
        labels.push({ label: "" });
      } else {
        values.push(responseM[index]);
        labels.push({ label: responseM[index] });
      }
    }

    let avg_temp = (response[0]['avgTemp'] * (9 / 5)) + 32;
    let normal_temp = avg_temp + 5;

    setAvgTemp(avg_temp?.toFixed(2))
    setLowestTemp(Math.min(...flattenedArr).toFixed(2));
    setHighestTemp(Math.max(...flattenedArr).toFixed(2))
    setNormalTemp(normal_temp?.toFixed(2))

    let lineChartData1 = response.flatMap((item: any) => {
      let start: any = (item.startTemp * (9 / 5)) + 32;
      let end: any = (item.endTemp * (9 / 5)) + 32;
      return [
        { value: start, color: (start >= Math.min(...flattenedArr).toFixed(2)) ? "#000066" : "" },
        { value: end, color: (end >= Math.min(...flattenedArr).toFixed(2)) ? "#e44a00" : "" }
      ]
    })

    let batteryTempData: any = lineChartData1.map((obj: any, index: any) => { return { ...obj } });

    setChartData(batteryTempData)
    setLabel(labels)
    setIsShowSpinner(false)
  }

  useEffect(() => {
    const handleResize = () => {
      setChartHeight(window.innerHeight * 0.63);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chartHeight]);

  useEffect(() => {
    var gettoken = userInfo.authToken;
    userMe(gettoken)
    setInitial(true);
    resize();
    hideSidebar();
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);

    (data?.batteryId === "") ? setShowSummary(false) : setShowSummary(true)

    let org = data?.orgId > -1 ? data?.orgId : userId;
    setReqBody({ ...reqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    setSummaryBatteryReq({ ...summaryBatteryReq, orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
    setCallBatteryId(!callBatteryId)
    setInitial(false);
  }, [])

  useEffect(() => {
    if (initial === false) {
      let data: any = sessionStorage.getItem('globalData');
      data = data && JSON.parse(data);
      (data?.batteryId === "") ? setShowSummary(false) : setShowSummary(true)

      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
      setReqBody({ ...reqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
      setSummaryBatteryReq({ ...summaryBatteryReq, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
    }
  }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

  const daysFilter = (startDate: any, endDate: string, value: string) => {
    var Difference_In_Time = new Date(endDate).getTime() - new Date(startDate).getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days > 60) {
      setModalMessage('The maximum range allowed is 60 days.');
      setIsModalVisible(true);
      return;
    }
    if (Difference_In_Days > 91) {
      setChartType("scrollline2d")
      setPoints("60")
    } else {
      setChartType("msline")
    }
    setReqBody({ ...reqBody, startDate, endDate })
    setSummaryBatteryReq({ ...summaryBatteryReq, startDate, endDate })
    setShowSelectedDays(value)
  };

  const onChange2 = (value2: [Date, Date]) => {
    const Difference_In_Time = value2[1].getTime() - value2[0].getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days > 60) {
      setModalMessage('The maximum range allowed is 60 days.');
      setIsModalVisible(true);
      return;
    }

    setValue(value2);
  };


  const handleModalClose = () => {
    // onChange([subDays(new Date(), 13), new Date()])
    setIsModalVisible(false);
    setModalMessage('');
  };

  useEffect(() => {
    if (action !== "") {
      onButtonClick(action)
    }
  }, [action])

  const onButtonClick = useCallback((value: string) => {
    if (ref.current === null) return

    setIsShowSpinner(true);
    if (value === 'png') {
      toPng(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'batteryTemperature.png'
          link.href = dataUrl
          link.click()
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    } else {
      html2canvas(ref.current)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const link = document.createElement('a')
          link.download = 'my-image-name.png';
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'PNG', 5, 20, 200, 100);
          pdf.save("batteryTemperature.pdf");
          setIsShowSpinner(false);
        })
        .catch((err) => {
          console.log(err)
          setIsShowSpinner(false);
        })
    }
  }, [ref])

  async function userMe(gettoken: string) {
    try {
      const response = await service({
        url: constantUrl.api.user.accountId,
        method: "GET",
        headers: { accept: "*/*", Authorization: "Bearer " + gettoken }
      });
      if (response) {
        const format = response?.data?.temperatureFormat;
        setTemperatureFormat(format);
        localStorage.setItem("temperatureFormat", format);
      }
    } catch (error) {
      console.log("User Details:", error);
    }
  }

  var chartConfigs_line = {
    type: 'zoomline',
    dataFormat: 'json',
    width: '100%',
    height: chartHeight,
    dataSource: {
      chart: {
        yaxisname: "BATTERY TEMPERATURE" + (temperatureFormat ? ` (°${temperatureFormat})` : ""),
        numbersuffix: "°",
        xAxisName: "DATE",
        xAxisNameFontColor: "#4f82b6",
        xAxisNameFontBold: "1",
        yAxisNameFontColor: "#4f82b6",
        yAxisNameFontBold: "1",
        yaxisminvalue: "0",
        yaxismaxvalue: "130",
        numDivLines: "7",
        rotatelabels: "1",
        lineThickness: "3",
        setadaptiveymin: "1",
        // showToolTip: '1',
        // drawAnchors: '1',
        "useCrossLine": "0",
        labelFontSize: "12",
        labelDisplay: "auto",
        //legendPadding: "400",
        chartTopMargin: "70",
        useEllipsesWhenOverflow: "1",
        flatScrollBars: "1",
        scrollheight: "10",
        numVisiblePlot: points,
        isTrendZone: "0",
        //"trendValueAlpha": "80",
        // exportEnabled:"1",
        // exportfilename:"Battery_Temperature",
        plotToolText: "<div class='__head'>Temperature</div><div class='__body'>$dataValue</div><div class='__footer'>$label</div>",
        toolTipBgAlpha: "100",
        labelStep: "1",
        theme: "fusion",
        // "forceAxisLimits": "1",
        "pixelsPerPoint": "0",
        /* "pixelsPerLabel": "30", */
        // "compactdatamode": "1",
        /* "dataseparator": "|", */
        // "labelHeight": "30",
        //"showHoverEffect": "1"
      },
      trendlines: [{
        line: [{
          startvalue: avgTemp,
          valueOnRight: "1",
          color: "#1fd284",
          alpha: "100",
          thickness: "2",
          dashed: "1",
          dashLen: "4",
          dashGap: "7",
          labelFontSize: "3"
        }]
      }],
      categories: [{
        category: label.length > 0 ? label : [{ label: "" }]
      }],
      dataset: [{
        data: chartData.length > 0 ? chartData : [{ value: "0" }]
      }]
    }
  };

  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div>
        {
          showSummary &&
          <BatteryDetails
            batteryData={summaryBattery[0]}
          />
        }
        <div className="container-battery-voltage" ref={ref}>
          <div className="chartTitle py-2 text-center d-flex align-items-center">
            <span className="ml-auto mr-auto">Battery Temperature Report </span>
          </div>
          {/* <span className="export2 graph_label_mobile_head">
            {showDateFilter && <span><DateRangePicker onChange={onChange} value={value} maxDate={new Date()} /></span>}
            <SelectDays daysFilter={daysFilter} customFilterStatusChange={setShowDateFilter} default14days={true} />
          </span> */}
          <span className="export2 graph_label_head">
            {showDateFilter && <span><DateRangePicker onChange={onChange} value={value} maxDate={new Date()} /></span>}
            <SelectDays daysFilter={daysFilter} customFilterStatusChange={setShowDateFilter} default14days={true} />
          </span>
          <Modal
            className="update-users"
            title="Date Range Error"
            centered
            open={isModalVisible}
            onOk={handleModalClose}
            footer={[
              <Button key="ok" type="primary" onClick={handleModalClose} style={{ background: '#004f9f', color: "white" }}>
                OK
              </Button>,
            ]}
            closable={false}
          >
            <p>{modalMessage}</p>
          </Modal>
          <div className='chart-tooltip'>
            <div className='col-12 p-0'>
              <ReactFC {...chartConfigs_line} />
            </div>
            <span className={chartData.length > 0 ? "chart-tooltiptext" : "chart-tooltiptextHide"} >
              <div className='d-flex count-body-text' style={{ gap: '5px' }}>
                <div className=''>
                  <div className='countLabel '>
                    <div className="green-dot"></div>
                    <span className="" >Avg Temp</span>
                  </div>
                  <div className='count-body'>{avgTemp ? `${avgTemp} °` : <></>}</div>
                </div>
                <div>
                  <div className='countLabel'>
                    <div className="red-dot"></div>
                    <span className="" >Highest Temp</span>
                  </div>
                  <div className='count-body'>{highestTemp ? `${highestTemp} °` : <></>}</div>
                </div>
                <div>
                  <div className='countLabel'>
                    <div className="blue-dot"></div>
                    <span className="" >Lowest Temp</span>
                  </div>
                  <div className='count-body'>{lowestTemp ? `${lowestTemp} °` : <></>}</div>
                </div>
                <div>
                  <div className='countLabel'>
                    <div className="yellow-dot"></div>
                    <span className="" >Normal Temp</span>
                  </div>
                  <div className='count-body'>{normalTemp ? `${normalTemp} °` : <></>}</div>
                </div>
              </div>
              <div className="left-dot-body ">
                <div className="count-body-text">
                  <div >{showSelectedDays}</div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <SessionWarning />
    </>
  )
}

export default BatteryTemperatureChart;