import React, { useState, useEffect, useRef, FC } from 'react'
import SessionWarning from '../reusableComponent/sessionWarning';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { format, parseISO, startOfMonth, subDays } from 'date-fns'
import DaysFilter from '../reusableComponent/DaysFilter';
import { Utility } from '../../helper';
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import {resize, hideSidebar} from '../../settings/resize';


const today = format(new Date(), 'dd-MMM-yyyy')
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy')

type resBody = {
  ahsCharged: number,
  ahsDischarged: number,
  lowestBatterySoc: number,
  highestBatterySoc: number,
  formatLastCompEqDate: string,
  formatLowestBatteryTemperature: number,
  formatHighestBatteryTemperature: number
}

type reqBody = {
  orgId: number,
  divisionId: number,
  locationId: number,
  buildingId: number,
  startDate: string,
  endDate: string,
  batteryId: string,
  vehicleId: string,
  serialNumber: string,
  pageOffset: number,
  pageLimit: number,
  // timeZone: string
}

let Body = {
  orgId: 0,
  divisionId: -1,
  locationId: -1,
  buildingId: -1,
  startDate: sevenDaysAgo,
  endDate: today,
  batteryId: "",
  vehicleId: "",
  serialNumber: "",
  pageOffset: 1,
  pageLimit: 10,
  // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

let BodyRes = {
  "ahsCharged": 0,
  "ahsDischarged": 0,
  "lowestBatterySoc": 0,
  "highestBatterySoc": 0,
  "formatLastCompEqDate": "",
  "formatLowestBatteryTemperature": 0,
  "formatHighestBatteryTemperature": 0
}

interface Props {
  globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
  action: string
  // refresh:number
}

const IssuesReport: FC<Props> = ({globalData, action}) => {
  // const IssuesReport: FC<Props> = ({globalData, action, refresh}) => {
  let userInfo: any = Utility.getUserInfo();
  let userId: number = userInfo.userId;
  const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
  const [selector, setSelector] = useState(false)
  const [value, onChange] = useState<any>([subDays(new Date(),6), new Date()]);
  const [tableReport, setTableReport] = useState<resBody>(BodyRes)
  const [tableReqBody, setTableReqBody] = useState<reqBody>(Body)
  const [exportReqBody, setExportReqBody] = useState<reqBody>(Body)
  const [dateLabel, setDateLabel] = useState<string>("Last 7 Days")
  const [dateRange, setDateRange] = useState<any>({ sevenDaysAgo, today })
  const [initial, setInitial] = useState<boolean>(true);


  useEffect(() => {
    let date1: any = value && format(value[0], 'dd-MMM-yyyy');
    let date2: any = value && format(value[1], 'dd-MMM-yyyy');
      
    // To calculate the time difference of two dates
    var Difference_In_Time = new Date(date2).getTime() - new Date(date1).getTime();
      
    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)+1;
if(date1 && date2) {
  daysFilter(date1 ?? sevenDaysAgo, date2 ?? today, `Last ${Difference_In_Days} Days`);
}
  }, [value])

  const selectorRef = useRef<HTMLDivElement>(null)

  // useEffect(()=>{
  //  if(refresh !==0){
  //   getIssuesReport()
  //  }
  // },[refresh])

  useEffect(() => {
    getIssuesReport()
  }, [tableReqBody])

  const getIssuesReport = async () => {
    if (tableReqBody.orgId === 0) return
    let userInfo: any = Utility.getUserInfo();
    let token: string = userInfo.authToken;
    setIsShowSpinner(true)
    try {
      const response = await service({
        url: constantUrl.api.issues1.getissues,
        method: "POST",
        headers: { accept: "*/*", Authorization: "Bearer " + token },
        data: tableReqBody
      });
      setTableReport(response)
      setIsShowSpinner(false)
    } catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  }

  useEffect(()=>{
    // check the page permission
    let permission:any =  localStorage.getItem('permissions')
    if(permission !== null){
      if(!permission.includes('Issues_report1_W'))window.history.back()  
    }

    resize();
    hideSidebar();
    let data: any = sessionStorage.getItem('globalData');
    data = data && JSON.parse(data);
    let org = data?.orgId > -1 ? data?.orgId : userId;
    setTableReqBody({ ...tableReqBody, orgId: org, divisionId: data?.divId, locationId: data?.locationId,buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId})
    setExportReqBody({ ...exportReqBody,orgId: org, divisionId: data?.divId, locationId: data?.locationId,buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId})
    setInitial(false)
  }, [])

  useEffect(() => {
    if(initial === false) {
      let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
        setTableReqBody({ ...tableReqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId,buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId})
        setExportReqBody({ ...exportReqBody, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId,buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId})
    }
}, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

  const daysFilter = (startDate: string, endDate: string,value?:string) => {
    setTableReqBody({ ...tableReqBody, startDate, endDate })
    claenderonChange(startDate, endDate)
    value && setDateLabel(value)
  };

  const formattedDate = (date: string) => {
    const date1 = date;
    const parsedDate = parseISO(date1);
    const formatDate = format(parsedDate, 'MM/dd/yyyy');
    return formatDate
  }

  const claenderonChange = (startDate: string, endDate: string) => {
    setDateRange({ ...dateRange, sevenDaysAgo: startDate, today: endDate })
  }

  useEffect(() => {
    if(action !== "") {
        dwnFormat(action)
    }
}, [action])

  const dwnFormat = async (format: string) => {
    let userInfo: any = Utility.getUserInfo();
    let token = userInfo.authToken
    setIsShowSpinner(true)
    try {
      const response = await service({
        url: constantUrl.api.issues1.issues1export + '/' + format,
        method: 'POST',
        responseType: 'blob',
        headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
        data: tableReqBody
      })
      if (response) {
        let url = window.URL.createObjectURL(response);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'Weekly-Issues-Report1.' + format;
        a.click();
        setIsShowSpinner(false)
      }
    } catch (error) {
      console.log(error)
      setIsShowSpinner(false)
    }
  }


  return (
    <>
      <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
      <div className='row mt-3'>
        <div className='col-12'>
        <div className='issues-report-drow'>
            {
              showDateFilter && <span>
                <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
              </span>
            }
            <span>
              <DaysFilter daysFilter={daysFilter} customFilterStatusChange={setShowDateFilter} />
            </span>
        </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-12 '>
          <div className='white'>
            <div className='row'>
              <div className='col-12 px-0'>
                <div className='col-12'>
                  <div className='col-12 heading-title'>Issues Report</div>
                  <div className='m-3 overflow-auto scrollbar'>
                    <div className='gap marginBlock'>
                      <div className='range'>{dateLabel}</div>
                      <div className='this-week'>{"(" + dateRange.sevenDaysAgo + " to " + dateRange.today + ")"}</div>
                    </div>
                    <table className='report-table'>
                      <tbody>
                        <tr>
                          <th>Ahs Charged</th>
                          <td>{tableReport?.ahsCharged}</td>
                        </tr>
                        <tr>
                          <th>Ahs Disharged</th>
                          <td>{tableReport?.ahsDischarged}</td>
                        </tr>
                        <tr>
                          <th>Lowest Battery SOC</th>
                          <td>{tableReport?.lowestBatterySoc}</td>
                        </tr>
                        <tr>
                          <th>Highest Battery SOC</th>
                          <td>{tableReport?.highestBatterySoc}</td>
                        </tr>
                        <tr>
                          <th>Last EQ Completed</th>
                          <td>{tableReport?.formatLastCompEqDate}</td>
                        </tr>
                        <tr>
                          <th>Lowest Battery Temperature</th>
                          <td>{tableReport?.formatLowestBatteryTemperature}</td>
                        </tr>
                        <tr>
                          <th>Highest Battery Temperature</th>
                          <td>{tableReport?.formatHighestBatteryTemperature}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SessionWarning />
    </>
  )
}

export default IssuesReport;