import React, { useState, useEffect, useRef, FC, useLayoutEffect } from 'react';
import LoadingSpinner from '../spinner/loading-spinner'
import { CaretDownOutlined } from '@ant-design/icons';
import { CaretUpOutlined } from '@ant-design/icons';
import ShowEntries from '../reusableComponent/showEntries';
import { Utility } from "../../helper/index";
import constantUrl from "../../settings/constants";
import service from '../../service/api/common';
import PaginationComponent from '../reusableComponent/pagination';
import SessionWarning from '../reusableComponent/sessionWarning';
import { Link } from 'react-router-dom';
import { resize, hideSidebar } from '../../settings/resize';
import { Input, ConfigProvider, Select, Space, Button, Modal } from "antd";

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    action: string,
    // refresh: number
}

const req = {
    pageOffset: 1,
    pageLimit: 10,
    orgId: 0,
    sortColumns: "",
    sortDirection: "",
    divisionId: -1,
    locationId: -1,
    buildingId: -1,
    batteryId: "",
    vehicleId: "",
    batteryRxSim: "",
    serialNumber: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

// const BMIDInventory: FC<Props> = ({ globalData, action, refresh }) => {
const BMIDInventory: FC<Props> = ({ globalData, action }) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;

    const [logReqBody, setlogReqBody] = useState<any>(req)
    const [countReqBody, setCountReqBody] = useState<any>(req)

    const [pageSize, setPageSize] = useState<number>(10)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [current, setCurrent] = useState<number>(1)
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [inventory, setInventory] = useState<string[]>([]);
    const [selector, setSelector] = useState(false)
    const [column, setColumn] = useState<any[]>([])
    const [alert, setAlert] = useState<boolean>(false);
    const [selectorLabel, setSelectorLabel] = useState<any[]>([])
    const [sort, setSort] = useState<any>({
        vehicleId: false, batteryId: false, serialNumber: false, sim: false, timeZone: false,
        dealer: false, company: false, location: false, site: false, protocal: false, boot: false, app: false,
        manufactureDate: false, inserviceDate: false, telephone: false, lastcontract: false, enabled: false,
        enabledDate: false, emeter: false, emeterSession: false, constart: false, conend: false, date_Updated: false
    })
    const [clearAll, setClearAll] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [initial, setinitial] = useState<boolean>(true);
    const selectorRef = useRef<HTMLDivElement>(null)
    const [emptyError, setemptyError] = useState<string>("");

    const [searchObject, setSearchObject] = useState<any>({
        batteryId: '',
        vehicleId: '',
        batteryRxSim: '',
        serialNumber: ''
    })

    const DropList = [
        { label: "Battery ID" },
        { label: "Vehicle ID" },
        { label: "Battery Rx SIM #" },
        { label: "Battery Rx Serial Number" }

    ];

    const [dropdownvalue, setDropDownValue] = useState<string>("Battery ID");
    const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
    const [inputvalue, setInput] = useState<any>("")
    const [tableHeight, setTableHeight] = useState<any>(445)
    const filtersRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
                callDebounce()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useLayoutEffect(() => {
        resize();
        hideSidebar();
        callDebounce()
    }, []);

    const callDebounce = () => {
        //if(window.innerHeight<=568) return
        let marginAndPaddings: any = 80
        let navHeight = localStorage.getItem('navHeight')
        let filtersHeight
        let paginationHeight
        if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
        if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
        let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
        let tableHeight = window.innerHeight - subHeight
        tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
    };

    const onChangedropdown = (value: string) => {
        setemptyError("")
        setDropDownValue(value);
        setPlaceholder(`Search ${value}`)
        setInput("");
        setSearchObject({
            batteryId: '',
            vehicleId: '',
            batteryRxSim: '',
            serialNumber: ''
        })

    }

    const onChangePayload = (value: any) => {
        let obj = { ...searchObject };
        if (dropdownvalue === "Battery ID") {
            obj.batteryId = value;
            setSearchObject({ ...obj })
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Battery Rx Serial Number") {
            obj.batteryRxSim = value;
            setSearchObject({ ...obj })
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Vehicle ID") {
            obj.vehicleId = value;
            setSearchObject({ ...obj })
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Battery Rx SIM #") {
            obj.serialNumber = value;
            setSearchObject({ ...obj })
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        }
        setInput(value);
        setemptyError("")
    }

    const callCountAndReport = (obj: any) => {
        setlogReqBody({ ...logReqBody, ...obj })
        getData({ ...logReqBody, ...obj })
        getCount({ ...logReqBody, ...obj })
    };

    // Search Enter Button
    const onPressEnter = (event: any) => {
        let value = event.target.value.trim()
        let obj = { ...searchObject };
        switch (dropdownvalue) {
            case 'Battery ID':
                obj.batteryId = value;
                break;
            case 'Battery Rx Serial Number':
                obj.batteryRxSim = value;
                break;
            case 'Vehicle ID':
                obj.vehicleId = value;
                break;
            case 'Battery Rx SIM #':
                obj.serialNumber = value;
                break;
        }
        setSearchObject({ ...obj })
        setlogReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
        getData({ ...logReqBody, ...obj, pageOffset: 1 })
        getCount({ ...logReqBody, ...obj, pageOffset: 1 })
    };


    const handleSearch = () => {
        const { batteryId, vehicleId, batteryRxSim, serialNumber } = searchObject
        let obj = { ...searchObject }
        switch (dropdownvalue) {
            case 'Battery ID':
                if (!batteryId) {
                    setemptyError("Please Enter Battery Id")
                    return
                } else {
                    obj.batteryId = inputvalue.trim();
                }
                break;
            case 'Battery Rx Serial Number':
                if (!batteryRxSim) {
                    setemptyError("Please Enter Battery RX Serial Number")
                    return
                } else {
                    obj.batteryRxSim = inputvalue.trim();
                }
                break;
            case 'Battery Rx SIM #':
                if (!serialNumber) {
                    setemptyError("Please Enter Battery Rx SIM")
                    return
                } else {
                    obj.serialNumber = inputvalue.trim();
                }
                break;
            case 'Vehicle ID':
                if (!vehicleId) {
                    setemptyError("Please Enter Vehicle ID")
                    return
                } else {
                    obj.vehicleId = inputvalue.trim();
                }
                break;
            default:
                setemptyError("")
        }
        setSearchObject({ ...obj })
        setlogReqBody({ ...logReqBody, ...obj, pageOffset: 1 })
        getData({ ...logReqBody, ...obj, pageOffset: 1 })
        getCount({ ...logReqBody, ...obj, pageOffset: 1 })
        setCurrent(1)
    };

    useEffect(() => {
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (!permission.includes('BbmidInventory_W')) window.history.back()

        setinitial(true)
        getReportSettings();
    }, [])

    useEffect(() => {
        if (initial == false) {
            let org = globalData?.orgId > -1 ? globalData?.orgId : userId;

            setlogReqBody({ ...logReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            setCountReqBody({ ...countReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            getData({ ...logReqBody, orgId: org, pageOffset: 1, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            getCount({ ...logReqBody, orgId: org, pageOffset: 1, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

    async function getData(load?: any) {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);


        let payload = load;
        if (payload?.batteryId !== "") {
            payload.batteryId = payload?.batteryId.trim();
        } else {
            payload.batteryId = data?.batteryId.trim();
        }

        if (payload?.vehicleId !== "") {
            payload.vehicleId = payload?.vehicleId.trim();
        } else {
            payload.vehicleId = data?.vehicleId;
        }

        setIsShowSpinner(true);
        try {
            const response = await service({
                url: constantUrl.api.BMIDInventory.getInventory,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setInventory(response);
            setIsShowSpinner(false);
        } catch (error) {
            console.error("getBMIDInventory:", error);
            setIsShowSpinner(false);
        }
    }

    async function getCount(load?: any) {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = load;
        if (payload?.batteryId !== "") {
            payload.batteryId = payload?.batteryId.trim();
        } else {
            payload.batteryId = data?.batteryId.trim();
        }

        if (payload?.vehicleId !== "") {
            payload.vehicleId = payload?.vehicleId.trim();
        } else {
            payload.vehicleId = data?.vehicleId;
        }

        try {
            const response = await service({
                url: constantUrl.api.BMIDInventory.getInventoryCount,
                method: "POST",
                data: load,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setTotalCount(response);
        } catch (error) {
            console.error("getBMIDInventoryCount:", error);
        }
    }

    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setPageSize(numPageSize)
        setCurrent(1);
        setlogReqBody({ ...logReqBody, pageLimit: numPageSize, pageOffset: 1 })
        getData({ ...logReqBody, pageLimit: numPageSize, pageOffset: 1 })
    }

    const handlePagination = (pageNumber: number) => {
        setlogReqBody({ ...logReqBody, pageOffset: pageNumber });
        getData({ ...logReqBody, pageOffset: pageNumber })
        setCurrent(pageNumber);
    }

    useEffect(() => {
        if (action !== "") {
            getDowFormat(action)
        }
    }, [action])

    // useEffect(() => {
    //     if (refresh !== 0) {
    //         doRefresh()
    //     }
    // }, [refresh])

    const getDowFormat = async (format: string) => {
        if (selectorLabel.some((item: any) => item.enabled == true)) {
            setIsShowSpinner(true)
            let userInfo: any = Utility.getUserInfo();
            let token = userInfo.authToken
            let payload = {
                pageOffset: 1,
                pageLimit: totalCount + 1,
                orgId: logReqBody?.orgId,
                sortColumns: logReqBody?.sortColumns,
                sortDirection: logReqBody?.sortDirection,
                batteryRxSim: logReqBody?.batteryRxSim,
                serialNumber: logReqBody?.serialNumber,
                divisionId: logReqBody?.divisionId,
                locationId: logReqBody?.locationId,
                buildingId: logReqBody?.buildingId,
                batteryId: logReqBody?.batteryId,
                vehicleId: logReqBody?.vehicleId,
                timeZone: logReqBody?.timeZone
            }
            let data: any = sessionStorage.getItem('globalData');
            data = data && JSON.parse(data);

            if (payload?.batteryId == "") {
                payload.batteryId = data?.batteryId.trim();
            }
            if (payload?.vehicleId == "") {
                payload.vehicleId = data?.vehicleId.trim();
            }

            try {
                const response = await service({
                    url: constantUrl.api.BMIDInventory.downloadFile + format,
                    method: 'POST',
                    responseType: 'blob',
                    headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                    data: payload
                })

                if (response) {
                    let url = window.URL.createObjectURL(response);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'BMID_Inventory.' + format;
                    a.click();
                    setIsShowSpinner(false)
                }
            } catch (error) {
                console.log(error)
                setIsShowSpinner(false)
            }
        }
        else {
            setAlert(true)
        }
    }

    const selectColumn = () => {
        if (selectorRef.current && selector) {
            selectorRef.current.style.right = "-370px";
        } else {
            if (selectorRef.current) { selectorRef.current.style.right = "0px" }
        }
        setSelector(!selector);
    };

    const applyChanges = async () => {
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;
        setIsShowSpinner(true);
        selectColumn();

        var columnPayload = {
            reportId: 'BMID_INVENTORY',
            reportName: 'BMID_INVENTORY',
            columnSettings: selectorLabel,
            sortColumn: logReqBody?.sortColumns,
            sortDirection: logReqBody?.sortDirection
        }

        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}`,
                method: "PUT",
                headers: { Authorization: "Bearer " + authToken },
                data: columnPayload
            })

            setIsShowSpinner(false)
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn([...columnData])
            setSelectorLabel([...selectorData])
        } catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    }

    const onchangeColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
        let columndata: any = selectorLabel
        let index: number = parseInt(event.target.name);
        let checked: boolean = event.target.checked;
        columndata[index].enabled = checked
        setSelectorLabel([...columndata])
        setClearAll(false)
        setSelectAll(false)
    };

    const clearall = () => {
        let columnLable = selectorLabel
        for (let i = 0; i < columnLable.length; i++) {
            columnLable[i]['enabled'] = false;
        }
        setSelectorLabel([...columnLable])
        setClearAll(true)
        setSelectAll(false)
    };

    const selectall = () => {
        let columnLable = selectorLabel
        for (let i = 0; i < columnLable.length; i++) {
            columnLable[i]['enabled'] = true;
        }
        setSelectorLabel(columnLable)
        setClearAll(false)
        setSelectAll(true)
    }

    const cancelSelection = () => {
        let columnData = selectorLabel;
        for (let i = 0; i < columnData.length; i++) {
            columnData[i]['enabled'] = columnData[i].enabled;
        }
        setSelectorLabel(columnData)
        selectColumn()
    };

    const getReportSettings = async () => {
        const reportId = 'BMID_INVENTORY'
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let org = data?.orgId > -1 ? data?.orgId : userId;

        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
                method: "GET",
                headers: { Authorization: "Bearer " + authToken },
            })

            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn(columnData)
            setSelectorLabel(selectorData)

            setlogReqBody({ ...logReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            setCountReqBody({ ...countReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            getData({ ...logReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            getCount({ ...logReqBody, sortColumns: response?.data?.sortColumn ?? "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            setinitial(false)
        } catch (error) {
            console.log(error)
        }
    }

    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]
        switch (id) {

            case "vehicleId":
                if (sort.vehicleId) {
                    setlogReqBody({ ...logReqBody, sortColumns: "vehicle_Id", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "vehicle_Id", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "vehicle_Id", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "vehicle_Id", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "batteryId":
                if (sort.batteryId) {
                    setlogReqBody({ ...logReqBody, sortColumns: "battery_Id", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "battery_Id", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "battery_Id", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "battery_Id", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "serialNumber":
                if (sort.serialNumber) {
                    setlogReqBody({ ...logReqBody, sortColumns: "serial_Number", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "serial_Number", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "serial_Number", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "serial_Number", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "sim":
                if (sort.sim) {
                    setlogReqBody({ ...logReqBody, sortColumns: "part_Nbr", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "part_Nbr", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "part_Nbr", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "part_Nbr", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "timeZone":
                if (sort.timeZone) {
                    setlogReqBody({ ...logReqBody, sortColumns: "timezone", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "timezone", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "timezone", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "timezone", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "dealer":
                if (sort.dealer) {
                    setlogReqBody({ ...logReqBody, sortColumns: "org_Id", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "org_Id", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "org_Id", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "org_Id", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "company":
                if (sort.company) {
                    setlogReqBody({ ...logReqBody, sortColumns: "division_Id", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "division_Id", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "division_Id", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "division_Id", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "location":
                if (sort.location) {
                    setlogReqBody({ ...logReqBody, sortColumns: "location_Id", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "location_Id", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "location_Id", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "location_Id", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "site":
                if (sort.site) {
                    setlogReqBody({ ...logReqBody, sortColumns: "building_Id", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "building_Id", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "building_Id", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "building_Id", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "protocal":
                if (sort.protocal) {
                    setlogReqBody({ ...logReqBody, sortColumns: "protocol_Version", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "protocol_Version", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "protocol_Version", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "protocol_Version", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "boot":
                if (sort.boot) {
                    setlogReqBody({ ...logReqBody, sortColumns: "boot_Kernel_Version", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "boot_Kernel_Version", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "boot_Kernel_Version", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "boot_Kernel_Version", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "app":
                if (sort.app) {
                    setlogReqBody({ ...logReqBody, sortColumns: "app_Version", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "app_Version", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "app_Version", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "app_Version", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "manufactureDate":
                if (sort.manufactureDate) {
                    setlogReqBody({ ...logReqBody, sortColumns: "manufacture_Date", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "manufacture_Date", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "manufacture_Date", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "manufacture_Date", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "inserviceDate":
                if (sort.inserviceDate) {
                    setlogReqBody({ ...logReqBody, sortColumns: "in_Service_Date", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "in_Service_Date", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "in_Service_Date", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "in_Service_Date", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "telephone":
                if (sort.telephone) {
                    setlogReqBody({ ...logReqBody, sortColumns: "telephone_Number", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "telephone_Number", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "telephone_Number", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "telephone_Number", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "lastcontract":
                if (sort.lastcontract) {
                    setlogReqBody({ ...logReqBody, sortColumns: "date_Last_Complete_Session", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "date_Last_Complete_Session", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "date_Last_Complete_Session", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "date_Last_Complete_Session", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "enabled":
                if (sort.enabled) {
                    setlogReqBody({ ...logReqBody, sortColumns: "enable_State", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "enable_State", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "enable_State", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "enable_State", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "enabledDate":
                if (sort.enabledDate) {
                    setlogReqBody({ ...logReqBody, sortColumns: "enabled_Date", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "enabled_Date", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "enabled_Date", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "enabled_Date", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "emeter":
                if (sort.emeter) {
                    setlogReqBody({ ...logReqBody, sortColumns: "emeter_Identifier", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "emeter_Identifier", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "emeter_Identifier", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "emeter_Identifier", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "emeterSession":
                if (sort.emeterSession) {
                    setlogReqBody({ ...logReqBody, sortColumns: "emeter_Session_Id", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "emeter_Session_Id", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "emeter_Session_Id", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "emeter_Session_Id", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "constart":
                if (sort.constart) {
                    setlogReqBody({ ...logReqBody, sortColumns: "date_Contract_Start", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "date_Contract_Start", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "date_Contract_Start", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "date_Contract_Start", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "conend":
                if (sort.conend) {
                    setlogReqBody({ ...logReqBody, sortColumns: "date_Contract_End", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "date_Contract_End", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "date_Contract_End", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "date_Contract_End", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "date_Updated":
                if (sort.date_Updated) {
                    setlogReqBody({ ...logReqBody, sortColumns: "date_Updated", sortDirection: "ASC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "date_Updated", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setlogReqBody({ ...logReqBody, sortColumns: "date_Updated", sortDirection: "DESC", pageOffset: 1 })
                    getData({ ...logReqBody, sortColumns: "date_Updated", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
        }
    };

    // const doRefresh = () => {
    //     getData({ ...logReqBody })
    //     getCount({ ...logReqBody })
    // }

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div>
                <div ref={filtersRef} className="posi_bmid_search_container">
                    <div className="col-md-6 px-0 drop_search-main">
                        <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                            <div className='ant-design-select'>
                                <Select
                                    value={dropdownvalue}
                                    size={'middle'}
                                    onChange={onChangedropdown}
                                    className='drop-filter'
                                    dropdownStyle={{ zIndex: 900 }}
                                >
                                    {DropList && DropList.map((item: any, index: any) => {
                                        return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                                    })}
                                </Select>
                            </div>
                            <div>
                                <Space direction="vertical" size="middle" className='search-input'>
                                    <Space.Compact className='posi_bmid_search_width'>
                                        <Input allowClear placeholder={placeholder} value={inputvalue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                                            onPressEnter={onPressEnter}
                                        />
                                        <Button type="primary" onClick={handleSearch}>Find</Button>
                                    </Space.Compact>
                                </Space>
                                <div className="errorColor">{emptyError}</div>
                            </div>

                        </ConfigProvider>
                    </div>

                    <div className="column-selector-btn">
                        <button
                            onClick={selectColumn}
                            type="button"
                            className="btn btn-light selector-btn"
                        >
                            Column Selector
                        </button>
                    </div>
                </div>
                <div className="main-panel pt-3 position-relative">
                    <div className="ChartPanel panelShadow bg-white">
                        <div className="chartTitle p-2">
                            <div className='name'>BMID Inventory</div>
                            <div className='show-entries'>
                                <ShowEntries getPageSize={onSelect} />
                            </div>
                        </div>
                        <div id="table-container">
                            <div style={{ height: tableHeight + 'px' }} className=" overflow-auto scrollbar">
                                {/* firmhis_report */}
                                <table className="billable width100">
                                    <thead className='tableHeader'>
                                        <tr className="billable-head">
                                            {column?.[0]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[0]?.['columnName']}</span>
                                                    {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[1]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[1]?.['columnName']}</span>
                                                    {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[2]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('serialNumber')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[2]?.['columnName']}</span>
                                                    {sort.serialNumber ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[3]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('sim')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[3]?.['columnName']}</span>
                                                    {sort.sim ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[4]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('timeZone')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[4]?.['columnName']}</span>
                                                    {sort.timeZone ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[5]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('dealer')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[5]?.['columnName']}</span>
                                                    {sort.dealer ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[6]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('company')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[6]?.['columnName']}</span>
                                                    {sort.company ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[7]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('location')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[7]?.['columnName']}</span>
                                                    {sort.location ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[8]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('site')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[8]?.['columnName']}</span>
                                                    {sort.site ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[9]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('protocal')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[9]?.['columnName']}</span>
                                                    {sort.protocal ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[10]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('boot')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[10]?.['columnName']}</span>
                                                    {sort.boot ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[11]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('app')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[11]?.['columnName']}</span>
                                                    {sort.app ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[12]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('manufactureDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer', width: "88px" }}>
                                                    <span >{column?.[12]?.['columnName']}</span>
                                                    {sort.manufactureDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[13]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('inserviceDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[13]?.['columnName']}</span>
                                                    {sort.inserviceDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[14]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('telephone')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[14]?.['columnName']}</span>
                                                    {sort.telephone ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[15]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('lastcontract')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[15]?.['columnName']}</span>
                                                    {sort.lastcontract ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[16]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('enabled')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[16]?.['columnName']}</span>
                                                    {sort.enabled ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[17]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('enabledDate')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[17]?.['columnName']}</span>
                                                    {sort.enabledDate ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[18]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('emeter')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[18]?.['columnName']}</span>
                                                    {sort.emeter ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[19]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('emeterSession')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[19]?.['columnName']}</span>
                                                    {sort.emeterSession ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[20]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('constart')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[20]?.['columnName']}</span>
                                                    {sort.constart ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[21]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('conend')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[21]?.['columnName']}</span>
                                                    {sort.conend ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                            {column?.[22]?.['enabled'] ? <th>
                                                <div onClick={() => sortBy('date_Updated')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                    <span >{column?.[22]?.['columnName']}</span>
                                                    {sort.date_Updated ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                </div>
                                            </th> : ""}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            inventory?.map((item: any, index: number) => {
                                                const { vehicle_Id, battery_Id, serial_Number, part_Nbr, timezone, org_Name, division_Name, location_Name,
                                                    building_Name, protocol_Version, boot_Kernel_Version, app_Version,
                                                    telephone_Number, enable_State, formatEnabled_Date, emeter_Identifier, emeter_Session_Id,
                                                    formatDate_Contract_Start, formatDate_Contract_End, formatDate_Updated, formatManufacture_Date, formatIn_Service_Date, formatDate_Last_Complete_Session } = item
                                                return <tr key={'keys' + index}>
                                                    {column?.[0]?.['enabled'] ? <td>{vehicle_Id}</td> : ""}
                                                    {column?.[1]?.['enabled'] ? <td>{battery_Id}</td> : ""}
                                                    {column?.[2]?.['enabled'] ? <td><Link to="/battery-rxconfig" state={{ sno: serial_Number, batterrid: battery_Id, vehicleid: vehicle_Id, rxsim: part_Nbr, appVer: app_Version }}>{serial_Number}</Link></td> : ""}
                                                    {column?.[3]?.['enabled'] ? <td>{part_Nbr}</td> : ""}
                                                    {column?.[4]?.['enabled'] ? <td>{timezone}</td> : ""}
                                                    {column?.[5]?.['enabled'] ? <td>{org_Name}</td> : ""}
                                                    {column?.[6]?.['enabled'] ? <td>{division_Name}</td> : ""}
                                                    {column?.[7]?.['enabled'] ? <td>{location_Name}</td> : ""}
                                                    {column?.[8]?.['enabled'] ? <td>{building_Name}</td> : ""}
                                                    {column?.[9]?.['enabled'] ? <td>{protocol_Version}</td> : ""}
                                                    {column?.[10]?.['enabled'] ? <td>{boot_Kernel_Version}</td> : ""}
                                                    {column?.[11]?.['enabled'] ? <td>{app_Version}</td> : ""}
                                                    {column?.[12]?.['enabled'] ? <td>{formatManufacture_Date}</td> : ""}
                                                    {column?.[13]?.['enabled'] ? <td>{formatIn_Service_Date}</td> : ""}
                                                    {column?.[14]?.['enabled'] ? <td>{telephone_Number}</td> : ""}
                                                    {column?.[15]?.['enabled'] ? <td>{formatDate_Last_Complete_Session}</td> : ""}
                                                    {column?.[16]?.['enabled'] ? <td>{enable_State}</td> : ""}
                                                    {column?.[17]?.['enabled'] ? <td>{formatEnabled_Date}</td> : ""}
                                                    {column?.[18]?.['enabled'] ? <td>{emeter_Identifier}</td> : ""}
                                                    {column?.[19]?.['enabled'] ? <td>{emeter_Session_Id}</td> : ""}
                                                    {column?.[20]?.['enabled'] ? <td>{formatDate_Contract_Start}</td> : ""}
                                                    {column?.[21]?.['enabled'] ? <td>{formatDate_Contract_End}</td> : ""}
                                                    {column?.[22]?.['enabled'] ? <td>{formatDate_Updated}</td> : ""}
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div ref={paginationRef}>
                                <PaginationComponent
                                    handlePagination={handlePagination}
                                    totalLength={totalCount}
                                    pageSize={pageSize}
                                    current={current}
                                />
                            </div>
                        </div>
                    </div>
                    <div ref={selectorRef} className="column-selector selector ">
                        <div className="column-title">Column Selector</div>
                        <div className="slector-body">
                            <label>
                                <input
                                    checked={clearAll}
                                    value="clearAll"
                                    type="checkbox"
                                    aria-label="checkbox"
                                    onChange={clearall}
                                />
                                <span></span>
                            </label>
                            <p>Clear All</p>

                            <label>
                                <input
                                    checked={selectAll}
                                    value="selectAll"
                                    type="checkbox"
                                    aria-label="checkbox"
                                    onChange={selectall}
                                />
                                <span></span>
                            </label>
                            <p>Select All</p>
                        </div>
                        <div style={{ height: tableHeight - 126 + 'px' }} className="selector-body-height scrollbar">
                            {selectorLabel?.map((item: any, index: any) => {
                                return (
                                    <div key={'key' + index} className="slector-body">
                                        <label>
                                            <input
                                                name={index}
                                                onChange={onchangeColumn}
                                                checked={item['enabled']}
                                                value={item.value}
                                                type="checkbox"
                                                aria-label="checkbox"
                                            />
                                            <span></span>
                                        </label>
                                        <p>{item['columnName']}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="m-flex">
                            <button onClick={cancelSelection} className="cancel">
                                Cancel
                            </button>
                            <button onClick={applyChanges} className="apply-changes mr-3">
                                Apply Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={alert}
                closable={false}
                centered title={"Atleast Select 1 Column"}
                footer={[
                    <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
                ]}
            ></Modal>
            <SessionWarning />
        </>
    );
}


export default BMIDInventory;