import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ShowEntries from "../reusableComponent/showEntries";
import PaginationComponent from "../reusableComponent/pagination";
import DaysFilter from "../reusableComponent/DaysFilter";
import { Utility } from "../../helper/index";
import constantUrl from '../../settings/constants';
import service from '../../service/api/common';
import LoadingSpinner from '../spinner/loading-spinner';
import SessionWarning from '../reusableComponent/sessionWarning';
import { format, subDays, parseISO } from 'date-fns';
import { resize, hideSidebar } from '../../settings/resize';
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Input, Select, Space, Modal } from "antd";

const today = format(new Date(), 'dd-MMM-yyyy');
const sevenDaysAgo = format(subDays(new Date(), 6), 'dd-MMM-yyyy');

const userInfo: any = Utility.getUserInfo();
const userId: number = userInfo.userId;
const token: any = userInfo.authToken

type reqBody = {
    orgId: number,
    divisionId: number,
    locationId: number,
    buildingId: number,
    startDate: string,
    endDate: string,
    batteryId: string,
    vehicleId: string,
    serialNumber: string,
    pageOffset: number,
    pageLimit: number,
    sortColumns: string,
    sortDirection: string,
    timeZone: string
}

let Body = {
    orgId: 0,
    divisionId: -1,
    locationId: -1,
    buildingId: -1,
    startDate: sevenDaysAgo,
    endDate: today,
    batteryId: "",
    vehicleId: "",
    serialNumber: "",
    pageOffset: 1,
    pageLimit: 10,
    sortColumns: "",
    sortDirection: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
}

interface Props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    }
    ,
    action: string
    // refresh: number
}

const ChargeSummaryReport: FC<Props> = ({ globalData, action}) => {
    // const ChargeSummaryReport: FC<Props> = ({ globalData, action, refresh }) => {
    let userInfo: any = Utility.getUserInfo();
    let userId: number = userInfo.userId;
    const [column, setColumn] = useState<any[]>([])
    const [selectorLabel, setSelectorLabel] = useState<any[]>([])
    const [sort, setSort] = useState<any>({
        batteryId: false, vehicleId: false, batterySim: false, batteryCapacity: false, chargeAhrs: false,
        totchrgHours: false, totRunHours: false, totIdleHours: false, formatAverageTemp: false, formatMaxTemp: false,
        minSOc: false, avgInitSoc: false, totChargeEvents: false, avgDailyUsage: false, avgChrg: false,
        avgIdle: false, avgRun: false, ebu: false, org: false, div: false, loc: false, site: false, dateStamp: false
    })
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [selector, setSelector] = useState(false)
    const [tableReqBody, setTableReqBody] = useState<reqBody>(Body)
    const [tableReport, setTableReport] = useState<any[]>([])
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false)
    const [current, setCurrent] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [count, setCount] = useState<number>(0)
    const [value, onChange] = useState<any>([subDays(new Date(), 6), new Date()]);
    const [clearAll, setClearAll] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [initial, setInitial] = useState<boolean>(true);
    const [alert, setAlert] = useState<boolean>(false);

    const selectorRef = useRef<HTMLDivElement>(null)

    const [emptyError, setemptyError] = useState<string>("");

    const [searchObject, setSearchObject] = useState<any>({
        confBatteryId: '',
        confVehicleId: '',
        serialNumber: ''
    })

    const DropList = [
        { label: "Battery Id" },
        { label: "Vehicle ID" },
        { label: "Battery Rx SIM #" }

    ];

    const [dropdownvalue, setDropDownValue] = useState<string>("Battery Id");
    const [placeholder, setPlaceholder] = useState<string>("Search Battery ID");
    const [inputvalue, setInput] = useState<any>("")

    const [tableHeight, setTableHeight] = useState<any>(445)
    const filtersRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
                callDebounce()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useLayoutEffect(() => {
        resize();
        hideSidebar();
        callDebounce()
    }, []);

    const callDebounce = () => {
        //if(window.innerHeight<=568) return
        let marginAndPaddings: any = 80
        let navHeight = localStorage.getItem('navHeight')
        let filtersHeight
        let paginationHeight
        if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
        if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
        let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
        let tableHeight = window.innerHeight - subHeight
        tableHeight < 445 ? setTableHeight(445) : setTableHeight(tableHeight)
    };

    const onChangedropdown = (value: string) => {
        setemptyError("")
        setDropDownValue(value);
        setPlaceholder(`Search ${value}`)
        setInput("");
        setSearchObject({
            batteryId: '',
            confBatteryId: '',
            vehicleId: '',
            confVehicleId: '',
            serialNumber: ''
        })

    }

    const onChangePayload = (value: any) => {
        let obj = { ...searchObject };
        if (dropdownvalue === "Battery Id") {
            obj.batteryId = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Battery Rx SIM #") {
            obj.serialNumber = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        } else if (dropdownvalue === "Vehicle ID") {
            obj.vehicleId = value.trim();
            setSearchObject({ ...obj })
            setInput(value);
            !value && callCountAndReport({ ...obj, pageOffset: 1 })
            setCurrent(1)
        }
        setemptyError("")
    }

    const callCountAndReport = (obj: any) => {
        setTableReqBody({ ...tableReqBody, ...obj })
    };

    // Search Enter Button
    const onPressEnter = (event: any) => {
        let value = event.target.value.trim()
        let obj = { ...searchObject };
        switch (dropdownvalue) {
            case 'Battery Id':
                obj.batteryId = value;
                break;
            case 'Battery RX SIM':
                obj.serialNumber = value;
                break;
            case 'Vehicle ID':
                obj.vehicleId = value;
                break;
        }
        setSearchObject({ ...obj })
        setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
    };

    const handleSearch = () => {
        const { confBatteryId, confVehicleId, serialNumber, batteryId, vehicleId } = searchObject
        let obj = { ...searchObject }
        switch (dropdownvalue) {
            case 'Battery Id':
                if (!batteryId) {
                    setemptyError("Please Enter Battery Id")
                    return
                } else {
                    obj.batteryId = inputvalue.trim();
                }
                break;
            case 'Battery Rx SIM #':
                if (!serialNumber) {
                    setemptyError("Please Enter Battery Rx SIM")
                    return
                } else {
                    obj.serialNumber = inputvalue.trim();
                }
                break;
            case 'Vehicle ID':
                if (!vehicleId) {
                    setemptyError("Please Enter Vehicle ID")
                    return
                } else {
                    obj.vehicleId = inputvalue.trim();
                }
                break;
            default:
                setemptyError("")
        }
        setSearchObject({ ...obj })
        setTableReqBody({ ...tableReqBody, ...obj, pageOffset: 1 })
    }

    useEffect(() => {
        let date1: any = value && format(value[0], 'dd-MMM-yyyy');
        let date2: any = value && format(value[1], 'dd-MMM-yyyy');
        if (date1 && date2) {
            daysFilter(date1 ?? sevenDaysAgo, date2 ?? today);
        }
    }, [value])

    useEffect(() => {
        getChargeSummary();
        getCount();
    }, [tableReqBody]);

    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setPageSize(numPageSize)
        setCurrent(1);
        setTableReqBody({ ...tableReqBody, pageLimit: numPageSize, pageOffset: 1 })
    }

    const handlePagination = (pageNumber: number) => {
        setTableReqBody({ ...tableReqBody, pageOffset: pageNumber });
        setCurrent(pageNumber);
    }

    const daysFilter = (startDate: string, endDate: string) => setTableReqBody({ ...tableReqBody, startDate, endDate });

    const selectColumn = () => {
        if (selectorRef.current && selector) {
            selectorRef.current.style.right = "-370px";
        } else {
            if (selectorRef.current) { selectorRef.current.style.right = "0px" }
        }
        setSelector(!selector);
    };

    const applyChanges = async () => {
        selectColumn()
        var columnPayload = {
            reportId: 'CHARGE_SUMMARY',
            reportName: 'CHARGE_SUMMARY',
            columnSettings: selectorLabel,
            sortColumn: tableReqBody?.sortColumns,
            sortDirection: tableReqBody?.sortDirection

        };
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;
        setIsShowSpinner(true);
        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}`,
                method: "PUT",
                headers: { Authorization: "Bearer " + authToken },
                data: columnPayload
            })
            setIsShowSpinner(false)
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn([...columnData])
            setSelectorLabel([...selectorData])
        }
        catch (error) {
            console.log(error)
            setIsShowSpinner(false)
        }
    };

    const onchangeColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
        let columndata: any = selectorLabel
        let index: number = parseInt(event.target.name);
        let checked: boolean = event.target.checked;
        columndata[index].enabled = checked
        setSelectorLabel([...columndata])
        setClearAll(false)
        setSelectAll(false)
    };

    const clearall = () => {
        let columnLable = selectorLabel
        for (let i = 0; i < columnLable.length; i++) {
            columnLable[i]['enabled'] = false;
        }
        setSelectorLabel([...columnLable])
        setClearAll(true)
        setSelectAll(false)
    };

    const selectall = () => {
        let columnLable = selectorLabel
        for (let i = 0; i < columnLable.length; i++) {
            columnLable[i]['enabled'] = true;
        }
        setSelectorLabel(columnLable)
        setClearAll(false)
        setSelectAll(true)
    }

    const cancelSelection = () => {
        let columnData = selectorLabel;
        for (let i = 0; i < columnData.length; i++) {
            columnData[i]['enabled'] = columnData[i].enabled;
        }
        setSelectorLabel(columnData)
        selectColumn()
    };

    const getReportSettings = async () => {
        const reportId = 'CHARGE_SUMMARY'
        let userInfo: any = Utility.getUserInfo();
        var authToken = userInfo.authToken;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let org = data?.orgId > -1 ? data?.orgId : userId;


        try {
            const response = await service({
                url: `${constantUrl.api.reportSettings.settings}?reportId=${reportId}`,
                method: "GET",
                headers: { Authorization: "Bearer " + authToken },
            })
            let columnData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            let selectorData = JSON.parse(JSON.stringify(response.data?.['columnSettings']));
            setColumn(columnData)
            setSelectorLabel(selectorData)

            setTableReqBody({ ...tableReqBody, sortColumns: response?.data?.sortColumn ?? "", serialNumber: "", sortDirection: response?.data?.sortDirection ?? "", orgId: org, divisionId: data?.divId, locationId: data?.locationId ? data?.locationId : -1, buildingId: data?.buildingId ? data?.buildingId : -1, batteryId: data?.batteryId, vehicleId: data?.vehicleId })
            setInitial(false)

        }
        catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('chargeSummary_W')) window.history.back()
        }

        getReportSettings();
        setInitial(true)
        window.scrollTo(0, 0)

    }, [])

    useEffect(() => {
        if (initial === false) {
            let org = globalData?.orgId > -1 ? globalData?.orgId : userId;
            setTableReqBody({ ...tableReqBody, pageOffset: 1, orgId: org, divisionId: globalData?.divId, locationId: globalData?.locationId, buildingId: globalData?.buildingId, batteryId: globalData?.batteryId, vehicleId: globalData?.vehicleId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId, globalData?.locationId, globalData?.buildingId, globalData?.batteryId, globalData?.vehicleId])

    const haveSameData = function (obj1: any, obj2: any) {
        const obj1Length = Object.keys(obj1).length;
        const obj2Length = Object.keys(obj2).length;

        if (obj1Length === obj2Length) {
            return Object.keys(obj1).every(
                key => obj2.hasOwnProperty(key)
                    && obj2[key] === obj1[key]);
        }
        return false;
    }

    async function getChargeSummary() {
        if (tableReqBody.orgId === 0) return
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = tableReqBody;

        if (payload.batteryId == "") payload.batteryId = data?.batteryId.trim();
        if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();

        if (payload.orgId === 0) return
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setIsShowSpinner(true)
        try {
            const response = await service({
                url: constantUrl.api.ChargeSummaryReport.getChargeSummary,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setTableReport(response);
            setIsShowSpinner(false);
        } catch (error) {
            console.error("getChargeSummary:", error);
            setIsShowSpinner(false);
        }
    }

    async function getCount() {
        if (tableReqBody.orgId === 0) return
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        let payload = tableReqBody;

        if (payload.batteryId == "") payload.batteryId = data?.batteryId.trim();
        if (payload.vehicleId === "") payload.vehicleId = data?.vehicleId.trim();

        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        try {
            const response = await service({
                url: constantUrl.api.ChargeSummaryReport.getChargeSummaryCount,
                method: "POST",
                data: payload,
                headers: { accept: "*/*", Authorization: "Bearer " + token },
            });
            setCount(response);
        } catch (error) {
            console.error("getChargeSummaryCount:", error);
        }
    }

    useEffect(() => {
        if (action !== "") {
            getDowFormat(action)
        }
    }, [action])

    const getDowFormat = async (format: string) => {
        if (selectorLabel.some((item: any) => item.enabled == true)) {
            setIsShowSpinner(true)
            let payload = {
                orgId: tableReqBody?.orgId,
                divisionId: tableReqBody?.divisionId,
                locationId: tableReqBody?.locationId,
                buildingId: tableReqBody?.buildingId,
                startDate: tableReqBody?.startDate,
                endDate: tableReqBody?.endDate,
                batteryId: tableReqBody?.batteryId,
                vehicleId: tableReqBody?.vehicleId,
                serialNumber: tableReqBody?.serialNumber,
                pageOffset: 1,
                pageLimit: count + 1,
                sortColumns: tableReqBody?.sortColumns,
                sortDirection: tableReqBody?.sortDirection,
                timeZone: tableReqBody?.timeZone
            }
            let userInfo: any = Utility.getUserInfo();
            let token: string = userInfo.authToken;
            try {
                const response = await service({
                    url: constantUrl.api.ChargeSummaryReport.downloadFile + format,
                    method: 'POST',
                    responseType: 'blob',
                    headers: { "Content-type": "application/json", Authorization: "Bearer " + token },
                    data: payload
                })

                if (response) {
                    let url = window.URL.createObjectURL(response);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'ChargeSummaryReport.' + format;
                    a.click();
                    setIsShowSpinner(false)
                }

            } catch (error) {
                console.log(error)
                setIsShowSpinner(false)
            }
        }
        else {
            setAlert(true)
        }
    }

    const formattedDate = (date: string) => {
        const date1 = date;
        const parsedDate = parseISO(date1);
        const formatDate = format(parsedDate, 'M/d/yyyy h:mm a');
        return formatDate
    }

    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]
        switch (id) {
            case "batteryId":
                if (sort.batteryId) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "confBatteryId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "confBatteryId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "vehicleId":
                if (sort.vehicleId) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "confVehicleId", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "confVehicleId", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "batterySim":
                if (sort.batterySim) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "serialNumber", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "serialNumber", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "batteryCapacity":
                if (sort.batteryCapacity) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "capacity", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "capacity", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "chargeAhrs":
                if (sort.chargeAhrs) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "totalChargeAh", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "totalChargeAh", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "totchrgHours":
                if (sort.totchrgHours) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "totalChargeMinutes", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "totalChargeMinutes", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "totRunHours":
                if (sort.totRunHours) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "totalRunTime", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "totalRunTime", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "totIdleHours":
                if (sort.totIdleHours) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "totalIdleTime", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "totalIdleTime", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "avgTemp":
                if (sort.avgTemp) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "formatAverageTemp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "formatAverageTemp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "maxTemp":
                if (sort.maxTemp) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "formatMaxTemp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "formatMaxTemp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "minSOc":
                if (sort.minSOc) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "minSoc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "minSoc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "avgInitSoc":
                if (sort.avgInitSoc) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "averageInitialSoc", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "averageInitialSoc", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "totChargeEvents":
                if (sort.totChargeEvents) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "totalChargeEvents", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "totalChargeEvents", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "avgDailyUsage":
                if (sort.avgDailyUsage) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "averageDailyUsage", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "averageDailyUsage", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "avgChrg":
                if (sort.avgChrg) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "averageDailyChargeMinutes", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "averageDailyChargeMinutes", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "avgIdle":
                if (sort.avgIdle) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "averageDailyIdleTime", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "averageDailyIdleTime", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "avgRun":
                if (sort.avgRun) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "averageDailyRunTime", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "averageDailyRunTime", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "ebu":
                if (sort.ebu) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "ebus", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "ebus", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "org":
                if (sort.org) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "organization", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "organization", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "div":
                if (sort.div) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "division", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "division", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "loc":
                if (sort.loc) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "location", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "location", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "site":
                if (sort.site) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "building", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "building", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case "dateStamp":
                if (sort.dateStamp) {
                    setTableReqBody({ ...tableReqBody, sortColumns: "dateStamp", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    setTableReqBody({ ...tableReqBody, sortColumns: "dateStamp", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
        }
    };


    // useEffect(() => {
    //     if (refresh === 0) return
    //     setTableReqBody({ ...tableReqBody })
    // }, [refresh]);

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div ref={filtersRef} className="posi_search_container">
                <div className="col-md-6 px-0 drop_search-main">
                    <ConfigProvider theme={{ token: { colorPrimary: '#004f9f', colorIconHover: "#282c34" } }}>
                        <div className='ant-design-select'>
                            <Select
                                value={dropdownvalue}
                                size={'middle'}
                                onChange={onChangedropdown}
                                className='drop-filter'
                                dropdownStyle={{ zIndex: 900 }}
                            >
                                {DropList && DropList.map((item: any, index: any) => {
                                    return <Select.Option name={item?.label ?? ""} value={item?.label ?? ""} key={index}>{item?.label ?? ""}</Select.Option>
                                })}
                            </Select>
                        </div>
                        <div>
                            <Space direction="vertical" size="middle" className='search-input'>
                                <Space.Compact className='posi_bmid_search_width'>
                                    <Input allowClear placeholder={placeholder} value={inputvalue} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangePayload(event.target.value) }}
                                        onPressEnter={onPressEnter} />
                                    <Button type="primary" onClick={handleSearch}>Find</Button>
                                </Space.Compact>
                            </Space>
                            <div className="errorColor">{emptyError}</div>
                        </div>
                    </ConfigProvider>
                </div>
                <div className="column-date-filter position-relative">
                    {
                        showDateFilter && <span className="date-selector">
                            <DateRangePicker onChange={onChange} value={value} maxDate={new Date()} />
                        </span>
                    }
                    <button
                        onClick={selectColumn}
                        type="button"
                        className="btn btn-light selector-btn"
                    >
                        Column Selector
                    </button>
                    <span className="date-dropdown">
                        <DaysFilter daysFilter={daysFilter} customFilterStatusChange={setShowDateFilter} />
                    </span>
                </div>
            </div>
            <div className="main-panel pt-3 position-relative">
                <div className="ChartPanel panelShadow bg-white">
                    <div className="chartTitle p-2 d-flex  align-items-center position-relative">
                        <div className='ml-auto mr-auto'>Charge Summary</div>
                        <div className='show-entries'>
                            <ShowEntries getPageSize={onSelect} />
                        </div>
                    </div>
                    <div id="table-container">
                        <div style={{ height: tableHeight + 'px' }} className="charge_summary overflow-auto scrollbar">
                            <table className="billable width100">
                                <thead  className='tableHeader'>
                                    <tr>
                                        {column?.[0]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('batteryId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[0]?.['columnName']}</span>
                                                {sort.batteryId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th>
                                            : ""}
                                        {column?.[1]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('vehicleId')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[1]?.['columnName']}</span>
                                                {sort.vehicleId ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[2]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('batterySim')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[2]?.['columnName']}</span>
                                                {sort.batterySim ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[3]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('batteryCapacity')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[3]?.['columnName']}</span>
                                                {sort.batteryCapacity ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[4]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('chargeAhrs')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[4]?.['columnName']}</span>
                                                {sort.chargeAhrs ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[5]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('totchrgHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[5]?.['columnName']}</span>
                                                {sort.totchrgHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[6]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('totRunHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[6]?.['columnName']}</span>
                                                {sort.totRunHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[7]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('totIdleHours')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[7]?.['columnName']}</span>
                                                {sort.totIdleHours ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[8]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('avgTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[8]?.['columnName']}</span>
                                                {sort.avgTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[9]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('maxTemp')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[9]?.['columnName']}</span>
                                                {sort.maxTemp ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[10]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('minSOc')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[10]?.['columnName']}</span>
                                                {sort.minSOc ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[11]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('avgInitSoc')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[11]?.['columnName']}</span>
                                                {sort.avgInitSoc ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {column?.[12]?.['enabled'] ? <th>
                                            <div onClick={() => sortBy('totChargeEvents')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                <span >{column?.[12]?.['columnName']}</span>
                                                {sort.totChargeEvents ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                            </div>
                                        </th> : ""}
                                        {/* {column?.[13]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('avgDailyUsage')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[13]?.['columnName']}</span>
                                                        {sort.avgDailyUsage ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""} */}
                                        {column?.[13]?.['enabled'] ? <th>
                                            <span >{column?.[13]?.['columnName']}</span>
                                        </th> : ""}
                                        {/* {column?.[14]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('avgChrg')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[14]?.['columnName']}</span>
                                                        {sort.avgChrg ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""} */}
                                        {column?.[14]?.['enabled'] ? <th>
                                            <span >{column?.[14]?.['columnName']}</span>
                                        </th> : ""}
                                        {/* {column?.[15]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('avgIdle')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[15]?.['columnName']}</span>
                                                        {sort.avgIdle ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""} */}
                                        {column?.[15]?.['enabled'] ? <th>
                                            <span >{column?.[15]?.['columnName']}</span>
                                        </th> : ""}
                                        {/* {column?.[16]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('avgRun')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[16]?.['columnName']}</span>
                                                        {sort.avgRun ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""} */}
                                        {column?.[16]?.['enabled'] ? <th>
                                            <span >{column?.[16]?.['columnName']}</span>
                                        </th> : ""}
                                        {/* {column?.[17]?.['enabled'] ? <th>
                                                    <div onClick={() => sortBy('ebu')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >{column?.[17]?.['columnName']}</span>
                                                        {sort.ebu ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th> : ""} */}
                                        {column?.[17]?.['enabled'] ? <th>
                                            <span >{column?.[17]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[18]?.['enabled'] ? <th>
                                            <span >{column?.[18]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[19]?.['enabled'] ? <th>
                                            <span >{column?.[19]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[20]?.['enabled'] ? <th>
                                            <span >{column?.[20]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[21]?.['enabled'] ? <th>
                                            <span >{column?.[21]?.['columnName']}</span>
                                        </th> : ""}
                                        {column?.[22]?.['enabled'] ? <th>
                                            <span >{column?.[22]?.['columnName']}</span>
                                        </th> : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableReport?.map((report: any, index: any) => {
                                            return <React.Fragment key={index}>
                                                <tr>
                                                    {column?.[0]?.['enabled'] ? <td>{report?.confBatteryId}</td> : ""}
                                                    {column?.[1]?.['enabled'] ? <td>{report?.confVehicleId}</td> : ""}
                                                    {column?.[2]?.['enabled'] ? <td>{report?.serialNumber}</td> : ""}
                                                    {column?.[3]?.['enabled'] ? <td>{report?.data?.capacity}</td> : ""}
                                                    {column?.[4]?.['enabled'] ? <td>{report?.data?.totalChargeAh}</td> : ""}
                                                    {column?.[5]?.['enabled'] ? <td>{report?.data?.totalChargeMinutes}</td> : ""}
                                                    {column?.[6]?.['enabled'] ? <td>{report?.data?.totalRunTime}</td> : ""}
                                                    {column?.[7]?.['enabled'] ? <td>{report?.data?.totalIdleTime}</td> : ""}
                                                    {column?.[8]?.['enabled'] ? <td>{report?.data?.formatAverageTemp}</td> : ""}
                                                    {column?.[9]?.['enabled'] ? <td>{report?.data?.formatMaxTemp}</td> : ""}
                                                    {column?.[10]?.['enabled'] ? <td>{report?.data?.minSoc}</td> : ""}
                                                    {column?.[11]?.['enabled'] ? <td>{report?.data?.averageInitialSoc}</td> : ""}
                                                    {column?.[12]?.['enabled'] ? <td>{report?.data?.totalChargeEvents}</td> : ""}
                                                    {column?.[13]?.['enabled'] ? <td>{report?.data?.averageDailyUsage}</td> : ""}
                                                    {column?.[14]?.['enabled'] ? <td>{report?.data?.averageDailyChargeMinutes}</td> : ""}
                                                    {column?.[15]?.['enabled'] ? <td>{report?.data?.averageDailyIdleTime}</td> : ""}
                                                    {column?.[16]?.['enabled'] ? <td>{report?.data?.averageDailyRunTime}</td> : ""}
                                                    {column?.[17]?.['enabled'] ? <td>{report?.data?.ebus}</td> : ""}
                                                    {column?.[18]?.['enabled'] ? <td>{report?.organization}</td> : ""}
                                                    {column?.[19]?.['enabled'] ? <td>{report?.division}</td> : ""}
                                                    {column?.[20]?.['enabled'] ? <td>{report?.location}</td> : ""}
                                                    {column?.[21]?.['enabled'] ? <td>{report?.building}</td> : ""}
                                                    {column?.[22]?.['enabled'] ? <td>{report?.formatDateStamp}</td> : ""}
                                                </tr>
                                            </React.Fragment>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div ref={paginationRef}>
                            <PaginationComponent
                                handlePagination={handlePagination}
                                totalLength={count}
                                pageSize={pageSize}
                                current={current}
                            />
                        </div>
                    </div>

                </div>
                <div ref={selectorRef} className="column-selector selector ">
                    <div className="column-title">Column Selector</div>
                    <div className="slector-body">
                        <label>
                            <input
                                checked={clearAll}
                                value="clearAll"
                                type="checkbox"
                                aria-label="checkbox"
                                onChange={clearall}
                            />
                            <span></span>
                        </label>
                        <p>Clear All</p>

                        <label>
                            <input
                                checked={selectAll}
                                value="selectAll"
                                type="checkbox"
                                aria-label="checkbox"
                                onChange={selectall}
                            />
                            <span></span>
                        </label>
                        <p>Select All</p>
                    </div>
                    <div style={{ height: tableHeight - 126 + 'px' }} className="selector-body-height scrollbar">
                        {selectorLabel?.map((item: any, index: any) => {
                            return (
                                <div key={'key' + index} className="slector-body">
                                    <label>
                                        <input
                                            name={index}
                                            onChange={onchangeColumn}
                                            checked={item['enabled']}
                                            value={item.value}
                                            type="checkbox"
                                            aria-label="checkbox"
                                        />
                                        <span></span>
                                    </label>
                                    <p>{item['columnName']}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className="m-flex">
                        <button onClick={cancelSelection} className="cancel">
                            Cancel
                        </button>
                        <button onClick={applyChanges} className="apply-changes mr-3">
                            Apply Changes
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                open={alert}
                closable={false}
                centered title={"Atleast Select 1 Column"}
                footer={[
                    <Button style={{ background: '#004f9f', color: "white" }} onClick={() => setAlert(false)}>OK</Button>
                ]}
            ></Modal>
            <SessionWarning />
        </>
    )
}
export default ChargeSummaryReport