import React, { FC, useState, useEffect, useLayoutEffect, useRef } from "react";
import { Modal, Form, Input, Button, Select, Tooltip, ConfigProvider, AutoComplete, SelectProps } from "antd";
import { PlusCircleOutlined, EditOutlined, DeleteOutlined, MinusCircleOutlined, CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import service from "../../service/api/common";
import constants from "../../settings/constants";
import { Utility } from "../../helper/index";
import LoadingSpinner from '../spinner/loading-spinner'
import constant1 from '../../settings/constants';
import CountryList from "../reusableComponent/country";
import SessionWarning from '../reusableComponent/sessionWarning';
import PaginationComponent from "../reusableComponent/pagination";
import ShowEntries from "../reusableComponent/showEntries";
import { resize, hideSidebar } from '../../settings/resize';
import * as roles from '../../settings/roles';
import { SearchOutlined } from "@mui/icons-material";

const req = {
    pageOffset: 1,
    pageLimit: 10,
    orgId: 0,
    divisionId: -1,
    sortColumns: "divisionName",
    sortDirection: "ASC"
}

type reqBody = {
    pageOffset: number,
    pageLimit: number,
    orgId: number,
    divisionId: number,
    sortColumns: string,
    sortDirection: string
}

interface props {
    globalData: {
        orgId: number,
        orgName: string,
        divId: number,
        divName: string,
        locationId: number,
        locationName: string,
        buildingId: number,
        batteryId: string,
        buildingName: string,
        batteryName: string,
        vehicleId: string,
        vehicleName: string,
        serialId: string,
        serialName: string
    },
    refresh: number
}

const DivisionMaintenance: FC<props> = ({ globalData, refresh }) => {
    const [reqBody, setReqBody] = useState<any>(req)
    const [show, setShow] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tableData, setTableData] = useState<any[]>([]);
    const [toggleDelete, setToggleDelete] = useState(false)
    const [Title, setTitle] = useState<string>("")
    const [id, setId] = useState<number>()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [toUpdate, setToUpdate] = useState<any>({ divisionId: null, orgId: null, orgName: "", divisionName: "", divisionAddress: "", divisionCountry: "" })
    const [isDivUpdated, setIsDivUpdated] = useState<boolean>(false)
    const [isDivDeleted, setIsDivDeleted] = useState<boolean>(false)
    const [sort, setSort] = useState<any>({ orgName: false, divisionName: false, divisionAddress: false, divisionCountry: false, deleted: false })
    const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [orgList, setOrgList] = useState<null[]>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [current, setCurrent] = useState<number>(1)
    const [initial, setInitial] = useState<boolean>(true);
    const [divQuery, setDivQuery] = useState<string>("")
    const [divisionEmpty, setDivisionEmpty] = useState<boolean>(false)
    const [options, setOptions] = useState<SelectProps<object>['options']>([]);

    const tabelRef = useRef<HTMLDivElement>(null)
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm()

    const [tableHeight, setTableHeight] = useState<any>(425)
    const filtersRef = useRef<HTMLDivElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
                callDebounce()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useLayoutEffect(() => {
        resize();
        hideSidebar();
        callDebounce()
    }, []);

    const callDebounce = () => {
        //if(window.innerHeight<=568) return
        let marginAndPaddings: any = 108
        let navHeight = localStorage.getItem('navHeight')
        let filtersHeight
        let paginationHeight
        if (filtersRef.current) filtersHeight = filtersRef.current.offsetHeight
        if (paginationRef.current) paginationHeight = paginationRef.current.offsetHeight
        let subHeight = marginAndPaddings + Number(navHeight) + filtersHeight + paginationHeight
        let tableHeight = window.innerHeight - subHeight
        tableHeight < 425 ? setTableHeight(425) : setTableHeight(tableHeight)
    };

    const showAddUser = () => {
        setShow(!show)
        form.resetFields()
    };

    useLayoutEffect(() => {
        let timer: any
        const debouncedHandleResize = () => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                resize()
            }, 500)
        }
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    });

    useEffect(() => {
        // check the page permission
        let permission: any = localStorage.getItem('permissions')
        if (permission !== null) {
            if (!permission.includes('divisionMaintenanceR_W')) window.history.back()
        }

        var userInfo: any = Utility.getUserInfo();
        var loginDealerId = userInfo.userId;

        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);
        let orgId = data?.orgId;

        setInitial(true);

        if (roles.PLATFORM_ADMIN === localStorage?.UserType) {
            getOrganization(0, 0, 0, 0);
        } else if (roles.DEALER_ADMIN === localStorage?.UserType) {
            getOrganization(loginDealerId, 0, 0, 0);
        }

        setReqBody({ ...reqBody, pageOffset: 1, orgId: orgId, divisionId: data?.divId })
        getDivisionData({ ...reqBody, pageOffset: 1, orgId: orgId, divisionId: data?.divId })
        getCount({ ...reqBody, pageOffset: 1, orgId: orgId, divisionId: data?.divId })

        setInitial(false);
    }, [])

    useEffect(() => {
        if (initial == false) {
            setReqBody({ ...reqBody, pageOffset: 1, orgId: globalData?.orgId, divisionId: globalData?.divId })
            getDivisionData({ ...reqBody, pageOffset: 1, orgId: globalData?.orgId, divisionId: globalData?.divId })
            getCount({ ...reqBody, pageOffset: 1, orgId: globalData?.orgId, divisionId: globalData?.divId })
            setCurrent(1)
        }
    }, [globalData?.orgId, globalData?.divId])

    async function getOrganization(orgid: any, divid: any, locid: any, siteid: any) {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        let payload = {
            "orgId": orgid,
            "divisionId": divid,
            "locationId": locid,
            "buildingId": siteid
        }

        try {
            const response = await service({
                url: constants.api.dashboard.getOrganizations,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });

            setOrgList(response);
        } catch (error) {
            console.log("getOrg:", error);
        }
    };

    const showUpdateDiv = (item: any) => {
        setIsModalOpen(true);
        const { divisionId, orgId, orgName, divisionName, divisionAddress, divisionCountry } = item;
        setToUpdate({ ...toUpdate, divisionId, orgId, orgName, divisionName, divisionAddress, divisionCountry });
    };

    const changeAddress = (event: any) => {
        setToUpdate({ ...toUpdate, divisionAddress: event.target.value })
    }

    async function handleUpdate() {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        const { divisionId, orgId, divisionName, divisionAddress, divisionCountry } = toUpdate
        try {
            const response = await service({
                url: constant1.api.division.putdivision,
                method: "PUT",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: { divisionId, orgId, divisionName, divisionAddress, divisionCountry }
            });
            setIsModalOpen(false);
            if (response.status === "FAILURE") {
                setMessage(response.errorMessage)
            } else {
                setMessage('Division Updated Successfully')
            }
            setIsDivUpdated(true)
        } catch (error: any) {
            if (error.status === 400) {
                const { data } = error
                if (data.divisionName) {
                    updateForm.setFields([{ name: 'divisionName', errors: [data.divisionName] }])
                }
                if (data.divisionAddress) {
                    updateForm.setFields([{ name: 'divisionAddress', errors: [data.divisionAddress] }])
                }
                if (data.divisionCountry) {
                    updateForm.setFields([{ name: 'divisionCountry', errors: [data.divisionCountry] }])
                }
                if (data.orgName) {
                    updateForm.setFields([{ name: 'orgName', errors: [data.orgName] }])
                }
            }
            console.log("UpdateDiv:", error);
        }
    };

    const isDivisionUpdated = () => {
        setIsDivUpdated(false)
        window.location.reload()
        // getDivisionData({ ...reqBody, pageOffset: 1 })
        // getCount({ ...reqBody, pageOffset: 1 })
    };

    async function getDivisionData(payload: any) {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        setIsShowSpinner(true)

        try {
            const response = await service({
                url: constants.api.division.getdivision,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });
            setTableData(response);
            setIsShowSpinner(false)
        } catch (error) {
            console.error("getdivision:", error);
            setIsShowSpinner(false)
        }
    }

    const getCount = async (payload: any) => {
        let userInfo: any = Utility.getUserInfo();
        var token = userInfo.authToken;

        try {
            const response = await service({
                url: constants.api.division.getdivisionByCount,
                method: "POST",
                headers: { accept: "*/*", Authorization: "Bearer " + token },
                data: payload
            });
            setTotalCount(response)
        } catch (error) {
            console.log(error);
            setIsShowSpinner(false)
        }
    };

    const handlePagination = (pageNumber: number) => {
        setReqBody({ ...reqBody, pageOffset: pageNumber });
        getDivisionData({ ...reqBody, pageOffset: pageNumber });
        getCount({ ...reqBody, pageOffset: pageNumber });
        setCurrent(pageNumber);
    }

    const onSelect = (getPageSize: string) => {
        const numPageSize = Number(getPageSize)
        setPageSize(numPageSize)
        setCurrent(1);
        setReqBody({ ...reqBody, pageLimit: Number(getPageSize), pageOffset: 1 })
        getDivisionData({ ...reqBody, pageLimit: Number(getPageSize), pageOffset: 1 });
        getCount({ ...reqBody, pageLimit: Number(getPageSize), pageOffset: 1 });
    }

    async function onAddFinish(value: any) {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;

        try {
            const response = await service({
                url: constants.api.division.postdivision,
                method: "POST",
                headers: { accept: "*/*", "content-type": "application/json", Authorization: "Bearer " + token },
                data: value,
            });
            if (response.status === "SUCCESS") {
                setShow(false)
                setMessage('Division Submitted Successfully')
                setIsDivUpdated(true)
            }
            if (response.status === "FAILURE" && response.errorMessage === "Division name already exists") {
                form.setFields([{ name: 'divisionName', errors: [response.errorMessage] }])
            }
        } catch (error: any) {
            if (error.status === 400) {
                const { data } = error
                if (data.divisionName) {
                    form.setFields([{ name: 'divisionName', errors: [data.divisionName] }])
                }
                if (data.divisionAddress) {
                    form.setFields([{ name: 'divisionAddress', errors: [data.divisionAddress] }])
                }
                if (data.divisionCountry) {
                    form.setFields([{ name: 'divisionCountry', errors: [data.divisionCountry] }])
                }
            }
            console.warn(error);
        }
    }

    async function deleteDivList(divisionId: number) {
        setToggleDelete(!toggleDelete)
        setId(divisionId)
    }

    const okDeleteDiv = async () => {
        let userInfo: any = Utility.getUserInfo();
        let token: string = userInfo.authToken;
        setToggleDelete(!toggleDelete)
        try {
            const response = await service({
                url: constants.api.division.deletedivision + id,
                method: "DELETE",
                headers: { accept: "*/*", "content-type": "application/json", Authorization: "Bearer " + token },
            });
            if (response.status === "SUCCESS" || response.status === 1) {
                setTitle("Division Deactivated Successfully")
                setIsDivDeleted(true)
            }
            if (response.status === "FAILURE" || response.status === -1) {
                setMessage(response.errorMessage)
                setTitle(response.errorMessage)
                setIsDivDeleted(true)
            }
        } catch (error) {
            console.warn("deletedivision:", error)
        }
    };

    const okDivDeleted = () => {
        setIsDivDeleted(false)
        window.location.reload()
        // getDivisionData({ ...reqBody, pageOffset: 1 })
        // getCount({ ...reqBody, pageOffset: 1 })
    }

    const sortBy = (id: string) => {
        var getIdboolean
        if (id) getIdboolean = !sort[id]

        switch (id) {
            case 'divisionName':
                if (sort.divisionName) {
                    getDivisionData({ ...reqBody, sortColumns: "divisionName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    getDivisionData({ ...reqBody, sortColumns: "divisionName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'divisionCountry':
                if (sort.divisionCountry) {
                    getDivisionData({ ...reqBody, sortColumns: "divisionCountry", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    getDivisionData({ ...reqBody, sortColumns: "divisionCountry", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'divisionAddress':
                if (sort.divisionAddress) {
                    getDivisionData({ ...reqBody, sortColumns: "divisionAddress", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    getDivisionData({ ...reqBody, sortColumns: "divisionAddress", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break
            case 'orgName':
                if (sort.orgName) {
                    getDivisionData({ ...reqBody, sortColumns: "orgName", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    getDivisionData({ ...reqBody, sortColumns: "orgName", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break

            case 'deleted':
                if (sort.deleted) {
                    getDivisionData({ ...reqBody, sortColumns: "deleted", sortDirection: "ASC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                } else {
                    getDivisionData({ ...reqBody, sortColumns: "deleted", sortDirection: "DESC", pageOffset: 1 })
                    setCurrent(1)
                    setSort({ ...sort, [id]: getIdboolean })
                }
                break

        }
    };

    const handleSearch = (value: string) => {
        let val = value ? value?.trim() : "";
        setDivQuery(val)
        setDivisionEmpty(false)
        setTimeout(() => {
            if (value === "") {
                setReqBody({ ...reqBody, divisionName: "", pageOffset: 1 })
                getDivisionData({ ...reqBody, divisionName: "", pageOffset: 1 })
                setCurrent(1)
            } else {
                setReqBody({ ...reqBody, divisionName: val, pageOffset: 1 })
                getDivisionData({ ...reqBody, divisionName: val, pageOffset: 1 })
                setCurrent(1)
            }
        }, 1000)
    };

    const searchOnSelect = (value: string) => {
        setCurrent(1);
        setReqBody({ ...reqBody, divisionName: value, pageOffset: 1 })
        getDivisionData({ ...reqBody, divisionName: value, pageOffset: 1 })
    };

    const onClearGetCall = () => { }

    const callDivisionApi = () => {
        if (!divQuery) { setDivisionEmpty(true) } else { setDivisionEmpty(false) }
        if (!divQuery) return
        setReqBody({ ...reqBody, divisionName: divQuery, pageOffset: 1 })
        getDivisionData({ ...reqBody, divisionName: divQuery, pageOffset: 1 })
        setCurrent(1)
    }

    const okButtonStyle = {
        background: '#004f9f',
        color: '#fff',
    };

    useEffect(() => {
        let data: any = sessionStorage.getItem('globalData');
        data = data && JSON.parse(data);

        if (refresh === 0 || initial == true) return
        getDivisionData({ ...reqBody, orgId: data?.orgId, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })
        getCount({ ...reqBody, orgId: data?.orgId, divisionId: data?.divId, locationId: data?.locationId, buildingId: data?.buildingId })
    }, [refresh])

    return (
        <>
            <LoadingSpinner isShowSpinner={isShowSpinner}></LoadingSpinner>
            <div className="container-fluid px-0 marginT">
                {localStorage.getItem('permissions')?.includes("divisionadd_W") ? <>
                    <div className=" row nav-filter align-items-center">
                        <div className="col-12  tab_content-battery">
                            <span onClick={showAddUser} className="d-flex align-items-center">
                                {show ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
                                <span className="pl-2 paddingT">Add New Division</span>
                            </span>
                        </div>
                    </div>
                </> : <></>}
                {show ? (
                    <>
                        <div
                            className={show ? "show-user  add-user" : "hide-user add-user"}>
                            <ConfigProvider
                                theme={{ token: { colorPrimary: "#004f9f" } }}>
                                <Form
                                    form={form}
                                    onFinish={onAddFinish}
                                    layout="vertical">
                                    <div className='row'>
                                        <div className='col-6'>
                                            <Form.Item style={{ marginBottom: "5px" }}
                                                label="Organization"
                                                name="orgId"
                                                rules={[{ required: true, message: 'Please Select Organization' }]}
                                            >
                                                <Select  
                                                    dropdownStyle={{ zIndex: 900 }}  
                                                    placeholder="Select Organization">
                                                    {orgList?.map((item: any, index: any) => {
                                                        return <Select.Option key={index} value={item.orgId}>{item.orgName}</Select.Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className='col-6'>
                                            <Form.Item
                                                name="divisionAddress"
                                                label="Address"
                                                style={{ marginBottom: "5px" }}
                                            >
                                                <Input placeholder="Enter Address" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <Form.Item style={{ marginBottom: "5px" }}
                                                label="Division Name"
                                                name="divisionName"
                                                rules={[{ required: true, message: 'Please Enter Division Name' }]}
                                            >
                                                <Input placeholder="Enter Division Name" />
                                            </Form.Item>
                                        </div>
                                        <div className='col-6'>
                                            <Form.Item style={{ marginBottom: "5px" }}
                                                label="Country"
                                                name="divisionCountry"
                                                rules={[{ required: true, message: 'Please Select Country' }]}
                                            >
                                                <Select
                                                    showSearch
                                                    dropdownStyle={{ zIndex: 900 }}
                                                    placeholder="Select Country">
                                                    {CountryList?.map((item: any, index: any) => {
                                                        return <Select.Option name={item} key={index} value={item}>{item}</Select.Option>
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div>
                                            <Form.Item style={{ marginBottom: "0px" }}>
                                                <Button
                                                    onClick={showAddUser}
                                                    style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white", }}>
                                                    Cancel
                                                </Button>
                                            </Form.Item>
                                        </div>
                                        <div className="pl-3">
                                            <Form.Item style={{ marginBottom: "0px" }}>
                                                <Button
                                                    style={{ background: "#004f9f", borderBlockColor: "#004f9f", color: "white" }}
                                                    htmlType="submit">
                                                    Add
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </ConfigProvider>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <ConfigProvider
                theme={{ token: { colorPrimary: "#004f9f" } }}>
                <Modal
                    className="update-users"
                    title="Modify Division Information"
                    centered
                    open={isModalOpen}
                    onCancel={() => setIsModalOpen(false)}
                    onOk={handleUpdate}
                    okText="Update"
                    cancelText="Cancel"
                    okButtonProps={{ style: okButtonStyle }}
                >
                    <Form
                        form={updateForm}
                        layout="vertical"
                    >
                        <div className="row">
                            <div className='col-6'>
                                <Form.Item style={{ marginBottom: "5px" }} label="Organization">
                                    <Select value={toUpdate.orgName}
                                        onChange={(value: any) => {
                                            setToUpdate({ ...toUpdate, orgId: value });
                                        }}
                                        disabled
                                        style={{ marginBottom: "10px" }}
                                        placeholder="Select Organization">
                                        {orgList?.map((item: any, index: any) => {
                                            return <Select.Option key={index} value={item.orgId}>{item.orgName}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-6'>
                                <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    label="Division Name"
                                    rules={[
                                        { required: true, message: "Please Enter Division Name" },
                                    ]}
                                >
                                    <Input
                                        disabled
                                        style={{ marginBottom: "10px" }}
                                        placeholder="Enter Division Name"
                                        value={toUpdate.divisionName}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-6'>
                                <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    label="Address"
                                >
                                    <Input
                                        style={{ marginBottom: "10px" }}
                                        placeholder="Enter Your Address"
                                        value={toUpdate.divisionAddress}
                                        onChange={changeAddress}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Form.Item
                                    style={{ marginBottom: "5px" }}
                                    label="Country">
                                    <Select
                                        showSearch
                                        style={{ marginBottom: "10px" }}
                                        placeholder="Select Country"
                                        value={toUpdate.divisionCountry}
                                        onChange={(value: any) => {
                                            setToUpdate({ ...toUpdate, divisionCountry: value });
                                        }}>
                                        {CountryList?.map((item: any, index: any) => {
                                            return <Select.Option name={item} key={index} value={item}>{item}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Modal>
            </ConfigProvider>

            <ConfigProvider
                theme={{ token: { colorPrimary: '#004f9f' } }}>
                <Modal open={isDivUpdated} closable={false} centered title={message}
                    footer={[<Button style={{ background: '#004f9f', color: "white" }} onClick={isDivisionUpdated}>OK</Button>]}
                ></Modal>
                <Modal open={toggleDelete} okButtonProps={{ color: "#004f9f" }} centered title={"Are You Sure Want To Deactivate"} onOk={okDeleteDiv} onCancel={() => setToggleDelete(false)} ></Modal>
                <Modal open={isDivDeleted} closable={false} centered title={Title}
                    footer={[<Button style={{ background: '#004f9f', color: "white" }} onClick={okDivDeleted}>OK</Button>]}
                ></Modal>
                <Modal okButtonProps={{ color: "#004f9f" }} centered title={Title} open={isOpen} onOk={() => setIsOpen(!isOpen)} onCancel={() => setIsOpen(!isOpen)} ></Modal>
            </ConfigProvider>
            <div ref={filtersRef} className="auto_complete search-org pt-2">
                <AutoComplete
                    size='middle'
                    style={{ width: 300, borderBottomRightRadius: 0, borderTopLeftRadius: 0 }}
                    options={options}
                    onSelect={searchOnSelect}
                    onSearch={handleSearch}
                    allowClear
                    onClear={onClearGetCall}
                    placeholder="Search Division Name"
                />
                <span onClick={callDivisionApi} className="search_icon"><SearchOutlined style={{ color: 'white' }} /></span>
                {
                    divisionEmpty && <div style={{ color: "red" }}>Please Enter Division Name</div>
                }
            </div>
            <div id="reporting_chart5" className="container-fluid pr-0 pl-0">
                <div className="row">
                    <div className="col-12 ">
                        <div className="container-daily-uses mt-2">
                            <div className="bgWhite">
                                <div className="chartTitle py-2 d-flex">
                                    <span>Divisions</span>
                                    <div className='ml-auto show-entries'>
                                        <ShowEntries getPageSize={onSelect} />
                                    </div>
                                </div>
                                <div style={{ height: tableHeight + 'px' }} ref={tabelRef} className="divisionWrapper overflow-auto customScroll">
                                    <table id="" className="billable" style={{ width: "100%" }}>
                                        <thead  className='tableHeader'>
                                            <tr>
                                                <th>
                                                    <div onClick={() => sortBy('orgName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >Organization</span>
                                                        {sort.orgName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div onClick={() => sortBy('divisionName')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >Division Name</span>
                                                        {sort.divisionName ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div onClick={() => sortBy('divisionAddress')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >Address</span>
                                                        {sort.divisionAddress ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div onClick={() => sortBy('divisionCountry')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span >Country</span>
                                                        {sort.divisionCountry ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div onClick={() => sortBy('deleted')} className='d-flex align-items-center justify-content-center' id='firstName' style={{ cursor: 'pointer' }}>
                                                        <span>Status</span>
                                                        {sort.deleted ? < CaretDownOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} /> : < CaretUpOutlined style={{ fontSize: '15px', color: '#004f9f', paddingRight: '0px' }} />}
                                                    </div>
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                tableData?.map((item: any, index: any) => {
                                                    const { divisionId, orgId, orgName, divisionName, divisionAddress, divisionCountry, deleted } = item;
                                                    return (
                                                        <tr key={index}>
                                                            <td>{orgName}</td>
                                                            <td>{divisionName}</td>
                                                            <td>{divisionAddress}</td>
                                                            <td>{divisionCountry}</td>
                                                            <td>{deleted === "Y" ? "Deactive" : "Active"}</td>
                                                            <td className="p-0">
                                                                {localStorage.getItem('permissions')?.includes("divisionedit_U") &&
                                                                    <Tooltip title={`${deleted === 'Y' ? "" : "Update"}`} color="#7a8185">
                                                                        <EditOutlined onClick={() => { if (deleted === 'Y') { } else { showUpdateDiv(item); } }}
                                                                            style={{ fontSize: "15px", color: "#004f9f", paddingRight: "0px", cursor: deleted === 'Y' ? "auto" : "pointer" }} />
                                                                    </Tooltip>}
                                                                {localStorage.getItem('permissions')?.includes("divisionedit_U") &&
                                                                    <Tooltip title={`${deleted === 'Y' ? "" : "Deactivate"}`} color="#7a8185">
                                                                        <DeleteOutlined onClick={() => { if (deleted === 'Y') { } else { deleteDivList(divisionId) } }}
                                                                            style={{ fontSize: "15px", color: "red", paddingRight: "0px", cursor: deleted === 'Y' ? "auto" : "pointer" }} />
                                                                    </Tooltip>}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='text-center pb-2 pt-1'>
                                    <div ref={paginationRef}>
                                        <PaginationComponent
                                            handlePagination={handlePagination}
                                            totalLength={totalCount}
                                            pageSize={pageSize}
                                            current={current}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SessionWarning />
        </>
    );
};
export default DivisionMaintenance;
